import {
  PasswordInput as MantinePasswordInput,
  TextInput as MantineTextInput,
  type TextInputProps as MantineTextInputProps,
} from '@mantine/core'
import classNames from 'classnames'
import { forwardRef, type ForwardedRef } from 'react'

import {
  getKuiInputFieldErrorProps,
  type KuiInputFieldErrorLocation,
} from 'components/kui/_internal/KuiInputWrapper'
import { KuiIcon, type KuiIconType } from 'components/kui/KuiIcon/KuiIcon'
import { type AsProps } from 'utils/types'

export type KuiTextInputProps<AsComponentProps = any> =
  AsProps<AsComponentProps> &
    Pick<
      MantineTextInputProps,
      | 'label'
      | 'description'
      | 'placeholder'
      | 'error'
      | 'disabled'
      | 'withAsterisk'
      | 'variant'
      | 'type'
      | 'autoComplete'
      | 'onClick'
      | 'onBlur'
      | 'onFocus'
      | 'autoFocus'
      | 'onKeyDown'
    > & {
      value: string
      onChange: (nextValue: string) => void

      iconType?: KuiIconType

      errorLocation?: KuiInputFieldErrorLocation
    }

function KuiTextInputImpl<AsComponentProps>(
  {
    value,
    label,
    placeholder = typeof label === 'string'
      ? `Enter ${label.toLowerCase()}`
      : undefined,
    type,
    iconType,
    autoComplete = type !== 'password' ? 'off' : undefined,
    as,
    asProps,
    onChange,
    error,
    errorLocation,
    ...restProps
  }: KuiTextInputProps<AsComponentProps>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const Component =
    type === 'password' ? MantinePasswordInput : MantineTextInput

  function getAutocompleteProps() {
    if (autoComplete === 'off') {
      return {
        'data-1p-ignore': true,
      }
    }

    return {}
  }

  return (
    <Component
      ref={ref}
      type={type}
      component={as}
      {...asProps}
      {...restProps}
      label={label}
      placeholder={placeholder}
      value={value}
      autoComplete={autoComplete}
      className={classNames({
        'sentry-ignore': type === 'password',
        'sentry-mask': type === 'password',
      })}
      leftSection={iconType && <KuiIcon type={iconType} color='hushed' />}
      {...getAutocompleteProps()}
      {...getKuiInputFieldErrorProps({ error, errorLocation })}
      onChange={(event) => onChange(event.currentTarget.value)}
    />
  )
}

export const KuiTextInput = forwardRef(KuiTextInputImpl) as <AsComponentProps>(
  props: KuiTextInputProps<AsComponentProps> & {
    ref?: ForwardedRef<HTMLInputElement>
  }
) => ReturnType<typeof KuiTextInputImpl>
