import { Loader } from '@mantine/core'
import { type ReactNode } from 'react'

import { KuiTooltip } from 'components/kui/KuiTooltip'

import { iconsMap } from './icons'

export type KuiIconType = keyof typeof iconsMap | 'loading_animated'

type KuiIconSize = 'xs' | 's' | 'm'

export const kuiIconSizeMap: Record<KuiIconSize, number> = {
  xs: 12,
  s: 16,
  m: 20,
}

type KuiIconColor =
  | 'inherit'
  | 'text'
  | 'constructive'
  | 'warning'
  | 'destructive'
  | 'hushed'

const colorMap: Record<KuiIconColor, string> = {
  inherit: 'currentColor',
  text: 'var(--mantine-color-text)',
  constructive: 'var(--mantine-color-green-filled)',
  warning: 'var(--mantine-color-yellow-filled)',
  destructive: 'var(--mantine-color-error)',
  hushed: 'var(--mantine-color-dark-2)',
}

type KuiIconProps = {
  type: KuiIconType

  /** @default 's' */
  size?: KuiIconSize

  /** @default 'inherit' */
  color?: KuiIconColor

  tooltipContent?: ReactNode
}

export function KuiIcon({
  type,
  size = 's',
  color = 'inherit',
  tooltipContent,
}: KuiIconProps) {
  const icon = getIcon()

  if (tooltipContent) {
    return <KuiTooltip content={tooltipContent}>{icon}</KuiTooltip>
  }

  return icon

  function getIcon() {
    if (type === 'loading_animated') {
      return <Loader color={colorMap[color]} size={kuiIconSizeMap[size]} />
    }

    const IconComponent = iconsMap[type]

    return (
      <IconComponent
        style={{ width: kuiIconSizeMap[size], height: kuiIconSizeMap[size] }}
        color={colorMap[color]}
      />
    )
  }
}
