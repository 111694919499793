import {
  IconAddressBook,
  IconAlertCircle,
  IconAlignJustified,
  IconArrowLeft,
  IconArrowRight,
  IconArrowsExchange,
  IconBarcode,
  IconBellOff,
  IconBellPlus,
  IconBold,
  IconBuildingBank,
  IconBuildingWarehouse,
  IconCalendar,
  IconCamera,
  IconCancel,
  IconCheck,
  IconCheckbox,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconCirclePlus,
  IconCircleSquare,
  IconContract,
  IconCurrencyDollar,
  IconDotsVertical,
  IconDownload,
  IconEdit,
  IconFile,
  IconFileDollar,
  IconFileInvoice,
  IconFileOff,
  IconFilter,
  IconForklift,
  IconGauge,
  IconGps,
  IconGraph,
  IconGripVertical,
  IconH1,
  IconH2,
  IconH3,
  IconHash,
  IconHome,
  IconInfoCircle,
  IconItalic,
  IconLink,
  IconList,
  IconListCheck,
  IconListDetails,
  IconListNumbers,
  IconLogout,
  IconMail,
  IconMessage,
  IconMinus,
  IconNote,
  IconPackageExport,
  IconPackageImport,
  IconPhone,
  IconPlus,
  IconReceipt,
  IconReportMoney,
  IconRestore,
  IconRoute,
  IconSearch,
  IconSelect,
  IconSettings,
  IconShoppingBag,
  IconShoppingCart,
  IconSignature,
  IconTool,
  IconTrash,
  IconTruckDelivery,
  IconUpload,
  IconUser,
  IconUserCancel,
  IconUsers,
  IconWand,
  IconX,
  IconPencil,
  type Icon,
  type IconProps,
} from '@tabler/icons-react'
import { type ForwardRefExoticComponent, type RefAttributes } from 'react'

export const iconsMap = {
  'address-book': IconAddressBook,
  'alert-circle': IconAlertCircle,
  'arrow-left': IconArrowLeft,
  'arrow-right': IconArrowRight,
  'arrows-exchange': IconArrowsExchange,
  'building-warehouse': IconBuildingWarehouse,
  'chevron-down': IconChevronDown,
  'chevron-left': IconChevronLeft,
  'chevron-right': IconChevronRight,
  'circle-square': IconCircleSquare,
  'currency-dollar': IconCurrencyDollar,
  'dots-vertical': IconDotsVertical,
  'file-dollar': IconFileDollar,
  'file-invoice': IconFileInvoice,
  'file-off': IconFileOff,
  'info-circle': IconInfoCircle,
  'list-numbers': IconListNumbers,
  'report-money': IconReportMoney,
  'shopping-bag': IconShoppingBag,
  'shopping-cart': IconShoppingCart,
  'user-cancel': IconUserCancel,
  'building-bank': IconBuildingBank,
  'truck-delivery': IconTruckDelivery,
  'bell-off': IconBellOff,
  'bell-plus': IconBellPlus,
  'circle-plus': IconCirclePlus,
  'grip-vertical': IconGripVertical,
  'align-justified': IconAlignJustified,
  'list-details': IconListDetails,
  'list-check': IconListCheck,
  'package-export': IconPackageExport,
  'package-import': IconPackageImport,
  pencil: IconPencil,
  barcode: IconBarcode,
  gps: IconGps,
  wand: IconWand,
  home: IconHome,
  minus: IconMinus,
  phone: IconPhone,
  route: IconRoute,
  checkbox: IconCheckbox,
  camera: IconCamera,
  signature: IconSignature,
  select: IconSelect,
  hash: IconHash,
  message: IconMessage,
  file: IconFile,
  upload: IconUpload,
  forklift: IconForklift,
  bold: IconBold,
  calendar: IconCalendar,
  cancel: IconCancel,
  check: IconCheck,
  contract: IconContract,
  edit: IconEdit,
  h1: IconH1,
  h2: IconH2,
  h3: IconH3,
  italic: IconItalic,
  list: IconList,
  mail: IconMail,
  note: IconNote,
  plus: IconPlus,
  receipt: IconReceipt,
  restore: IconRestore,
  settings: IconSettings,
  tool: IconTool,
  trash: IconTrash,
  user: IconUser,
  users: IconUsers,
  x: IconX,
  logout: IconLogout,
  download: IconDownload,
  graph: IconGraph,
  search: IconSearch,
  gauge: IconGauge,
  filter: IconFilter,
  link: IconLink,
} satisfies Record<
  string,
  ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>
>
