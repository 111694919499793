import { useDroppable } from '@dnd-kit/core'
import { Skeleton } from '@mantine/core'
import classNames from 'classnames'
import {
  Fragment,
  type Dispatch,
  type Key,
  type ReactNode,
  type SetStateAction,
} from 'react'
import styled from 'styled-components'

import {
  kuiThemeVars,
  type KuiThemeSpacingSize,
} from 'components/kui/_internal/theme'
import { KuiBadge, type KuiBadgeProps } from 'components/kui/KuiBadge'
import { KuiFlex } from 'components/kui/KuiFlex'
import { KuiPad, mixKuiPad } from 'components/kui/KuiPad'
import {
  KuiSortableList,
  type KuiSortableListProps,
} from 'components/kui/KuiSortableList'
import { KuiStack } from 'components/kui/KuiStack'
import { KuiText } from 'components/kui/KuiText'
import { KuiTextBits } from 'components/kui/KuiTextBits'

import { KuiPagination, type KuiPaginationProps } from './KuiPagination'

const KuiStackedListRoot = styled.div`
  --kui-stacked-list-border-color: var(--mantine-color-gray-3);

  &.KuiStackedListRoot--surface {
    border-radius: 8px;
    border: 1px solid var(--kui-stacked-list-border-color);

    background-color: ${kuiThemeVars.colors.emptyShade};
    overflow: hidden;
  }

  &.KuiStackedListRoot--flush {
    border-top: 1px solid var(--kui-stacked-list-border-color);
    border-bottom: 1px solid var(--kui-stacked-list-border-color);

    background-color: ${kuiThemeVars.colors.emptyShade};
    overflow: hidden;
  }

  &.KuiStackedListRoot--cards {
    display: flex;
    flex-direction: column;
    gap: ${kuiThemeVars.spacingSizes.xs};
  }
`

const KuiStackedListRowRoot = styled.div<{ $gutterSize: KuiThemeSpacingSize }>`
  background-color: ${kuiThemeVars.colors.emptyShade};

  ${(p) => mixKuiPad({ size: 'xs', horizontalSize: p.$gutterSize })}

  &.KuiStackedListRowRoot--hasBorderTop {
    border-top: 1px solid var(--mantine-color-gray-3);
  }

  &.KuiStackedListRowRoot--card {
    border-radius: 8px;
    border: 1px solid var(--mantine-color-gray-3);
    background-color: ${kuiThemeVars.colors.emptyShade};
  }

  &.KuiStackedListRowRoot--clickable:hover {
    cursor: pointer;
    background-color: var(--mantine-color-gray-1);
  }

  &.KuiStackedListRowRoot--draggingOver {
    background-color: var(--mantine-color-gray-1);
  }
`

export type KuiStackedListRow = {
  title: string
  description?: string | { bits: ReactNode[] }

  leftContent?: ReactNode
  rightContent?: ReactNode

  badgeProps?: KuiBadgeProps
}

type KuiTablePaginationProps = KuiPaginationProps & {
  visible: boolean
}

type KuiStackedListVariant = 'surface' | 'flush' | 'cards'

type KuiStackedListSortableProps<TRow> = Pick<
  KuiSortableListProps<TRow>,
  'getItemData' | 'getItemId' | 'getItemIsDisabled' | 'disabled'
> &
  (
    | {
        withContext?: true
        onSort: Dispatch<SetStateAction<TRow[]>>
      }
    | {
        withContext: false
        onSort?: never
      }
  )

export type KuiStackedListProps<TRow> = {
  rows: TRow[]
  getRowKey: (row: TRow) => Key
  renderRow: (row: TRow) => KuiStackedListRow
  pagination?: KuiTablePaginationProps
  loading?: boolean

  /** @default 5 */
  numLoadingRows?: number

  onRowClick?: (row: TRow) => void

  /** @default () => true */
  getRowIsClickable?: (row: TRow) => boolean

  onRowMouseEnter?: (row: TRow) => void
  onRowMouseLeave?: (row: TRow) => void

  /** @default 'Nothing found' */
  emptyState?: ReactNode

  /** @default 'surface' */
  variant?: KuiStackedListVariant

  gutterSize?: KuiThemeSpacingSize

  _getRowDroppableProps?: (
    row: TRow
  ) => { id: string; data?: any; disabled?: boolean } | null

  _sortableProps?: KuiStackedListSortableProps<TRow>
}

export function KuiStackedList<TRow>({
  rows,
  getRowKey,
  renderRow,
  onRowClick,
  getRowIsClickable = () => true,
  onRowMouseEnter,
  onRowMouseLeave,
  pagination: consumerPagination,
  loading,
  numLoadingRows = 5,
  emptyState = 'Nothing found',
  variant = 'surface',
  gutterSize = 'xs',
  _getRowDroppableProps,
  _sortableProps,
}: KuiStackedListProps<TRow>) {
  const pagination = consumerPagination?.visible
    ? consumerPagination
    : undefined

  return (
    <KuiStack gapSize='xs'>
      <KuiStackedListRoot
        className={classNames({
          [`KuiStackedListRoot--${variant}`]: true,
        })}
      >
        {rows.length === 0 && !loading && (
          <KuiStackedListRowRoot
            $gutterSize={gutterSize}
            className={classNames({
              'KuiStackedListRowRoot--card': variant === 'cards',
            })}
          >
            <KuiText.p color='hushed'>{emptyState}</KuiText.p>
          </KuiStackedListRowRoot>
        )}

        {loading && (
          <KuiStackedListLoadingRows
            numRows={numLoadingRows}
            variant={variant}
            gutterSize={gutterSize}
          />
        )}

        {!loading && _sortableProps && rows.length > 0 && (
          <KuiSortableList
            {..._sortableProps}
            items={rows}
            renderItem={({ item, dragHandle }) => (
              <KuiStackedListRow
                row={item}
                dragHandle={dragHandle}
                gutterSize={gutterSize}
                variant={variant}
                renderRow={renderRow}
                getRowIsClickable={getRowIsClickable}
                onRowClick={onRowClick}
                onRowMouseEnter={onRowMouseEnter}
                onRowMouseLeave={onRowMouseLeave}
              />
            )}
          />
        )}

        {!loading &&
          !_sortableProps &&
          rows.length > 0 &&
          rows.map((row, index) => {
            const droppableProps = _getRowDroppableProps?.(row)

            const commonProps = {
              index,
              variant,
              row,
              gutterSize,
              renderRow,
              getRowIsClickable,
              onRowClick,
              onRowMouseEnter,
              onRowMouseLeave,
            }

            if (droppableProps) {
              return (
                <Droppable
                  key={getRowKey(row)}
                  {...droppableProps}
                  render={({ isOver }) => (
                    <KuiStackedListRow {...commonProps} draggingOver={isOver} />
                  )}
                />
              )
            }

            return <KuiStackedListRow key={getRowKey(row)} {...commonProps} />
          })}
      </KuiStackedListRoot>

      {pagination && (
        <KuiPad
          bottomSize={variant === 'flush' ? 'xs' : 'none'}
          horizontalSize={gutterSize}
        >
          <KuiFlex justifyContent='flexEnd'>
            <KuiPagination {...pagination} />
          </KuiFlex>
        </KuiPad>
      )}
    </KuiStack>
  )
}

type KuiStackedListRowProps<TRow> = Pick<
  KuiStackedListProps<TRow>,
  | 'renderRow'
  | 'onRowClick'
  | 'getRowIsClickable'
  | 'onRowMouseEnter'
  | 'onRowMouseLeave'
> &
  Required<Pick<KuiStackedListProps<TRow>, 'gutterSize' | 'variant'>> & {
    row: TRow
    index?: number
    draggingOver?: boolean
    dragHandle?: ReactNode
  }

export function KuiStackedListRow<TRow>({
  index,
  row,
  variant,
  dragHandle,
  gutterSize,
  draggingOver = false,
  renderRow,
  getRowIsClickable = () => true,
  onRowClick,
  onRowMouseEnter,
  onRowMouseLeave,
}: KuiStackedListRowProps<TRow>) {
  const { title, description, badgeProps, leftContent, rightContent } =
    renderRow(row)

  return (
    <KuiStackedListRowRoot
      $gutterSize={gutterSize}
      className={classNames({
        'KuiStackedListRowRoot--card': variant === 'cards',
        'KuiStackedListRowRoot--hasBorderTop':
          index !== undefined && index !== 0,
        'KuiStackedListRowRoot--clickable':
          onRowClick && getRowIsClickable(row),
        'KuiStackedListRowRoot--draggingOver': draggingOver,
      })}
      onClick={
        onRowClick && getRowIsClickable(row) ? () => onRowClick(row) : undefined
      }
      onMouseEnter={onRowMouseEnter ? () => onRowMouseEnter(row) : undefined}
      onMouseLeave={onRowMouseLeave ? () => onRowMouseLeave(row) : undefined}
    >
      <KuiFlex justifyContent='spaceBetween' gapSize='xs'>
        {dragHandle ?? leftContent}

        <KuiFlex.Item grow={1}>
          <KuiFlex gapSize='xs'>
            <KuiText.p size='sm' truncate={true}>
              {title}
            </KuiText.p>

            {badgeProps && (
              <KuiFlex shrink={0}>
                <KuiBadge {...badgeProps} />
              </KuiFlex>
            )}
          </KuiFlex>

          {description && (
            <KuiTextBits
              as='div'
              size='xs'
              color='hushed'
              bits={
                typeof description === 'string'
                  ? [description]
                  : description.bits
              }
            />
          )}
        </KuiFlex.Item>

        <KuiText.p size='sm'>{rightContent}</KuiText.p>
      </KuiFlex>
    </KuiStackedListRowRoot>
  )
}

function KuiStackedListLoadingRows<TRow>({
  numRows,
  variant,
  gutterSize,
}: Required<Pick<KuiStackedListProps<TRow>, 'gutterSize' | 'variant'>> & {
  numRows: number
}) {
  return (
    <Fragment>
      {Array.from({ length: numRows }).map((_, index) => (
        <KuiStackedListRowRoot
          $gutterSize={gutterSize}
          key={index}
          className={classNames({
            'KuiStackedListRowRoot--card': variant === 'cards',
            'KuiStackedListRowRoot--hasBorderTop': index !== 0,
          })}
        >
          <KuiFlex justifyContent='spaceBetween'>
            <Skeleton>
              <KuiText.p size='sm'>Loading...</KuiText.p>

              <KuiTextBits
                as='div'
                size='xs'
                color='hushed'
                bits={['Loading…']}
              />
            </Skeleton>
          </KuiFlex>
        </KuiStackedListRowRoot>
      ))}
    </Fragment>
  )
}

function Droppable({
  id,
  data,
  disabled,
  render,
}: {
  id: string
  data?: any
  disabled?: boolean
  render: (_: { isOver: boolean }) => ReactNode
}) {
  const { isOver, setNodeRef } = useDroppable({
    id,
    data,
    disabled,
  })

  return <div ref={setNodeRef}>{render({ isOver })}</div>
}
