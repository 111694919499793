import { lazily } from 'react-lazily'

import { getGetKlassQueryOptions } from '_autogenerated'
import { klassUrls } from 'routes/urls'
import { createRoute } from 'routing'

const {
  KlassListPage,
  KlassCreatePage,
  KlassDetailPageRoot,
  KlassDetailPageAssetsTab,
  KlassDetailPageDetailsTab,
  KlassDetailPageOrdersTab,
  KlassDetailPagePurchaseOrdersTab,
  KlassEditPage,
} = lazily(() => import('./components'))

export const klassRoutes = [
  createRoute({
    route: klassUrls,
    Component: KlassListPage,
  }),
  createRoute({
    route: klassUrls.create,
    Component: KlassCreatePage,
  }),
  createRoute({
    route: klassUrls.detail,
    Component: KlassDetailPageRoot,
    prefetch: ({ id }) => [getGetKlassQueryOptions(id)],
    children: [
      createRoute({
        route: klassUrls.detail,
        Component: KlassDetailPageDetailsTab,
      }),
      createRoute({
        route: klassUrls.detail.assets,
        Component: KlassDetailPageAssetsTab,
      }),
      createRoute({
        route: klassUrls.detail.orders,
        Component: KlassDetailPageOrdersTab,
      }),
      createRoute({
        route: klassUrls.detail.purchaseOrders,
        Component: KlassDetailPagePurchaseOrdersTab,
      }),
    ],
  }),
  createRoute({
    route: klassUrls.edit,
    prefetch: ({ id }) => [getGetKlassQueryOptions(id)],
    Component: KlassEditPage,
  }),
]
