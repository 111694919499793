import {
  ActionIcon as MantineActionIcon,
  Button as MantineButton,
  type MantineStyleProp,
  type MantineColor,
  type MantineSize,
} from '@mantine/core'
import classNames from 'classnames'
import {
  forwardRef,
  useState,
  type ButtonHTMLAttributes,
  type ReactNode,
} from 'react'
import styled from 'styled-components'

import { KuiIcon, kuiIconSizeMap, type KuiIconType } from './KuiIcon/KuiIcon'
import { getAsLinkMantineProps, type MaybeLinkProps } from './KuiLink'
import { KuiTooltip } from './KuiTooltip'
import { wrapKuiApiRequest } from './wrapKuiApiRequest'

type KuiButtonVariant = 'subtle' | 'outline' | 'filled' | 'transparent'

type KuiButtonColor = 'primary' | 'accent' | 'destructive'

const colorMap: Record<KuiButtonColor, MantineColor> = {
  primary: 'dark',
  accent: 'blue',
  destructive: 'red',
}

type KuiButtonSize = 'xs' | 's' | 'm'

const sizeMap: Record<KuiButtonSize, MantineSize> = {
  xs: 'xs',
  s: 'sm',
  m: 'md',
}

const iconSizeMap: Record<KuiButtonSize, string> = {
  xs: '30px',
  s: '36px',
  m: '42px',
}

type KuiButtonIconSide = 'left' | 'right'

const KuiButtonRoot = styled(MantineButton)`
  &.KuiButtonRoot--variant--transparent:hover {
    text-decoration: underline;
  }
`

export type KuiButtonProps = Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'aria-label' | 'className' | 'type' | 'disabled'
> &
  MaybeLinkProps & {
    /** @default 'subtle' */
    variant?: KuiButtonVariant

    /** @default 'primary' */
    color?: KuiButtonColor

    /** @default 's' */
    size?: KuiButtonSize

    /** @default false */
    fullWidth?: boolean

    iconType?: KuiIconType

    /** @default 'left' */
    iconSide?: KuiButtonIconSide

    /** @default false */
    loading?: boolean

    onClick?: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => unknown | Promise<unknown>

    tooltipContent?: string

    children?: ReactNode

    _leftAlign?: boolean

    _compactIconButton?: boolean

    _style?: MantineStyleProp
  }

export const KuiButton = forwardRef<HTMLButtonElement, KuiButtonProps>(
  (
    {
      variant: consumerVariant = 'subtle',
      color = 'primary',
      size = 's',
      fullWidth = false,
      loading: consumerLoading = false,
      iconType,
      iconSide = 'left',
      children,
      to,
      href,
      state,
      tooltipContent,
      onClick: consumerOnClick,
      _leftAlign = false,
      _compactIconButton = false,
      _style,
      ...restProps
    },
    ref
  ) => {
    const iconOnly = iconType && !children
    const variant = getVariant()
    const asLinkProps = getAsLinkMantineProps({ to, state, href })

    const [internalLoading, setLoading] = useState(false)
    const loading = consumerLoading || internalLoading

    const onClick = getOnClick(consumerOnClick)

    const commonProps = {
      variant,
      color: colorMap[color],
      loading,
      onClick,
      ...asLinkProps,
      ...restProps,
    }

    if (iconOnly) {
      return (
        <KuiTooltip content={tooltipContent}>
          <MantineActionIcon
            ref={ref}
            {...commonProps}
            size={_compactIconButton ? '24px' : iconSizeMap[size]}
            p={variant === 'transparent' ? 0 : undefined}
            loaderProps={{ size: kuiIconSizeMap.s }}
            style={_style}
          >
            <KuiIcon type={iconType} size='s' />
          </MantineActionIcon>
        </KuiTooltip>
      )
    }

    return (
      <KuiTooltip content={tooltipContent}>
        <KuiButtonRoot
          ref={ref}
          className={classNames({
            'KuiButtonRoot--variant--transparent': variant === 'transparent',
          })}
          {...commonProps}
          size={sizeMap[size]}
          fullWidth={fullWidth}
          leftSection={
            iconType && iconSide === 'left' && <KuiIcon type={iconType} />
          }
          rightSection={
            iconType && iconSide === 'right' && <KuiIcon type={iconType} />
          }
          p={variant === 'transparent' ? 0 : undefined}
          justify={_leftAlign ? 'flex-start' : undefined}
          style={_style}
        >
          {children}
        </KuiButtonRoot>
      </KuiTooltip>
    )

    function getVariant() {
      if (consumerVariant === 'outline' && color === 'primary') {
        return 'default'
      }

      return consumerVariant
    }

    function getOnClick(consumerOnClick: KuiButtonProps['onClick']) {
      if (!consumerOnClick) {
        return
      }

      return async function (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) {
        const maybePromise = consumerOnClick(event)

        if (maybePromise instanceof Promise) {
          try {
            setLoading(true)
            await wrapKuiApiRequest(() => Promise.resolve(maybePromise))()
          } finally {
            setLoading(false)
          }
        }
      }
    }
  }
)
