/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  CustomFormConfigurationsDumpSchema,
  CustomFormConfigurationsUpdateSchema,
  CustomFormCreateSchema,
  CustomFormDumpSchema,
  CustomFormResponseCreateSchema,
  CustomFormResponseDumpSchema,
  CustomFormResponseUpdateSchema,
  CustomFormUpdateSchema,
  GetCustomFormsParams,
  PaginatedCustomFormSchema
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getCustomForms = (
    params?: GetCustomFormsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedCustomFormSchema>(
      {url: `/custom_forms`, method: 'GET',
        params, signal
    },
      options);
    }


export const getGetCustomFormsQueryKey = (params?: GetCustomFormsParams,) => {
    return [`/custom_forms`, ...(params ? [params]: [])] as const;
    }


export const getGetCustomFormsQueryOptions = <TData = Awaited<ReturnType<typeof getCustomForms>>, TError = ErrorType<unknown>>(params?: GetCustomFormsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomFormsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomForms>>> = ({ signal }) => getCustomForms(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomFormsQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomForms>>>
export type GetCustomFormsQueryError = ErrorType<unknown>


export function useGetCustomForms<TData = Awaited<ReturnType<typeof getCustomForms>>, TError = ErrorType<unknown>>(
 params: undefined |  GetCustomFormsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustomForms>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomForms<TData = Awaited<ReturnType<typeof getCustomForms>>, TError = ErrorType<unknown>>(
 params?: GetCustomFormsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustomForms>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomForms<TData = Awaited<ReturnType<typeof getCustomForms>>, TError = ErrorType<unknown>>(
 params?: GetCustomFormsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCustomForms<TData = Awaited<ReturnType<typeof getCustomForms>>, TError = ErrorType<unknown>>(
 params?: GetCustomFormsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCustomFormsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCustomFormsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCustomForms>>, TError = ErrorType<unknown>>(params?: GetCustomFormsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomFormsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomForms>>> = ({ signal }) => getCustomForms(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomFormsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomForms>>>
export type GetCustomFormsSuspenseQueryError = ErrorType<unknown>


export function useGetCustomFormsSuspense<TData = Awaited<ReturnType<typeof getCustomForms>>, TError = ErrorType<unknown>>(
 params: undefined |  GetCustomFormsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormsSuspense<TData = Awaited<ReturnType<typeof getCustomForms>>, TError = ErrorType<unknown>>(
 params?: GetCustomFormsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormsSuspense<TData = Awaited<ReturnType<typeof getCustomForms>>, TError = ErrorType<unknown>>(
 params?: GetCustomFormsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCustomFormsSuspense<TData = Awaited<ReturnType<typeof getCustomForms>>, TError = ErrorType<unknown>>(
 params?: GetCustomFormsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForms>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCustomFormsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createCustomForm = (
    customFormCreateSchema: BodyType<CustomFormCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CustomFormDumpSchema>(
      {url: `/custom_forms`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: customFormCreateSchema
    },
      options);
    }


export const getCustomForm = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<CustomFormDumpSchema>(
      {url: `/custom_forms/${id}`, method: 'GET', signal
    },
      options);
    }


export const getGetCustomFormQueryKey = (id: string,) => {
    return [`/custom_forms/${id}`] as const;
    }


export const getGetCustomFormQueryOptions = <TData = Awaited<ReturnType<typeof getCustomForm>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomFormQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomForm>>> = ({ signal }) => getCustomForm(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomFormQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomForm>>>
export type GetCustomFormQueryError = ErrorType<unknown>


export function useGetCustomForm<TData = Awaited<ReturnType<typeof getCustomForm>>, TError = ErrorType<unknown>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustomForm>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomForm<TData = Awaited<ReturnType<typeof getCustomForm>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustomForm>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomForm<TData = Awaited<ReturnType<typeof getCustomForm>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCustomForm<TData = Awaited<ReturnType<typeof getCustomForm>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCustomFormQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCustomFormSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCustomForm>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomFormQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomForm>>> = ({ signal }) => getCustomForm(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomFormSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomForm>>>
export type GetCustomFormSuspenseQueryError = ErrorType<unknown>


export function useGetCustomFormSuspense<TData = Awaited<ReturnType<typeof getCustomForm>>, TError = ErrorType<unknown>>(
 id: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormSuspense<TData = Awaited<ReturnType<typeof getCustomForm>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormSuspense<TData = Awaited<ReturnType<typeof getCustomForm>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCustomFormSuspense<TData = Awaited<ReturnType<typeof getCustomForm>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomForm>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCustomFormSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateCustomForm = (
    id: string,
    customFormUpdateSchema: BodyType<CustomFormUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CustomFormDumpSchema>(
      {url: `/custom_forms/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: customFormUpdateSchema
    },
      options);
    }


export const createCustomFormResponse = (
    id: string,
    customFormResponseCreateSchema: BodyType<CustomFormResponseCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CustomFormResponseDumpSchema>(
      {url: `/custom_forms/${id}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: customFormResponseCreateSchema
    },
      options);
    }


export const getCustomFormSettings = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<CustomFormConfigurationsDumpSchema>(
      {url: `/custom_forms/settings`, method: 'GET', signal
    },
      options);
    }


export const getGetCustomFormSettingsQueryKey = () => {
    return [`/custom_forms/settings`] as const;
    }


export const getGetCustomFormSettingsQueryOptions = <TData = Awaited<ReturnType<typeof getCustomFormSettings>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomFormSettingsQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomFormSettings>>> = ({ signal }) => getCustomFormSettings(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomFormSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomFormSettings>>>
export type GetCustomFormSettingsQueryError = ErrorType<unknown>


export function useGetCustomFormSettings<TData = Awaited<ReturnType<typeof getCustomFormSettings>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustomFormSettings>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormSettings<TData = Awaited<ReturnType<typeof getCustomFormSettings>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustomFormSettings>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormSettings<TData = Awaited<ReturnType<typeof getCustomFormSettings>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCustomFormSettings<TData = Awaited<ReturnType<typeof getCustomFormSettings>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCustomFormSettingsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCustomFormSettingsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCustomFormSettings>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomFormSettingsQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomFormSettings>>> = ({ signal }) => getCustomFormSettings(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomFormSettingsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomFormSettings>>>
export type GetCustomFormSettingsSuspenseQueryError = ErrorType<unknown>


export function useGetCustomFormSettingsSuspense<TData = Awaited<ReturnType<typeof getCustomFormSettings>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormSettingsSuspense<TData = Awaited<ReturnType<typeof getCustomFormSettings>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormSettingsSuspense<TData = Awaited<ReturnType<typeof getCustomFormSettings>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCustomFormSettingsSuspense<TData = Awaited<ReturnType<typeof getCustomFormSettings>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCustomFormSettingsSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateCustomFormSettings = (
    customFormConfigurationsUpdateSchema: BodyType<CustomFormConfigurationsUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CustomFormConfigurationsDumpSchema>(
      {url: `/custom_forms/settings`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: customFormConfigurationsUpdateSchema
    },
      options);
    }


export const getCustomFormResponse = (
    responseId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<CustomFormResponseDumpSchema>(
      {url: `/custom_forms/responses/${responseId}`, method: 'GET', signal
    },
      options);
    }


export const getGetCustomFormResponseQueryKey = (responseId: string,) => {
    return [`/custom_forms/responses/${responseId}`] as const;
    }


export const getGetCustomFormResponseQueryOptions = <TData = Awaited<ReturnType<typeof getCustomFormResponse>>, TError = ErrorType<unknown>>(responseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomFormResponseQueryKey(responseId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomFormResponse>>> = ({ signal }) => getCustomFormResponse(responseId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(responseId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomFormResponseQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomFormResponse>>>
export type GetCustomFormResponseQueryError = ErrorType<unknown>


export function useGetCustomFormResponse<TData = Awaited<ReturnType<typeof getCustomFormResponse>>, TError = ErrorType<unknown>>(
 responseId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustomFormResponse>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormResponse<TData = Awaited<ReturnType<typeof getCustomFormResponse>>, TError = ErrorType<unknown>>(
 responseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustomFormResponse>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormResponse<TData = Awaited<ReturnType<typeof getCustomFormResponse>>, TError = ErrorType<unknown>>(
 responseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCustomFormResponse<TData = Awaited<ReturnType<typeof getCustomFormResponse>>, TError = ErrorType<unknown>>(
 responseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCustomFormResponseQueryOptions(responseId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCustomFormResponseSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCustomFormResponse>>, TError = ErrorType<unknown>>(responseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomFormResponseQueryKey(responseId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomFormResponse>>> = ({ signal }) => getCustomFormResponse(responseId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(responseId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomFormResponseSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomFormResponse>>>
export type GetCustomFormResponseSuspenseQueryError = ErrorType<unknown>


export function useGetCustomFormResponseSuspense<TData = Awaited<ReturnType<typeof getCustomFormResponse>>, TError = ErrorType<unknown>>(
 responseId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormResponseSuspense<TData = Awaited<ReturnType<typeof getCustomFormResponse>>, TError = ErrorType<unknown>>(
 responseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustomFormResponseSuspense<TData = Awaited<ReturnType<typeof getCustomFormResponse>>, TError = ErrorType<unknown>>(
 responseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCustomFormResponseSuspense<TData = Awaited<ReturnType<typeof getCustomFormResponse>>, TError = ErrorType<unknown>>(
 responseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomFormResponse>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCustomFormResponseSuspenseQueryOptions(responseId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateCustomFormResponse = (
    responseId: string,
    customFormResponseUpdateSchema: BodyType<CustomFormResponseUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CustomFormResponseDumpSchema>(
      {url: `/custom_forms/responses/${responseId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: customFormResponseUpdateSchema
    },
      options);
    }


