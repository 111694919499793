/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  AssetListSchema,
  AssetSelectionSchema,
  ItemReceiptCreateSchema,
  ItemReceiptHydratedDumpSchema,
  ItemReceiptListParams,
  ItemReceiptUpdateSchema,
  PaginatedItemReceiptSchema,
  PaginatedPurchaseOrderSchema,
  PaginatedVendorBillSchema,
  PaginatedVendorSchema,
  PurchaseOrderCreateSchema,
  PurchaseOrderHydratedDumpSchema,
  PurchaseOrderListParams,
  PurchaseOrderUpdateSchema,
  ReceivingSchema,
  SuccessResponse,
  VendorBillCreateSchema,
  VendorBillHydratedDumpSchema,
  VendorBillListParams,
  VendorBillUpdateSchema,
  VendorCreateSchema,
  VendorDumpSchema,
  VendorListParams
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const vendorList = (
    params?: VendorListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedVendorSchema>(
      {url: `/purchasing/vendors`, method: 'GET',
        params, signal
    },
      options);
    }


export const getVendorListQueryKey = (params?: VendorListParams,) => {
    return [`/purchasing/vendors`, ...(params ? [params]: [])] as const;
    }


export const getVendorListQueryOptions = <TData = Awaited<ReturnType<typeof vendorList>>, TError = ErrorType<unknown>>(params?: VendorListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorList>>> = ({ signal }) => vendorList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorListQueryResult = NonNullable<Awaited<ReturnType<typeof vendorList>>>
export type VendorListQueryError = ErrorType<unknown>


export function useVendorList<TData = Awaited<ReturnType<typeof vendorList>>, TError = ErrorType<unknown>>(
 params: undefined |  VendorListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof vendorList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorList<TData = Awaited<ReturnType<typeof vendorList>>, TError = ErrorType<unknown>>(
 params?: VendorListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof vendorList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorList<TData = Awaited<ReturnType<typeof vendorList>>, TError = ErrorType<unknown>>(
 params?: VendorListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useVendorList<TData = Awaited<ReturnType<typeof vendorList>>, TError = ErrorType<unknown>>(
 params?: VendorListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getVendorListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getVendorListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof vendorList>>, TError = ErrorType<unknown>>(params?: VendorListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorList>>> = ({ signal }) => vendorList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof vendorList>>>
export type VendorListSuspenseQueryError = ErrorType<unknown>


export function useVendorListSuspense<TData = Awaited<ReturnType<typeof vendorList>>, TError = ErrorType<unknown>>(
 params: undefined |  VendorListParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorListSuspense<TData = Awaited<ReturnType<typeof vendorList>>, TError = ErrorType<unknown>>(
 params?: VendorListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorListSuspense<TData = Awaited<ReturnType<typeof vendorList>>, TError = ErrorType<unknown>>(
 params?: VendorListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useVendorListSuspense<TData = Awaited<ReturnType<typeof vendorList>>, TError = ErrorType<unknown>>(
 params?: VendorListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getVendorListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const vendorCreate = (
    vendorCreateSchema: BodyType<VendorCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<VendorDumpSchema>(
      {url: `/purchasing/vendors`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: vendorCreateSchema
    },
      options);
    }


export const vendorGet = (
    vendorId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<VendorDumpSchema>(
      {url: `/purchasing/vendors/${vendorId}`, method: 'GET', signal
    },
      options);
    }


export const getVendorGetQueryKey = (vendorId: string,) => {
    return [`/purchasing/vendors/${vendorId}`] as const;
    }


export const getVendorGetQueryOptions = <TData = Awaited<ReturnType<typeof vendorGet>>, TError = ErrorType<unknown>>(vendorId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorGetQueryKey(vendorId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorGet>>> = ({ signal }) => vendorGet(vendorId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(vendorId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorGetQueryResult = NonNullable<Awaited<ReturnType<typeof vendorGet>>>
export type VendorGetQueryError = ErrorType<unknown>


export function useVendorGet<TData = Awaited<ReturnType<typeof vendorGet>>, TError = ErrorType<unknown>>(
 vendorId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof vendorGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorGet<TData = Awaited<ReturnType<typeof vendorGet>>, TError = ErrorType<unknown>>(
 vendorId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof vendorGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorGet<TData = Awaited<ReturnType<typeof vendorGet>>, TError = ErrorType<unknown>>(
 vendorId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useVendorGet<TData = Awaited<ReturnType<typeof vendorGet>>, TError = ErrorType<unknown>>(
 vendorId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getVendorGetQueryOptions(vendorId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getVendorGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof vendorGet>>, TError = ErrorType<unknown>>(vendorId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorGetQueryKey(vendorId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorGet>>> = ({ signal }) => vendorGet(vendorId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(vendorId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof vendorGet>>>
export type VendorGetSuspenseQueryError = ErrorType<unknown>


export function useVendorGetSuspense<TData = Awaited<ReturnType<typeof vendorGet>>, TError = ErrorType<unknown>>(
 vendorId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorGetSuspense<TData = Awaited<ReturnType<typeof vendorGet>>, TError = ErrorType<unknown>>(
 vendorId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorGetSuspense<TData = Awaited<ReturnType<typeof vendorGet>>, TError = ErrorType<unknown>>(
 vendorId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useVendorGetSuspense<TData = Awaited<ReturnType<typeof vendorGet>>, TError = ErrorType<unknown>>(
 vendorId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getVendorGetSuspenseQueryOptions(vendorId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const vendorUpdate = (
    vendorId: string,
    vendorCreateSchema: BodyType<VendorCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<VendorDumpSchema>(
      {url: `/purchasing/vendors/${vendorId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: vendorCreateSchema
    },
      options);
    }


export const vendorDelete = (
    vendorId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/purchasing/vendors/${vendorId}`, method: 'DELETE'
    },
      options);
    }


export const purchaseOrderList = (
    params?: PurchaseOrderListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedPurchaseOrderSchema>(
      {url: `/purchasing/purchase-orders`, method: 'GET',
        params, signal
    },
      options);
    }


export const getPurchaseOrderListQueryKey = (params?: PurchaseOrderListParams,) => {
    return [`/purchasing/purchase-orders`, ...(params ? [params]: [])] as const;
    }


export const getPurchaseOrderListQueryOptions = <TData = Awaited<ReturnType<typeof purchaseOrderList>>, TError = ErrorType<unknown>>(params?: PurchaseOrderListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPurchaseOrderListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof purchaseOrderList>>> = ({ signal }) => purchaseOrderList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData> & { queryKey: QueryKey }
}

export type PurchaseOrderListQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseOrderList>>>
export type PurchaseOrderListQueryError = ErrorType<unknown>


export function usePurchaseOrderList<TData = Awaited<ReturnType<typeof purchaseOrderList>>, TError = ErrorType<unknown>>(
 params: undefined |  PurchaseOrderListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof purchaseOrderList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePurchaseOrderList<TData = Awaited<ReturnType<typeof purchaseOrderList>>, TError = ErrorType<unknown>>(
 params?: PurchaseOrderListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof purchaseOrderList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePurchaseOrderList<TData = Awaited<ReturnType<typeof purchaseOrderList>>, TError = ErrorType<unknown>>(
 params?: PurchaseOrderListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePurchaseOrderList<TData = Awaited<ReturnType<typeof purchaseOrderList>>, TError = ErrorType<unknown>>(
 params?: PurchaseOrderListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPurchaseOrderListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPurchaseOrderListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof purchaseOrderList>>, TError = ErrorType<unknown>>(params?: PurchaseOrderListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPurchaseOrderListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof purchaseOrderList>>> = ({ signal }) => purchaseOrderList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData> & { queryKey: QueryKey }
}

export type PurchaseOrderListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseOrderList>>>
export type PurchaseOrderListSuspenseQueryError = ErrorType<unknown>


export function usePurchaseOrderListSuspense<TData = Awaited<ReturnType<typeof purchaseOrderList>>, TError = ErrorType<unknown>>(
 params: undefined |  PurchaseOrderListParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePurchaseOrderListSuspense<TData = Awaited<ReturnType<typeof purchaseOrderList>>, TError = ErrorType<unknown>>(
 params?: PurchaseOrderListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePurchaseOrderListSuspense<TData = Awaited<ReturnType<typeof purchaseOrderList>>, TError = ErrorType<unknown>>(
 params?: PurchaseOrderListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePurchaseOrderListSuspense<TData = Awaited<ReturnType<typeof purchaseOrderList>>, TError = ErrorType<unknown>>(
 params?: PurchaseOrderListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPurchaseOrderListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const purchaseOrderCreate = (
    purchaseOrderCreateSchema: BodyType<PurchaseOrderCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PurchaseOrderHydratedDumpSchema>(
      {url: `/purchasing/purchase-orders`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: purchaseOrderCreateSchema
    },
      options);
    }


export const purchaseOrderGet = (
    purchaseOrderId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PurchaseOrderHydratedDumpSchema>(
      {url: `/purchasing/purchase-orders/${purchaseOrderId}`, method: 'GET', signal
    },
      options);
    }


export const getPurchaseOrderGetQueryKey = (purchaseOrderId: string,) => {
    return [`/purchasing/purchase-orders/${purchaseOrderId}`] as const;
    }


export const getPurchaseOrderGetQueryOptions = <TData = Awaited<ReturnType<typeof purchaseOrderGet>>, TError = ErrorType<unknown>>(purchaseOrderId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPurchaseOrderGetQueryKey(purchaseOrderId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof purchaseOrderGet>>> = ({ signal }) => purchaseOrderGet(purchaseOrderId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(purchaseOrderId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PurchaseOrderGetQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseOrderGet>>>
export type PurchaseOrderGetQueryError = ErrorType<unknown>


export function usePurchaseOrderGet<TData = Awaited<ReturnType<typeof purchaseOrderGet>>, TError = ErrorType<unknown>>(
 purchaseOrderId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof purchaseOrderGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePurchaseOrderGet<TData = Awaited<ReturnType<typeof purchaseOrderGet>>, TError = ErrorType<unknown>>(
 purchaseOrderId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof purchaseOrderGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePurchaseOrderGet<TData = Awaited<ReturnType<typeof purchaseOrderGet>>, TError = ErrorType<unknown>>(
 purchaseOrderId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePurchaseOrderGet<TData = Awaited<ReturnType<typeof purchaseOrderGet>>, TError = ErrorType<unknown>>(
 purchaseOrderId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPurchaseOrderGetQueryOptions(purchaseOrderId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPurchaseOrderGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof purchaseOrderGet>>, TError = ErrorType<unknown>>(purchaseOrderId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPurchaseOrderGetQueryKey(purchaseOrderId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof purchaseOrderGet>>> = ({ signal }) => purchaseOrderGet(purchaseOrderId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(purchaseOrderId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PurchaseOrderGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseOrderGet>>>
export type PurchaseOrderGetSuspenseQueryError = ErrorType<unknown>


export function usePurchaseOrderGetSuspense<TData = Awaited<ReturnType<typeof purchaseOrderGet>>, TError = ErrorType<unknown>>(
 purchaseOrderId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePurchaseOrderGetSuspense<TData = Awaited<ReturnType<typeof purchaseOrderGet>>, TError = ErrorType<unknown>>(
 purchaseOrderId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePurchaseOrderGetSuspense<TData = Awaited<ReturnType<typeof purchaseOrderGet>>, TError = ErrorType<unknown>>(
 purchaseOrderId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePurchaseOrderGetSuspense<TData = Awaited<ReturnType<typeof purchaseOrderGet>>, TError = ErrorType<unknown>>(
 purchaseOrderId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof purchaseOrderGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPurchaseOrderGetSuspenseQueryOptions(purchaseOrderId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const purchaseOrderUpdate = (
    purchaseOrderId: string,
    purchaseOrderUpdateSchema: BodyType<PurchaseOrderUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PurchaseOrderHydratedDumpSchema>(
      {url: `/purchasing/purchase-orders/${purchaseOrderId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: purchaseOrderUpdateSchema
    },
      options);
    }


export const getAssetsForSerializationRoute = (
    assetSelectionSchema: BodyType<AssetSelectionSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<AssetListSchema>(
      {url: `/purchasing/purchase-orders/serialization/search`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assetSelectionSchema
    },
      options);
    }


export const serialization = (
    receivingSchema: BodyType<ReceivingSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/purchasing/purchase-orders/serialization/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: receivingSchema
    },
      options);
    }


export const vendorBillList = (
    params?: VendorBillListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedVendorBillSchema>(
      {url: `/purchasing/vendor-bills`, method: 'GET',
        params, signal
    },
      options);
    }


export const getVendorBillListQueryKey = (params?: VendorBillListParams,) => {
    return [`/purchasing/vendor-bills`, ...(params ? [params]: [])] as const;
    }


export const getVendorBillListQueryOptions = <TData = Awaited<ReturnType<typeof vendorBillList>>, TError = ErrorType<unknown>>(params?: VendorBillListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorBillListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorBillList>>> = ({ signal }) => vendorBillList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorBillListQueryResult = NonNullable<Awaited<ReturnType<typeof vendorBillList>>>
export type VendorBillListQueryError = ErrorType<unknown>


export function useVendorBillList<TData = Awaited<ReturnType<typeof vendorBillList>>, TError = ErrorType<unknown>>(
 params: undefined |  VendorBillListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof vendorBillList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorBillList<TData = Awaited<ReturnType<typeof vendorBillList>>, TError = ErrorType<unknown>>(
 params?: VendorBillListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof vendorBillList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorBillList<TData = Awaited<ReturnType<typeof vendorBillList>>, TError = ErrorType<unknown>>(
 params?: VendorBillListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useVendorBillList<TData = Awaited<ReturnType<typeof vendorBillList>>, TError = ErrorType<unknown>>(
 params?: VendorBillListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getVendorBillListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getVendorBillListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof vendorBillList>>, TError = ErrorType<unknown>>(params?: VendorBillListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorBillListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorBillList>>> = ({ signal }) => vendorBillList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorBillListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof vendorBillList>>>
export type VendorBillListSuspenseQueryError = ErrorType<unknown>


export function useVendorBillListSuspense<TData = Awaited<ReturnType<typeof vendorBillList>>, TError = ErrorType<unknown>>(
 params: undefined |  VendorBillListParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorBillListSuspense<TData = Awaited<ReturnType<typeof vendorBillList>>, TError = ErrorType<unknown>>(
 params?: VendorBillListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorBillListSuspense<TData = Awaited<ReturnType<typeof vendorBillList>>, TError = ErrorType<unknown>>(
 params?: VendorBillListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useVendorBillListSuspense<TData = Awaited<ReturnType<typeof vendorBillList>>, TError = ErrorType<unknown>>(
 params?: VendorBillListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getVendorBillListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const vendorBillCreate = (
    vendorBillCreateSchema: BodyType<VendorBillCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<VendorBillHydratedDumpSchema>(
      {url: `/purchasing/vendor-bills`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: vendorBillCreateSchema
    },
      options);
    }


export const vendorBillGet = (
    vendorBillId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<VendorBillHydratedDumpSchema>(
      {url: `/purchasing/vendor-bills/${vendorBillId}`, method: 'GET', signal
    },
      options);
    }


export const getVendorBillGetQueryKey = (vendorBillId: string,) => {
    return [`/purchasing/vendor-bills/${vendorBillId}`] as const;
    }


export const getVendorBillGetQueryOptions = <TData = Awaited<ReturnType<typeof vendorBillGet>>, TError = ErrorType<unknown>>(vendorBillId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorBillGetQueryKey(vendorBillId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorBillGet>>> = ({ signal }) => vendorBillGet(vendorBillId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(vendorBillId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorBillGetQueryResult = NonNullable<Awaited<ReturnType<typeof vendorBillGet>>>
export type VendorBillGetQueryError = ErrorType<unknown>


export function useVendorBillGet<TData = Awaited<ReturnType<typeof vendorBillGet>>, TError = ErrorType<unknown>>(
 vendorBillId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof vendorBillGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorBillGet<TData = Awaited<ReturnType<typeof vendorBillGet>>, TError = ErrorType<unknown>>(
 vendorBillId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof vendorBillGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorBillGet<TData = Awaited<ReturnType<typeof vendorBillGet>>, TError = ErrorType<unknown>>(
 vendorBillId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useVendorBillGet<TData = Awaited<ReturnType<typeof vendorBillGet>>, TError = ErrorType<unknown>>(
 vendorBillId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getVendorBillGetQueryOptions(vendorBillId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getVendorBillGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof vendorBillGet>>, TError = ErrorType<unknown>>(vendorBillId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorBillGetQueryKey(vendorBillId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorBillGet>>> = ({ signal }) => vendorBillGet(vendorBillId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(vendorBillId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorBillGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof vendorBillGet>>>
export type VendorBillGetSuspenseQueryError = ErrorType<unknown>


export function useVendorBillGetSuspense<TData = Awaited<ReturnType<typeof vendorBillGet>>, TError = ErrorType<unknown>>(
 vendorBillId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorBillGetSuspense<TData = Awaited<ReturnType<typeof vendorBillGet>>, TError = ErrorType<unknown>>(
 vendorBillId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useVendorBillGetSuspense<TData = Awaited<ReturnType<typeof vendorBillGet>>, TError = ErrorType<unknown>>(
 vendorBillId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useVendorBillGetSuspense<TData = Awaited<ReturnType<typeof vendorBillGet>>, TError = ErrorType<unknown>>(
 vendorBillId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof vendorBillGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getVendorBillGetSuspenseQueryOptions(vendorBillId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const vendorBillUpdate = (
    vendorBillId: string,
    vendorBillUpdateSchema: BodyType<VendorBillUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<VendorBillHydratedDumpSchema>(
      {url: `/purchasing/vendor-bills/${vendorBillId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: vendorBillUpdateSchema
    },
      options);
    }


export const itemReceiptList = (
    params?: ItemReceiptListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedItemReceiptSchema>(
      {url: `/purchasing/item-receipts`, method: 'GET',
        params, signal
    },
      options);
    }


export const getItemReceiptListQueryKey = (params?: ItemReceiptListParams,) => {
    return [`/purchasing/item-receipts`, ...(params ? [params]: [])] as const;
    }


export const getItemReceiptListQueryOptions = <TData = Awaited<ReturnType<typeof itemReceiptList>>, TError = ErrorType<unknown>>(params?: ItemReceiptListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getItemReceiptListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof itemReceiptList>>> = ({ signal }) => itemReceiptList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData> & { queryKey: QueryKey }
}

export type ItemReceiptListQueryResult = NonNullable<Awaited<ReturnType<typeof itemReceiptList>>>
export type ItemReceiptListQueryError = ErrorType<unknown>


export function useItemReceiptList<TData = Awaited<ReturnType<typeof itemReceiptList>>, TError = ErrorType<unknown>>(
 params: undefined |  ItemReceiptListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemReceiptList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useItemReceiptList<TData = Awaited<ReturnType<typeof itemReceiptList>>, TError = ErrorType<unknown>>(
 params?: ItemReceiptListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemReceiptList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useItemReceiptList<TData = Awaited<ReturnType<typeof itemReceiptList>>, TError = ErrorType<unknown>>(
 params?: ItemReceiptListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useItemReceiptList<TData = Awaited<ReturnType<typeof itemReceiptList>>, TError = ErrorType<unknown>>(
 params?: ItemReceiptListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getItemReceiptListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getItemReceiptListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof itemReceiptList>>, TError = ErrorType<unknown>>(params?: ItemReceiptListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getItemReceiptListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof itemReceiptList>>> = ({ signal }) => itemReceiptList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData> & { queryKey: QueryKey }
}

export type ItemReceiptListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof itemReceiptList>>>
export type ItemReceiptListSuspenseQueryError = ErrorType<unknown>


export function useItemReceiptListSuspense<TData = Awaited<ReturnType<typeof itemReceiptList>>, TError = ErrorType<unknown>>(
 params: undefined |  ItemReceiptListParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useItemReceiptListSuspense<TData = Awaited<ReturnType<typeof itemReceiptList>>, TError = ErrorType<unknown>>(
 params?: ItemReceiptListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useItemReceiptListSuspense<TData = Awaited<ReturnType<typeof itemReceiptList>>, TError = ErrorType<unknown>>(
 params?: ItemReceiptListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useItemReceiptListSuspense<TData = Awaited<ReturnType<typeof itemReceiptList>>, TError = ErrorType<unknown>>(
 params?: ItemReceiptListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getItemReceiptListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const itemReceiptCreate = (
    itemReceiptCreateSchema: BodyType<ItemReceiptCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<ItemReceiptHydratedDumpSchema>(
      {url: `/purchasing/item-receipts`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: itemReceiptCreateSchema
    },
      options);
    }


export const itemReceiptGet = (
    itemReceiptId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<ItemReceiptHydratedDumpSchema>(
      {url: `/purchasing/item-receipts/${itemReceiptId}`, method: 'GET', signal
    },
      options);
    }


export const getItemReceiptGetQueryKey = (itemReceiptId: string,) => {
    return [`/purchasing/item-receipts/${itemReceiptId}`] as const;
    }


export const getItemReceiptGetQueryOptions = <TData = Awaited<ReturnType<typeof itemReceiptGet>>, TError = ErrorType<unknown>>(itemReceiptId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getItemReceiptGetQueryKey(itemReceiptId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof itemReceiptGet>>> = ({ signal }) => itemReceiptGet(itemReceiptId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(itemReceiptId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ItemReceiptGetQueryResult = NonNullable<Awaited<ReturnType<typeof itemReceiptGet>>>
export type ItemReceiptGetQueryError = ErrorType<unknown>


export function useItemReceiptGet<TData = Awaited<ReturnType<typeof itemReceiptGet>>, TError = ErrorType<unknown>>(
 itemReceiptId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemReceiptGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useItemReceiptGet<TData = Awaited<ReturnType<typeof itemReceiptGet>>, TError = ErrorType<unknown>>(
 itemReceiptId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemReceiptGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useItemReceiptGet<TData = Awaited<ReturnType<typeof itemReceiptGet>>, TError = ErrorType<unknown>>(
 itemReceiptId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useItemReceiptGet<TData = Awaited<ReturnType<typeof itemReceiptGet>>, TError = ErrorType<unknown>>(
 itemReceiptId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getItemReceiptGetQueryOptions(itemReceiptId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getItemReceiptGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof itemReceiptGet>>, TError = ErrorType<unknown>>(itemReceiptId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getItemReceiptGetQueryKey(itemReceiptId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof itemReceiptGet>>> = ({ signal }) => itemReceiptGet(itemReceiptId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(itemReceiptId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ItemReceiptGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof itemReceiptGet>>>
export type ItemReceiptGetSuspenseQueryError = ErrorType<unknown>


export function useItemReceiptGetSuspense<TData = Awaited<ReturnType<typeof itemReceiptGet>>, TError = ErrorType<unknown>>(
 itemReceiptId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useItemReceiptGetSuspense<TData = Awaited<ReturnType<typeof itemReceiptGet>>, TError = ErrorType<unknown>>(
 itemReceiptId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useItemReceiptGetSuspense<TData = Awaited<ReturnType<typeof itemReceiptGet>>, TError = ErrorType<unknown>>(
 itemReceiptId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useItemReceiptGetSuspense<TData = Awaited<ReturnType<typeof itemReceiptGet>>, TError = ErrorType<unknown>>(
 itemReceiptId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof itemReceiptGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getItemReceiptGetSuspenseQueryOptions(itemReceiptId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const itemReceiptUpdate = (
    itemReceiptId: string,
    itemReceiptUpdateSchema: BodyType<ItemReceiptUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<ItemReceiptHydratedDumpSchema>(
      {url: `/purchasing/item-receipts/${itemReceiptId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: itemReceiptUpdateSchema
    },
      options);
    }


