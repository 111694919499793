import {
  createAutoformatPlugin,
  type AutoformatBlockRule,
  type AutoformatRule,
} from '@udecode/plate-autoformat'
import {
  createBoldPlugin,
  createItalicPlugin,
  MARK_BOLD,
  MARK_ITALIC,
} from '@udecode/plate-basic-marks'
import {
  createPlugins,
  getParentNode,
  isElement,
  Plate,
  PlateContent,
  PlateElement,
  PlateLeaf,
  withRef,
  type PlateEditor,
  type PlateElementProps,
  type Value,
} from '@udecode/plate-common'
import {
  createHeadingPlugin,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
} from '@udecode/plate-heading'
import {
  createListPlugin,
  ELEMENT_LI,
  ELEMENT_OL,
  ELEMENT_UL,
  toggleList,
  unwrapList,
} from '@udecode/plate-list'
import { useMemo } from 'react'
import styled from 'styled-components'

import {
  type LinkNameSchema,
  type UserDumpSchema,
  type BaseVariableSchema,
} from '_autogenerated'
import {
  KuiInputWrapper,
  type KuiInputWrapperProps,
} from 'components/kui/_internal/KuiInputWrapper'
import { mixKuiPad } from 'components/kui/KuiPad'

import {
  createKuiRichTextSelectPlugin,
  KuiRichTextSelectContext,
  type KuiRichTextSelectComboboxProps,
} from './createKuiRichTextSelectPlugin'
import { inputStyles } from './inputStyles'
import {
  KuiRichTextToolbar,
  type KuiRichTextEnabledTypes,
} from './KuiRichTextToolbar'
import { prettifyVariableName } from './utils'

const KuiRichTextRoot = styled.div`
  ${inputStyles}

  overflow: hidden;
`

const KuiRichTextToolbarContainer = styled.div`
  padding-inline-start: var(--input-padding-inline-start);
  padding-inline-end: var(--input-padding-inline-end);
  ${mixKuiPad({ verticalSize: 'xs' })}

  border-bottom: 1px solid var(--input-bd);
`

const StyledPlateContent = styled(PlateContent)`
  overflow-y: auto;

  padding-inline-start: var(--input-padding-inline-start);
  padding-inline-end: var(--input-padding-inline-end);
  ${mixKuiPad({ verticalSize: 'xs' })}

  &:focus,
  &:focus-within {
    outline: none;
  }
`

export type KuiRichTextValue = Value

type VariablePluginProps = {
  selectProps: KuiRichTextSelectComboboxProps<BaseVariableSchema>
  resolvedVariables?: Record<string, string>
}

type MentionPluginProps = {
  selectProps: KuiRichTextSelectComboboxProps<UserDumpSchema>
}

export type KuiRichTextProps = Pick<
  KuiInputWrapperProps,
  'label' | 'description' | 'error'
> & {
  placeholder?: string

  disabled?: boolean

  /** @default { marks: true, headings: true, lists: true } */
  enabledTypes?: KuiRichTextEnabledTypes

  variablePluginProps?: VariablePluginProps

  mentionPluginProps?: MentionPluginProps

  initialValue?: Value

  /** @default true */
  toolbar?: boolean

  /** @default '200px' */
  minHeight?: string

  /** @default '400px' */
  maxHeight?: string

  onChange?: (nextValue: Value) => void
}

export function KuiRichText({
  label,
  description,
  error,
  placeholder,
  disabled = false,
  enabledTypes = { marks: true, headings: true, lists: true },
  toolbar = true,
  variablePluginProps,
  mentionPluginProps,
  initialValue: consumerInitialValue,
  minHeight = '200px',
  maxHeight = minHeight,
  onChange,
}: KuiRichTextProps) {
  const readonly = !onChange

  const variablesEnabled = !!variablePluginProps || readonly
  const mentionedEnabled = !!mentionPluginProps || readonly

  const plugins = useMemo(() => {
    const variablePlugin = createKuiRichTextSelectPlugin<
      BaseVariableSchema,
      string
    >({
      key: 'variable',
      trigger: '{',
      inputPlaceholder: 'Search variables',
      parseItem: (item) => ({
        key: item.name,
        label: prettifyVariableName(item.name),
      }),
      getItemValue: (item) => item.name,
      enabled: variablesEnabled,
    })

    const mentionPlugin = createKuiRichTextSelectPlugin<
      UserDumpSchema,
      LinkNameSchema
    >({
      key: 'mention',
      trigger: '@',
      inputPlaceholder: 'Search users',
      parseItem: (user) => ({
        key: user.id,
        label: user.full_name,
      }),
      getItemValue: (user) => ({ id: user.id, name: user.full_name }),
      enabled: mentionedEnabled,
    })

    return createPlugins(
      [
        variablePlugin.plugin,
        mentionPlugin.plugin,
        createBoldPlugin({
          component: BoldLeaf,
          enabled: !!enabledTypes.marks,
        }),
        createItalicPlugin({
          component: ItalicLeaf,
          enabled: !!enabledTypes.marks,
        }),
        createHeadingPlugin({ enabled: !!enabledTypes.headings, levels: 3 }),
        // createLinkPlugin({
        //   enabled: linksEnabled,
        //   // renderAfterEditable: LinkFloatingToolbar as RenderAfterEditable,
        // }),
        createListPlugin({ enabled: !!enabledTypes.lists }),
        createAutoformatPlugin({
          options: {
            rules: getAutoformatRules({ enabledTypes }),
            enableUndoOnDelete: true,
          },
        }),
      ],
      {
        components: {
          ...variablePlugin.components,
          ...mentionPlugin.components,
          [MARK_BOLD]: BoldLeaf,
          [MARK_ITALIC]: ItalicLeaf,
          [ELEMENT_H1]: createHeadingElement({ variant: 'h1' }),
          [ELEMENT_H2]: createHeadingElement({ variant: 'h2' }),
          [ELEMENT_H3]: createHeadingElement({ variant: 'h3' }),
          // [ELEMENT_LINK]: LinkElement,
          [ELEMENT_UL]: createListElement({ variant: 'ul' }),
          [ELEMENT_OL]: createListElement({ variant: 'ol' }),
          [ELEMENT_LI]: ListItemElement,
        },
      }
    )
  }, [enabledTypes, mentionedEnabled, variablesEnabled])

  const selectContextValue = useMemo(
    () => ({
      variableSelectProps: variablePluginProps?.selectProps,
      resolvedVariables: variablePluginProps?.resolvedVariables,

      mentionSelectProps: mentionPluginProps?.selectProps,
    }),
    [
      mentionPluginProps?.selectProps,
      variablePluginProps?.selectProps,
      variablePluginProps?.resolvedVariables,
    ]
  )

  const initialValue = consumerInitialValue?.length
    ? consumerInitialValue
    : undefined

  if (readonly) {
    return (
      <KuiRichTextSelectContext.Provider value={selectContextValue}>
        <Plate plugins={plugins} initialValue={initialValue} readOnly={true}>
          <StyledPlateContent
            placeholder={placeholder}
            style={{ padding: '0' }}
          />
        </Plate>
      </KuiRichTextSelectContext.Provider>
    )
  }

  return (
    <KuiRichTextSelectContext.Provider value={selectContextValue}>
      <KuiInputWrapper label={label} description={description} error={error}>
        <Plate
          plugins={plugins}
          initialValue={initialValue}
          onChange={onChange}
          readOnly={disabled}
        >
          <KuiRichTextRoot data-error={!!error} data-disabled={disabled}>
            {toolbar && (
              <KuiRichTextToolbarContainer>
                <KuiRichTextToolbar
                  enabledTypes={enabledTypes}
                  disabled={disabled}
                />
              </KuiRichTextToolbarContainer>
            )}

            <StyledPlateContent
              placeholder={placeholder}
              style={{
                minHeight,
                maxHeight,
              }}
            />
          </KuiRichTextRoot>
        </Plate>
      </KuiInputWrapper>
    </KuiRichTextSelectContext.Provider>
  )
}

const BoldLeaf = styled(PlateLeaf)`
  font-weight: 700;
`

const ItalicLeaf = styled(PlateLeaf)`
  font-style: italic;
`

const HeadingElementRoot = styled(PlateElement)`
  margin: 0;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
`

function createHeadingElement({ variant }: { variant: 'h1' | 'h2' | 'h3' }) {
  const Component = variant

  return withRef<typeof PlateElement>(({ children, ...restProps }, ref) => {
    return (
      <HeadingElementRoot ref={ref} asChild={true} {...restProps}>
        <Component>{children}</Component>
      </HeadingElementRoot>
    )
  })
}

function createListElement({ variant }: { variant: 'ul' | 'ol' }) {
  const Component = variant

  return withRef<typeof PlateElement>(({ children, ...restProps }, ref) => {
    return (
      <PlateElement ref={ref} asChild={true} {...restProps}>
        <Component>{children}</Component>
      </PlateElement>
    )
  })
}

function ListItemElement({ ...restProps }: PlateElementProps) {
  return <PlateElement as='li' {...restProps} />
}

const preFormat: AutoformatBlockRule['preFormat'] = (editor) =>
  unwrapList(editor)

function getAutoformatRules({
  enabledTypes,
}: {
  enabledTypes: KuiRichTextEnabledTypes
}): AutoformatRule[] {
  const rules: AutoformatRule[] = []

  if (enabledTypes.marks) {
    rules.push(
      {
        mode: 'mark',
        type: MARK_BOLD,
        match: '**',
      },
      {
        mode: 'mark',
        type: MARK_ITALIC,
        match: '*',
      },
      {
        mode: 'mark',
        type: MARK_ITALIC,
        match: '_',
      }
    )
  }

  if (enabledTypes.headings) {
    rules.push(
      {
        mode: 'block',
        type: ELEMENT_H1,
        match: '# ',
        preFormat,
      },
      {
        mode: 'block',
        type: ELEMENT_H2,
        match: '## ',
        preFormat,
      },
      {
        mode: 'block',
        type: ELEMENT_H3,
        match: '### ',
        preFormat,
      }
    )
  }

  if (enabledTypes.lists) {
    rules.push(
      {
        mode: 'block',
        type: ELEMENT_LI,
        match: ['* ', '- '],
        preFormat,
        format: (editor) => formatList(editor, ELEMENT_UL),
      },
      {
        mode: 'block',
        type: ELEMENT_LI,
        match: ['1. ', '1) '],
        preFormat,
        format: (editor) => formatList(editor, ELEMENT_OL),
      }
    )
  }

  return rules
}

function formatList(editor: PlateEditor, elementType: string) {
  if (editor.selection) {
    const parentEntry = getParentNode(editor, editor.selection)
    if (!parentEntry) {
      return
    }

    const [node] = parentEntry
    if (isElement(node)) {
      toggleList(editor, {
        type: elementType,
      })
    }
  }
}
