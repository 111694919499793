import { lazily } from 'react-lazily'

import { getGetJournalEntryQueryOptions } from '_autogenerated'
import { accountingUrls } from 'routes/urls'
import { createRoute } from 'routing'

const { JournalEntryListPage, JournalEntryDetailPage, JournalEntryEditPage } =
  lazily(() => import('./components'))

export const accountingRoutes = [
  createRoute({
    route: accountingUrls,
    Component: JournalEntryListPage,
  }),
  createRoute({
    route: accountingUrls.detail,
    Component: JournalEntryDetailPage,
    prefetch: ({ id }) => [getGetJournalEntryQueryOptions(id)],
  }),
  createRoute({
    route: accountingUrls.edit,
    Component: JournalEntryEditPage,
    prefetch: ({ id }) => [getGetJournalEntryQueryOptions(id)],
  }),
]
