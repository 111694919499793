/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  StripeActiveSchema,
  StripeOnboardingSchema
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const onboard = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<StripeOnboardingSchema>(
      {url: `/stripe/onboard`, method: 'POST'
    },
      options);
    }


export const getStripeActive = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<StripeActiveSchema>(
      {url: `/stripe`, method: 'GET', signal
    },
      options);
    }


export const getGetStripeActiveQueryKey = () => {
    return [`/stripe`] as const;
    }


export const getGetStripeActiveQueryOptions = <TData = Awaited<ReturnType<typeof getStripeActive>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStripeActiveQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStripeActive>>> = ({ signal }) => getStripeActive(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStripeActiveQueryResult = NonNullable<Awaited<ReturnType<typeof getStripeActive>>>
export type GetStripeActiveQueryError = ErrorType<unknown>


export function useGetStripeActive<TData = Awaited<ReturnType<typeof getStripeActive>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getStripeActive>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetStripeActive<TData = Awaited<ReturnType<typeof getStripeActive>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getStripeActive>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetStripeActive<TData = Awaited<ReturnType<typeof getStripeActive>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetStripeActive<TData = Awaited<ReturnType<typeof getStripeActive>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetStripeActiveQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetStripeActiveSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getStripeActive>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStripeActiveQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStripeActive>>> = ({ signal }) => getStripeActive(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStripeActiveSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getStripeActive>>>
export type GetStripeActiveSuspenseQueryError = ErrorType<unknown>


export function useGetStripeActiveSuspense<TData = Awaited<ReturnType<typeof getStripeActive>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetStripeActiveSuspense<TData = Awaited<ReturnType<typeof getStripeActive>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetStripeActiveSuspense<TData = Awaited<ReturnType<typeof getStripeActive>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetStripeActiveSuspense<TData = Awaited<ReturnType<typeof getStripeActive>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getStripeActive>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetStripeActiveSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



