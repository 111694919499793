import { type ReactNode } from 'react'
import styled, { css } from 'styled-components'

import {
  kuiThemeVars,
  type KuiThemeFontSize,
  type KuiThemeFontWeight,
} from './_internal/theme'

type KuiTextColor = 'text' | 'hushed' | 'destructive'

const colorMap: Record<KuiTextColor, string> = {
  text: 'var(--mantine-color-text)',
  hushed: 'var(--mantine-color-dark-3)',
  destructive: 'var(--mantine-color-error)',
}

type KuiThemeFontWeightWithInherit =
  | Exclude<KuiThemeFontWeight, 'headings'>
  | 'inherit'

type KuiTextWrap = 'wrap' | 'no-wrap'

const textWrapStyles: Record<KuiTextWrap, string> = {
  wrap: 'white-space: normal;',
  'no-wrap': 'white-space: nowrap;',
}

export type MixKuiTextProps = {
  size?: KuiThemeFontSize
  weight?: KuiThemeFontWeightWithInherit
  color?: KuiTextColor
  textAlign?: 'left' | 'center' | 'right'
  wrap?: KuiTextWrap
  truncate?: boolean
}

export const mixKuiText = ({
  size,
  weight = 'inherit',
  color,
  textAlign,
  wrap,
  truncate,
}: MixKuiTextProps) => css`
  ${size && `font-size: ${kuiThemeVars.fontSizes[size]};`}
  ${size && `line-height: ${kuiThemeVars.lineHeights[size]};`}
  ${color && `color: ${colorMap[color]};`}
  font-weight: ${weight === 'inherit'
    ? 'inherit'
    : kuiThemeVars.fontWeights[weight]};
  ${textAlign && `text-align: ${textAlign};`}
  ${truncate &&
  `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
  ${wrap && textWrapStyles[wrap]}
`

type KuiTextRootProps = {
  $textProps: MixKuiTextProps
}

const KuiTextRoot = styled.span<KuiTextRootProps>`
  margin: 0;
  ${(p) => mixKuiText(p.$textProps)}
`

export type KuiTextProps = MixKuiTextProps & {
  children?: ReactNode
}

export type KuiTextAsProps = KuiTextProps & {
  as?: 'span' | 'div' | 'p' | 'dl' | 'dt' | 'dd' | 'ul' | 'ol' | 'li'
}

export function KuiTextAs({
  as = 'span',
  size,
  weight,
  color,
  wrap,
  textAlign,
  truncate,
  ...restProps
}: KuiTextAsProps) {
  return (
    <KuiTextRoot
      as={as}
      $textProps={{
        size,
        weight,
        color,
        textAlign,
        wrap,
        truncate,
      }}
      {...restProps}
    />
  )
}

function KuiTextAsSpan(props: KuiTextProps) {
  return <KuiTextAs as='span' {...props} />
}

function KuiTextAsDiv(props: KuiTextProps) {
  return <KuiTextAs as='div' {...props} />
}

function KuiTextAsP(props: KuiTextProps) {
  return <KuiTextAs as='p' {...props} />
}

function KuiTextAsDl(props: KuiTextProps) {
  return <KuiTextAs as='dl' {...props} />
}

function KuiTextAsDt(props: KuiTextProps) {
  return <KuiTextAs as='dt' {...props} />
}

function KuiTextAsDd(props: KuiTextProps) {
  return <KuiTextAs as='dd' {...props} />
}

function KuiTextAsUl(props: KuiTextProps) {
  return <KuiTextAs as='ul' {...props} />
}

function KuiTextAsOl(props: KuiTextProps) {
  return <KuiTextAs as='ol' {...props} />
}

function KuiTextAsLi(props: KuiTextProps) {
  return <KuiTextAs as='li' {...props} />
}

export const KuiText = {
  span: KuiTextAsSpan,
  div: KuiTextAsDiv,
  p: KuiTextAsP,
  dl: KuiTextAsDl,
  dt: KuiTextAsDt,
  dd: KuiTextAsDd,
  ul: KuiTextAsUl,
  ol: KuiTextAsOl,
  li: KuiTextAsLi,
}
