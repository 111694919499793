/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  PaginatedUserSchema,
  SuccessResponse,
  UserCreateSchema,
  UserDumpSchema,
  UserForgotPasswordBodySchema,
  UserHydratedDumpSchema,
  UserLocationUpdateSchema,
  UserPasswordUpdateBodySchema,
  UserUpdateSchema,
  UsersParams
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const users = (
    params?: UsersParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedUserSchema>(
      {url: `/profile/users`, method: 'GET',
        params, signal
    },
      options);
    }


export const getUsersQueryKey = (params?: UsersParams,) => {
    return [`/profile/users`, ...(params ? [params]: [])] as const;
    }


export const getUsersQueryOptions = <TData = Awaited<ReturnType<typeof users>>, TError = ErrorType<unknown>>(params?: UsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof users>>> = ({ signal }) => users(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData> & { queryKey: QueryKey }
}

export type UsersQueryResult = NonNullable<Awaited<ReturnType<typeof users>>>
export type UsersQueryError = ErrorType<unknown>


export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = ErrorType<unknown>>(
 params: undefined |  UsersParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof users>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = ErrorType<unknown>>(
 params?: UsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof users>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = ErrorType<unknown>>(
 params?: UsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = ErrorType<unknown>>(
 params?: UsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getUsersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof users>>, TError = ErrorType<unknown>>(params?: UsersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof users>>> = ({ signal }) => users(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData> & { queryKey: QueryKey }
}

export type UsersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof users>>>
export type UsersSuspenseQueryError = ErrorType<unknown>


export function useUsersSuspense<TData = Awaited<ReturnType<typeof users>>, TError = ErrorType<unknown>>(
 params: undefined |  UsersParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsersSuspense<TData = Awaited<ReturnType<typeof users>>, TError = ErrorType<unknown>>(
 params?: UsersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsersSuspense<TData = Awaited<ReturnType<typeof users>>, TError = ErrorType<unknown>>(
 params?: UsersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useUsersSuspense<TData = Awaited<ReturnType<typeof users>>, TError = ErrorType<unknown>>(
 params?: UsersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getUsersSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createUser = (
    userCreateSchema: BodyType<UserCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<UserHydratedDumpSchema>(
      {url: `/profile/users`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userCreateSchema
    },
      options);
    }


export const getUser = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<UserHydratedDumpSchema>(
      {url: `/profile/users/${id}`, method: 'GET', signal
    },
      options);
    }


export const getGetUserQueryKey = (id: string,) => {
    return [`/profile/users/${id}`] as const;
    }


export const getGetUserQueryOptions = <TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) => getUser(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserQueryError = ErrorType<unknown>


export function useGetUser<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUser>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUser<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUser>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUser<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetUser<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) => getUser(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserSuspenseQueryError = ErrorType<unknown>


export function useGetUserSuspense<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(
 id: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUserSuspense<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUserSuspense<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetUserSuspense<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateUser = (
    id: string,
    userUpdateSchema: BodyType<UserUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<UserHydratedDumpSchema>(
      {url: `/profile/users/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: userUpdateSchema
    },
      options);
    }


export const updateUserPassword = (
    id: string,
    userPasswordUpdateBodySchema: BodyType<UserPasswordUpdateBodySchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<UserDumpSchema>(
      {url: `/profile/users/${id}/password`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: userPasswordUpdateBodySchema
    },
      options);
    }


export const forgotPassword = (
    userForgotPasswordBodySchema: BodyType<UserForgotPasswordBodySchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/profile/users/forgot-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userForgotPasswordBodySchema
    },
      options);
    }


export const updateUserLocation = (
    userLocationUpdateSchema: BodyType<UserLocationUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<UserHydratedDumpSchema>(
      {url: `/profile/users/location`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: userLocationUpdateSchema
    },
      options);
    }


export const currentUser = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<UserHydratedDumpSchema>(
      {url: `/profile/current-user`, method: 'GET', signal
    },
      options);
    }


export const getCurrentUserQueryKey = () => {
    return [`/profile/current-user`] as const;
    }


export const getCurrentUserQueryOptions = <TData = Awaited<ReturnType<typeof currentUser>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCurrentUserQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof currentUser>>> = ({ signal }) => currentUser(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData> & { queryKey: QueryKey }
}

export type CurrentUserQueryResult = NonNullable<Awaited<ReturnType<typeof currentUser>>>
export type CurrentUserQueryError = ErrorType<unknown>


export function useCurrentUser<TData = Awaited<ReturnType<typeof currentUser>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof currentUser>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useCurrentUser<TData = Awaited<ReturnType<typeof currentUser>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof currentUser>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useCurrentUser<TData = Awaited<ReturnType<typeof currentUser>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useCurrentUser<TData = Awaited<ReturnType<typeof currentUser>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getCurrentUserQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCurrentUserSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof currentUser>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCurrentUserQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof currentUser>>> = ({ signal }) => currentUser(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData> & { queryKey: QueryKey }
}

export type CurrentUserSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof currentUser>>>
export type CurrentUserSuspenseQueryError = ErrorType<unknown>


export function useCurrentUserSuspense<TData = Awaited<ReturnType<typeof currentUser>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useCurrentUserSuspense<TData = Awaited<ReturnType<typeof currentUser>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useCurrentUserSuspense<TData = Awaited<ReturnType<typeof currentUser>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useCurrentUserSuspense<TData = Awaited<ReturnType<typeof currentUser>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getCurrentUserSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



