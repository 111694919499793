import { lazily } from 'react-lazily'

import { getRepairGetQueryOptions } from '_autogenerated'
import { repairsUrls } from 'routes/urls'
import { createRoute } from 'routing'

const {
  RepairListPage,
  RepairCreatePage,
  RepairDetailPageRoot,
  RepairDetailPageDetailsTab,
  RepairDetailPageActivityTab,
  RepairEditPage,
  RepairTechnicianWorkPage,
  RepairTechnicianWorkPageActivity,
  RepairTechnicianEditLineItemsPage,
} = lazily(() => import('./components'))

export const repairRoutes = [
  createRoute({
    route: repairsUrls,
    Component: RepairListPage,
  }),
  createRoute({
    route: repairsUrls.create,
    Component: RepairCreatePage,
  }),
  createRoute({
    route: repairsUrls.detail,
    Component: RepairDetailPageRoot,
    prefetch: ({ id }) => [getRepairGetQueryOptions(id)],
    children: [
      createRoute({
        route: repairsUrls.detail,
        Component: RepairDetailPageDetailsTab,
      }),
      createRoute({
        route: repairsUrls.detail.activity,
        Component: RepairDetailPageActivityTab,
      }),
    ],
  }),
  createRoute({
    route: repairsUrls.edit,
    Component: RepairEditPage,
    prefetch: ({ id }) => [getRepairGetQueryOptions(id)],
  }),
]

export const repairFullPageRoutes = [
  createRoute({
    route: repairsUrls.technicianWork,
    Component: RepairTechnicianWorkPage,
    fullPage: true,
    prefetch: ({ id }) => [getRepairGetQueryOptions(id)],
  }),
  createRoute({
    route: repairsUrls.technicianWork.activity,
    Component: RepairTechnicianWorkPageActivity,
    fullPage: true,
    prefetch: ({ id }) => [getRepairGetQueryOptions(id)],
  }),
  createRoute({
    route: repairsUrls.technicianWork.editLineItems,
    Component: RepairTechnicianEditLineItemsPage,
    fullPage: true,
    prefetch: ({ id }) => [getRepairGetQueryOptions(id)],
  }),
]
