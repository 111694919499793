import { lazily } from 'react-lazily'

import { settingsUrls } from 'routes/Settings/settingsUrls'
import { createRoute } from 'routing'

const { UserSettingsPage, EditUserSettingsPage, CommunicationPreferencesPage } =
  lazily(() => import('./components'))

export const personalSettingsRoutes = [
  createRoute({
    route: settingsUrls.user,
    Component: UserSettingsPage,
  }),
  createRoute({
    route: settingsUrls.user.edit,
    Component: EditUserSettingsPage,
  }),
  createRoute({
    route: settingsUrls.user.communicationPreferences,
    Component: CommunicationPreferencesPage,
  }),
]
