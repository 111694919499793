import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  type Dispatch,
  type PropsWithChildren,
} from 'react'

import { ActionTypes, type AuthActions, type AuthContextType } from './types'

const authInitialState: AuthContextType = {}

const initializer = (initialValue: AuthContextType = authInitialState) => {
  const parsedState = JSON.parse(localStorage.getItem('user') || '{}')

  const state: AuthContextType = parsedState || initialValue

  if (state.user) {
    const { user } = state

    LogRocket.identify(user.id, {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
    })

    Sentry.setUser({ id: user.id, email: user.email })
  }

  return state
}

const authReducer = (state: AuthContextType, action: AuthActions) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return { ...state, user: action.payload.user }
    case ActionTypes.LOGOUT:
      return { ...state, user: undefined }
    default:
      return state
  }
}

const AuthContext = createContext<{
  state: AuthContextType
  dispatch: Dispatch<AuthActions>
}>({
  state: authInitialState,
  dispatch: () => null,
})

export const AuthContextProvider = (props: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(
    authReducer,
    authInitialState,
    initializer
  )

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(state))
  }, [state])

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
