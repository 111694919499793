/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  PaginatedPaymentSchema,
  PaymentCreateBodySchema,
  PaymentCreateResponseSchema,
  PaymentHydratedDumpSchema,
  PaymentListParams,
  PaymentReconcileInitialStateBodySchema,
  PaymentReconcileResponseSchema,
  PaymentSimpleCreateSchema,
  PaymentSurchargeAmountCheck,
  PaymentSurchargeAmountDump,
  PaymentSurchargeListSchema,
  PaymentUpdateSchema
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const paymentReconcileInitialState = (
    paymentReconcileInitialStateBodySchema: BodyType<PaymentReconcileInitialStateBodySchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PaymentReconcileResponseSchema>(
      {url: `/payments/payments/reconcile-initial-state`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: paymentReconcileInitialStateBodySchema
    },
      options);
    }


export const payInvoice = (
    invoiceId: string,
    paymentSimpleCreateSchema: BodyType<PaymentSimpleCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PaymentCreateResponseSchema>(
      {url: `/payments/payments/pay-invoice/${invoiceId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: paymentSimpleCreateSchema
    },
      options);
    }


export const paymentCreate = (
    paymentCreateBodySchema: BodyType<PaymentCreateBodySchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PaymentCreateResponseSchema>(
      {url: `/payments/payments`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: paymentCreateBodySchema
    },
      options);
    }


export const paymentList = (
    params?: PaymentListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedPaymentSchema>(
      {url: `/payments/payments`, method: 'GET',
        params, signal
    },
      options);
    }


export const getPaymentListQueryKey = (params?: PaymentListParams,) => {
    return [`/payments/payments`, ...(params ? [params]: [])] as const;
    }


export const getPaymentListQueryOptions = <TData = Awaited<ReturnType<typeof paymentList>>, TError = ErrorType<unknown>>(params?: PaymentListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPaymentListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentList>>> = ({ signal }) => paymentList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData> & { queryKey: QueryKey }
}

export type PaymentListQueryResult = NonNullable<Awaited<ReturnType<typeof paymentList>>>
export type PaymentListQueryError = ErrorType<unknown>


export function usePaymentList<TData = Awaited<ReturnType<typeof paymentList>>, TError = ErrorType<unknown>>(
 params: undefined |  PaymentListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof paymentList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePaymentList<TData = Awaited<ReturnType<typeof paymentList>>, TError = ErrorType<unknown>>(
 params?: PaymentListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof paymentList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePaymentList<TData = Awaited<ReturnType<typeof paymentList>>, TError = ErrorType<unknown>>(
 params?: PaymentListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePaymentList<TData = Awaited<ReturnType<typeof paymentList>>, TError = ErrorType<unknown>>(
 params?: PaymentListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPaymentListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPaymentListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof paymentList>>, TError = ErrorType<unknown>>(params?: PaymentListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPaymentListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentList>>> = ({ signal }) => paymentList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData> & { queryKey: QueryKey }
}

export type PaymentListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof paymentList>>>
export type PaymentListSuspenseQueryError = ErrorType<unknown>


export function usePaymentListSuspense<TData = Awaited<ReturnType<typeof paymentList>>, TError = ErrorType<unknown>>(
 params: undefined |  PaymentListParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePaymentListSuspense<TData = Awaited<ReturnType<typeof paymentList>>, TError = ErrorType<unknown>>(
 params?: PaymentListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePaymentListSuspense<TData = Awaited<ReturnType<typeof paymentList>>, TError = ErrorType<unknown>>(
 params?: PaymentListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePaymentListSuspense<TData = Awaited<ReturnType<typeof paymentList>>, TError = ErrorType<unknown>>(
 params?: PaymentListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPaymentListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const paymentGet = (
    paymentId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaymentHydratedDumpSchema>(
      {url: `/payments/payments/${paymentId}`, method: 'GET', signal
    },
      options);
    }


export const getPaymentGetQueryKey = (paymentId: string,) => {
    return [`/payments/payments/${paymentId}`] as const;
    }


export const getPaymentGetQueryOptions = <TData = Awaited<ReturnType<typeof paymentGet>>, TError = ErrorType<unknown>>(paymentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPaymentGetQueryKey(paymentId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentGet>>> = ({ signal }) => paymentGet(paymentId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(paymentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PaymentGetQueryResult = NonNullable<Awaited<ReturnType<typeof paymentGet>>>
export type PaymentGetQueryError = ErrorType<unknown>


export function usePaymentGet<TData = Awaited<ReturnType<typeof paymentGet>>, TError = ErrorType<unknown>>(
 paymentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof paymentGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePaymentGet<TData = Awaited<ReturnType<typeof paymentGet>>, TError = ErrorType<unknown>>(
 paymentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof paymentGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePaymentGet<TData = Awaited<ReturnType<typeof paymentGet>>, TError = ErrorType<unknown>>(
 paymentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePaymentGet<TData = Awaited<ReturnType<typeof paymentGet>>, TError = ErrorType<unknown>>(
 paymentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPaymentGetQueryOptions(paymentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPaymentGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof paymentGet>>, TError = ErrorType<unknown>>(paymentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPaymentGetQueryKey(paymentId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentGet>>> = ({ signal }) => paymentGet(paymentId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(paymentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PaymentGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof paymentGet>>>
export type PaymentGetSuspenseQueryError = ErrorType<unknown>


export function usePaymentGetSuspense<TData = Awaited<ReturnType<typeof paymentGet>>, TError = ErrorType<unknown>>(
 paymentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePaymentGetSuspense<TData = Awaited<ReturnType<typeof paymentGet>>, TError = ErrorType<unknown>>(
 paymentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePaymentGetSuspense<TData = Awaited<ReturnType<typeof paymentGet>>, TError = ErrorType<unknown>>(
 paymentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePaymentGetSuspense<TData = Awaited<ReturnType<typeof paymentGet>>, TError = ErrorType<unknown>>(
 paymentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof paymentGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPaymentGetSuspenseQueryOptions(paymentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const paymentUpdate = (
    paymentId: string,
    paymentUpdateSchema: BodyType<PaymentUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PaymentHydratedDumpSchema>(
      {url: `/payments/payments/${paymentId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: paymentUpdateSchema
    },
      options);
    }


export const refreshPaymentEvent = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PaymentHydratedDumpSchema>(
      {url: `/payments/payments/${id}/refresh`, method: 'POST'
    },
      options);
    }


export const voidPayment = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PaymentHydratedDumpSchema>(
      {url: `/payments/payments/${id}/void`, method: 'POST'
    },
      options);
    }


export const listPaymentSurcharges = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaymentSurchargeListSchema>(
      {url: `/payments/surcharges`, method: 'GET', signal
    },
      options);
    }


export const getListPaymentSurchargesQueryKey = () => {
    return [`/payments/surcharges`] as const;
    }


export const getListPaymentSurchargesQueryOptions = <TData = Awaited<ReturnType<typeof listPaymentSurcharges>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListPaymentSurchargesQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listPaymentSurcharges>>> = ({ signal }) => listPaymentSurcharges(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData> & { queryKey: QueryKey }
}

export type ListPaymentSurchargesQueryResult = NonNullable<Awaited<ReturnType<typeof listPaymentSurcharges>>>
export type ListPaymentSurchargesQueryError = ErrorType<unknown>


export function useListPaymentSurcharges<TData = Awaited<ReturnType<typeof listPaymentSurcharges>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listPaymentSurcharges>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListPaymentSurcharges<TData = Awaited<ReturnType<typeof listPaymentSurcharges>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listPaymentSurcharges>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListPaymentSurcharges<TData = Awaited<ReturnType<typeof listPaymentSurcharges>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useListPaymentSurcharges<TData = Awaited<ReturnType<typeof listPaymentSurcharges>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getListPaymentSurchargesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListPaymentSurchargesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listPaymentSurcharges>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListPaymentSurchargesQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listPaymentSurcharges>>> = ({ signal }) => listPaymentSurcharges(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData> & { queryKey: QueryKey }
}

export type ListPaymentSurchargesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listPaymentSurcharges>>>
export type ListPaymentSurchargesSuspenseQueryError = ErrorType<unknown>


export function useListPaymentSurchargesSuspense<TData = Awaited<ReturnType<typeof listPaymentSurcharges>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListPaymentSurchargesSuspense<TData = Awaited<ReturnType<typeof listPaymentSurcharges>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListPaymentSurchargesSuspense<TData = Awaited<ReturnType<typeof listPaymentSurcharges>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useListPaymentSurchargesSuspense<TData = Awaited<ReturnType<typeof listPaymentSurcharges>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listPaymentSurcharges>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getListPaymentSurchargesSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updatePaymentSurcharge = (
    paymentSurchargeListSchema: BodyType<PaymentSurchargeListSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PaymentSurchargeListSchema>(
      {url: `/payments/surcharges`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: paymentSurchargeListSchema
    },
      options);
    }


export const calculateSurchargeAmount = (
    paymentSurchargeAmountCheck: BodyType<PaymentSurchargeAmountCheck>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PaymentSurchargeAmountDump>(
      {url: `/payments/surcharges/calculate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: paymentSurchargeAmountCheck
    },
      options);
    }


