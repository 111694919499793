import {
  AppShell as MantineAppShell,
  Burger as MantineBurger,
  Image as MantineImage,
  NavLink as MantineNavLink,
  ScrollArea,
  Title,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import classNames from 'classnames'
import { Fragment, useState, type ReactNode } from 'react'
import { Link, NavLink, useLocation, type To } from 'react-router-dom'
import styled from 'styled-components'

import { useCurrentUser, type LocationDumpSchema } from '_autogenerated'
import { useLocationSelectProps } from 'apis/location'
import { useUpdateCurrentUserLocation } from 'apis/user'
import logo from 'assets/logo.svg'

import { kuiThemeVars } from './_internal/theme'
import { KuiSelect } from './inputs'
import { KuiFlex } from './KuiFlex'
import { KuiIcon, type KuiIconType } from './KuiIcon/KuiIcon'
import { KuiMenu, type KuiMenuProps } from './KuiMenu'
import { KuiPad } from './KuiPad'
import { KuiStack } from './KuiStack'
import { useKuiMediaQuery } from './useKuiMediaQuery'
import { wrapKuiApiRequest } from './wrapKuiApiRequest'

const KuiAppShellHeaderRoot = styled(MantineAppShell.Header)`
  background-color: ${kuiThemeVars.colors.mediumShade};
  display: flex;
  flex-direction: column;
`

const KuiAppShellNavbarRoot = styled(MantineAppShell.Navbar)`
  background-color: ${kuiThemeVars.colors.mediumShade};
`

const KuiAppShellMainRoot = styled(MantineAppShell.Main)`
  background-color: ${kuiThemeVars.colors.lightShade};
`

const CALLOUT_BANNER_HEIGHT = '30px'

const KuiAppShellCalloutBannerRoot = styled.div`
  min-height: ${CALLOUT_BANNER_HEIGHT};
  background-color: #c65102;
  color: white;
`

export type KuiAppShellProps = {
  callout?: string
  children?: ReactNode

  navbarProps: Pick<
    KuiAppShellNavBarProps,
    'items' | 'footerItems' | 'footerMenu' | 'onSearchClick'
  >

  renderSpotlight?: (_: { closeNavbar: () => void }) => ReactNode
}

export function KuiAppShell({
  callout,
  navbarProps,
  renderSpotlight,
  children,
}: KuiAppShellProps) {
  const [opened, { toggle, close }] = useDisclosure()

  const isMobile = useKuiMediaQuery({ maxWidth: 'sm' })

  const headerHeight = getHeaderHeight()

  return (
    <MantineAppShell
      header={
        headerHeight
          ? {
              height: headerHeight,
              collapsed: false,
            }
          : undefined
      }
      navbar={{
        width: 240,
        breakpoint: 'sm',
        collapsed: { mobile: !opened },
      }}
      padding='0'
    >
      {renderSpotlight?.({ closeNavbar: close })}

      <KuiAppShellHeaderRoot>
        {callout && (
          <KuiAppShellCalloutBannerRoot>
            <KuiFlex justifyContent='center' fullHeight={true}>
              <Title order={6}>{callout}</Title>
            </KuiFlex>
          </KuiAppShellCalloutBannerRoot>
        )}

        {isMobile && (
          <KuiFlex
            justifyContent='spaceBetween'
            fullHeight={true}
            padding={{ horizontalSize: 'md' }}
          >
            <div>
              <Link to='/'>
                <MantineImage src={logo} fit='contain' h='20px' />
              </Link>
            </div>

            <MantineBurger opened={opened} onClick={toggle} size='md' />
          </KuiFlex>
        )}
      </KuiAppShellHeaderRoot>

      <KuiAppShellNavbarRoot pt={isMobile ? 'md' : 'xl'} pb='md'>
        {!isMobile && (
          <MantineAppShell.Section px='28px' pb='xl'>
            <Link to='/'>
              <MantineImage src={logo} fit='contain' w='130px' />
            </Link>
          </MantineAppShell.Section>
        )}

        <KuiAppShellNavBar {...navbarProps} onCollapse={close} />
      </KuiAppShellNavbarRoot>

      <KuiAppShellMainRoot>
        <KuiPad size={isMobile ? 'md' : 'xl'}>{children}</KuiPad>
      </KuiAppShellMainRoot>
    </MantineAppShell>
  )

  function getHeaderHeight() {
    if (isMobile) {
      return callout ? `calc(50px + ${CALLOUT_BANNER_HEIGHT})` : '50px'
    }

    return callout ? CALLOUT_BANNER_HEIGHT : undefined
  }
}

const KuiNavItemsStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2px;
`

export type KuiNavItem = {
  iconType: KuiIconType
  label: string
  to?: To
  onClick?: () => void

  children?: Omit<KuiNavItem, 'iconType'>[]
}

type KuiAppShellNavBarProps = {
  items: KuiNavItem[]
  footerItems?: KuiNavItem[]
  footerMenu?: Pick<KuiMenuProps, 'items'> &
    Pick<KuiNavItem, 'label' | 'iconType'>

  onCollapse: () => void

  onSearchClick?: () => void
}

function KuiAppShellNavBar({
  items,
  footerItems = [],
  footerMenu,
  onCollapse,
  onSearchClick,
}: KuiAppShellNavBarProps) {
  const location = useLocation()

  const [expandedItem, setExpandedItem] = useState<string | null>(
    () =>
      [...items, ...footerItems].find((item) =>
        item.children?.some(
          (child) =>
            typeof child.to === 'string' &&
            location.pathname.startsWith(child.to)
        )
      )?.label ?? null
  )

  return (
    <Fragment>
      <MantineAppShell.Section grow={true} component={ScrollArea} px='md'>
        <KuiStack gapSize='sm'>
          <BranchSelector />

          {onSearchClick && (
            <KuiNavItem
              iconType='search'
              label='Search'
              onClick={onSearchClick}
            />
          )}

          <KuiNavItemsStack>
            {items.map((item) => (
              <KuiNavItem
                key={item.label}
                {...item}
                expanded={item.label === expandedItem}
                onClick={() => {
                  item.onClick?.()

                  if (item.children && item.children.length > 0) {
                    setExpandedItem((prev) =>
                      prev === item.label ? null : item.label
                    )
                  }
                }}
                onCollapse={onCollapse}
              />
            ))}
          </KuiNavItemsStack>
        </KuiStack>
      </MantineAppShell.Section>

      <MantineAppShell.Section px='md'>
        <KuiNavItemsStack>
          {footerItems.map((item) => (
            <KuiNavItem
              key={item.label}
              {...item}
              expanded={item.label === expandedItem}
              onClick={() => {
                item.onClick?.()

                if (item.children && item.children.length > 0) {
                  setExpandedItem((prev) =>
                    prev === item.label ? null : item.label
                  )
                }
              }}
              onCollapse={onCollapse}
            />
          ))}

          {footerMenu && (
            <KuiMenu
              width='target'
              _target={
                <div>
                  <KuiNavItem
                    iconType={footerMenu.iconType}
                    label={footerMenu.label}
                  />
                </div>
              }
              {...footerMenu}
              items={footerMenu.items.map((item) => ({
                ...item,
                onClick: () => {
                  item.onClick?.()
                  onCollapse()
                },
              }))}
            />
          )}
        </KuiNavItemsStack>
      </MantineAppShell.Section>
    </Fragment>
  )
}

const KuiNavItemRoot = styled(MantineNavLink)`
  border-radius: var(--mantine-radius-sm);
  font-weight: 400;
  color: var(--mantine-color-dark-9);

  outline-offset: -2px !important;

  &:hover {
    background-color: var(--mantine-color-dark-light-hover);
  }

  &.KuiNavItemRoot--active {
    background-color: black;
    color: var(--mantine-color-white) !important;
  }
` as typeof MantineNavLink

type KuiNavItemProps = {
  iconType?: KuiIconType
  label: string

  to?: To
  onClick?: () => void

  onCollapse?: () => void

  expanded?: boolean

  children?: Omit<KuiNavItem, 'iconType'>[]

  rightSection?: ReactNode
}

function KuiNavItem({
  label,
  iconType,
  to,
  children = [],
  expanded,
  rightSection,
  onClick,
  onCollapse,
}: KuiNavItemProps) {
  return (
    <KuiNavItemRoot
      label={label}
      leftSection={iconType ? <KuiIcon type={iconType} /> : undefined}
      rightSection={rightSection}
      opened={expanded}
      component={!to ? 'button' : undefined}
      renderRoot={
        to
          ? (props) => (
              <NavLink
                to={to}
                {...props}
                className={({ isActive }) =>
                  classNames(props.className, {
                    'KuiNavItemRoot--active': isActive,
                  })
                }
              />
            )
          : undefined
      }
      onClick={() => {
        onClick?.()

        if (!children.length) {
          onCollapse?.()
        }
      }}
    >
      {children.length > 0 && (
        <KuiNavItemsStack>
          {children.map((child) => (
            <KuiNavItem key={child.label} {...child} onCollapse={onCollapse} />
          ))}
        </KuiNavItemsStack>
      )}
    </KuiNavItemRoot>
  )
}

const allBranchesItem = Symbol()

function BranchSelector() {
  const { data: currentUser } = useCurrentUser()

  const locationSelectProps = useLocationSelectProps()

  const currentLocation = currentUser?.current_location ?? allBranchesItem

  const updateUserLocation = useUpdateCurrentUserLocation()

  const [optimisticLocation, setOptimisticLocation] = useState<
    typeof allBranchesItem | LocationDumpSchema | null
  >(null)

  return (
    <KuiSelect
      {...locationSelectProps}
      value={currentLocation}
      items={[allBranchesItem, ...locationSelectProps.items]}
      parseItem={parseLocationItem}
      dropdownMinWidth='relative'
      renderTarget={({ toggleDropdown }) => (
        <div>
          <KuiNavItem
            iconType='building-warehouse'
            label={
              parseLocationItem(optimisticLocation ?? currentLocation).label
            }
            rightSection={<KuiIcon type='chevron-down' />}
            onClick={toggleDropdown}
          />
        </div>
      )}
      onChange={onChange}
    />
  )

  async function onChange(
    nextLocation: typeof allBranchesItem | LocationDumpSchema
  ) {
    try {
      setOptimisticLocation(nextLocation)
      await wrapKuiApiRequest(() =>
        updateUserLocation({
          current_location_id:
            nextLocation === allBranchesItem
              ? null
              : (nextLocation as LocationDumpSchema).id,
        })
      )()
    } finally {
      setOptimisticLocation(null)
    }
  }

  function parseLocationItem(
    _location: LocationDumpSchema | typeof allBranchesItem
  ) {
    if (_location === allBranchesItem) {
      return { key: 'all-branches', label: 'All branches' }
    }

    const location = _location as LocationDumpSchema

    return { key: location.id, label: location.name }
  }
}
