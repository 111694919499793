import { lazily } from 'react-lazily'

import {
  getGetCommissionMatrixQueryOptions,
  getGetCustomFormQueryOptions,
  getListCustomerCommissionGroupsQueryOptions,
  getListInventoryCommissionGroupsQueryOptions,
  getProcedureGetQueryOptions,
} from '_autogenerated'
import { configurationSettingsUrls } from 'routes/Settings/settingsUrls'
import { createRoute } from 'routing'

const {
  ConfigurationsSettingsRoute,
  BillingProfileSettingsTabContent,
  BillingProfileSettingsCreatePage,
  BillingProfileSettingsDetailPage,
  BillingProfileSettingsEditPage,
  DepreciationSettingsTabContent,
  DepreciationSettingsCreatePage,
  DepreciationSettingsDetailPage,
  DepreciationSettingsEditPage,
  TaxRateSettingsTabContent,
  TaxRateCreatePage,
  TaxRateDetailPage,
  TaxRateEditPage,
  UsageMetricTypeSettingsTabContent,
  UsageMetricTypeCreatePage,
  UsageMetricTypeDetailPage,
  UsageMetricTypeEditPage,
  CommissionMatricesSettingsPage,
  CommissionMatrixCreatePage,
  CommissionMatrixEditPage,
  CustomFormsSettingsPage,
  CustomFormCreatePage,
  CustomFormEditPage,
  ServiceProceduresSettingsPage,
  ServiceProcedureCreatePage,
  ServiceProcedureEditPage,
} = lazily(() => import('./components'))

export const configurationSettingsRoutes = [
  createRoute({
    route: configurationSettingsUrls,
    Component: ConfigurationsSettingsRoute,
    children: [
      createRoute({
        route: configurationSettingsUrls,
        redirect: configurationSettingsUrls.billing.buildUrl(),
      }),
      createRoute({
        route: configurationSettingsUrls.billing,
        Component: BillingProfileSettingsTabContent,
      }),
      createRoute({
        route: configurationSettingsUrls.taxes,
        Component: TaxRateSettingsTabContent,
      }),
      createRoute({
        route: configurationSettingsUrls.depreciation,
        Component: DepreciationSettingsTabContent,
      }),
      createRoute({
        route: configurationSettingsUrls.meters,
        Component: UsageMetricTypeSettingsTabContent,
      }),
      createRoute({
        route: configurationSettingsUrls.commissionMatrix,
        Component: CommissionMatricesSettingsPage,
      }),
      createRoute({
        route: configurationSettingsUrls.customForms,
        Component: CustomFormsSettingsPage,
      }),
      createRoute({
        route: configurationSettingsUrls.serviceProcedures,
        Component: ServiceProceduresSettingsPage,
      }),
    ],
  }),
  createRoute({
    route: configurationSettingsUrls.billing.create,
    Component: BillingProfileSettingsCreatePage,
  }),
  createRoute({
    route: configurationSettingsUrls.billing.detail,
    Component: BillingProfileSettingsDetailPage,
  }),
  createRoute({
    route: configurationSettingsUrls.billing.edit,
    Component: BillingProfileSettingsEditPage,
  }),
  createRoute({
    route: configurationSettingsUrls.taxes.create,
    Component: TaxRateCreatePage,
  }),
  createRoute({
    route: configurationSettingsUrls.taxes.detail,
    Component: TaxRateDetailPage,
  }),
  createRoute({
    route: configurationSettingsUrls.taxes.edit,
    Component: TaxRateEditPage,
  }),
  createRoute({
    route: configurationSettingsUrls.depreciation.create,
    Component: DepreciationSettingsCreatePage,
  }),
  createRoute({
    route: configurationSettingsUrls.depreciation.detail,
    Component: DepreciationSettingsDetailPage,
  }),
  createRoute({
    route: configurationSettingsUrls.depreciation.edit,
    Component: DepreciationSettingsEditPage,
  }),
  createRoute({
    route: configurationSettingsUrls.meters.create,
    Component: UsageMetricTypeCreatePage,
  }),
  createRoute({
    route: configurationSettingsUrls.meters.detail,
    Component: UsageMetricTypeDetailPage,
  }),
  createRoute({
    route: configurationSettingsUrls.meters.edit,
    Component: UsageMetricTypeEditPage,
  }),
  createRoute({
    route: configurationSettingsUrls.commissionMatrix.create,
    Component: CommissionMatrixCreatePage,
    prefetch: () => [
      getListCustomerCommissionGroupsQueryOptions(),
      getListInventoryCommissionGroupsQueryOptions(),
    ],
  }),
  createRoute({
    route: configurationSettingsUrls.commissionMatrix.edit,
    Component: CommissionMatrixEditPage,
    prefetch: ({ id }) => [
      getListCustomerCommissionGroupsQueryOptions(),
      getListInventoryCommissionGroupsQueryOptions(),
      getGetCommissionMatrixQueryOptions(id),
    ],
  }),
  createRoute({
    route: configurationSettingsUrls.customForms.create,
    Component: CustomFormCreatePage,
  }),
  createRoute({
    route: configurationSettingsUrls.customForms.edit,
    Component: CustomFormEditPage,
    prefetch: ({ id }) => [getGetCustomFormQueryOptions(id)],
  }),
  createRoute({
    route: configurationSettingsUrls.serviceProcedures.create,
    Component: ServiceProcedureCreatePage,
  }),
  createRoute({
    route: configurationSettingsUrls.serviceProcedures.edit,
    Component: ServiceProcedureEditPage,
    prefetch: ({ id }) => [getProcedureGetQueryOptions(id)],
  }),
]
