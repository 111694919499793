import { businessSettingsRoutes } from './Business'
import { communicationSettingsRoutes } from './Communications'
import { configurationSettingsRoutes } from './Configurations'
import { integrationSettingsRoutes } from './Integrations'
import { personalSettingsRoutes } from './PersonalSettings'
import { pricingSettingsRoutes } from './Pricing'

export const settingsRoutes = [
  ...businessSettingsRoutes,
  ...configurationSettingsRoutes,
  ...pricingSettingsRoutes,
  ...communicationSettingsRoutes,
  ...integrationSettingsRoutes,
  ...personalSettingsRoutes,
]
