/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import type {
  LoginBodySchema,
  SuccessResponse
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Logs a user in
 */
export const login = (
    loginBodySchema: BodyType<LoginBodySchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/auth/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: loginBodySchema
    },
      options);
    }


/**
 * @summary Logs a user out
 */
export const logout = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/auth/logout`, method: 'POST'
    },
      options);
    }


