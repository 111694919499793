import { format, parse, set, type DateValues } from 'date-fns'
import { formatInTimeZone, fromZonedTime, toZonedTime } from 'date-fns-tz'
import { useCallback } from 'react'

import { useCurrentUserSuspense, type TimezoneEnum } from '_autogenerated'

import { isLocalDev } from './helpers'

export type KuiTimezone = TimezoneEnum | 'UTC'

export function useCurrentUserTimezone() {
  const { data: currentUser } = useCurrentUserSuspense()

  return currentUser.current_location?.timezone ?? LOCAL_TIMEZONE
}

export function useFormatDateForCurrentUser() {
  const timezone = useCurrentUserTimezone()

  const formatDatetimeForCurrentUser = useCallback(
    <TDate extends DateOrDateString | null | undefined>(
      date: TDate,
      options?: Omit<FormatDatetimeOptions, 'timezone'>
    ) =>
      formatDatetime(date, {
        ...options,
        timezone,
      }),
    [timezone]
  )

  return { formatDatetime: formatDatetimeForCurrentUser }
}

const dateFormatMap = {
  short: 'MM/dd/yyyy',
  // medium: 'MMM d, yyyy',
}

const dateTimeFormatMap = {
  short__date_only: 'MM/dd/yyyy',
  short__time_only: 'h:mm a zzz',
  short: 'MM/dd/yyyy h:mm a zzz',
  // medium: 'MMM d, yyyy h:mm a zzz',
}

type DateTimeFormat = keyof typeof dateTimeFormatMap

type DateOrDateString = Date | number | string

type FormatDatetimeOptions = {
  timezone: KuiTimezone
  style?: DateTimeFormat

  /** @default true */
  withTime?: boolean
}

export function formatDatetime<
  TDate extends DateOrDateString | null | undefined,
>(
  date: TDate,
  { timezone, withTime = true, style = 'short' }: FormatDatetimeOptions
): TDate extends null | undefined ? null : string {
  if (isLocalDev && typeof date === 'string' && !date.includes('T')) {
    throw new Error(
      'formatDatetime should only be called with iso strings. Did you mean to use formatDate instead?'
    )
  }

  return (
    date
      ? formatInTimeZone(
          date,
          timezone,
          dateTimeFormatMap[withTime === false ? 'short__date_only' : style]
        )
      : undefined
  ) as any
}

/**
 * Use for dates without a timestamp (e.g. 2021-01-01)
 */
export function formatDate<TDate extends string | null | undefined>(
  date: TDate
): TDate extends null | undefined ? null : string {
  if (isLocalDev && date?.includes('T')) {
    throw new Error(
      'formatDate should be used for dates without a timestamp (e.g. 2021-01-01). Did you mean to use formatDatetime instead?'
    )
  }

  return (
    date ? formatInTimeZone(date, 'UTC', dateFormatMap.short) : undefined
  ) as any
}

export function formatTimeString(time: string) {
  return format(parse(time, 'HH:mm:ss', new Date()), 'h:mma')
}

export function dateToApiDate<TDate extends Date | null | undefined>(
  date: TDate
): TDate extends Date ? string : null {
  if (!date) {
    return null as any
  }

  return date.toISOString().split('T')[0] as any
}

export function dateToApiDatetime<TDate extends Date | null | undefined>(
  date: TDate
): TDate extends Date ? string : null {
  if (!date) {
    return null as any
  }

  return date.toISOString() as any
}

export function enrichDateWithTime(
  date: Date,
  timezone: KuiTimezone,
  timeString: string
): Date {
  const [hours, minutes] = timeString.split(':')

  return setInTimezone(date, timezone, {
    hours: parseInt(hours),
    minutes: parseInt(minutes),
  })
}

export function setInTimezone(
  date: Date,
  timezone: KuiTimezone,
  values: DateValues
): Date {
  const newDate = set(toZonedTime(date, timezone), values)

  return fromZonedTime(newDate, timezone)
}

export const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions()
  .timeZone as TimezoneEnum
