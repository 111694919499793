import {
  Box,
  Divider,
  Flex,
  Space,
  Stack,
  Text,
  Title,
  type BoxProps,
  type StackProps,
} from '@mantine/core'
import clsx from 'clsx'
import { type PropsWithChildren, type ReactNode } from 'react'

import { KuiFlex, KuiStack } from 'components/kui'

import classes from './KyusuCard.module.css'

export type KyusuCardProps = PropsWithChildren<{}> & {
  titleSection?: ReactNode
  title?: string
  subtitle?: string
  error?: boolean
  rightSection?: ReactNode
} & BoxProps

export function KyusuCardDivider() {
  return <Divider ml='-16' mr='-16' />
}

export function KyusuCardSubsection({
  title,
  children,
}: { title: string } & PropsWithChildren<{}>) {
  return (
    <Box>
      <Title order={5}>{title}</Title>
      <Space h='xs' />
      <Stack>{children}</Stack>
    </Box>
  )
}

const Body = ({ children, ...props }: { children: ReactNode } & StackProps) => (
  <Stack {...props}>{children}</Stack>
)

const Footer = ({ children, ...props }: { children: ReactNode } & BoxProps) => (
  <Box {...props}>{children}</Box>
)

function Header({
  title,
  subtitle,
  titleSection,
  rightSection,
}: Pick<
  KyusuCardProps,
  'title' | 'titleSection' | 'rightSection' | 'subtitle'
>) {
  return title || titleSection || rightSection ? (
    <KuiFlex direction='row' justifyContent='spaceBetween'>
      <Box>
        {titleSection}
        {(title || subtitle) && (
          <Flex direction='column'>
            {title && <Title order={5}>{title}</Title>}
            {subtitle && (
              <Text c='dark.2' size='sm'>
                {subtitle}
              </Text>
            )}
          </Flex>
        )}
      </Box>

      {rightSection}
    </KuiFlex>
  ) : (
    <></>
  )
}

export function KyusuBaseCard({
  title,
  subtitle,
  titleSection,
  children,
  rightSection,
  error,
  ...props
}: KyusuCardProps) {
  return (
    <Box
      className={clsx(classes.kyusuCard, {
        [classes.kyusuCardError]: error,
      })}
      bg='white'
      {...props}
    >
      <Box pl='md' pr='md'>
        <Header
          title={title}
          subtitle={subtitle}
          titleSection={titleSection}
          rightSection={rightSection}
        />
      </Box>
      <Box p={props.p || 'md'} h='100%'>
        {children}
      </Box>
    </Box>
  )
}

function KyusuCard({
  title,
  subtitle,
  titleSection,
  rightSection,
  ...props
}: KyusuCardProps) {
  return (
    <KyusuBaseCard {...props}>
      <KuiStack gapSize='md'>
        <Header
          title={title}
          subtitle={subtitle}
          titleSection={titleSection}
          rightSection={rightSection}
        />

        {props.children}
      </KuiStack>
    </KyusuBaseCard>
  )
}

KyusuCard.Body = Body
KyusuCard.Footer = Footer
KyusuCard.Header = Header
KyusuCard.Divider = KyusuCardDivider

export default KyusuCard
