import {
  Badge as MantineBadge,
  type BadgeProps as MantineBadgeProps,
} from '@mantine/core'
import { type ReactNode } from 'react'

import { KuiIcon, type KuiIconType } from './KuiIcon/KuiIcon'

export type KuiBadgeColor =
  | 'constructive'
  | 'destructive'
  | 'warning'
  | 'accent'
  | 'secondaryAccent'
  | 'tertiaryAccent'
  | 'disabled'

const colorMap: Record<KuiBadgeColor, MantineBadgeProps['color']> = {
  constructive: 'green',
  destructive: 'red',
  warning: 'orange',
  accent: 'blue',
  secondaryAccent: 'violet',
  tertiaryAccent: 'cyan',
  disabled: 'gray',
}

type KuiBadgeIconSide = 'left' | 'right'

export type KuiBadgeProps = {
  color: KuiBadgeColor

  iconType?: KuiIconType

  /** @default 'right' */
  iconSide?: KuiBadgeIconSide

  children: ReactNode
}

export function KuiBadge({
  color,
  iconType,
  iconSide = 'right',
  children,
}: KuiBadgeProps) {
  return (
    <MantineBadge
      variant='light'
      color={colorMap[color]}
      leftSection={
        iconType && iconSide === 'left' ? (
          <KuiIcon
            type={iconType}
            size={iconType === 'loading_animated' ? 'xs' : 's'}
          />
        ) : undefined
      }
      rightSection={
        iconType && iconSide === 'right' ? (
          <KuiIcon
            type={iconType}
            size={iconType === 'loading_animated' ? 'xs' : 's'}
          />
        ) : undefined
      }
    >
      {children}
    </MantineBadge>
  )
}
