/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
export type RepairListParams = {
start?: number;
page_size?: number;
search?: string;
order_ids?: string[];
statuses?: string[];
};

export type ProcedureListParams = {
start?: number;
page_size?: number;
search?: string;
product_ids?: string[];
};

export type ListCommissionMatrixsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListInventoryCommissionGroupsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListCustomerCommissionGroupsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type UsersParams = {
start?: number;
page_size?: number;
search?: string;
};

export type GetProductCodesParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListTagsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type SerpImagesParams = {
query: string;
start?: number;
};

export type OrderListParams = {
start?: number;
page_size?: number;
search?: string;
statuses?: string[];
customer_ids?: string[];
asset_ids?: string[];
klass_ids?: string[];
location_ids?: string[];
is_signed?: boolean;
sources?: string[];
expires_at_start?: string;
expires_at_end?: string;
amount_min?: string;
amount_max?: string;
amount_due_min?: string;
amount_due_max?: string;
contract_starts_at?: string;
contract_ends_at?: string;
billing_starts_at?: string;
billing_ends_at?: string;
};

export type BillingProfileListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type InvoiceListParams = {
start?: number;
page_size?: number;
search?: string;
customer_ids?: string[];
order_ids?: string[];
location_ids?: string[];
types?: string[];
statuses?: string[];
issue_date_start?: string;
issue_date_end?: string;
due_date_start?: string;
due_date_end?: string;
amount_min?: string;
amount_max?: string;
amount_due_nonzero?: boolean;
};

export type DepositListParams = {
start?: number;
page_size?: number;
search?: string;
statuses?: string[];
deposit_at_start?: string;
deposit_at_end?: string;
type?: string;
amount_min?: string;
amount_max?: string;
};

export type GetPaymentSummaryReportParams = {
start_date: string;
end_date?: string;
};

export type GetAccountsReceivableReportParams = {
as_of_date?: string;
};

export type ItemReceiptListParams = {
start?: number;
page_size?: number;
search?: string;
purchase_order_ids?: string[];
posting_date?: string;
};

export type VendorBillListParams = {
start?: number;
page_size?: number;
search?: string;
purchase_order_ids?: string[];
posting_date?: string;
due_date?: string;
};

export type PurchaseOrderListParams = {
start?: number;
page_size?: number;
search?: string;
statuses?: string[];
posting_date_start?: string;
posting_date_end?: string;
amount_min?: string;
amount_max?: string;
location_ids?: string[];
vendor_ids?: string[];
klass_ids?: string[];
};

export type VendorListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type PaymentListParams = {
start?: number;
page_size?: number;
search?: string;
customer_ids?: string[];
is_reconciled?: boolean;
is_deposit_reconciled?: boolean;
payment_providers?: string[];
paid_at_start?: string;
paid_at_end?: string;
amount_min?: string;
amount_max?: string;
payment_types?: string[];
statuses?: string[];
};

export type ListKitsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type GetProductsParams = {
start?: number;
page_size?: number;
search?: string;
vendor_id?: string;
};

export type RateTemplatesParams = {
start?: number;
page_size?: number;
search?: string;
is_business_default?: boolean;
};

export type RateProfilesParams = {
start?: number;
page_size?: number;
search?: string;
};

export type UsageMetricTypesParams = {
start?: number;
page_size?: number;
search?: string;
type?: string;
};

export type GetKlassesOrderSearchParams = {
start?: number;
page_size?: number;
search?: string;
category_ids?: string[];
types?: string[];
tracking?: string;
visible_storefront?: boolean;
visible_orders?: boolean;
};

export type GetKlassAvailabilityParams = {
start_date?: string;
end_date?: string;
location_id?: string;
location_group_id?: string;
};

export type GetKlassesParams = {
start?: number;
page_size?: number;
search?: string;
category_ids?: string[];
types?: string[];
tracking?: string;
visible_storefront?: boolean;
visible_orders?: boolean;
};

export type AssetUsageMetricParams = {
start?: number;
page_size?: number;
};

export type AssetListParams = {
start?: number;
page_size?: number;
tracking: string;
search?: string;
types?: string[];
product_ids?: string[];
klass_ids?: string[];
category_ids?: string[];
location_ids?: string[];
statuses?: string[];
};

export type AttachmentsParams = {
start?: number;
page_size?: number;
search?: string;
type: string;
asset_id?: string;
category_id?: string;
klass_id?: string;
};

export type ExpenseClassListParams = {
start?: number;
page_size?: number;
search?: string;
fees_only?: boolean;
discounts_only?: boolean;
customer_id?: string;
billing_profile_id?: string;
klass_id?: string;
kit_line_item_id?: string;
};

export type ListDeliveryRequestsParams = {
start?: number;
page_size?: number;
location_ids?: string[];
date?: string;
is_assigned?: boolean;
include_all_incomplete_pickups?: boolean;
};

export type ListDeliveryDriversParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListDeliveryTrucksParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListDeliveryRoutesParams = {
start?: number;
page_size?: number;
search?: string;
delivery_date?: string;
location_id?: string;
};

export type DeliveryConfigurationsParams = {
start?: number;
page_size?: number;
search?: string;
};

export type GetCustomFormsParams = {
start?: number;
page_size?: number;
label?: string;
types?: string[];
usage_types?: string[];
};

export type CustomerGetAddressesParams = {
start?: number;
page_size?: number;
customer_id: string;
search?: string;
};

export type CustomerWithContactListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type CustomerListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListTermsAndConditionsAddendumsParams = {
start?: number;
page_size?: number;
};

export type LocationGroupListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type LocationListParams = {
start?: number;
page_size?: number;
search?: string;
};

export type ListTemplatesParams = {
start?: number;
page_size?: number;
search?: string;
type?: string;
};

export type GetCategoriesParams = {
start?: number;
page_size?: number;
search?: string;
deleted_only?: boolean;
types?: string[];
};

export type JournalEntryListParams = {
start?: number;
page_size?: number;
statuses?: string[];
related_objects?: string[];
};

export type RemoteObjectsParams = {
start?: number;
page_size?: number;
type: string;
search?: string;
};

export type RemoteAccountsParams = {
start?: number;
page_size?: number;
search?: string;
account_number?: string;
};

export type LedgerAccountsParams = {
start?: number;
page_size?: number;
search?: string;
account_number?: string;
};

export type InventoryProfileListParams = {
start?: number;
page_size?: number;
search?: string;
asset_types?: string[];
};

export type AccountMappingsParams = {
inventory_profile_ids?: string[];
};

export type DepreciationSettingsParams = {
start?: number;
page_size?: number;
search?: string;
};

/**
 * @nullable
 */
export type RepairDirectCreateSchemaScheduledFor = string | null;

/**
 * @nullable
 */
export type RepairDirectCreateSchemaProcedureId = string | null;

/**
 * @nullable
 */
export type RepairDirectCreateSchemaOrderId = string | null;

export interface RepairDirectCreateSchema {
  asset_id: string;
  line_items: LineItemSaleCreateSchema[];
  /** @nullable */
  order_id?: RepairDirectCreateSchemaOrderId;
  /** @nullable */
  procedure_id?: RepairDirectCreateSchemaProcedureId;
  /** @nullable */
  scheduled_for?: RepairDirectCreateSchemaScheduledFor;
  type: ProcedureType;
}

/**
 * @nullable
 */
export type RepairHydratedDumpSchemaScheduledFor = string | null;

/**
 * @nullable
 */
export type RepairHydratedDumpSchemaProcedureId = string | null;

/**
 * @nullable
 */
export type RepairHydratedDumpSchemaProcedure = LinkNameSchema | null;

/**
 * @nullable
 */
export type RepairHydratedDumpSchemaCustomFormResponse = CustomFormResponseDumpSchema | null;

export interface RepairHydratedDumpSchema {
  actions: RepairAction[];
  activity_log_id: string;
  asset: AssetDumpSchema;
  asset_id: string;
  /** @nullable */
  custom_form_response?: RepairHydratedDumpSchemaCustomFormResponse;
  id: string;
  identifier: string;
  line_items: SaleLineItemHydratedDumpSchema[];
  order: RepairOrderSchema;
  /** @nullable */
  procedure?: RepairHydratedDumpSchemaProcedure;
  /** @nullable */
  procedure_id?: RepairHydratedDumpSchemaProcedureId;
  /** @nullable */
  scheduled_for?: RepairHydratedDumpSchemaScheduledFor;
  type: ProcedureType;
}

/**
 * @nullable
 */
export type PaginatedRepairSchemaNext = number | null;

export interface PaginatedRepairSchema {
  elements: RepairDumpSchema[];
  /** @nullable */
  next?: PaginatedRepairSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type ProcedureUpdateSchemaProductId = string | null;

/**
 * @nullable
 */
export type ProcedureUpdateSchemaCustomFormId = string | null;

export interface ProcedureUpdateSchema {
  /** @nullable */
  custom_form_id?: ProcedureUpdateSchemaCustomFormId;
  line_items: ProcedureLineItemCreateSchema[];
  name: string;
  /** @nullable */
  product_id?: ProcedureUpdateSchemaProductId;
  type: ProcedureType;
}

/**
 * @nullable
 */
export type ProcedureCreateSchemaProductId = string | null;

/**
 * @nullable
 */
export type ProcedureCreateSchemaCustomFormId = string | null;

export interface ProcedureLineItemCreateSchema {
  klass_id: string;
  quantity: number;
}

export interface ProcedureCreateSchema {
  /** @nullable */
  custom_form_id?: ProcedureCreateSchemaCustomFormId;
  line_items: ProcedureLineItemCreateSchema[];
  name: string;
  /** @nullable */
  product_id?: ProcedureCreateSchemaProductId;
  type: ProcedureType;
}

/**
 * @nullable
 */
export type ProcedureHydratedDumpSchemaProductId = string | null;

/**
 * @nullable
 */
export type ProcedureHydratedDumpSchemaProduct = LinkNameSchema | null;

/**
 * @nullable
 */
export type ProcedureHydratedDumpSchemaCustomFormId = string | null;

/**
 * @nullable
 */
export type ProcedureHydratedDumpSchemaCustomForm = CustomFormDumpSchema | null;

export interface ProcedureLineItemDumpSchema {
  id: string;
  klass: KlassDumpSchema;
  klass_id: string;
  quantity: number;
}

export interface ProcedureHydratedDumpSchema {
  /** @nullable */
  custom_form?: ProcedureHydratedDumpSchemaCustomForm;
  /** @nullable */
  custom_form_id?: ProcedureHydratedDumpSchemaCustomFormId;
  id: string;
  line_items: ProcedureLineItemDumpSchema[];
  name: string;
  /** @nullable */
  product?: ProcedureHydratedDumpSchemaProduct;
  /** @nullable */
  product_id?: ProcedureHydratedDumpSchemaProductId;
  type: ProcedureType;
}

/**
 * @nullable
 */
export type PaginatedProcedureSchemaNext = number | null;

export interface PaginatedProcedureSchema {
  elements: ProcedureDumpSchema[];
  /** @nullable */
  next?: PaginatedProcedureSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type ProcedureDumpSchemaProductId = string | null;

/**
 * @nullable
 */
export type ProcedureDumpSchemaProduct = LinkNameSchema | null;

/**
 * @nullable
 */
export type ProcedureDumpSchemaCustomFormId = string | null;

/**
 * @nullable
 */
export type ProcedureDumpSchemaCustomForm = LinkNameSchema | null;

export interface ProcedureDumpSchema {
  /** @nullable */
  custom_form?: ProcedureDumpSchemaCustomForm;
  /** @nullable */
  custom_form_id?: ProcedureDumpSchemaCustomFormId;
  id: string;
  name: string;
  /** @nullable */
  product?: ProcedureDumpSchemaProduct;
  /** @nullable */
  product_id?: ProcedureDumpSchemaProductId;
  type: ProcedureType;
}

export type DynamicActionBodyAction = OrderDynamicObjectActionBody | ItemReceiptDynamicObjectActionBody | PurchaseOrderDynamicObjectActionBody | VendorBillDynamicObjectActionBody | RepairDynamicObjectActionBody;

export interface DynamicActionBody {
  action: DynamicActionBodyAction;
}

export type VendorBillViewInErpDynamicActionBodyAction = typeof VendorBillViewInErpDynamicActionBodyAction[keyof typeof VendorBillViewInErpDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorBillViewInErpDynamicActionBodyAction = {
  view_in_erp: 'view_in_erp',
} as const;

export interface VendorBillViewInErpDynamicActionBody {
  action: VendorBillViewInErpDynamicActionBodyAction;
}

export type VendorBillSyncDynamicActionBodyAction = typeof VendorBillSyncDynamicActionBodyAction[keyof typeof VendorBillSyncDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorBillSyncDynamicActionBodyAction = {
  sync: 'sync',
} as const;

export interface VendorBillSyncDynamicActionBody {
  action: VendorBillSyncDynamicActionBodyAction;
}

export type VendorBillErpLinkLoadingDynamicActionBodyAction = typeof VendorBillErpLinkLoadingDynamicActionBodyAction[keyof typeof VendorBillErpLinkLoadingDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorBillErpLinkLoadingDynamicActionBodyAction = {
  erp_link_loading: 'erp_link_loading',
} as const;

export interface VendorBillErpLinkLoadingDynamicActionBody {
  action: VendorBillErpLinkLoadingDynamicActionBodyAction;
}

export type VendorBillEditDynamicActionBodyAction = typeof VendorBillEditDynamicActionBodyAction[keyof typeof VendorBillEditDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorBillEditDynamicActionBodyAction = {
  edit: 'edit',
} as const;

export interface VendorBillEditDynamicActionBody {
  action: VendorBillEditDynamicActionBodyAction;
}

export type VendorBillDynamicObjectActionBodyObjectType = typeof VendorBillDynamicObjectActionBodyObjectType[keyof typeof VendorBillDynamicObjectActionBodyObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorBillDynamicObjectActionBodyObjectType = {
  vendor_bill: 'vendor_bill',
} as const;

export type VendorBillDynamicObjectActionBodyActionBody = VendorBillEditDynamicActionBody | VendorBillSyncDynamicActionBody | VendorBillViewInErpDynamicActionBody | VendorBillErpLinkLoadingDynamicActionBody;

export interface VendorBillDynamicObjectActionBody {
  action_body: VendorBillDynamicObjectActionBodyActionBody;
  object_id: string;
  object_type: VendorBillDynamicObjectActionBodyObjectType;
}

export type RepairStartWorkDynamicActionBodyAction = typeof RepairStartWorkDynamicActionBodyAction[keyof typeof RepairStartWorkDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RepairStartWorkDynamicActionBodyAction = {
  start_work: 'start_work',
} as const;

export interface RepairStartWorkDynamicActionBody {
  action: RepairStartWorkDynamicActionBodyAction;
}

export type RepairScheduleDynamicActionBodyAction = typeof RepairScheduleDynamicActionBodyAction[keyof typeof RepairScheduleDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RepairScheduleDynamicActionBodyAction = {
  schedule: 'schedule',
} as const;

export interface RepairScheduleDynamicActionBody {
  action: RepairScheduleDynamicActionBodyAction;
}

export type RepairEndWorkDynamicActionBodyAction = typeof RepairEndWorkDynamicActionBodyAction[keyof typeof RepairEndWorkDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RepairEndWorkDynamicActionBodyAction = {
  end_work: 'end_work',
} as const;

export interface RepairEndWorkDynamicActionBody {
  action: RepairEndWorkDynamicActionBodyAction;
}

export type RepairEditDynamicActionBodyAction = typeof RepairEditDynamicActionBodyAction[keyof typeof RepairEditDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RepairEditDynamicActionBodyAction = {
  edit: 'edit',
} as const;

export interface RepairEditDynamicActionBody {
  action: RepairEditDynamicActionBodyAction;
}

export type RepairDynamicObjectActionBodyObjectType = typeof RepairDynamicObjectActionBodyObjectType[keyof typeof RepairDynamicObjectActionBodyObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RepairDynamicObjectActionBodyObjectType = {
  repair: 'repair',
} as const;

export interface RepairDynamicObjectActionBody {
  action_body: RepairDynamicObjectActionBodyActionBody;
  object_id: string;
  object_type: RepairDynamicObjectActionBodyObjectType;
}

export type RepairContinueWorkDynamicActionBodyAction = typeof RepairContinueWorkDynamicActionBodyAction[keyof typeof RepairContinueWorkDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RepairContinueWorkDynamicActionBodyAction = {
  continue_work: 'continue_work',
} as const;

export interface RepairContinueWorkDynamicActionBody {
  action: RepairContinueWorkDynamicActionBodyAction;
}

export type RepairCompleteDynamicActionBodyAction = typeof RepairCompleteDynamicActionBodyAction[keyof typeof RepairCompleteDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RepairCompleteDynamicActionBodyAction = {
  complete: 'complete',
} as const;

export interface RepairCompleteDynamicActionBody {
  action: RepairCompleteDynamicActionBodyAction;
}

export type RepairDynamicObjectActionBodyActionBody = RepairScheduleDynamicActionBody | RepairStartWorkDynamicActionBody | RepairContinueWorkDynamicActionBody | RepairEndWorkDynamicActionBody | RepairCompleteDynamicActionBody | RepairEditDynamicActionBody;

export type PurchaseOrderSendEmailDynamicActionBodyAction = typeof PurchaseOrderSendEmailDynamicActionBodyAction[keyof typeof PurchaseOrderSendEmailDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderSendEmailDynamicActionBodyAction = {
  send_email: 'send_email',
} as const;

export interface PurchaseOrderSendEmailDynamicActionBody {
  action: PurchaseOrderSendEmailDynamicActionBodyAction;
}

export type PurchaseOrderReceiveDynamicActionBodyAction = typeof PurchaseOrderReceiveDynamicActionBodyAction[keyof typeof PurchaseOrderReceiveDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderReceiveDynamicActionBodyAction = {
  receive: 'receive',
} as const;

export interface PurchaseOrderReceiveDynamicActionBody {
  action: PurchaseOrderReceiveDynamicActionBodyAction;
}

export type PurchaseOrderEditDynamicActionBodyAction = typeof PurchaseOrderEditDynamicActionBodyAction[keyof typeof PurchaseOrderEditDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderEditDynamicActionBodyAction = {
  edit: 'edit',
} as const;

export interface PurchaseOrderEditDynamicActionBody {
  action: PurchaseOrderEditDynamicActionBodyAction;
}

export type PurchaseOrderDynamicObjectActionBodyObjectType = typeof PurchaseOrderDynamicObjectActionBodyObjectType[keyof typeof PurchaseOrderDynamicObjectActionBodyObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderDynamicObjectActionBodyObjectType = {
  purchase_order: 'purchase_order',
} as const;

export interface PurchaseOrderDynamicObjectActionBody {
  action_body: PurchaseOrderDynamicObjectActionBodyActionBody;
  object_id: string;
  object_type: PurchaseOrderDynamicObjectActionBodyObjectType;
}

export type PurchaseOrderDownloadPdfDynamicActionBodyAction = typeof PurchaseOrderDownloadPdfDynamicActionBodyAction[keyof typeof PurchaseOrderDownloadPdfDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderDownloadPdfDynamicActionBodyAction = {
  download_pdf: 'download_pdf',
} as const;

export interface PurchaseOrderDownloadPdfDynamicActionBody {
  action: PurchaseOrderDownloadPdfDynamicActionBodyAction;
}

export type PurchaseOrderConvertToPoDynamicActionBodyAction = typeof PurchaseOrderConvertToPoDynamicActionBodyAction[keyof typeof PurchaseOrderConvertToPoDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderConvertToPoDynamicActionBodyAction = {
  convert_to_po: 'convert_to_po',
} as const;

export interface PurchaseOrderConvertToPoDynamicActionBody {
  action: PurchaseOrderConvertToPoDynamicActionBodyAction;
}

export type PurchaseOrderCancelDynamicActionBodyAction = typeof PurchaseOrderCancelDynamicActionBodyAction[keyof typeof PurchaseOrderCancelDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderCancelDynamicActionBodyAction = {
  cancel: 'cancel',
} as const;

export interface PurchaseOrderCancelDynamicActionBody {
  action: PurchaseOrderCancelDynamicActionBodyAction;
}

export type PurchaseOrderDynamicObjectActionBodyActionBody = PurchaseOrderReceiveDynamicActionBody | PurchaseOrderBillDynamicActionBody | PurchaseOrderConvertToPoDynamicActionBody | PurchaseOrderCancelDynamicActionBody | PurchaseOrderEditDynamicActionBody | PurchaseOrderDownloadPdfDynamicActionBody | PurchaseOrderSendEmailDynamicActionBody;

export type PurchaseOrderBillDynamicActionBodyAction = typeof PurchaseOrderBillDynamicActionBodyAction[keyof typeof PurchaseOrderBillDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderBillDynamicActionBodyAction = {
  bill: 'bill',
} as const;

export interface PurchaseOrderBillDynamicActionBody {
  action: PurchaseOrderBillDynamicActionBodyAction;
}

export type OrderEmailDynamicActionBodyAction = typeof OrderEmailDynamicActionBodyAction[keyof typeof OrderEmailDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderEmailDynamicActionBodyAction = {
  email: 'email',
} as const;

export interface OrderEmailDynamicActionBody {
  action: OrderEmailDynamicActionBodyAction;
}

export type OrderEditDynamicActionBodyAction = typeof OrderEditDynamicActionBodyAction[keyof typeof OrderEditDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderEditDynamicActionBodyAction = {
  edit: 'edit',
} as const;

export interface OrderEditDynamicActionBody {
  action: OrderEditDynamicActionBodyAction;
}

export type OrderDynamicObjectActionBodyObjectType = typeof OrderDynamicObjectActionBodyObjectType[keyof typeof OrderDynamicObjectActionBodyObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderDynamicObjectActionBodyObjectType = {
  order: 'order',
} as const;

export interface OrderDynamicObjectActionBody {
  action_body: OrderDynamicObjectActionBodyActionBody;
  object_id: string;
  object_type: OrderDynamicObjectActionBodyObjectType;
}

export type OrderDownloadDynamicActionBodyAction = typeof OrderDownloadDynamicActionBodyAction[keyof typeof OrderDownloadDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderDownloadDynamicActionBodyAction = {
  download: 'download',
} as const;

export interface OrderDownloadDynamicActionBody {
  action: OrderDownloadDynamicActionBodyAction;
}

export type OrderDynamicObjectActionBodyActionBody = OrderDownloadDynamicActionBody | OrderEmailDynamicActionBody | OrderEditDynamicActionBody | OrderConvertToReservationDynamicActionBody | OrderConvertToContractDynamicActionBody | OrderConvertToActiveDynamicActionBody | OrderConvertToOffRentDynamicActionBody | OrderConvertToReturnedDynamicActionBody | OrderCollectPaymentDynamicActionBody | OrderDeleteDynamicActionBody;

export type OrderDeleteDynamicActionBodyAction = typeof OrderDeleteDynamicActionBodyAction[keyof typeof OrderDeleteDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderDeleteDynamicActionBodyAction = {
  delete: 'delete',
} as const;

export interface OrderDeleteDynamicActionBody {
  action: OrderDeleteDynamicActionBodyAction;
}

export type OrderConvertToReturnedDynamicActionBodyAction = typeof OrderConvertToReturnedDynamicActionBodyAction[keyof typeof OrderConvertToReturnedDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderConvertToReturnedDynamicActionBodyAction = {
  convert_to_returned: 'convert_to_returned',
} as const;

export interface OrderConvertToReturnedDynamicActionBody {
  action: OrderConvertToReturnedDynamicActionBodyAction;
}

export type OrderConvertToReservationDynamicActionBodyAction = typeof OrderConvertToReservationDynamicActionBodyAction[keyof typeof OrderConvertToReservationDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderConvertToReservationDynamicActionBodyAction = {
  convert_to_reservation: 'convert_to_reservation',
} as const;

export interface OrderConvertToReservationDynamicActionBody {
  action: OrderConvertToReservationDynamicActionBodyAction;
}

export type OrderConvertToOffRentDynamicActionBodyAction = typeof OrderConvertToOffRentDynamicActionBodyAction[keyof typeof OrderConvertToOffRentDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderConvertToOffRentDynamicActionBodyAction = {
  convert_to_off_rent: 'convert_to_off_rent',
} as const;

export interface OrderConvertToOffRentDynamicActionBody {
  action: OrderConvertToOffRentDynamicActionBodyAction;
}

export type OrderConvertToContractDynamicActionBodyAction = typeof OrderConvertToContractDynamicActionBodyAction[keyof typeof OrderConvertToContractDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderConvertToContractDynamicActionBodyAction = {
  convert_to_contract: 'convert_to_contract',
} as const;

export interface OrderConvertToContractDynamicActionBody {
  action: OrderConvertToContractDynamicActionBodyAction;
}

export type OrderConvertToActiveDynamicActionBodyAction = typeof OrderConvertToActiveDynamicActionBodyAction[keyof typeof OrderConvertToActiveDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderConvertToActiveDynamicActionBodyAction = {
  convert_to_active: 'convert_to_active',
} as const;

export interface OrderConvertToActiveDynamicActionBody {
  action: OrderConvertToActiveDynamicActionBodyAction;
}

export type OrderCollectPaymentDynamicActionBodyAction = typeof OrderCollectPaymentDynamicActionBodyAction[keyof typeof OrderCollectPaymentDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderCollectPaymentDynamicActionBodyAction = {
  collect_payment: 'collect_payment',
} as const;

export interface OrderCollectPaymentDynamicActionBody {
  action: OrderCollectPaymentDynamicActionBodyAction;
}

export type ItemReceiptSerializeDynamicActionBodyAction = typeof ItemReceiptSerializeDynamicActionBodyAction[keyof typeof ItemReceiptSerializeDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemReceiptSerializeDynamicActionBodyAction = {
  serialize: 'serialize',
} as const;

export interface ItemReceiptSerializeDynamicActionBody {
  action: ItemReceiptSerializeDynamicActionBodyAction;
}

export type ItemReceiptReceiveDynamicActionBodyAction = typeof ItemReceiptReceiveDynamicActionBodyAction[keyof typeof ItemReceiptReceiveDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemReceiptReceiveDynamicActionBodyAction = {
  receive: 'receive',
} as const;

export interface ItemReceiptReceiveDynamicActionBody {
  action: ItemReceiptReceiveDynamicActionBodyAction;
}

export type ItemReceiptEditDynamicActionBodyAction = typeof ItemReceiptEditDynamicActionBodyAction[keyof typeof ItemReceiptEditDynamicActionBodyAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemReceiptEditDynamicActionBodyAction = {
  edit: 'edit',
} as const;

export interface ItemReceiptEditDynamicActionBody {
  action: ItemReceiptEditDynamicActionBodyAction;
}

export type ItemReceiptDynamicObjectActionBodyObjectType = typeof ItemReceiptDynamicObjectActionBodyObjectType[keyof typeof ItemReceiptDynamicObjectActionBodyObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemReceiptDynamicObjectActionBodyObjectType = {
  item_receipt: 'item_receipt',
} as const;

export type ItemReceiptDynamicObjectActionBodyActionBody = ItemReceiptReceiveDynamicActionBody | ItemReceiptEditDynamicActionBody | ItemReceiptSerializeDynamicActionBody;

export interface ItemReceiptDynamicObjectActionBody {
  action_body: ItemReceiptDynamicObjectActionBodyActionBody;
  object_id: string;
  object_type: ItemReceiptDynamicObjectActionBodyObjectType;
}

/**
 * @nullable
 */
export type PaginatedCommissionMatrixDumpSchemaNext = number | null;

export interface PaginatedCommissionMatrixDumpSchema {
  elements: CommissionMatrixDumpSchema[];
  /** @nullable */
  next?: PaginatedCommissionMatrixDumpSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type CommissionMatrixValueSchemaInventoryCommissionGroupId = string | null;

/**
 * @nullable
 */
export type CommissionMatrixValueSchemaCustomerCommissionGroupId = string | null;

export interface CommissionMatrixValueSchema {
  /** @nullable */
  customer_commission_group_id?: CommissionMatrixValueSchemaCustomerCommissionGroupId;
  /** @nullable */
  inventory_commission_group_id?: CommissionMatrixValueSchemaInventoryCommissionGroupId;
  value: string;
}

export interface CommissionMatrixUpdateSchema {
  name: string;
  values: CommissionMatrixValueSchema[];
}

export interface CommissionMatrixCreateSchema {
  name: string;
  values: CommissionMatrixValueSchema[];
}

export interface CommissionMatrixDumpSchema {
  id: string;
  name: string;
  values: CommissionMatrixValueSchema[];
}

export interface InventoryCommissionGroupUpdateSchema {
  name: string;
}

export interface InventoryCommissionGroupCreateSchema {
  name: string;
}

export interface InventoryCommissionGroupDumpSchema {
  id: string;
  name: string;
}

export interface InventoryCommissionListDumpSchema {
  elements: InventoryCommissionGroupDumpSchema[];
}

export interface CustomerCommissionGroupUpdateSchema {
  name: string;
}

export interface CustomerCommissionGroupCreateSchema {
  name: string;
}

export interface CustomerCommissionGroupDumpSchema {
  id: string;
  name: string;
}

export interface CustomerCommissionListDumpSchema {
  elements: CustomerCommissionGroupDumpSchema[];
}

/**
 * @nullable
 */
export type UserLocationUpdateSchemaCurrentLocationId = string | null;

export interface UserLocationUpdateSchema {
  /** @nullable */
  current_location_id?: UserLocationUpdateSchemaCurrentLocationId;
}

export interface UserForgotPasswordBodySchema {
  email: string;
}

export interface UserPasswordUpdateBodySchema {
  current_password: string;
  new_password: string;
}

/**
 * @nullable
 */
export type UserUpdateSchemaPhone = string | null;

/**
 * @nullable
 */
export type UserUpdateSchemaLastName = string | null;

/**
 * @nullable
 */
export type UserUpdateSchemaFirstName = string | null;

/**
 * @nullable
 */
export type UserUpdateSchemaDefaultLocationId = string | null;

export interface UserUpdateSchema {
  /** @nullable */
  default_location_id?: UserUpdateSchemaDefaultLocationId;
  email: string;
  /** @nullable */
  first_name?: UserUpdateSchemaFirstName;
  is_delivery_driver?: boolean;
  /** @nullable */
  last_name?: UserUpdateSchemaLastName;
  /** @nullable */
  phone?: UserUpdateSchemaPhone;
  roles: StaticRole[];
  secondary_locations: string[];
}

/**
 * @nullable
 */
export type UserCreateSchemaPhone = string | null;

/**
 * @nullable
 */
export type UserCreateSchemaLastName = string | null;

/**
 * @nullable
 */
export type UserCreateSchemaFirstName = string | null;

/**
 * @nullable
 */
export type UserCreateSchemaDefaultLocationId = string | null;

export interface UserCreateSchema {
  /** @nullable */
  default_location_id?: UserCreateSchemaDefaultLocationId;
  email: string;
  /** @nullable */
  first_name?: UserCreateSchemaFirstName;
  is_delivery_driver?: boolean;
  /** @nullable */
  last_name?: UserCreateSchemaLastName;
  /** @nullable */
  phone?: UserCreateSchemaPhone;
  roles: StaticRole[];
  secondary_locations: string[];
}

/**
 * @nullable
 */
export type UserHydratedDumpSchemaPhone = string | null;

/**
 * @nullable
 */
export type UserHydratedDumpSchemaLastName = string | null;

/**
 * @nullable
 */
export type UserHydratedDumpSchemaFirstName = string | null;

/**
 * @nullable
 */
export type UserHydratedDumpSchemaDefaultLocation = LocationDumpSchema | null;

/**
 * @nullable
 */
export type UserHydratedDumpSchemaCurrentLocation = LocationDumpSchema | null;

export type StaticRole = typeof StaticRole[keyof typeof StaticRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaticRole = {
  admin: 'admin',
  manager: 'manager',
  user: 'user',
} as const;

export type PermissionVerb = typeof PermissionVerb[keyof typeof PermissionVerb];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionVerb = {
  create: 'create',
  delete: 'delete',
  list: 'list',
  read: 'read',
  update: 'update',
} as const;

/**
 * The intention of these is to be combined with Resources

e.g. 'write' user.password implies the ability to change a user's
password

In the case of there being a permission on the resource and a
specific sub resource, the resource shall exclude the sub
resource.

e.g. 'write' user does not include 'write' user.password

The naming of these enums should be <RESOURCE>__<IDENITIFER> =
lower(<identifier>)

This is validated in tests.
 */
export type PermissionSubResource = typeof PermissionSubResource[keyof typeof PermissionSubResource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionSubResource = {
  user__password: 'user__password',
  order__customer: 'order__customer',
  order__sign: 'order__sign',
  payment__pay_invoice: 'payment__pay_invoice',
  business__pdf: 'business__pdf',
} as const;

/**
 * @nullable
 */
export type PermissionTypeSubresource = PermissionSubResource | null;

export type PermissionResource = typeof PermissionResource[keyof typeof PermissionResource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionResource = {
  accounting_accounts: 'accounting_accounts',
  accounting_journal_entry: 'accounting_journal_entry',
  accounting_ledger_account: 'accounting_ledger_account',
  accounting_ledger_entry: 'accounting_ledger_entry',
  accounting_objects: 'accounting_objects',
  accounting_provider: 'accounting_provider',
  accounting_settings: 'accounting_settings',
  address: 'address',
  asset: 'asset',
  billing_profile: 'billing_profile',
  business: 'business',
  calendar: 'calendar',
  category: 'category',
  comments: 'comments',
  comms_template: 'comms_template',
  communication_preference: 'communication_preference',
  customer: 'customer',
  custom_form: 'custom_form',
  delivery: 'delivery',
  delivery_configuration: 'delivery_configuration',
  deposit: 'deposit',
  depreciation_settings: 'depreciation_settings',
  document_configuration: 'document_configuration',
  email: 'email',
  event_configuration: 'event_configuration',
  expense__expense_class: 'expense__expense_class',
  inventory__usage_metric_type: 'inventory__usage_metric_type',
  inventory_attachment: 'inventory_attachment',
  invoice: 'invoice',
  kit: 'kit',
  klass: 'klass',
  location: 'location',
  location_group: 'location_group',
  order: 'order',
  order_line_item: 'order_line_item',
  order_line_item_configuration: 'order_line_item_configuration',
  payment: 'payment',
  payment_surcharge: 'payment_surcharge',
  product: 'product',
  purchasing__purchase_order: 'purchasing__purchase_order',
  purchasing__item_receipt: 'purchasing__item_receipt',
  purchasing__vendor_bill: 'purchasing__vendor_bill',
  purchasing__vendor: 'purchasing__vendor',
  procedure: 'procedure',
  rate_profile: 'rate_profile',
  rate_template: 'rate_template',
  report: 'report',
  search: 'search',
  stripe: 'stripe',
  tag: 'tag',
  tax: 'tax',
  terms_and_conditions_addendum: 'terms_and_conditions_addendum',
  user: 'user',
  repair: 'repair',
  sales__customer_commission_group: 'sales__customer_commission_group',
  sales__inventory_commission_group: 'sales__inventory_commission_group',
  sales__commission_matrix: 'sales__commission_matrix',
} as const;

export interface PermissionType {
  resource: PermissionResource;
  /** @nullable */
  subresource?: PermissionTypeSubresource;
  verb: PermissionVerb;
}

export interface UserHydratedDumpSchema {
  /** @nullable */
  current_location?: UserHydratedDumpSchemaCurrentLocation;
  /** @nullable */
  default_location?: UserHydratedDumpSchemaDefaultLocation;
  email: string;
  /** @nullable */
  first_name?: UserHydratedDumpSchemaFirstName;
  full_name: string;
  id: string;
  is_delivery_driver: boolean;
  /** @nullable */
  last_name?: UserHydratedDumpSchemaLastName;
  permissions: PermissionType[];
  /** @nullable */
  phone?: UserHydratedDumpSchemaPhone;
  roles: StaticRole[];
  secondary_locations: LocationDumpSchema[];
  username: string;
}

/**
 * @nullable
 */
export type PaginatedUserSchemaNext = number | null;

export interface PaginatedUserSchema {
  elements: UserDumpSchema[];
  /** @nullable */
  next?: PaginatedUserSchemaNext;
  total_count: number;
}

export interface ProductTaxCodeGroupUpdateSchema {
  configurations: TaxConfigurationCreateSchema[];
  is_business_default: boolean;
  name: string;
}

export interface ProductTaxCodeGroupCreateSchema {
  configurations: TaxConfigurationCreateSchema[];
  is_business_default: boolean;
  name: string;
}

/**
 * @nullable
 */
export type TaxConfigurationCreateSchemaTaxLiabilityAccountId = string | null;

/**
 * @nullable
 */
export type TaxConfigurationCreateSchemaDescription = string | null;

/**
 * @nullable
 */
export type TaxConfigurationChildCreateSchemaTaxLiabilityAccountId = string | null;

export interface TaxConfigurationChildCreateSchema {
  name: string;
  rate: string;
  /** @nullable */
  tax_liability_account_id?: TaxConfigurationChildCreateSchemaTaxLiabilityAccountId;
  zip_codes: string[];
}

export interface TaxConfigurationCreateSchema {
  country_code: CountryEnum;
  /** @nullable */
  description?: TaxConfigurationCreateSchemaDescription;
  name: string;
  rate: string;
  source: TaxSourceEnum;
  state_code: StateEnum;
  sub_configurations: TaxConfigurationChildCreateSchema[];
  /** @nullable */
  tax_liability_account_id?: TaxConfigurationCreateSchemaTaxLiabilityAccountId;
  type: TaxTypeEnum;
}

/**
 * @nullable
 */
export type PaginatedProductTaxCodeGroupDumpSchemaNext = number | null;

export interface PaginatedProductTaxCodeGroupDumpSchema {
  elements: ProductTaxCodeGroupDumpSchema[];
  /** @nullable */
  next?: PaginatedProductTaxCodeGroupDumpSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type PaginatedTagSchemaNext = number | null;

export interface TagDumpSchema {
  id: string;
  name: string;
}

export interface PaginatedTagSchema {
  elements: TagDumpSchema[];
  /** @nullable */
  next?: PaginatedTagSchemaNext;
  total_count: number;
}

export interface StripeActiveSchema {
  active: boolean;
}

export interface StripeOnboardingSchema {
  url: string;
}

/**
 * @nullable
 */
export type SerpImageResponseSchemaPrev = number | null;

export interface SerpImage {
  original: string;
  thumbnail: string;
}

export interface SerpImageResponseSchema {
  images: SerpImage[];
  next: number;
  /** @nullable */
  prev?: SerpImageResponseSchemaPrev;
}

/**
 * @nullable
 */
export type SearchResponseSchemaNext = number | null;

export interface SearchResponseSchema {
  elements: SearchResponseSchemaElementsItem[];
  /** @nullable */
  next?: SearchResponseSchemaNext;
  total_count: number;
}

export type VendorSearchObjectType = typeof VendorSearchObjectType[keyof typeof VendorSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorSearchObjectType = {
  vendor: 'vendor',
} as const;

export interface VendorSearchObject {
  id: string;
  obj: VendorDumpSchema;
  rank: number;
  type: VendorSearchObjectType;
}

export type PurchaseOrderSearchObjectType = typeof PurchaseOrderSearchObjectType[keyof typeof PurchaseOrderSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderSearchObjectType = {
  purchase_order: 'purchase_order',
} as const;

export interface PurchaseOrderSearchObject {
  id: string;
  obj: PurchaseOrderDumpSchema;
  rank: number;
  type: PurchaseOrderSearchObjectType;
}

export type ProductSearchObjectType = typeof ProductSearchObjectType[keyof typeof ProductSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductSearchObjectType = {
  product: 'product',
} as const;

export interface ProductSearchObject {
  id: string;
  obj: ProductDumpSchema;
  rank: number;
  type: ProductSearchObjectType;
}

export interface OrderSearchObject {
  id: string;
  obj: OrderDumpSchema;
  rank: number;
  type: OrderSearchObjectType;
}

export type SearchResponseSchemaElementsItem = OrderSearchObject | VendorSearchObject | CustomerSearchObject | CategorySearchObject | KlassSearchObject | AssetSearchObject | LocationSearchObject | ProductSearchObject | PurchaseOrderSearchObject;

export type OrderSearchObjectType = typeof OrderSearchObjectType[keyof typeof OrderSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderSearchObjectType = {
  order: 'order',
} as const;

export type LocationSearchObjectType = typeof LocationSearchObjectType[keyof typeof LocationSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LocationSearchObjectType = {
  location: 'location',
} as const;

export interface LocationSearchObject {
  id: string;
  obj: LocationDumpSchema;
  rank: number;
  type: LocationSearchObjectType;
}

export type KlassSearchObjectType = typeof KlassSearchObjectType[keyof typeof KlassSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KlassSearchObjectType = {
  klass: 'klass',
} as const;

export interface KlassSearchObject {
  id: string;
  obj: KlassDumpSchema;
  rank: number;
  type: KlassSearchObjectType;
}

export type CustomerSearchObjectType = typeof CustomerSearchObjectType[keyof typeof CustomerSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerSearchObjectType = {
  customer: 'customer',
} as const;

export interface CustomerSearchObject {
  id: string;
  obj: CustomerDumpSchema;
  rank: number;
  type: CustomerSearchObjectType;
}

export type CategorySearchObjectType = typeof CategorySearchObjectType[keyof typeof CategorySearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategorySearchObjectType = {
  category: 'category',
} as const;

export interface CategorySearchObject {
  id: string;
  obj: CategoryDumpSchema;
  rank: number;
  type: CategorySearchObjectType;
}

export type AssetSearchObjectType = typeof AssetSearchObjectType[keyof typeof AssetSearchObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetSearchObjectType = {
  asset: 'asset',
} as const;

export interface AssetSearchObject {
  id: string;
  obj: AssetDumpSchema;
  rank: number;
  type: AssetSearchObjectType;
}

export type SearchObjects = typeof SearchObjects[keyof typeof SearchObjects];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchObjects = {
  asset: 'asset',
  klass: 'klass',
  category: 'category',
  customer: 'customer',
  vendor: 'vendor',
  order: 'order',
  purchase_order: 'purchase_order',
  location: 'location',
  product: 'product',
} as const;

/**
 * @nullable
 */
export type SearchRequestSchemaObjects = SearchObjects[] | null;

export interface SearchRequestSchema {
  /** @nullable */
  objects?: SearchRequestSchemaObjects;
  search: string;
}

export interface TransientDeliveryRequestsDumpSchema {
  delivery_requests: TransientDeliveryRequestDumpSchema[];
}

/**
 * @nullable
 */
export type TransientDeliveryRequestDumpSchemaToWindow = DateTimeWindowSchema | null;

export type TransientDeliveryRequestDumpSchemaToLocation = DeliveryLocationBranchDumpSchema | DeliveryLocationCustomerLocationDumpSchema;

/**
 * @nullable
 */
export type TransientDeliveryRequestDumpSchemaTaxRate = string | null;

/**
 * @nullable
 */
export type TransientDeliveryRequestDumpSchemaTaxGroupId = string | null;

/**
 * @nullable
 */
export type TransientDeliveryRequestDumpSchemaId = string | null;

/**
 * @nullable
 */
export type TransientDeliveryRequestDumpSchemaFromWindow = DateTimeWindowSchema | null;

export type TransientDeliveryRequestDumpSchemaFromLocation = DeliveryLocationBranchDumpSchema | DeliveryLocationCustomerLocationDumpSchema;

/**
 * @nullable
 */
export type TransientDeliveryRequestDumpSchemaDeliverySecondaryRate = string | null;

/**
 * @nullable
 */
export type TransientDeliveryRequestDumpSchemaClientId = string | null;

/**
 * @nullable
 */
export type TransientDeliveryRequestDumpSchemaBillingProfileId = string | null;

export interface TransientDeliveryRequestDumpSchema {
  amount: string;
  /** @nullable */
  billing_profile_id?: TransientDeliveryRequestDumpSchemaBillingProfileId;
  charge_at: string;
  /** @nullable */
  client_id?: TransientDeliveryRequestDumpSchemaClientId;
  delivery_configuration: DeliveryConfigurationDumpSchema;
  delivery_configuration_id: string;
  delivery_rate: string;
  /** @nullable */
  delivery_secondary_rate?: TransientDeliveryRequestDumpSchemaDeliverySecondaryRate;
  direction: DeliveryRequestDirection;
  from_location: TransientDeliveryRequestDumpSchemaFromLocation;
  /** @nullable */
  from_window?: TransientDeliveryRequestDumpSchemaFromWindow;
  /** @nullable */
  id?: TransientDeliveryRequestDumpSchemaId;
  line_items: string[];
  /** @nullable */
  tax_group_id?: TransientDeliveryRequestDumpSchemaTaxGroupId;
  /** @nullable */
  tax_rate?: TransientDeliveryRequestDumpSchemaTaxRate;
  to_location: TransientDeliveryRequestDumpSchemaToLocation;
  /** @nullable */
  to_window?: TransientDeliveryRequestDumpSchemaToWindow;
}

export type TransientDeliveryRequestCreateSchemaLineItemsAnyOfTwoItem = LineItemRentalUpdateSchema | LineItemSaleUpdateSchema;

export type TransientDeliveryRequestCreateSchemaLineItemsAnyOfItem = LineItemRentalCreateSchema | LineItemSaleCreateSchema;

export type TransientDeliveryRequestCreateSchemaLineItems = TransientDeliveryRequestCreateSchemaLineItemsAnyOfItem[] | TransientDeliveryRequestCreateSchemaLineItemsAnyOfTwoItem[];

/**
 * @nullable
 */
export type TransientDeliveryRequestCreateSchemaDeliveryRequests = DeliveryRequestUpdateSchema[] | null;

/**
 * @nullable
 */
export type TransientDeliveryRequestCreateSchemaDeliveryAddressId = string | null;

/**
 * @nullable
 */
export type TransientDeliveryRequestCreateSchemaBillingProfileId = string | null;

export interface TransientDeliveryRequestCreateSchema {
  /** @nullable */
  billing_profile_id?: TransientDeliveryRequestCreateSchemaBillingProfileId;
  /** @nullable */
  delivery_address_id?: TransientDeliveryRequestCreateSchemaDeliveryAddressId;
  /** @nullable */
  delivery_requests?: TransientDeliveryRequestCreateSchemaDeliveryRequests;
  inbound: boolean;
  line_items: TransientDeliveryRequestCreateSchemaLineItems;
  location_id: string;
  modifiers: ModifierSchema[];
  outbound: boolean;
}

export type DeliveryRequestHydratedRouteStopDumpSchemaStopAnyOf = DeliveryRouteBranchStopDumpSchema | DeliveryRouteCustomerStopDumpSchema;

/**
 * @nullable
 */
export type DeliveryRequestHydratedRouteStopDumpSchemaStop = DeliveryRequestHydratedRouteStopDumpSchemaStopAnyOf | null;

/**
 * @nullable
 */
export type DeliveryRequestHydratedRouteStopDumpSchemaRoute = DeliveryRouteDumpSchema | null;

export interface DeliveryRequestHydratedRouteStopDumpSchema {
  delivery_request: DeliveryRequestDumpSchema;
  /** @nullable */
  route?: DeliveryRequestHydratedRouteStopDumpSchemaRoute;
  /** @nullable */
  stop?: DeliveryRequestHydratedRouteStopDumpSchemaStop;
}

export interface DeliveryRequestsHydratedRouteStopDumpSchema {
  delivery_requests: DeliveryRequestHydratedRouteStopDumpSchema[];
}

export interface OrderSignatureSchema {
  signed_by: string;
}

/**
 * @nullable
 */
export type OrderExternalDumpSchemaSignedByIp = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaSignedBy = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaSignedAt = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaSecondarySalesRepId = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaSecondarySalesRep = UserDumpSchema | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaSalesRepId = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaSalesRep = UserDumpSchema | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaSaleTaxRate = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaRentalTaxRate = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaPaymentMethodId = string | null;

export type OrderExternalDumpSchemaLineItemsItem = RentalLineItemExternalDumpSchema | SaleLineItemExternalDumpSchema;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaInvoiceDueNow = LinkIDSchema | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaInternalNotes = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaExternalNotes = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaCustomerPoNumber = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaCustomerJobSiteNumber = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaCustomerId = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaCustomerContactId = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaCustomerContact = CustomerContactHydratedDumpSchema | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaCustomer = CustomerHydratedDumpSchema | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaContractEndsAt = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaBusinessTermsAndConditions = unknown[] | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaBusinessLogoUrl = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaBillingEndsAt = string | null;

/**
 * @nullable
 */
export type OrderExternalDumpSchemaBillingAddress = AddressDumpSchema | null;

export interface OrderExternalDumpSchema {
  actions: OrderAction[];
  activity_log_id: string;
  amount: string;
  amount_billed: string;
  amount_due: string;
  amount_paid: string;
  autogenerated_identifier: string;
  /** @nullable */
  billing_address?: OrderExternalDumpSchemaBillingAddress;
  /** @nullable */
  billing_ends_at?: OrderExternalDumpSchemaBillingEndsAt;
  /** @nullable */
  billing_profile_id?: OrderExternalDumpSchemaBillingProfileId;
  billing_starts_at: string;
  /** @nullable */
  business_logo_url?: OrderExternalDumpSchemaBusinessLogoUrl;
  business_name: string;
  /** @nullable */
  business_terms_and_conditions?: OrderExternalDumpSchemaBusinessTermsAndConditions;
  /** @nullable */
  contract_ends_at?: OrderExternalDumpSchemaContractEndsAt;
  contract_starts_at: string;
  /** @nullable */
  customer?: OrderExternalDumpSchemaCustomer;
  /** @nullable */
  customer_contact?: OrderExternalDumpSchemaCustomerContact;
  /** @nullable */
  customer_contact_id?: OrderExternalDumpSchemaCustomerContactId;
  /** @nullable */
  customer_id?: OrderExternalDumpSchemaCustomerId;
  /** @nullable */
  customer_job_site_number?: OrderExternalDumpSchemaCustomerJobSiteNumber;
  /** @nullable */
  customer_po_number?: OrderExternalDumpSchemaCustomerPoNumber;
  delivery_requests: DeliveryRequestSlimDumpSchema[];
  delivery_subtotal: string;
  expires_at: string;
  /** @nullable */
  external_notes?: OrderExternalDumpSchemaExternalNotes;
  /** @nullable */
  future_invoice_payment_method?: OrderExternalDumpSchemaFutureInvoicePaymentMethod;
  has_amount_owed: boolean;
  id: string;
  /** @nullable */
  internal_notes?: OrderExternalDumpSchemaInternalNotes;
  /** @nullable */
  invoice_due_now?: OrderExternalDumpSchemaInvoiceDueNow;
  invoices: InvoiceExternalDumpSchema[];
  line_items: OrderExternalDumpSchemaLineItemsItem[];
  location: LocationDumpSchema;
  location_id: string;
  modifiers: ModifierDumpSchema[];
  /** @nullable */
  payment_method_id?: OrderExternalDumpSchemaPaymentMethodId;
  /** @nullable */
  rental_tax_rate?: OrderExternalDumpSchemaRentalTaxRate;
  rental_tax_subtotal: string;
  /** @nullable */
  sale_tax_rate?: OrderExternalDumpSchemaSaleTaxRate;
  sale_tax_subtotal: string;
  /** @nullable */
  sales_rep?: OrderExternalDumpSchemaSalesRep;
  /** @nullable */
  sales_rep_id?: OrderExternalDumpSchemaSalesRepId;
  /** @nullable */
  secondary_sales_rep?: OrderExternalDumpSchemaSecondarySalesRep;
  /** @nullable */
  secondary_sales_rep_id?: OrderExternalDumpSchemaSecondarySalesRepId;
  /** @nullable */
  signed_at?: OrderExternalDumpSchemaSignedAt;
  /** @nullable */
  signed_by?: OrderExternalDumpSchemaSignedBy;
  /** @nullable */
  signed_by_ip?: OrderExternalDumpSchemaSignedByIp;
  source: OrderSource;
  status: OrderStatus;
  summary: OrderSummarySection[];
  tax_type: TaxType;
  terms_and_conditions_addendums: TermsAndConditionsAddendumDumpSchema[];
  type: OrderType;
}

export type SaleLineItemExternalDumpSchemaType = typeof SaleLineItemExternalDumpSchemaType[keyof typeof SaleLineItemExternalDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaleLineItemExternalDumpSchemaType = {
  sale: 'sale',
} as const;

/**
 * @nullable
 */
export type SaleLineItemExternalDumpSchemaNotes = string | null;

/**
 * @nullable
 */
export type SaleLineItemExternalDumpSchemaExternalDescription = string | null;

/**
 * @nullable
 */
export type SaleLineItemExternalDumpSchemaContractStartedAt = string | null;

/**
 * @nullable
 */
export type SaleLineItemExternalDumpSchemaBillingStartedAt = string | null;

/**
 * @nullable
 */
export type SaleLineItemExternalDumpSchemaAssetId = string | null;

/**
 * @nullable
 */
export type SaleLineItemExternalDumpSchemaAssetCustomerIdentifier = string | null;

export interface SaleLineItemExternalDumpSchema {
  amount_per_unit: string;
  /** @nullable */
  asset_customer_identifier?: SaleLineItemExternalDumpSchemaAssetCustomerIdentifier;
  /** @nullable */
  asset_id?: SaleLineItemExternalDumpSchemaAssetId;
  assets: AssetExternalDumpSchema[];
  base_amount_per_unit: string;
  /** @nullable */
  billing_started_at?: SaleLineItemExternalDumpSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  /** @nullable */
  contract_started_at?: SaleLineItemExternalDumpSchemaContractStartedAt;
  contract_starts_at: string;
  extended_amount: string;
  extended_base_amount: string;
  /** @nullable */
  external_description?: SaleLineItemExternalDumpSchemaExternalDescription;
  id: string;
  klass_id: string;
  klass_name: string;
  klass_tracking: TrackingType;
  klass_type: KlassType;
  modifiers?: ModifierDumpSchema[];
  /** @nullable */
  notes?: SaleLineItemExternalDumpSchemaNotes;
  parent_client_id: string;
  payer_type: LineItemPayerType;
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  source: LineItemSource;
  status: LineItemStatus;
  type: SaleLineItemExternalDumpSchemaType;
}

export type RentalLineItemExternalDumpSchemaType = typeof RentalLineItemExternalDumpSchemaType[keyof typeof RentalLineItemExternalDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RentalLineItemExternalDumpSchemaType = {
  rental: 'rental',
} as const;

/**
 * @nullable
 */
export type RentalLineItemExternalDumpSchemaShiftId = string | null;

/**
 * @nullable
 */
export type RentalLineItemExternalDumpSchemaShift = ShiftDumpSchema | null;

/**
 * @nullable
 */
export type RentalLineItemExternalDumpSchemaNotes = string | null;

/**
 * @nullable
 */
export type RentalLineItemExternalDumpSchemaExternalDescription = string | null;

/**
 * @nullable
 */
export type RentalLineItemExternalDumpSchemaContractStartedAt = string | null;

/**
 * @nullable
 */
export type RentalLineItemExternalDumpSchemaContractEndedAt = string | null;

/**
 * @nullable
 */
export type RentalLineItemExternalDumpSchemaBillingStartedAt = string | null;

/**
 * @nullable
 */
export type RentalLineItemExternalDumpSchemaBillingEndedAt = string | null;

/**
 * @nullable
 */
export type RentalLineItemExternalDumpSchemaAssetId = string | null;

/**
 * @nullable
 */
export type RentalLineItemExternalDumpSchemaAssetCustomerIdentifier = string | null;

export interface RentalLineItemExternalDumpSchema {
  amount_per_unit: string;
  /** @nullable */
  asset_customer_identifier?: RentalLineItemExternalDumpSchemaAssetCustomerIdentifier;
  /** @nullable */
  asset_id?: RentalLineItemExternalDumpSchemaAssetId;
  assets: AssetExternalDumpSchema[];
  base_amount_per_unit: string;
  /** @nullable */
  billing_ended_at?: RentalLineItemExternalDumpSchemaBillingEndedAt;
  billing_ends_at: string;
  /** @nullable */
  billing_started_at?: RentalLineItemExternalDumpSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  /** @nullable */
  contract_ended_at?: RentalLineItemExternalDumpSchemaContractEndedAt;
  contract_ends_at: string;
  /** @nullable */
  contract_started_at?: RentalLineItemExternalDumpSchemaContractStartedAt;
  contract_starts_at: string;
  extended_amount: string;
  extended_base_amount: string;
  /** @nullable */
  external_description?: RentalLineItemExternalDumpSchemaExternalDescription;
  id: string;
  klass_id: string;
  klass_name: string;
  klass_tracking: TrackingType;
  klass_type: KlassType;
  modifiers?: ModifierDumpSchema[];
  /** @nullable */
  notes?: RentalLineItemExternalDumpSchemaNotes;
  parent_client_id: string;
  payer_type: LineItemPayerType;
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  /** @nullable */
  shift?: RentalLineItemExternalDumpSchemaShift;
  /** @nullable */
  shift_id?: RentalLineItemExternalDumpSchemaShiftId;
  source: LineItemSource;
  status: LineItemStatus;
  type: RentalLineItemExternalDumpSchemaType;
}

/**
 * @nullable
 */
export type DeliveryRequestSlimDumpSchemaToWindow = DateTimeWindowSchema | null;

export type DeliveryRequestSlimDumpSchemaToLocation = DeliveryLocationBranchDumpSchema | DeliveryLocationCustomerLocationDumpSchema;

/**
 * @nullable
 */
export type DeliveryRequestSlimDumpSchemaTaxRate = string | null;

/**
 * @nullable
 */
export type DeliveryRequestSlimDumpSchemaTaxGroupId = string | null;

/**
 * @nullable
 */
export type DeliveryRequestSlimDumpSchemaFromWindow = DateTimeWindowSchema | null;

export type DeliveryRequestSlimDumpSchemaFromLocation = DeliveryLocationBranchDumpSchema | DeliveryLocationCustomerLocationDumpSchema;

/**
 * @nullable
 */
export type DeliveryRequestSlimDumpSchemaDeliverySecondaryRate = string | null;

/**
 * @nullable
 */
export type DeliveryRequestSlimDumpSchemaBillingProfileId = string | null;

export interface DeliveryRequestSlimDumpSchema {
  amount: string;
  /** @nullable */
  billing_profile_id?: DeliveryRequestSlimDumpSchemaBillingProfileId;
  charge_at: string;
  delivery_configuration_id: string;
  delivery_rate: string;
  /** @nullable */
  delivery_secondary_rate?: DeliveryRequestSlimDumpSchemaDeliverySecondaryRate;
  direction: DeliveryRequestDirection;
  from_location: DeliveryRequestSlimDumpSchemaFromLocation;
  /** @nullable */
  from_window?: DeliveryRequestSlimDumpSchemaFromWindow;
  /** @nullable */
  tax_group_id?: DeliveryRequestSlimDumpSchemaTaxGroupId;
  /** @nullable */
  tax_rate?: DeliveryRequestSlimDumpSchemaTaxRate;
  to_location: DeliveryRequestSlimDumpSchemaToLocation;
  /** @nullable */
  to_window?: DeliveryRequestSlimDumpSchemaToWindow;
}

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaSignedByIp = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaSignedBy = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaSignedAt = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaSecondarySalesRepId = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaSalesRepId = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaSaleTaxRate = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaRentalTaxRate = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaPaymentMethodId = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaInternalNotes = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaExternalNotes = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaCustomerPoNumber = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaCustomerJobSiteNumber = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaCustomerId = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaCustomerContactId = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaContractEndsAt = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type OrderReceivingDumpSchemaBillingEndsAt = string | null;

export interface OrderReceivingDumpSchema {
  actions: OrderAction[];
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  /** @nullable */
  billing_ends_at?: OrderReceivingDumpSchemaBillingEndsAt;
  /** @nullable */
  billing_profile_id?: OrderReceivingDumpSchemaBillingProfileId;
  billing_starts_at: string;
  /** @nullable */
  contract_ends_at?: OrderReceivingDumpSchemaContractEndsAt;
  contract_starts_at: string;
  /** @nullable */
  customer_contact_id?: OrderReceivingDumpSchemaCustomerContactId;
  /** @nullable */
  customer_id?: OrderReceivingDumpSchemaCustomerId;
  /** @nullable */
  customer_job_site_number?: OrderReceivingDumpSchemaCustomerJobSiteNumber;
  /** @nullable */
  customer_po_number?: OrderReceivingDumpSchemaCustomerPoNumber;
  expires_at: string;
  /** @nullable */
  external_notes?: OrderReceivingDumpSchemaExternalNotes;
  /** @nullable */
  future_invoice_payment_method?: OrderReceivingDumpSchemaFutureInvoicePaymentMethod;
  has_amount_owed: boolean;
  id: string;
  /** @nullable */
  internal_notes?: OrderReceivingDumpSchemaInternalNotes;
  line_items: LineItemReceivingDumpSchema[];
  location_id: string;
  /** @nullable */
  payment_method_id?: OrderReceivingDumpSchemaPaymentMethodId;
  /** @nullable */
  rental_tax_rate?: OrderReceivingDumpSchemaRentalTaxRate;
  /** @nullable */
  sale_tax_rate?: OrderReceivingDumpSchemaSaleTaxRate;
  /** @nullable */
  sales_rep_id?: OrderReceivingDumpSchemaSalesRepId;
  /** @nullable */
  secondary_sales_rep_id?: OrderReceivingDumpSchemaSecondarySalesRepId;
  /** @nullable */
  signed_at?: OrderReceivingDumpSchemaSignedAt;
  /** @nullable */
  signed_by?: OrderReceivingDumpSchemaSignedBy;
  /** @nullable */
  signed_by_ip?: OrderReceivingDumpSchemaSignedByIp;
  source: OrderSource;
  status: OrderStatus;
  tax_type: TaxType;
  type: OrderType;
}

/**
 * @nullable
 */
export type LineItemReceivingDumpSchemaAssetId = string | null;

/**
 * @nullable
 */
export type LineItemReceivingDumpSchemaAsset = AssetHydratedDumpSchema | null;

export interface LineItemReceivingDumpSchema {
  /** @nullable */
  asset?: LineItemReceivingDumpSchemaAsset;
  /** @nullable */
  asset_id?: LineItemReceivingDumpSchemaAssetId;
  id: string;
  klass: KlassDumpSchema;
  klass_id: string;
  quantity: number;
}

/**
 * @nullable
 */
export type OrderUpdateSchemaSecondarySalesRepId = string | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaSalesRepId = string | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaSaleTaxRate = string | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaRentalTaxRate = string | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaPaymentMethodId = string | null;

export type OrderUpdateSchemaLineItemsItem = LineItemRentalUpdateSchema | LineItemSaleUpdateSchema;

/**
 * @nullable
 */
export type OrderUpdateSchemaInternalNotes = string | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaExternalNotes = string | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaExpiresAt = string | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaCustomerPoNumber = string | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaCustomerJobSiteNumber = string | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaCustomerId = string | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaCustomerContactId = string | null;

/**
 * @nullable
 */
export type OrderUpdateSchemaBillingProfileId = string | null;

export interface OrderUpdateSchema {
  /** @nullable */
  billing_profile_id?: OrderUpdateSchemaBillingProfileId;
  /** @nullable */
  customer_contact_id?: OrderUpdateSchemaCustomerContactId;
  /** @nullable */
  customer_id?: OrderUpdateSchemaCustomerId;
  /** @nullable */
  customer_job_site_number?: OrderUpdateSchemaCustomerJobSiteNumber;
  /** @nullable */
  customer_po_number?: OrderUpdateSchemaCustomerPoNumber;
  delivery: TransientDeliveryRequestBaseCreateSchema;
  delivery_requests: DeliveryRequestUpdateSchema[];
  /** @nullable */
  expires_at?: OrderUpdateSchemaExpiresAt;
  /** @nullable */
  external_notes?: OrderUpdateSchemaExternalNotes;
  /** @nullable */
  future_invoice_payment_method?: OrderUpdateSchemaFutureInvoicePaymentMethod;
  /** @nullable */
  internal_notes?: OrderUpdateSchemaInternalNotes;
  line_items: OrderUpdateSchemaLineItemsItem[];
  location_id: string;
  modifiers: ModifierSchema[];
  /** @nullable */
  payment_method_id?: OrderUpdateSchemaPaymentMethodId;
  /** @nullable */
  rental_tax_rate?: OrderUpdateSchemaRentalTaxRate;
  repairs: RepairUpdateSchema[];
  /** @nullable */
  sale_tax_rate?: OrderUpdateSchemaSaleTaxRate;
  /** @nullable */
  sales_rep_id?: OrderUpdateSchemaSalesRepId;
  /** @nullable */
  secondary_sales_rep_id?: OrderUpdateSchemaSecondarySalesRepId;
  source: OrderSource;
  status: OrderStatus;
  tags: string[];
  tax_type: TaxType;
}

/**
 * @nullable
 */
export type RepairUpdateSchemaScheduledFor = string | null;

/**
 * @nullable
 */
export type RepairUpdateSchemaProcedureId = string | null;

/**
 * @nullable
 */
export type RepairUpdateSchemaId = string | null;

/**
 * @nullable
 */
export type ReceiveLineItemSchemaReceivingNotes = string | null;

export interface ReceiveLineItemSchema {
  receiving_attachments: string[];
  /** @nullable */
  receiving_notes?: ReceiveLineItemSchemaReceivingNotes;
  usage_metric_values: UsageMetricValueCreateSchema[];
}

export type LineItemSaleUpdateSchemaType = typeof LineItemSaleUpdateSchemaType[keyof typeof LineItemSaleUpdateSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemSaleUpdateSchemaType = {
  sale: 'sale',
} as const;

/**
 * @nullable
 */
export type LineItemSaleUpdateSchemaReceiving = ReceiveLineItemSchema | null;

/**
 * @nullable
 */
export type LineItemSaleUpdateSchemaNotes = string | null;

/**
 * @nullable
 */
export type LineItemSaleUpdateSchemaId = string | null;

/**
 * @nullable
 */
export type LineItemSaleUpdateSchemaContractStartedAt = string | null;

/**
 * @nullable
 */
export type LineItemSaleUpdateSchemaBillingStartedAt = string | null;

/**
 * @nullable
 */
export type LineItemSaleUpdateSchemaAssetId = string | null;

export interface LineItemSaleUpdateSchema {
  /** @nullable */
  asset_id?: LineItemSaleUpdateSchemaAssetId;
  /** @nullable */
  billing_started_at?: LineItemSaleUpdateSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  /** @nullable */
  contract_started_at?: LineItemSaleUpdateSchemaContractStartedAt;
  contract_starts_at: string;
  /** @nullable */
  id?: LineItemSaleUpdateSchemaId;
  is_ready_for_receiving?: boolean;
  klass_id: string;
  modifiers: ModifierSchema[];
  /** @nullable */
  notes?: LineItemSaleUpdateSchemaNotes;
  parent_client_id: string;
  payer_type: LineItemPayerType;
  pricings?: KlassPricingCreateSchema[];
  quantity: number;
  /** @nullable */
  receiving?: LineItemSaleUpdateSchemaReceiving;
  source?: LineItemSource;
  type: LineItemSaleUpdateSchemaType;
}

export interface RepairUpdateSchema {
  asset_id: string;
  /** @nullable */
  id?: RepairUpdateSchemaId;
  line_items: LineItemSaleUpdateSchema[];
  /** @nullable */
  procedure_id?: RepairUpdateSchemaProcedureId;
  /** @nullable */
  scheduled_for?: RepairUpdateSchemaScheduledFor;
  type: ProcedureType;
}

export type LineItemRentalUpdateSchemaType = typeof LineItemRentalUpdateSchemaType[keyof typeof LineItemRentalUpdateSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemRentalUpdateSchemaType = {
  rental: 'rental',
} as const;

/**
 * @nullable
 */
export type LineItemRentalUpdateSchemaShiftId = string | null;

/**
 * @nullable
 */
export type LineItemRentalUpdateSchemaReceiving = ReceiveLineItemSchema | null;

/**
 * @nullable
 */
export type LineItemRentalUpdateSchemaNotes = string | null;

/**
 * @nullable
 */
export type LineItemRentalUpdateSchemaId = string | null;

/**
 * @nullable
 */
export type LineItemRentalUpdateSchemaContractStartedAt = string | null;

/**
 * @nullable
 */
export type LineItemRentalUpdateSchemaContractEndedAt = string | null;

/**
 * @nullable
 */
export type LineItemRentalUpdateSchemaBillingStartedAt = string | null;

/**
 * @nullable
 */
export type LineItemRentalUpdateSchemaBillingEndedAt = string | null;

/**
 * @nullable
 */
export type LineItemRentalUpdateSchemaAssetId = string | null;

export interface LineItemRentalUpdateSchema {
  /** @nullable */
  asset_id?: LineItemRentalUpdateSchemaAssetId;
  /** @nullable */
  billing_ended_at?: LineItemRentalUpdateSchemaBillingEndedAt;
  billing_ends_at: string;
  /** @nullable */
  billing_started_at?: LineItemRentalUpdateSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  /** @nullable */
  contract_ended_at?: LineItemRentalUpdateSchemaContractEndedAt;
  contract_ends_at: string;
  /** @nullable */
  contract_started_at?: LineItemRentalUpdateSchemaContractStartedAt;
  contract_starts_at: string;
  /** @nullable */
  id?: LineItemRentalUpdateSchemaId;
  is_ready_for_receiving?: boolean;
  klass_id: string;
  modifiers: ModifierSchema[];
  /** @nullable */
  notes?: LineItemRentalUpdateSchemaNotes;
  parent_client_id: string;
  payer_type: LineItemPayerType;
  pricings?: KlassPricingCreateSchema[];
  quantity: number;
  /** @nullable */
  receiving?: LineItemRentalUpdateSchemaReceiving;
  /** @nullable */
  shift_id?: LineItemRentalUpdateSchemaShiftId;
  source?: LineItemSource;
  type: LineItemRentalUpdateSchemaType;
}

/**
 * @nullable
 */
export type OrderCreateSchemaSecondarySalesRepId = string | null;

/**
 * @nullable
 */
export type OrderCreateSchemaSalesRepId = string | null;

/**
 * @nullable
 */
export type OrderCreateSchemaSaleTaxRate = string | null;

/**
 * @nullable
 */
export type OrderCreateSchemaRentalTaxRate = string | null;

/**
 * @nullable
 */
export type OrderCreateSchemaPaymentMethodId = string | null;

export type OrderCreateSchemaLineItemsItem = LineItemRentalCreateSchema | LineItemSaleCreateSchema;

/**
 * @nullable
 */
export type OrderCreateSchemaInternalNotes = string | null;

/**
 * @nullable
 */
export type OrderCreateSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

/**
 * @nullable
 */
export type OrderCreateSchemaExternalNotes = string | null;

/**
 * @nullable
 */
export type OrderCreateSchemaExpiresAt = string | null;

/**
 * @nullable
 */
export type OrderCreateSchemaCustomerPoNumber = string | null;

/**
 * @nullable
 */
export type OrderCreateSchemaCustomerJobSiteNumber = string | null;

/**
 * @nullable
 */
export type OrderCreateSchemaCustomerId = string | null;

/**
 * @nullable
 */
export type OrderCreateSchemaCustomerContactId = string | null;

/**
 * @nullable
 */
export type OrderCreateSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type RepairCreateSchemaScheduledFor = string | null;

/**
 * @nullable
 */
export type RepairCreateSchemaProcedureId = string | null;

export interface RepairCreateSchema {
  asset_id: string;
  line_items: LineItemSaleCreateSchema[];
  /** @nullable */
  procedure_id?: RepairCreateSchemaProcedureId;
  /** @nullable */
  scheduled_for?: RepairCreateSchemaScheduledFor;
  type: ProcedureType;
}

export interface OrderCreateSchema {
  /** @nullable */
  billing_profile_id?: OrderCreateSchemaBillingProfileId;
  /** @nullable */
  customer_contact_id?: OrderCreateSchemaCustomerContactId;
  /** @nullable */
  customer_id?: OrderCreateSchemaCustomerId;
  /** @nullable */
  customer_job_site_number?: OrderCreateSchemaCustomerJobSiteNumber;
  /** @nullable */
  customer_po_number?: OrderCreateSchemaCustomerPoNumber;
  delivery: TransientDeliveryRequestBaseCreateSchema;
  delivery_requests: DeliveryRequestUpdateSchema[];
  /** @nullable */
  expires_at?: OrderCreateSchemaExpiresAt;
  /** @nullable */
  external_notes?: OrderCreateSchemaExternalNotes;
  /** @nullable */
  future_invoice_payment_method?: OrderCreateSchemaFutureInvoicePaymentMethod;
  /** @nullable */
  internal_notes?: OrderCreateSchemaInternalNotes;
  line_items: OrderCreateSchemaLineItemsItem[];
  location_id: string;
  modifiers: ModifierSchema[];
  /** @nullable */
  payment_method_id?: OrderCreateSchemaPaymentMethodId;
  /** @nullable */
  rental_tax_rate?: OrderCreateSchemaRentalTaxRate;
  repairs: RepairCreateSchema[];
  /** @nullable */
  sale_tax_rate?: OrderCreateSchemaSaleTaxRate;
  /** @nullable */
  sales_rep_id?: OrderCreateSchemaSalesRepId;
  /** @nullable */
  secondary_sales_rep_id?: OrderCreateSchemaSecondarySalesRepId;
  source: OrderSource;
  status: OrderStatus;
  tags: string[];
  tax_type: TaxType;
}

/**
 * @nullable
 */
export type PartialOrderCreateSchemaSecondarySalesRepId = string | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaSalesRepId = string | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaSaleTaxRate = string | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaRentalTaxRate = string | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaPaymentMethodId = string | null;

export type PartialOrderCreateSchemaLineItemsItem = LineItemRentalCreateSchema | LineItemSaleCreateSchema;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaInternalNotes = string | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaExternalNotes = string | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaExpiresAt = string | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaCustomerPoNumber = string | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaCustomerJobSiteNumber = string | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaCustomerId = string | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaCustomerContactId = string | null;

/**
 * @nullable
 */
export type PartialOrderCreateSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type TransientDeliveryRequestBaseCreateSchemaDeliveryAddressId = string | null;

export interface TransientDeliveryRequestBaseCreateSchema {
  /** @nullable */
  delivery_address_id?: TransientDeliveryRequestBaseCreateSchemaDeliveryAddressId;
  inbound: boolean;
  outbound: boolean;
}

export interface PartialOrderCreateSchema {
  /** @nullable */
  billing_profile_id?: PartialOrderCreateSchemaBillingProfileId;
  /** @nullable */
  customer_contact_id?: PartialOrderCreateSchemaCustomerContactId;
  /** @nullable */
  customer_id?: PartialOrderCreateSchemaCustomerId;
  /** @nullable */
  customer_job_site_number?: PartialOrderCreateSchemaCustomerJobSiteNumber;
  /** @nullable */
  customer_po_number?: PartialOrderCreateSchemaCustomerPoNumber;
  delivery: TransientDeliveryRequestBaseCreateSchema;
  delivery_requests: DeliveryRequestUpdateSchema[];
  /** @nullable */
  expires_at?: PartialOrderCreateSchemaExpiresAt;
  /** @nullable */
  external_notes?: PartialOrderCreateSchemaExternalNotes;
  /** @nullable */
  future_invoice_payment_method?: PartialOrderCreateSchemaFutureInvoicePaymentMethod;
  /** @nullable */
  internal_notes?: PartialOrderCreateSchemaInternalNotes;
  line_items: PartialOrderCreateSchemaLineItemsItem[];
  location_id: string;
  modifiers: ModifierSchema[];
  /** @nullable */
  payment_method_id?: PartialOrderCreateSchemaPaymentMethodId;
  /** @nullable */
  rental_tax_rate?: PartialOrderCreateSchemaRentalTaxRate;
  /** @nullable */
  sale_tax_rate?: PartialOrderCreateSchemaSaleTaxRate;
  /** @nullable */
  sales_rep_id?: PartialOrderCreateSchemaSalesRepId;
  /** @nullable */
  secondary_sales_rep_id?: PartialOrderCreateSchemaSecondarySalesRepId;
  source: OrderSource;
  status: OrderStatus;
  tags: string[];
  tax_type: TaxType;
}

export type LineItemSaleCreateSchemaType = typeof LineItemSaleCreateSchemaType[keyof typeof LineItemSaleCreateSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemSaleCreateSchemaType = {
  sale: 'sale',
} as const;

/**
 * @nullable
 */
export type LineItemSaleCreateSchemaNotes = string | null;

/**
 * @nullable
 */
export type LineItemSaleCreateSchemaContractStartedAt = string | null;

/**
 * @nullable
 */
export type LineItemSaleCreateSchemaBillingStartedAt = string | null;

/**
 * @nullable
 */
export type LineItemSaleCreateSchemaAssetId = string | null;

export interface LineItemSaleCreateSchema {
  /** @nullable */
  asset_id?: LineItemSaleCreateSchemaAssetId;
  /** @nullable */
  billing_started_at?: LineItemSaleCreateSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  /** @nullable */
  contract_started_at?: LineItemSaleCreateSchemaContractStartedAt;
  contract_starts_at: string;
  is_ready_for_receiving?: boolean;
  klass_id: string;
  modifiers: ModifierSchema[];
  /** @nullable */
  notes?: LineItemSaleCreateSchemaNotes;
  parent_client_id: string;
  payer_type: LineItemPayerType;
  pricings?: KlassPricingCreateSchema[];
  quantity: number;
  source?: LineItemSource;
  type: LineItemSaleCreateSchemaType;
}

export type LineItemRentalCreateSchemaType = typeof LineItemRentalCreateSchemaType[keyof typeof LineItemRentalCreateSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemRentalCreateSchemaType = {
  rental: 'rental',
} as const;

/**
 * @nullable
 */
export type LineItemRentalCreateSchemaShiftId = string | null;

/**
 * @nullable
 */
export type LineItemRentalCreateSchemaNotes = string | null;

/**
 * @nullable
 */
export type LineItemRentalCreateSchemaContractStartedAt = string | null;

/**
 * @nullable
 */
export type LineItemRentalCreateSchemaContractEndedAt = string | null;

/**
 * @nullable
 */
export type LineItemRentalCreateSchemaBillingStartedAt = string | null;

/**
 * @nullable
 */
export type LineItemRentalCreateSchemaBillingEndedAt = string | null;

/**
 * @nullable
 */
export type LineItemRentalCreateSchemaAssetId = string | null;

export interface LineItemRentalCreateSchema {
  /** @nullable */
  asset_id?: LineItemRentalCreateSchemaAssetId;
  /** @nullable */
  billing_ended_at?: LineItemRentalCreateSchemaBillingEndedAt;
  billing_ends_at: string;
  /** @nullable */
  billing_started_at?: LineItemRentalCreateSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  /** @nullable */
  contract_ended_at?: LineItemRentalCreateSchemaContractEndedAt;
  contract_ends_at: string;
  /** @nullable */
  contract_started_at?: LineItemRentalCreateSchemaContractStartedAt;
  contract_starts_at: string;
  is_ready_for_receiving?: boolean;
  klass_id: string;
  modifiers: ModifierSchema[];
  /** @nullable */
  notes?: LineItemRentalCreateSchemaNotes;
  parent_client_id: string;
  payer_type: LineItemPayerType;
  pricings?: KlassPricingCreateSchema[];
  quantity: number;
  /** @nullable */
  shift_id?: LineItemRentalCreateSchemaShiftId;
  source?: LineItemSource;
  type: LineItemRentalCreateSchemaType;
}

/**
 * @nullable
 */
export type DeliveryRequestUpdateSchemaToWindow = DateTimeWindowSchema | null;

/**
 * @nullable
 */
export type DeliveryRequestUpdateSchemaTaxRate = string | null;

/**
 * @nullable
 */
export type DeliveryRequestUpdateSchemaTaxGroupId = string | null;

/**
 * @nullable
 */
export type DeliveryRequestUpdateSchemaId = string | null;

/**
 * @nullable
 */
export type DeliveryRequestUpdateSchemaFromWindow = DateTimeWindowSchema | null;

/**
 * @nullable
 */
export type DeliveryRequestUpdateSchemaDeliverySecondaryRate = string | null;

/**
 * @nullable
 */
export type DeliveryRequestUpdateSchemaClientId = string | null;

/**
 * @nullable
 */
export type DeliveryRequestUpdateSchemaBillingProfileId = string | null;

export interface DeliveryRequestUpdateSchema {
  /** @nullable */
  billing_profile_id?: DeliveryRequestUpdateSchemaBillingProfileId;
  charge_at: string;
  /** @nullable */
  client_id?: DeliveryRequestUpdateSchemaClientId;
  delivery_address_id: string;
  delivery_configuration_id: string;
  delivery_rate: string;
  /** @nullable */
  delivery_secondary_rate?: DeliveryRequestUpdateSchemaDeliverySecondaryRate;
  direction: DeliveryRequestDirection;
  /** @nullable */
  from_window?: DeliveryRequestUpdateSchemaFromWindow;
  /** @nullable */
  id?: DeliveryRequestUpdateSchemaId;
  line_items: string[];
  modifiers: ModifierSchema[];
  /** @nullable */
  tax_group_id?: DeliveryRequestUpdateSchemaTaxGroupId;
  /** @nullable */
  tax_rate?: DeliveryRequestUpdateSchemaTaxRate;
  /** @nullable */
  to_window?: DeliveryRequestUpdateSchemaToWindow;
}

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaSignedByIp = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaSignedBy = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaSignedAt = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaSecondarySalesRepId = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaSecondarySalesRep = UserDumpSchema | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaSalesRepId = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaSalesRep = UserDumpSchema | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaSaleTaxRate = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaRentalTaxRate = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaPaymentMethodId = string | null;

export type OrderHydratedDumpSchemaLineItemsItem = RentalLineItemHydratedDumpSchema | SaleLineItemHydratedDumpSchema;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaInvoiceDueNow = LinkIDSchema | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaInternalNotes = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaExternalNotes = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaCustomerPoNumber = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaCustomerJobSiteNumber = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaCustomerId = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaCustomerContactId = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaCustomerContact = CustomerContactHydratedDumpSchema | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaCustomer = CustomerHydratedDumpSchema | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaContractEndsAt = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaBillingProfile = BillingProfileDumpSchema | null;

/**
 * @nullable
 */
export type OrderHydratedDumpSchemaBillingEndsAt = string | null;

export interface OrderHydratedDumpSchema {
  actions: OrderAction[];
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  /** @nullable */
  billing_ends_at?: OrderHydratedDumpSchemaBillingEndsAt;
  /** @nullable */
  billing_profile?: OrderHydratedDumpSchemaBillingProfile;
  /** @nullable */
  billing_profile_id?: OrderHydratedDumpSchemaBillingProfileId;
  billing_starts_at: string;
  /** @nullable */
  contract_ends_at?: OrderHydratedDumpSchemaContractEndsAt;
  contract_starts_at: string;
  count_cycle_prepayment_invoices: number;
  /** @nullable */
  customer?: OrderHydratedDumpSchemaCustomer;
  /** @nullable */
  customer_contact?: OrderHydratedDumpSchemaCustomerContact;
  /** @nullable */
  customer_contact_id?: OrderHydratedDumpSchemaCustomerContactId;
  /** @nullable */
  customer_id?: OrderHydratedDumpSchemaCustomerId;
  /** @nullable */
  customer_job_site_number?: OrderHydratedDumpSchemaCustomerJobSiteNumber;
  /** @nullable */
  customer_po_number?: OrderHydratedDumpSchemaCustomerPoNumber;
  delivery_requests: OrderDeliveryRequestDumpSchema[];
  delivery_subtotal: string;
  expires_at: string;
  /** @nullable */
  external_notes?: OrderHydratedDumpSchemaExternalNotes;
  /** @nullable */
  future_invoice_payment_method?: OrderHydratedDumpSchemaFutureInvoicePaymentMethod;
  has_amount_owed: boolean;
  id: string;
  /** @nullable */
  internal_notes?: OrderHydratedDumpSchemaInternalNotes;
  /** @nullable */
  invoice_due_now?: OrderHydratedDumpSchemaInvoiceDueNow;
  line_items: OrderHydratedDumpSchemaLineItemsItem[];
  location: LocationDumpSchema;
  location_id: string;
  modifiers: ModifierDumpSchema[];
  /** @nullable */
  payment_method_id?: OrderHydratedDumpSchemaPaymentMethodId;
  /** @nullable */
  rental_tax_rate?: OrderHydratedDumpSchemaRentalTaxRate;
  rental_tax_subtotal: string;
  repairs: RepairDumpSchema[];
  /** @nullable */
  sale_tax_rate?: OrderHydratedDumpSchemaSaleTaxRate;
  sale_tax_subtotal: string;
  /** @nullable */
  sales_rep?: OrderHydratedDumpSchemaSalesRep;
  /** @nullable */
  sales_rep_id?: OrderHydratedDumpSchemaSalesRepId;
  /** @nullable */
  secondary_sales_rep?: OrderHydratedDumpSchemaSecondarySalesRep;
  /** @nullable */
  secondary_sales_rep_id?: OrderHydratedDumpSchemaSecondarySalesRepId;
  /** @nullable */
  signed_at?: OrderHydratedDumpSchemaSignedAt;
  /** @nullable */
  signed_by?: OrderHydratedDumpSchemaSignedBy;
  /** @nullable */
  signed_by_ip?: OrderHydratedDumpSchemaSignedByIp;
  source: OrderSource;
  status: OrderStatus;
  summary: OrderSummarySection[];
  tags: string[];
  tax_type: TaxType;
  type: OrderType;
}

export type SaleLineItemHydratedDumpSchemaType = typeof SaleLineItemHydratedDumpSchemaType[keyof typeof SaleLineItemHydratedDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaleLineItemHydratedDumpSchemaType = {
  sale: 'sale',
} as const;

/**
 * @nullable
 */
export type SaleLineItemHydratedDumpSchemaNotes = string | null;

/**
 * @nullable
 */
export type SaleLineItemHydratedDumpSchemaContractStartedAt = string | null;

/**
 * @nullable
 */
export type SaleLineItemHydratedDumpSchemaBillingStartedAt = string | null;

/**
 * @nullable
 */
export type SaleLineItemHydratedDumpSchemaAssetId = string | null;

/**
 * @nullable
 */
export type SaleLineItemHydratedDumpSchemaAssetCustomerIdentifier = string | null;

/**
 * @nullable
 */
export type SaleLineItemHydratedDumpSchemaAsset = AssetDumpSchema | null;

export interface SaleLineItemHydratedDumpSchema {
  amount_per_unit: string;
  /** @nullable */
  asset?: SaleLineItemHydratedDumpSchemaAsset;
  /** @nullable */
  asset_customer_identifier?: SaleLineItemHydratedDumpSchemaAssetCustomerIdentifier;
  /** @nullable */
  asset_id?: SaleLineItemHydratedDumpSchemaAssetId;
  base_amount_per_unit: string;
  /** @nullable */
  billing_started_at?: SaleLineItemHydratedDumpSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  /** @nullable */
  contract_started_at?: SaleLineItemHydratedDumpSchemaContractStartedAt;
  contract_starts_at: string;
  extended_amount: string;
  extended_base_amount: string;
  id: string;
  is_ready_for_receiving: boolean;
  klass: KlassDumpSchema;
  klass_id: string;
  klass_name: string;
  klass_tracking: TrackingType;
  klass_type: KlassType;
  modifiers: ModifierDumpSchema[];
  /** @nullable */
  notes?: SaleLineItemHydratedDumpSchemaNotes;
  parent_client_id: string;
  payer_type: LineItemPayerType;
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  source: LineItemSource;
  status: LineItemStatus;
  type: SaleLineItemHydratedDumpSchemaType;
}

/**
 * @nullable
 */
export type RepairOrderSchemaLocationGroupId = string | null;

/**
 * @nullable
 */
export type RepairOrderSchemaCustomerId = string | null;

/**
 * @nullable
 */
export type RepairOrderSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type RepairOrderSchemaBillingEndsAt = string | null;

export interface RepairOrderSchema {
  /** @nullable */
  billing_ends_at?: RepairOrderSchemaBillingEndsAt;
  /** @nullable */
  billing_profile_id?: RepairOrderSchemaBillingProfileId;
  billing_starts_at: string;
  /** @nullable */
  customer_id?: RepairOrderSchemaCustomerId;
  id: string;
  identifier: string;
  /** @nullable */
  location_group_id?: RepairOrderSchemaLocationGroupId;
  location_id: string;
  payer_type: OrderPayerType;
}

/**
 * @nullable
 */
export type RepairDumpSchemaScheduledFor = string | null;

/**
 * @nullable
 */
export type RepairDumpSchemaProcedureId = string | null;

/**
 * @nullable
 */
export type RepairDumpSchemaProcedure = LinkNameSchema | null;

export type RepairAction = typeof RepairAction[keyof typeof RepairAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RepairAction = {
  schedule: 'schedule',
  start_work: 'start_work',
  end_work: 'end_work',
  continue_work: 'continue_work',
  complete: 'complete',
  edit: 'edit',
} as const;

export interface RepairDumpSchema {
  actions: RepairAction[];
  activity_log_id: string;
  asset: AssetDumpSchema;
  asset_id: string;
  id: string;
  identifier: string;
  order: RepairOrderSchema;
  /** @nullable */
  procedure?: RepairDumpSchemaProcedure;
  /** @nullable */
  procedure_id?: RepairDumpSchemaProcedureId;
  /** @nullable */
  scheduled_for?: RepairDumpSchemaScheduledFor;
  type: ProcedureType;
}

export type RentalLineItemHydratedDumpSchemaType = typeof RentalLineItemHydratedDumpSchemaType[keyof typeof RentalLineItemHydratedDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RentalLineItemHydratedDumpSchemaType = {
  rental: 'rental',
} as const;

/**
 * @nullable
 */
export type RentalLineItemHydratedDumpSchemaShiftId = string | null;

/**
 * @nullable
 */
export type RentalLineItemHydratedDumpSchemaShift = ShiftDumpSchema | null;

/**
 * @nullable
 */
export type RentalLineItemHydratedDumpSchemaNotes = string | null;

/**
 * @nullable
 */
export type RentalLineItemHydratedDumpSchemaContractStartedAt = string | null;

/**
 * @nullable
 */
export type RentalLineItemHydratedDumpSchemaContractEndedAt = string | null;

/**
 * @nullable
 */
export type RentalLineItemHydratedDumpSchemaBillingStartedAt = string | null;

/**
 * @nullable
 */
export type RentalLineItemHydratedDumpSchemaBillingEndedAt = string | null;

/**
 * @nullable
 */
export type RentalLineItemHydratedDumpSchemaAssetId = string | null;

/**
 * @nullable
 */
export type RentalLineItemHydratedDumpSchemaAssetCustomerIdentifier = string | null;

/**
 * @nullable
 */
export type RentalLineItemHydratedDumpSchemaAsset = AssetDumpSchema | null;

/**
 * @nullable
 */
export type ReceiveLineItemHydratedDumpSchemaReceivingNotes = string | null;

export interface ReceiveLineItemHydratedDumpSchema {
  receiving_attachments: CustomerFileDumpSchema[];
  /** @nullable */
  receiving_notes?: ReceiveLineItemHydratedDumpSchemaReceivingNotes;
  usage_metric_values: UsageMetricValueDumpSchema[];
}

/**
 * @nullable
 */
export type RentalLineItemHydratedDumpSchemaReceiving = ReceiveLineItemHydratedDumpSchema | null;

export interface RentalLineItemHydratedDumpSchema {
  amount_per_unit: string;
  /** @nullable */
  asset?: RentalLineItemHydratedDumpSchemaAsset;
  /** @nullable */
  asset_customer_identifier?: RentalLineItemHydratedDumpSchemaAssetCustomerIdentifier;
  /** @nullable */
  asset_id?: RentalLineItemHydratedDumpSchemaAssetId;
  base_amount_per_unit: string;
  /** @nullable */
  billing_ended_at?: RentalLineItemHydratedDumpSchemaBillingEndedAt;
  billing_ends_at: string;
  /** @nullable */
  billing_started_at?: RentalLineItemHydratedDumpSchemaBillingStartedAt;
  billing_starts_at: string;
  client_id: string;
  /** @nullable */
  contract_ended_at?: RentalLineItemHydratedDumpSchemaContractEndedAt;
  contract_ends_at: string;
  /** @nullable */
  contract_started_at?: RentalLineItemHydratedDumpSchemaContractStartedAt;
  contract_starts_at: string;
  extended_amount: string;
  extended_base_amount: string;
  id: string;
  is_ready_for_receiving: boolean;
  klass: KlassDumpSchema;
  klass_id: string;
  klass_name: string;
  klass_tracking: TrackingType;
  klass_type: KlassType;
  modifiers: ModifierDumpSchema[];
  /** @nullable */
  notes?: RentalLineItemHydratedDumpSchemaNotes;
  parent_client_id: string;
  payer_type: LineItemPayerType;
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  /** @nullable */
  receiving?: RentalLineItemHydratedDumpSchemaReceiving;
  /** @nullable */
  shift?: RentalLineItemHydratedDumpSchemaShift;
  /** @nullable */
  shift_id?: RentalLineItemHydratedDumpSchemaShiftId;
  source: LineItemSource;
  status: LineItemStatus;
  type: RentalLineItemHydratedDumpSchemaType;
}

export type ProcedureType = typeof ProcedureType[keyof typeof ProcedureType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcedureType = {
  asset_available: 'asset_available',
  asset_unavailable: 'asset_unavailable',
} as const;

export interface OrderSummarySection {
  bold?: boolean;
  lines: OrderSummaryLine[];
}

/**
 * @nullable
 */
export type OrderDeliveryRequestDumpSchemaToWindow = DateTimeWindowSchema | null;

export type OrderDeliveryRequestDumpSchemaToLocation = DeliveryLocationBranchDumpSchema | DeliveryLocationCustomerLocationDumpSchema;

/**
 * @nullable
 */
export type OrderDeliveryRequestDumpSchemaTaxRate = string | null;

/**
 * @nullable
 */
export type OrderDeliveryRequestDumpSchemaTaxGroupId = string | null;

/**
 * @nullable
 */
export type OrderDeliveryRequestDumpSchemaFromWindow = DateTimeWindowSchema | null;

export type OrderDeliveryRequestDumpSchemaFromLocation = DeliveryLocationBranchDumpSchema | DeliveryLocationCustomerLocationDumpSchema;

/**
 * @nullable
 */
export type OrderDeliveryRequestDumpSchemaDeliverySecondaryRate = string | null;

/**
 * @nullable
 */
export type OrderDeliveryRequestDumpSchemaBillingProfileId = string | null;

export interface OrderDeliveryRequestDumpSchema {
  amount: string;
  /** @nullable */
  billing_profile_id?: OrderDeliveryRequestDumpSchemaBillingProfileId;
  charge_at: string;
  delivery_configuration: DeliveryConfigurationDumpSchema;
  delivery_configuration_id: string;
  delivery_rate: string;
  /** @nullable */
  delivery_secondary_rate?: OrderDeliveryRequestDumpSchemaDeliverySecondaryRate;
  direction: DeliveryRequestDirection;
  from_location: OrderDeliveryRequestDumpSchemaFromLocation;
  /** @nullable */
  from_window?: OrderDeliveryRequestDumpSchemaFromWindow;
  id: string;
  modifiers: ModifierDumpSchema[];
  /** @nullable */
  tax_group_id?: OrderDeliveryRequestDumpSchemaTaxGroupId;
  /** @nullable */
  tax_rate?: OrderDeliveryRequestDumpSchemaTaxRate;
  to_location: OrderDeliveryRequestDumpSchemaToLocation;
  /** @nullable */
  to_window?: OrderDeliveryRequestDumpSchemaToWindow;
}

export type LineItemStatus = typeof LineItemStatus[keyof typeof LineItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemStatus = {
  order: 'order',
  reserved: 'reserved',
  on_rent: 'on_rent',
  off_rent: 'off_rent',
  returned: 'returned',
  sold: 'sold',
} as const;

export type LineItemSource = typeof LineItemSource[keyof typeof LineItemSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemSource = {
  user: 'user',
  receiving__manual: 'receiving__manual',
  receiving__automatic: 'receiving__automatic',
} as const;

export type LineItemPayerType = typeof LineItemPayerType[keyof typeof LineItemPayerType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemPayerType = {
  customer: 'customer',
  warranty: 'warranty',
  internal: 'internal',
} as const;

/**
 * @nullable
 */
export type PaginatedOrderSchemaNext = number | null;

export interface PaginatedOrderSchema {
  elements: OrderDumpSchema[];
  /** @nullable */
  next?: PaginatedOrderSchemaNext;
  total_count: number;
}

export type OrderType = typeof OrderType[keyof typeof OrderType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderType = {
  order: 'order',
  repair: 'repair',
} as const;

export type OrderSource = typeof OrderSource[keyof typeof OrderSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderSource = {
  self_service: 'self_service',
  sales: 'sales',
  internal_repair: 'internal_repair',
} as const;

/**
 * @nullable
 */
export type OrderDumpSchemaVendor = LinkNameSchema | null;

/**
 * @nullable
 */
export type OrderDumpSchemaSignedByIp = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaSignedBy = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaSignedAt = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaSecondarySalesRepId = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaSecondarySalesRep = LinkNameSchema | null;

/**
 * @nullable
 */
export type OrderDumpSchemaSalesRepId = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaSalesRep = LinkNameSchema | null;

/**
 * @nullable
 */
export type OrderDumpSchemaSaleTaxRate = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaRentalTaxRate = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaPaymentMethodId = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaInternalNotes = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaFutureInvoicePaymentMethod = FutureInvoicePaymentMethod | null;

/**
 * @nullable
 */
export type OrderDumpSchemaExternalNotes = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaCustomerPoNumber = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaCustomerJobSiteNumber = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaCustomerId = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaCustomerContactId = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaCustomer = LinkIDNameSchema | null;

/**
 * @nullable
 */
export type OrderDumpSchemaContractEndsAt = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type OrderDumpSchemaBillingEndsAt = string | null;

export type OrderAction = typeof OrderAction[keyof typeof OrderAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderAction = {
  download: 'download',
  email: 'email',
  edit: 'edit',
  convert_to_reservation: 'convert_to_reservation',
  convert_to_contract: 'convert_to_contract',
  convert_to_active: 'convert_to_active',
  convert_to_off_rent: 'convert_to_off_rent',
  convert_to_returned: 'convert_to_returned',
  collect_payment: 'collect_payment',
  delete: 'delete',
} as const;

export interface OrderDumpSchema {
  actions: OrderAction[];
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  /** @nullable */
  billing_ends_at?: OrderDumpSchemaBillingEndsAt;
  /** @nullable */
  billing_profile_id?: OrderDumpSchemaBillingProfileId;
  billing_starts_at: string;
  /** @nullable */
  contract_ends_at?: OrderDumpSchemaContractEndsAt;
  contract_starts_at: string;
  /** @nullable */
  customer?: OrderDumpSchemaCustomer;
  /** @nullable */
  customer_contact_id?: OrderDumpSchemaCustomerContactId;
  /** @nullable */
  customer_id?: OrderDumpSchemaCustomerId;
  /** @nullable */
  customer_job_site_number?: OrderDumpSchemaCustomerJobSiteNumber;
  /** @nullable */
  customer_po_number?: OrderDumpSchemaCustomerPoNumber;
  expires_at: string;
  /** @nullable */
  external_notes?: OrderDumpSchemaExternalNotes;
  /** @nullable */
  future_invoice_payment_method?: OrderDumpSchemaFutureInvoicePaymentMethod;
  has_amount_owed: boolean;
  id: string;
  /** @nullable */
  internal_notes?: OrderDumpSchemaInternalNotes;
  location: LinkIDNameSchema;
  location_id: string;
  /** @nullable */
  payment_method_id?: OrderDumpSchemaPaymentMethodId;
  /** @nullable */
  rental_tax_rate?: OrderDumpSchemaRentalTaxRate;
  /** @nullable */
  sale_tax_rate?: OrderDumpSchemaSaleTaxRate;
  /** @nullable */
  sales_rep?: OrderDumpSchemaSalesRep;
  /** @nullable */
  sales_rep_id?: OrderDumpSchemaSalesRepId;
  /** @nullable */
  secondary_sales_rep?: OrderDumpSchemaSecondarySalesRep;
  /** @nullable */
  secondary_sales_rep_id?: OrderDumpSchemaSecondarySalesRepId;
  /** @nullable */
  signed_at?: OrderDumpSchemaSignedAt;
  /** @nullable */
  signed_by?: OrderDumpSchemaSignedBy;
  /** @nullable */
  signed_by_ip?: OrderDumpSchemaSignedByIp;
  source: OrderSource;
  status: OrderStatus;
  tax_type: TaxType;
  type: OrderType;
  /** @nullable */
  vendor?: OrderDumpSchemaVendor;
}

export type FutureInvoicePaymentMethod = typeof FutureInvoicePaymentMethod[keyof typeof FutureInvoicePaymentMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FutureInvoicePaymentMethod = {
  auto_charge: 'auto_charge',
  email_no_link: 'email_no_link',
  email_with_link: 'email_with_link',
} as const;

export interface BillingProfileCreateSchema {
  cycle: BillingCycle;
  due: PaymentDue;
  name: string;
  prepayments: PrepaymentCreateSchema[];
  terms: PaymentTerms;
  type: BillingProfileType;
}

/**
 * @nullable
 */
export type PrepaymentCreateSchemaChargeTime = PrepaymentChargeTime | null;

/**
 * @nullable
 */
export type PrepaymentCreateSchemaChargeAmount = string | null;

export interface BillingProfileHydratedDumpSchema {
  cycle: BillingCycle;
  due: PaymentDue;
  id: string;
  name: string;
  prepayments: PrepaymentDumpSchema[];
  terms: PaymentTerms;
  type: BillingProfileType;
}

export type PrepaymentType = typeof PrepaymentType[keyof typeof PrepaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrepaymentType = {
  one_time: 'one_time',
  recurring: 'recurring',
} as const;

/**
 * @nullable
 */
export type PrepaymentDumpSchemaChargeAmount = string | null;

export type PrepaymentCycle = typeof PrepaymentCycle[keyof typeof PrepaymentCycle];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrepaymentCycle = {
  entire: 'entire',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
} as const;

export interface PrepaymentCreateSchema {
  /** @nullable */
  charge_amount?: PrepaymentCreateSchemaChargeAmount;
  /** @nullable */
  charge_time?: PrepaymentCreateSchemaChargeTime;
  cycle: PrepaymentCycle;
  type: PrepaymentType;
}

export interface PrepaymentDumpSchema {
  /** @nullable */
  charge_amount?: PrepaymentDumpSchemaChargeAmount;
  /** @nullable */
  charge_time?: PrepaymentDumpSchemaChargeTime;
  cycle: PrepaymentCycle;
  id: string;
  type: PrepaymentType;
}

export type PrepaymentChargeTime = typeof PrepaymentChargeTime[keyof typeof PrepaymentChargeTime];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrepaymentChargeTime = {
  reservation_creation: 'reservation_creation',
  bd1_contract_start: 'bd1_contract_start',
  bd2_contract_start: 'bd2_contract_start',
  bd14_contract_start: 'bd14_contract_start',
  start_of_contract: 'start_of_contract',
} as const;

/**
 * @nullable
 */
export type PrepaymentDumpSchemaChargeTime = PrepaymentChargeTime | null;

/**
 * @nullable
 */
export type PaginatedBillingProfileSchemaNext = number | null;

export interface PaginatedBillingProfileSchema {
  elements: BillingProfileDumpSchema[];
  /** @nullable */
  next?: PaginatedBillingProfileSchemaNext;
  total_count: number;
}

export interface InvoiceCustomerBalanceBodySchema {
  amount: string;
}

export interface InvoiceAdjustmentBodySchema {
  amount: string;
  reason: string;
}

/**
 * @nullable
 */
export type InvoiceExternalHydratedDumpSchemaSecondarySalesRep = UserDumpSchema | null;

/**
 * @nullable
 */
export type InvoiceExternalHydratedDumpSchemaSalesRep = UserDumpSchema | null;

/**
 * @nullable
 */
export type InvoiceExternalHydratedDumpSchemaExternalNotes = string | null;

/**
 * @nullable
 */
export type InvoiceExternalHydratedDumpSchemaCycleStart = string | null;

/**
 * @nullable
 */
export type InvoiceExternalHydratedDumpSchemaCycleEnd = string | null;

/**
 * @nullable
 */
export type InvoiceExternalHydratedDumpSchemaCustomerPoNumber = string | null;

/**
 * @nullable
 */
export type InvoiceExternalHydratedDumpSchemaCustomerJobSiteNumber = string | null;

/**
 * @nullable
 */
export type InvoiceExternalHydratedDumpSchemaCustomerContact = CustomerContactHydratedDumpSchema | null;

/**
 * @nullable
 */
export type InvoiceExternalHydratedDumpSchemaCustomer = CustomerHydratedDumpSchema | null;

/**
 * @nullable
 */
export type InvoiceExternalHydratedDumpSchemaBillingAddress = AddressDumpSchema | null;

export type LineItemType = typeof LineItemType[keyof typeof LineItemType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemType = {
  rental: 'rental',
  sale: 'sale',
} as const;

export interface InvoiceLineItemNonItemExternalDumpSchema {
  amount: string;
  id: string;
  label: string;
  type: InvoiceLineItemType;
}

export interface InvoiceExternalHydratedDumpSchema {
  adjustment_subtotal: string;
  amount: string;
  amount_billed: string;
  amount_due: string;
  amount_paid: string;
  /** @nullable */
  billing_address?: InvoiceExternalHydratedDumpSchemaBillingAddress;
  business_name: string;
  charge_at: string;
  child_invoices: InvoiceExternalDumpSchema[];
  created_at: string;
  /** @nullable */
  customer?: InvoiceExternalHydratedDumpSchemaCustomer;
  /** @nullable */
  customer_contact?: InvoiceExternalHydratedDumpSchemaCustomerContact;
  customer_id: string;
  /** @nullable */
  customer_job_site_number?: InvoiceExternalHydratedDumpSchemaCustomerJobSiteNumber;
  /** @nullable */
  customer_po_number?: InvoiceExternalHydratedDumpSchemaCustomerPoNumber;
  /** @nullable */
  cycle_end?: InvoiceExternalHydratedDumpSchemaCycleEnd;
  /** @nullable */
  cycle_start?: InvoiceExternalHydratedDumpSchemaCycleStart;
  due_at: string;
  /** @nullable */
  external_notes?: InvoiceExternalHydratedDumpSchemaExternalNotes;
  id: string;
  identifier: string;
  invoice_only_line_items: InvoiceLineItemNonItemExternalDumpSchema[];
  line_items: InvoiceLineItemItemExternalDumpSchema[];
  location: LocationDumpSchema;
  modifiers: ModifierDumpSchema[];
  order: LinkIDSchema;
  order_id: string;
  payments: PaymentDumpSchema[];
  prepayment_amount: string;
  prepayment_amount_paid: string;
  rental_item_subtotal: string;
  sale_item_subtotal: string;
  /** @nullable */
  sales_rep?: InvoiceExternalHydratedDumpSchemaSalesRep;
  /** @nullable */
  secondary_sales_rep?: InvoiceExternalHydratedDumpSchemaSecondarySalesRep;
  status: InvoiceStatus;
  summary: OrderSummaryLine[];
  tax_subtotal: string;
  type: InvoiceType;
}

/**
 * @nullable
 */
export type InvoiceLineItemItemExternalDumpSchemaShiftId = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemItemExternalDumpSchemaShift = ShiftDumpSchema | null;

/**
 * @nullable
 */
export type InvoiceLineItemItemExternalDumpSchemaExternalDescription = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemItemExternalDumpSchemaContractStartedAt = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemItemExternalDumpSchemaContractEndedAt = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemItemExternalDumpSchemaAssetId = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemItemExternalDumpSchemaAssetCustomerIdentifier = string | null;

export interface InvoiceLineItemItemExternalDumpSchema {
  amount_per_unit: string;
  /** @nullable */
  asset_customer_identifier?: InvoiceLineItemItemExternalDumpSchemaAssetCustomerIdentifier;
  /** @nullable */
  asset_id?: InvoiceLineItemItemExternalDumpSchemaAssetId;
  assets: AssetExternalDumpSchema[];
  base_amount_per_unit: string;
  /** @nullable */
  contract_ended_at?: InvoiceLineItemItemExternalDumpSchemaContractEndedAt;
  contract_ends_at: string;
  /** @nullable */
  contract_started_at?: InvoiceLineItemItemExternalDumpSchemaContractStartedAt;
  contract_starts_at: string;
  extended_amount: string;
  extended_base_amount: string;
  /** @nullable */
  external_description?: InvoiceLineItemItemExternalDumpSchemaExternalDescription;
  id: string;
  klass_id: string;
  klass_name: string;
  klass_tracking: TrackingType;
  klass_type: KlassType;
  label: string;
  modifiers?: ModifierDumpSchema[];
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  /** @nullable */
  shift?: InvoiceLineItemItemExternalDumpSchemaShift;
  /** @nullable */
  shift_id?: InvoiceLineItemItemExternalDumpSchemaShiftId;
  type: LineItemType;
}

/**
 * @nullable
 */
export type InvoiceExternalDumpSchemaCycleStart = string | null;

/**
 * @nullable
 */
export type InvoiceExternalDumpSchemaCycleEnd = string | null;

/**
 * @nullable
 */
export type InvoiceExternalDumpSchemaCustomerPoNumber = string | null;

export interface InvoiceExternalDumpSchema {
  adjustment_subtotal: string;
  amount: string;
  amount_billed: string;
  amount_due: string;
  amount_paid: string;
  charge_at: string;
  created_at: string;
  customer: LinkIDNameSchema;
  customer_id: string;
  /** @nullable */
  customer_po_number?: InvoiceExternalDumpSchemaCustomerPoNumber;
  /** @nullable */
  cycle_end?: InvoiceExternalDumpSchemaCycleEnd;
  /** @nullable */
  cycle_start?: InvoiceExternalDumpSchemaCycleStart;
  due_at: string;
  id: string;
  identifier: string;
  order: LinkIDSchema;
  order_id: string;
  payments: PaymentDumpSchema[];
  prepayment_amount: string;
  prepayment_amount_paid: string;
  rental_item_subtotal: string;
  sale_item_subtotal: string;
  status: InvoiceStatus;
  tax_subtotal: string;
  type: InvoiceType;
}

/**
 * @nullable
 */
export type AssetExternalDumpSchemaPrimarySerialNumber = string | null;

/**
 * @nullable
 */
export type AssetExternalDumpSchemaMeterOutValue = string | null;

/**
 * @nullable
 */
export type AssetExternalDumpSchemaMeterOutUnit = UsageMetricUnit | null;

/**
 * @nullable
 */
export type AssetExternalDumpSchemaMeterInValue = string | null;

/**
 * @nullable
 */
export type AssetExternalDumpSchemaGaugeType = FuelType | null;

/**
 * @nullable
 */
export type AssetExternalDumpSchemaGaugeOutValue = string | null;

/**
 * @nullable
 */
export type AssetExternalDumpSchemaGaugeOutUnit = UsageMetricUnit | null;

/**
 * @nullable
 */
export type AssetExternalDumpSchemaGaugeInValue = string | null;

export interface AssetExternalDumpSchema {
  /** @nullable */
  gauge_in_value?: AssetExternalDumpSchemaGaugeInValue;
  /** @nullable */
  gauge_out_unit?: AssetExternalDumpSchemaGaugeOutUnit;
  /** @nullable */
  gauge_out_value?: AssetExternalDumpSchemaGaugeOutValue;
  /** @nullable */
  gauge_type?: AssetExternalDumpSchemaGaugeType;
  identifier: string;
  /** @nullable */
  meter_in_value?: AssetExternalDumpSchemaMeterInValue;
  /** @nullable */
  meter_out_unit?: AssetExternalDumpSchemaMeterOutUnit;
  /** @nullable */
  meter_out_value?: AssetExternalDumpSchemaMeterOutValue;
  /** @nullable */
  primary_serial_number?: AssetExternalDumpSchemaPrimarySerialNumber;
}

/**
 * @nullable
 */
export type InvoicePOUpdateBodySchemaCustomerPoNumber = string | null;

export interface InvoicePOUpdateBodySchema {
  /** @nullable */
  customer_po_number?: InvoicePOUpdateBodySchemaCustomerPoNumber;
}

/**
 * @nullable
 */
export type InvoiceHydratedDumpSchemaParentInvoiceId = string | null;

/**
 * @nullable
 */
export type InvoiceHydratedDumpSchemaParentInvoice = LinkIDSchema | null;

/**
 * @nullable
 */
export type InvoiceHydratedDumpSchemaCycleStart = string | null;

/**
 * @nullable
 */
export type InvoiceHydratedDumpSchemaCycleEnd = string | null;

/**
 * @nullable
 */
export type InvoiceHydratedDumpSchemaCustomerPoNumber = string | null;

/**
 * @nullable
 */
export type InvoiceHydratedDumpSchemaBillingCycleEnd = string | null;

export type TaxType = typeof TaxType[keyof typeof TaxType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxType = {
  auto: 'auto',
  manual: 'manual',
} as const;

export interface OrderSummaryLine {
  amount: string;
  label: string;
}

/**
 * @nullable
 */
export type ModifierDumpSchemaExpenseRuleId = string | null;

export interface ModifierDumpSchema {
  amount: string;
  expense_class: ExpenseClassBaseDumpSchema;
  expense_rule: ExpenseRuleDumpSchema;
  /** @nullable */
  expense_rule_id?: ModifierDumpSchemaExpenseRuleId;
  rate: string;
}

export interface InvoicePaymentDumpSchema {
  amount: string;
  payment: PaymentDumpSchema;
}

export type InvoiceLineItemType = typeof InvoiceLineItemType[keyof typeof InvoiceLineItemType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceLineItemType = {
  tax: 'tax',
  adjustment: 'adjustment',
  surcharge: 'surcharge',
  delivery: 'delivery',
  item__rental: 'item__rental',
  item__sale: 'item__sale',
} as const;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaTaxType = TaxType | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaTaxRate = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaTaxGroupId = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaShiftId = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaShift = ShiftDumpSchema | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaLineItemId = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaKlassId = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaKlass = KlassDumpSchema | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaContractStartsAt = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaContractStartedAt = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaContractEndsAt = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaContractEndedAt = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaAssetId = string | null;

/**
 * @nullable
 */
export type InvoiceLineItemDumpSchemaAsset = AssetDumpSchema | null;

export interface InvoiceLineItemDumpSchema {
  amount_per_unit: string;
  /** @nullable */
  asset?: InvoiceLineItemDumpSchemaAsset;
  /** @nullable */
  asset_id?: InvoiceLineItemDumpSchemaAssetId;
  base_amount_per_unit: string;
  /** @nullable */
  contract_ended_at?: InvoiceLineItemDumpSchemaContractEndedAt;
  /** @nullable */
  contract_ends_at?: InvoiceLineItemDumpSchemaContractEndsAt;
  /** @nullable */
  contract_started_at?: InvoiceLineItemDumpSchemaContractStartedAt;
  /** @nullable */
  contract_starts_at?: InvoiceLineItemDumpSchemaContractStartsAt;
  extended_amount: string;
  extended_base_amount: string;
  id: string;
  /** @nullable */
  klass?: InvoiceLineItemDumpSchemaKlass;
  /** @nullable */
  klass_id?: InvoiceLineItemDumpSchemaKlassId;
  label: string;
  /** @nullable */
  line_item_id?: InvoiceLineItemDumpSchemaLineItemId;
  modifiers: ModifierDumpSchema[];
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  /** @nullable */
  shift?: InvoiceLineItemDumpSchemaShift;
  /** @nullable */
  shift_id?: InvoiceLineItemDumpSchemaShiftId;
  /** @nullable */
  tax_group_id?: InvoiceLineItemDumpSchemaTaxGroupId;
  /** @nullable */
  tax_rate?: InvoiceLineItemDumpSchemaTaxRate;
  /** @nullable */
  tax_type?: InvoiceLineItemDumpSchemaTaxType;
  type: InvoiceLineItemType;
}

export interface InvoiceHydratedDumpSchema {
  activity_log_id: string;
  adjustment_subtotal: string;
  amount: string;
  amount_due: string;
  amount_paid: string;
  /** @nullable */
  billing_cycle_end?: InvoiceHydratedDumpSchemaBillingCycleEnd;
  charge_at: string;
  child_invoices: InvoiceDumpSchema[];
  created_at: string;
  customer: CustomerHydratedDumpSchema;
  customer_id: string;
  /** @nullable */
  customer_po_number?: InvoiceHydratedDumpSchemaCustomerPoNumber;
  /** @nullable */
  cycle_end?: InvoiceHydratedDumpSchemaCycleEnd;
  /** @nullable */
  cycle_start?: InvoiceHydratedDumpSchemaCycleStart;
  due_at: string;
  id: string;
  identifier: string;
  item_subtotal: string;
  line_items: InvoiceLineItemDumpSchema[];
  modifiers: ModifierDumpSchema[];
  order: LinkIDSchema;
  order_id: string;
  /** @nullable */
  parent_invoice?: InvoiceHydratedDumpSchemaParentInvoice;
  /** @nullable */
  parent_invoice_id?: InvoiceHydratedDumpSchemaParentInvoiceId;
  payments: InvoicePaymentDumpSchema[];
  status: InvoiceStatus;
  summary: OrderSummaryLine[];
  tax_subtotal: string;
  type: InvoiceType;
}

/**
 * @nullable
 */
export type ExpenseClassBaseDumpSchemaRevenueAccountId = string | null;

/**
 * @nullable
 */
export type ExpenseClassBaseDumpSchemaProductTaxCodeGroupId = string | null;

export interface ExpenseClassBaseDumpSchema {
  display_type: ExpenseClassDisplayType;
  id: string;
  name: string;
  /** @nullable */
  product_tax_code_group_id?: ExpenseClassBaseDumpSchemaProductTaxCodeGroupId;
  /** @nullable */
  revenue_account_id?: ExpenseClassBaseDumpSchemaRevenueAccountId;
  type: ExpenseClassType;
}

/**
 * @nullable
 */
export type PaginatedInvoiceSchemaNext = number | null;

export interface PaginatedInvoiceSchema {
  elements: InvoiceDumpSchema[];
  /** @nullable */
  next?: PaginatedInvoiceSchemaNext;
  total_count: number;
}

export interface PaymentDepositReconciliationSchema {
  payment_id: string;
}

export interface DepositUpdateSchema {
  deposit_at: string;
  name: string;
  payments: PaymentDepositReconciliationSchema[];
  status: DepositStatus;
}

/**
 * @nullable
 */
export type PaginatedDepositSchemaNext = number | null;

export interface PaginatedDepositSchema {
  elements: DumpDepositSchema[];
  /** @nullable */
  next?: PaginatedDepositSchemaNext;
  total_count: number;
}

export interface PaymentDepositReconciliationDumpSchema {
  payment: PaymentDumpSchema;
  payment_id: string;
}

export type DepositType = typeof DepositType[keyof typeof DepositType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepositType = {
  manual: 'manual',
  stripe: 'stripe',
} as const;

export interface CreateDepositSchema {
  deposit_at: string;
  name: string;
  payments: PaymentDepositReconciliationSchema[];
  type: DepositType;
}

export interface DumpDepositSchema {
  amount: string;
  deposit_at: string;
  id: string;
  name: string;
  payments: PaymentDepositReconciliationDumpSchema[];
  status: DepositStatus;
  type: DepositType;
}

export interface KlassAvailabilitySegmentSchema {
  count_available: number;
  count_total: number;
  count_unavailable: number;
  date: string;
}

export interface KlassAvailabilityCalendarDumpSchema {
  segments: KlassAvailabilitySegmentSchema[];
}

/**
 * @nullable
 */
export type KlassAvailabilityCalendarBodySchemaLocationId = string | null;

/**
 * @nullable
 */
export type KlassAvailabilityCalendarBodySchemaLocationGroupId = string | null;

/**
 * @nullable
 */
export type KlassAvailabilityCalendarBodySchemaKlassId = string | null;

/**
 * @nullable
 */
export type KlassAvailabilityCalendarBodySchemaEndDate = string | null;

/**
 * @nullable
 */
export type KlassAvailabilityCalendarBodySchemaAssetId = string | null;

export interface KlassAvailabilityCalendarBodySchema {
  /** @nullable */
  asset_id?: KlassAvailabilityCalendarBodySchemaAssetId;
  /** @nullable */
  end_date?: KlassAvailabilityCalendarBodySchemaEndDate;
  /** @nullable */
  klass_id?: KlassAvailabilityCalendarBodySchemaKlassId;
  /** @nullable */
  location_group_id?: KlassAvailabilityCalendarBodySchemaLocationGroupId;
  /** @nullable */
  location_id?: KlassAvailabilityCalendarBodySchemaLocationId;
  start_date: string;
}

export type ReportResponseResponseAnyOf = { [key: string]: unknown };

export type ReportResponseResponse = string | Blob | ReportResponseResponseAnyOf;

export interface ReportResponse {
  /** @nullable */
  headers: ReportResponseHeaders;
  response: ReportResponseResponse;
}

export type ReportResponseHeadersAnyOf = {[key: string]: string};

/**
 * @nullable
 */
export type ReportResponseHeaders = ReportResponseHeadersAnyOf | null;

export interface ItemReceiptUpdateSchema {
  expense_line_items: PurchaseOrderLineItemAssociationSchema[];
  item_line_items: PurchaseOrderLineItemAssociationSchema[];
  posting_date: string;
  purchase_order_id: string;
  uploads: string[];
}

export interface ItemReceiptCreateSchema {
  expense_line_items: PurchaseOrderLineItemAssociationSchema[];
  item_line_items: PurchaseOrderLineItemAssociationSchema[];
  posting_date: string;
  purchase_order_id: string;
  uploads: string[];
}

export interface ItemReceiptHydratedDumpSchema {
  actions: ItemReceiptAction[];
  autogenerated_identifier: string;
  expense_line_items: ExpenseLineItemDumpSchema[];
  id: string;
  item_line_items: ItemLineItemDumpSchema[];
  posting_date: string;
  purchase_order: LinkIDSchema;
  purchase_order_id: string;
  status: ItemReceiptStatus;
  uploads: CustomerFileDumpSchema[];
}

/**
 * @nullable
 */
export type PaginatedItemReceiptSchemaNext = number | null;

export interface PaginatedItemReceiptSchema {
  elements: ItemReceiptDumpSchema[];
  /** @nullable */
  next?: PaginatedItemReceiptSchemaNext;
  total_count: number;
}

export type ItemReceiptAction = typeof ItemReceiptAction[keyof typeof ItemReceiptAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemReceiptAction = {
  receive: 'receive',
  edit: 'edit',
  serialize: 'serialize',
} as const;

export interface ItemReceiptDumpSchema {
  actions: ItemReceiptAction[];
  autogenerated_identifier: string;
  id: string;
  posting_date: string;
  purchase_order: LinkIDSchema;
  purchase_order_id: string;
  status: ItemReceiptStatus;
  uploads: CustomerFileDumpSchema[];
}

/**
 * @nullable
 */
export type ExpenseLineItemUpdateSchemaRemoteAccountId = string | null;

/**
 * @nullable
 */
export type ExpenseLineItemUpdateSchemaId = string | null;

export interface ExpenseLineItemUpdateSchema {
  grouping_key?: string;
  /** @nullable */
  id?: ExpenseLineItemUpdateSchemaId;
  is_capitalized: boolean;
  label: string;
  quantity: number;
  /** @nullable */
  remote_account_id?: ExpenseLineItemUpdateSchemaRemoteAccountId;
  unit_price: string;
}

export interface PurchaseOrderLineItemAssociationSchema {
  grouping_key: string;
  quantity: number;
}

export interface VendorBillUpdateSchema {
  additional_expense_line_items: ExpenseLineItemUpdateSchema[];
  due_date: string;
  expense_line_items: PurchaseOrderLineItemAssociationSchema[];
  item_line_items: PurchaseOrderLineItemAssociationSchema[];
  posting_date: string;
  purchase_order_id: string;
  uploads: string[];
}

export interface VendorBillCreateSchema {
  additional_expense_line_items: ExpenseLineItemCreateSchema[];
  due_date: string;
  expense_line_items: PurchaseOrderLineItemAssociationSchema[];
  item_line_items: PurchaseOrderLineItemAssociationSchema[];
  posting_date: string;
  purchase_order_id: string;
  uploads: string[];
}

/**
 * @nullable
 */
export type VendorBillHydratedDumpSchemaRemoteSync = AccountingActionDumpSchema | null;

/**
 * @nullable
 */
export type VendorBillHydratedDumpSchemaErpLink = string | null;

/**
 * @nullable
 */
export type PaginatedVendorBillSchemaNext = number | null;

export interface PaginatedVendorBillSchema {
  elements: VendorBillDumpSchema[];
  /** @nullable */
  next?: PaginatedVendorBillSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type VendorBillDumpSchemaRemoteSync = AccountingActionDumpSchema | null;

export type VendorBillAction = typeof VendorBillAction[keyof typeof VendorBillAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorBillAction = {
  edit: 'edit',
  sync: 'sync',
  view_in_erp: 'view_in_erp',
  erp_link_loading: 'erp_link_loading',
} as const;

export interface VendorBillHydratedDumpSchema {
  actions: VendorBillAction[];
  additional_expense_line_items: ExpenseLineItemDumpSchema[];
  autogenerated_identifier: string;
  due_date: string;
  /** @nullable */
  erp_link?: VendorBillHydratedDumpSchemaErpLink;
  expense_line_items: ExpenseLineItemDumpSchema[];
  id: string;
  item_line_items: ItemLineItemDumpSchema[];
  posting_date: string;
  purchase_order: LinkIDSchema;
  purchase_order_id: string;
  /** @nullable */
  remote_sync?: VendorBillHydratedDumpSchemaRemoteSync;
  uploads: CustomerFileDumpSchema[];
}

export interface VendorBillDumpSchema {
  actions: VendorBillAction[];
  autogenerated_identifier: string;
  due_date: string;
  id: string;
  posting_date: string;
  purchase_order: LinkIDSchema;
  purchase_order_id: string;
  /** @nullable */
  remote_sync?: VendorBillDumpSchemaRemoteSync;
  uploads: CustomerFileDumpSchema[];
}

export type AssetIDSchemaSelectionType = typeof AssetIDSchemaSelectionType[keyof typeof AssetIDSchemaSelectionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetIDSchemaSelectionType = {
  asset_id: 'asset_id',
} as const;

export interface AssetIDSchema {
  asset_id: string;
  selection_type: AssetIDSchemaSelectionType;
}

export type ReceivingSchemaSelection = AssetSelectionSchema | AssetIDSchema;

export interface ReceivingSchema {
  selection: ReceivingSchemaSelection;
  serial_number_values: SerialNumberValueCreateSchema[];
  usage_metric_values: UsageMetricValueCreateSchema[];
}

export interface AssetListSchema {
  elements: AssetDumpSchema[];
}

export type AssetSelectionSchemaSelectionType = typeof AssetSelectionSchemaSelectionType[keyof typeof AssetSelectionSchemaSelectionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetSelectionSchemaSelectionType = {
  asset_selection: 'asset_selection',
} as const;

/**
 * @nullable
 */
export type AssetSelectionSchemaLocationId = string | null;

/**
 * @nullable
 */
export type AssetSelectionSchemaItemReceiptId = string | null;

export interface AssetSelectionSchema {
  /** @nullable */
  item_receipt_id?: AssetSelectionSchemaItemReceiptId;
  /** @nullable */
  location_id?: AssetSelectionSchemaLocationId;
  product_id: string;
  quantity: number;
  selection_type: AssetSelectionSchemaSelectionType;
}

/**
 * @nullable
 */
export type PurchaseOrderUpdateSchemaInternalNotes = string | null;

export interface PurchaseOrderUpdateSchema {
  expense_line_items: ExpenseLineItemCreateSchema[];
  /** @nullable */
  internal_notes?: PurchaseOrderUpdateSchemaInternalNotes;
  item_line_items: ItemLineItemCreateSchema[];
  location_id: string;
  posting_date: string;
  tags: string[];
  uploads: string[];
  vendor_contact_id: string;
  vendor_id: string;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderCreateSchemaStatus = {  draft__touched: 'draft__touched',
  pending_receipt: 'pending_receipt',
} as const
/**
 * @nullable
 */
export type PurchaseOrderCreateSchemaInternalNotes = string | null;

/**
 * @nullable
 */
export type ItemLineItemCreateSchemaModelYear = number | null;

export interface ItemLineItemCreateSchema {
  grouping_key?: string;
  /** @nullable */
  model_year?: ItemLineItemCreateSchemaModelYear;
  product_id: string;
  quantity: number;
  unit_price: string;
}

/**
 * @nullable
 */
export type ExpenseLineItemCreateSchemaRemoteAccountId = string | null;

export interface ExpenseLineItemCreateSchema {
  grouping_key?: string;
  is_capitalized: boolean;
  label: string;
  quantity: number;
  /** @nullable */
  remote_account_id?: ExpenseLineItemCreateSchemaRemoteAccountId;
  unit_price: string;
}

export interface PurchaseOrderCreateSchema {
  expense_line_items: ExpenseLineItemCreateSchema[];
  /** @nullable */
  internal_notes?: PurchaseOrderCreateSchemaInternalNotes;
  item_line_items: ItemLineItemCreateSchema[];
  location_id: string;
  posting_date: string;
  status?: typeof PurchaseOrderCreateSchemaStatus[keyof typeof PurchaseOrderCreateSchemaStatus] ;
  tags: string[];
  uploads: string[];
  vendor_contact_id: string;
  vendor_id: string;
}

/**
 * @nullable
 */
export type PurchaseOrderHydratedDumpSchemaInternalNotes = string | null;

export interface PurchaseOrderHydratedDumpSchema {
  actions: PurchaseOrderAction[];
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  expense_line_items: ExpenseLineItemDumpSchema[];
  id: string;
  /** @nullable */
  internal_notes?: PurchaseOrderHydratedDumpSchemaInternalNotes;
  item_line_items: ItemLineItemDumpSchema[];
  location: LinkIDNameSchema;
  location_id: string;
  posting_date: string;
  status: PurchaseOrderStatus;
  tags: string[];
  uploads: CustomerFileDumpSchema[];
  vendor: LinkIDNameSchema;
  vendor_contact: VendorContactDumpSchema;
  vendor_contact_id: string;
  vendor_id: string;
}

export type PurchaseOrderLineItemStatus = typeof PurchaseOrderLineItemStatus[keyof typeof PurchaseOrderLineItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderLineItemStatus = {
  pending_receipt: 'pending_receipt',
  pending_bill: 'pending_bill',
  fully_billed: 'fully_billed',
} as const;

/**
 * @nullable
 */
export type ItemLineItemDumpSchemaRemoteAccount = RemoteAccountDumpSchema | null;

/**
 * @nullable
 */
export type ItemLineItemDumpSchemaModelYear = number | null;

export interface ItemLineItemDumpSchema {
  count_billed: number;
  count_inactive: number;
  count_received: number;
  count_total: number;
  grouping_key: string;
  id: string;
  /** @nullable */
  model_year?: ItemLineItemDumpSchemaModelYear;
  product: ProductDumpSchemaWithKlassAndSKU;
  product_id: string;
  quantity: number;
  /** @nullable */
  remote_account?: ItemLineItemDumpSchemaRemoteAccount;
  status: PurchaseOrderLineItemStatus;
  total_price: string;
  unit_price: string;
}

/**
 * @nullable
 */
export type ExpenseLineItemDumpSchemaRemoteAccount = RemoteAccountDumpSchema | null;

export interface ExpenseLineItemDumpSchema {
  count_billed: number;
  count_received: number;
  count_total: number;
  grouping_key: string;
  id: string;
  is_capitalized: boolean;
  label: string;
  quantity: number;
  /** @nullable */
  remote_account?: ExpenseLineItemDumpSchemaRemoteAccount;
  status: PurchaseOrderLineItemStatus;
  total_price: string;
  unit_price: string;
}

/**
 * @nullable
 */
export type PaginatedPurchaseOrderSchemaNext = number | null;

export interface PaginatedPurchaseOrderSchema {
  elements: PurchaseOrderDumpSchema[];
  /** @nullable */
  next?: PaginatedPurchaseOrderSchemaNext;
  total_count: number;
}

export type PurchaseOrderStatus = typeof PurchaseOrderStatus[keyof typeof PurchaseOrderStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderStatus = {
  draft: 'draft',
  draft__touched: 'draft__touched',
  cancelled: 'cancelled',
  pending_receipt: 'pending_receipt',
  partially_received: 'partially_received',
  partially_received_partially_billed: 'partially_received_partially_billed',
  pending_bill: 'pending_bill',
  fully_billed: 'fully_billed',
} as const;

/**
 * @nullable
 */
export type PurchaseOrderDumpSchemaInternalNotes = string | null;

export type PurchaseOrderAction = typeof PurchaseOrderAction[keyof typeof PurchaseOrderAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderAction = {
  receive: 'receive',
  bill: 'bill',
  convert_to_po: 'convert_to_po',
  cancel: 'cancel',
  edit: 'edit',
  download_pdf: 'download_pdf',
  send_email: 'send_email',
} as const;

export interface PurchaseOrderDumpSchema {
  actions: PurchaseOrderAction[];
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  id: string;
  /** @nullable */
  internal_notes?: PurchaseOrderDumpSchemaInternalNotes;
  location: LinkIDNameSchema;
  location_id: string;
  posting_date: string;
  status: PurchaseOrderStatus;
  tags: string[];
  uploads: CustomerFileDumpSchema[];
  vendor: LinkIDNameSchema;
  vendor_contact: VendorContactDumpSchema;
  vendor_contact_id: string;
  vendor_id: string;
}

/**
 * @nullable
 */
export type VendorCreateSchemaWebAddress = string | null;

/**
 * @nullable
 */
export type VendorCreateSchemaRemoteObjectId = string | null;

/**
 * @nullable
 */
export type VendorCreateSchemaProducts = string | null;

/**
 * @nullable
 */
export type VendorCreateSchemaPhone = string | null;

/**
 * @nullable
 */
export type VendorCreateSchemaNotes = string | null;

/**
 * @nullable
 */
export type VendorCreateSchemaEmail = string | null;

/**
 * @nullable
 */
export type VendorCreateSchemaDisplayName = string | null;

/**
 * @nullable
 */
export type VendorCreateSchemaAddress = AddressCreateSchema | null;

/**
 * @nullable
 */
export type VendorCreateSchemaAccountNumber = string | null;

export interface VendorCreateSchema {
  /** @nullable */
  account_number?: VendorCreateSchemaAccountNumber;
  /** @nullable */
  address?: VendorCreateSchemaAddress;
  contacts: VendorContactCreateSchema[];
  /** @nullable */
  display_name?: VendorCreateSchemaDisplayName;
  /** @nullable */
  email?: VendorCreateSchemaEmail;
  name: string;
  /** @nullable */
  notes?: VendorCreateSchemaNotes;
  payment_method: PaymentMethodType;
  payment_terms: PaymentTerms;
  /** @nullable */
  phone?: VendorCreateSchemaPhone;
  /** @nullable */
  products?: VendorCreateSchemaProducts;
  /** @nullable */
  remote_object_id?: VendorCreateSchemaRemoteObjectId;
  type: VendorTypeEnum;
  /** @nullable */
  web_address?: VendorCreateSchemaWebAddress;
}

/**
 * @nullable
 */
export type VendorContactCreateSchemaPhone = string | null;

/**
 * @nullable
 */
export type VendorContactCreateSchemaEmail = string | null;

export interface VendorContactCreateSchema {
  /** @nullable */
  email?: VendorContactCreateSchemaEmail;
  first_name: string;
  last_name: string;
  /** @nullable */
  phone?: VendorContactCreateSchemaPhone;
}

/**
 * @nullable
 */
export type PaginatedVendorSchemaNext = number | null;

export interface PaginatedVendorSchema {
  elements: VendorDumpSchema[];
  /** @nullable */
  next?: PaginatedVendorSchemaNext;
  total_count: number;
}

export interface PaymentSurchargeAmountDump {
  amount: string;
  fee_amount: string;
  total_amount: string;
}

export interface PaymentSurchargeAmountCheck {
  amount: string;
  payment_type: PaymentTypeEnum;
}

/**
 * @nullable
 */
export type PaymentSurchargeBaseSchemaCapDollarAmount = string | null;

export interface PaymentSurchargeBaseSchema {
  /** @nullable */
  cap_dollar_amount?: PaymentSurchargeBaseSchemaCapDollarAmount;
  payment_type: PaymentTypeEnum;
  percentage_amount: string;
}

export interface PaymentSurchargeListSchema {
  surcharges?: PaymentSurchargeBaseSchema[];
}

/**
 * @nullable
 */
export type PaymentUpdateSchemaPaidAt = string | null;

/**
 * @nullable
 */
export type PaymentUpdateSchemaNotes = string | null;

/**
 * @nullable
 */
export type PaymentUpdateSchemaCheckNumber = string | null;

export interface PaymentUpdateSchema {
  amount: string;
  /** @nullable */
  check_number?: PaymentUpdateSchemaCheckNumber;
  /** @nullable */
  notes?: PaymentUpdateSchemaNotes;
  /** @nullable */
  paid_at?: PaymentUpdateSchemaPaidAt;
  payment_type: PaymentTypeEnum;
}

/**
 * @nullable
 */
export type PaginatedPaymentSchemaNext = number | null;

export interface PaginatedPaymentSchema {
  elements: PaymentDumpSchema[];
  /** @nullable */
  next?: PaginatedPaymentSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type PaymentCreateBodySchemaPayment = PaymentCreateSchema | null;

/**
 * @nullable
 */
export type PaymentCreateBodySchemaIncludesAllReconciliationsForPaymentId = string | null;

/**
 * @nullable
 */
export type PaymentCreateBodySchemaIncludesAllReconciliationsForInvoiceId = string | null;

export interface PaymentReconciliationBodySchema {
  amount: string;
  payment_id: string;
}

export interface PaymentCreateBodySchema {
  customer_balance_used: string;
  customer_id: string;
  /** @nullable */
  includes_all_reconciliations_for_invoice_id?: PaymentCreateBodySchemaIncludesAllReconciliationsForInvoiceId;
  /** @nullable */
  includes_all_reconciliations_for_payment_id?: PaymentCreateBodySchemaIncludesAllReconciliationsForPaymentId;
  invoices: InvoiceReconciliationBodySchema[];
  /** @nullable */
  payment?: PaymentCreateBodySchemaPayment;
  payments: PaymentReconciliationBodySchema[];
}

/**
 * @nullable
 */
export type PaymentCreateSchemaStripePaymentMethodId = string | null;

/**
 * @nullable
 */
export type PaymentCreateSchemaPaidAt = string | null;

/**
 * @nullable
 */
export type PaymentCreateSchemaNotes = string | null;

/**
 * @nullable
 */
export type PaymentCreateSchemaCheckNumber = string | null;

export interface PaymentCreateSchema {
  amount: string;
  /** @nullable */
  check_number?: PaymentCreateSchemaCheckNumber;
  customer_id: string;
  /** @nullable */
  notes?: PaymentCreateSchemaNotes;
  /** @nullable */
  paid_at?: PaymentCreateSchemaPaidAt;
  payment_provider: PaymentProvider;
  payment_type: PaymentTypeEnum;
  /** @nullable */
  stripe_payment_method_id?: PaymentCreateSchemaStripePaymentMethodId;
}

export interface InvoiceReconciliationBodySchema {
  amount: string;
  invoice_id: string;
}

/**
 * @nullable
 */
export type PaymentCreateResponseSchemaPayment = PaymentHydratedDumpSchema | null;

export interface PaymentCreateResponseSchema {
  /** @nullable */
  payment?: PaymentCreateResponseSchemaPayment;
  /** @nullable */
  stripe_secret?: PaymentCreateResponseSchemaStripeSecret;
}

export interface StripeClientSecretSchema {
  account_id: string;
  client_secret: string;
}

/**
 * @nullable
 */
export type PaymentCreateResponseSchemaStripeSecret = StripeClientSecretSchema | null;

export interface PaymentInvoiceDumpSchema {
  amount: string;
  invoice: InvoiceDumpSchema;
}

/**
 * @nullable
 */
export type PaymentHydratedDumpSchemaPaymentMethod = PaymentMethodDumpSchema | null;

/**
 * @nullable
 */
export type PaymentHydratedDumpSchemaPaidAt = string | null;

/**
 * @nullable
 */
export type PaymentHydratedDumpSchemaNotes = string | null;

/**
 * @nullable
 */
export type PaymentHydratedDumpSchemaDepositStatus = DepositStatus | null;

/**
 * @nullable
 */
export type PaymentHydratedDumpSchemaCheckNumber = string | null;

/**
 * @nullable
 */
export type PaymentSimpleCreateSchemaStripePaymentMethodId = string | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSimpleCreateSchemaPaymentType = {  card: 'card',
  bank: 'bank',
} as const
export type PaymentSimpleCreateSchemaPaymentProvider = typeof PaymentSimpleCreateSchemaPaymentProvider[keyof typeof PaymentSimpleCreateSchemaPaymentProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSimpleCreateSchemaPaymentProvider = {
  stripe: 'stripe',
} as const;

/**
 * @nullable
 */
export type PaymentSimpleCreateSchemaPaidAt = string | null;

export interface PaymentSimpleCreateSchema {
  customer_id: string;
  /** @nullable */
  paid_at?: PaymentSimpleCreateSchemaPaidAt;
  payment_provider: PaymentSimpleCreateSchemaPaymentProvider;
  payment_type: typeof PaymentSimpleCreateSchemaPaymentType[keyof typeof PaymentSimpleCreateSchemaPaymentType] ;
  /** @nullable */
  stripe_payment_method_id?: PaymentSimpleCreateSchemaStripePaymentMethodId;
}

/**
 * @nullable
 */
export type PaymentReconcileResponseSchemaNewPaymentMethodId = string | null;

export interface PaymentReconcileResponseSchema {
  customer_balance_available: string;
  customer_balance_used: string;
  invoices: InvoiceApplicationSchema[];
  new_payment_amount: string;
  /** @nullable */
  new_payment_method_id?: PaymentReconcileResponseSchemaNewPaymentMethodId;
  payments: PaymentApplicationSchema[];
}

export type PaymentTypeEnum = typeof PaymentTypeEnum[keyof typeof PaymentTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentTypeEnum = {
  check: 'check',
  cash: 'cash',
  bank: 'bank',
  card: 'card',
} as const;

export type PaymentStatus = typeof PaymentStatus[keyof typeof PaymentStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentStatus = {
  pending: 'pending',
  paid: 'paid',
  failed: 'failed',
  refunded: 'refunded',
  partially_refunded: 'partially_refunded',
  voided: 'voided',
} as const;

export type PaymentProvider = typeof PaymentProvider[keyof typeof PaymentProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentProvider = {
  stripe: 'stripe',
  manual: 'manual',
} as const;

export interface PaymentHydratedDumpSchema {
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  /** @nullable */
  check_number?: PaymentHydratedDumpSchemaCheckNumber;
  customer: LinkIDNameSchema;
  customer_id: string;
  /** @nullable */
  deposit_status?: PaymentHydratedDumpSchemaDepositStatus;
  id: string;
  invoices: PaymentInvoiceDumpSchema[];
  /** @nullable */
  notes?: PaymentHydratedDumpSchemaNotes;
  /** @nullable */
  paid_at?: PaymentHydratedDumpSchemaPaidAt;
  /** @nullable */
  payment_method?: PaymentHydratedDumpSchemaPaymentMethod;
  payment_provider: PaymentProvider;
  payment_type: PaymentTypeEnum;
  status: PaymentStatus;
  unreconciled_amount: string;
  unreconciled_deposit_amount: string;
}

/**
 * @nullable
 */
export type PaymentDumpSchemaPaymentMethod = PaymentMethodDumpSchema | null;

/**
 * @nullable
 */
export type PaymentDumpSchemaPaidAt = string | null;

/**
 * @nullable
 */
export type PaymentDumpSchemaNotes = string | null;

/**
 * @nullable
 */
export type PaymentDumpSchemaDepositStatus = DepositStatus | null;

/**
 * @nullable
 */
export type PaymentDumpSchemaCheckNumber = string | null;

export interface PaymentDumpSchema {
  activity_log_id: string;
  amount: string;
  autogenerated_identifier: string;
  /** @nullable */
  check_number?: PaymentDumpSchemaCheckNumber;
  customer: LinkIDNameSchema;
  customer_id: string;
  /** @nullable */
  deposit_status?: PaymentDumpSchemaDepositStatus;
  id: string;
  /** @nullable */
  notes?: PaymentDumpSchemaNotes;
  /** @nullable */
  paid_at?: PaymentDumpSchemaPaidAt;
  /** @nullable */
  payment_method?: PaymentDumpSchemaPaymentMethod;
  payment_provider: PaymentProvider;
  payment_type: PaymentTypeEnum;
  status: PaymentStatus;
  unreconciled_amount: string;
  unreconciled_deposit_amount: string;
}

export interface PaymentApplicationSchema {
  amount: string;
  payment: PaymentDumpSchema;
}

export type InvoiceType = typeof InvoiceType[keyof typeof InvoiceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceType = {
  cycle: 'cycle',
  payment_surcharge: 'payment_surcharge',
  prepayment: 'prepayment',
  credit_note: 'credit_note',
  debit_note: 'debit_note',
} as const;

export type InvoiceStatus = typeof InvoiceStatus[keyof typeof InvoiceStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceStatus = {
  scheduled: 'scheduled',
  pending: 'pending',
  paid: 'paid',
  voided: 'voided',
  delinquent: 'delinquent',
  uncollectible: 'uncollectible',
} as const;

/**
 * @nullable
 */
export type InvoiceDumpSchemaParentInvoiceId = string | null;

/**
 * @nullable
 */
export type InvoiceDumpSchemaParentInvoice = LinkIDSchema | null;

/**
 * @nullable
 */
export type InvoiceDumpSchemaCycleStart = string | null;

/**
 * @nullable
 */
export type InvoiceDumpSchemaCycleEnd = string | null;

/**
 * @nullable
 */
export type InvoiceDumpSchemaCustomerPoNumber = string | null;

/**
 * @nullable
 */
export type InvoiceDumpSchemaBillingCycleEnd = string | null;

export interface InvoiceDumpSchema {
  activity_log_id: string;
  amount: string;
  amount_due: string;
  amount_paid: string;
  /** @nullable */
  billing_cycle_end?: InvoiceDumpSchemaBillingCycleEnd;
  charge_at: string;
  created_at: string;
  customer: LinkIDNameSchema;
  customer_id: string;
  /** @nullable */
  customer_po_number?: InvoiceDumpSchemaCustomerPoNumber;
  /** @nullable */
  cycle_end?: InvoiceDumpSchemaCycleEnd;
  /** @nullable */
  cycle_start?: InvoiceDumpSchemaCycleStart;
  due_at: string;
  id: string;
  identifier: string;
  order: LinkIDSchema;
  order_id: string;
  /** @nullable */
  parent_invoice?: InvoiceDumpSchemaParentInvoice;
  /** @nullable */
  parent_invoice_id?: InvoiceDumpSchemaParentInvoiceId;
  status: InvoiceStatus;
  type: InvoiceType;
}

export interface InvoiceApplicationSchema {
  amount: string;
  invoice: InvoiceDumpSchema;
}

export type DepositStatus = typeof DepositStatus[keyof typeof DepositStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepositStatus = {
  pending: 'pending',
  paid: 'paid',
  failed: 'failed',
} as const;

/**
 * @nullable
 */
export type PaymentReconcileInitialStateBodySchemaPaymentId = string | null;

/**
 * @nullable
 */
export type PaymentReconcileInitialStateBodySchemaOrderId = string | null;

/**
 * @nullable
 */
export type PaymentReconcileInitialStateBodySchemaInvoiceId = string | null;

/**
 * @nullable
 */
export type PaymentReconcileInitialStateBodySchemaCustomerId = string | null;

export interface PaymentReconcileInitialStateBodySchema {
  /** @nullable */
  customer_id?: PaymentReconcileInitialStateBodySchemaCustomerId;
  /** @nullable */
  invoice_id?: PaymentReconcileInitialStateBodySchemaInvoiceId;
  /** @nullable */
  order_id?: PaymentReconcileInitialStateBodySchemaOrderId;
  /** @nullable */
  payment_id?: PaymentReconcileInitialStateBodySchemaPaymentId;
}

export interface KitLineItemCreateSchema {
  klass_id: string;
  quantity: number;
}

export interface KitCreateSchema {
  line_items: KitLineItemCreateSchema[];
  name: string;
}

/**
 * @nullable
 */
export type PaginatedKitSchemaNext = number | null;

export interface KitLineItemDumpSchema {
  id: string;
  klass: KlassDumpSchema;
  quantity: number;
}

export interface KitDumpSchema {
  id: string;
  line_items: KitLineItemDumpSchema[];
  name: string;
}

export interface PaginatedKitSchema {
  elements: KitDumpSchema[];
  /** @nullable */
  next?: PaginatedKitSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type ProductDumpSchemaWithKlassModel = string | null;

/**
 * @nullable
 */
export type ProductDumpSchemaWithKlassMake = string | null;

export interface ProductDumpSchemaWithKlass {
  asset_count: number;
  backordered_configurations: BackorderedConfigurationDumpSchema[];
  can_delete: boolean;
  id: string;
  klass: KlassDumpSchema;
  /** @nullable */
  make?: ProductDumpSchemaWithKlassMake;
  /** @nullable */
  model?: ProductDumpSchemaWithKlassModel;
  name: string;
  serial_number_configurations: SerialNumberConfigurationDumpSchema[];
  usage_gauge_configurations: UsageMetricConfigurationDumpSchema[];
  usage_metric_configurations: UsageMetricConfigurationDumpSchema[];
  vendors: ProductVendorDumpSchema[];
}

/**
 * @nullable
 */
export type PaginatedProductDumpSchemaNext = number | null;

/**
 * @nullable
 */
export type ProductDumpSchemaWithKlassAndSKUVendorSku = string | null;

/**
 * @nullable
 */
export type ProductDumpSchemaWithKlassAndSKUModel = string | null;

/**
 * @nullable
 */
export type ProductDumpSchemaWithKlassAndSKUMake = string | null;

export interface ProductDumpSchemaWithKlassAndSKU {
  asset_count: number;
  backordered_configurations: BackorderedConfigurationDumpSchema[];
  can_delete: boolean;
  id: string;
  klass: KlassDumpSchema;
  /** @nullable */
  make?: ProductDumpSchemaWithKlassAndSKUMake;
  /** @nullable */
  model?: ProductDumpSchemaWithKlassAndSKUModel;
  name: string;
  serial_number_configurations: SerialNumberConfigurationDumpSchema[];
  usage_gauge_configurations: UsageMetricConfigurationDumpSchema[];
  usage_metric_configurations: UsageMetricConfigurationDumpSchema[];
  /** @nullable */
  vendor_sku?: ProductDumpSchemaWithKlassAndSKUVendorSku;
  vendors: ProductVendorDumpSchema[];
}

export interface PaginatedProductDumpSchema {
  elements: ProductDumpSchemaWithKlassAndSKU[];
  /** @nullable */
  next?: PaginatedProductDumpSchemaNext;
  total_count: number;
}

export interface BaseRateTemplateLineItemSchema {
  frequency: KlassPricingFrequency;
  multiplier: string;
}

export interface RateTemplateCreateSchema {
  is_business_default: boolean;
  line_items: BaseRateTemplateLineItemSchema[];
  name: string;
}

/**
 * @nullable
 */
export type PaginatedRateTemplateSchemaNext = number | null;

export interface PaginatedRateTemplateSchema {
  elements: RateTemplateDumpSchema[];
  /** @nullable */
  next?: PaginatedRateTemplateSchemaNext;
  total_count: number;
}

export interface RateTemplateLineItemDumpSchema {
  frequency: KlassPricingFrequency;
  id: string;
  multiplier: string;
}

export interface RateTemplateDumpSchema {
  id: string;
  is_business_default: boolean;
  line_items: RateTemplateLineItemDumpSchema[];
  name: string;
}

/**
 * @nullable
 */
export type BaseRateProfileSchemaMeterHoursPerWeek = number | null;

/**
 * @nullable
 */
export type BaseRateProfileSchemaMeterHoursPerMonth = number | null;

/**
 * @nullable
 */
export type BaseRateProfileSchemaMeterHoursPerDay = number | null;

/**
 * @nullable
 */
export type BaseRateProfileSchemaIsProrateExtensionsEnabled = boolean | null;

/**
 * @nullable
 */
export type BaseRateProfileSchemaIsProrateEarlyCallOffsEnabled = boolean | null;

/**
 * @nullable
 */
export type BaseRateProfileSchemaClockOvertimePenalty = string | null;

/**
 * @nullable
 */
export type BaseRateProfileSchemaClockHoursPerWeek = number | null;

/**
 * @nullable
 */
export type BaseRateProfileSchemaClockHoursPerMonth = number | null;

/**
 * @nullable
 */
export type BaseRateProfileSchemaClockHoursPerDay = number | null;

export interface ShiftSchema {
  max_hours_per_day: number;
  max_hours_per_month: number;
  max_hours_per_week: number;
  multiplier: string;
  name: string;
}

export interface BaseRateProfileSchema {
  always_subtract_grace_period: boolean;
  clock_grace_period: number;
  /** @nullable */
  clock_hours_per_day?: BaseRateProfileSchemaClockHoursPerDay;
  /** @nullable */
  clock_hours_per_month?: BaseRateProfileSchemaClockHoursPerMonth;
  /** @nullable */
  clock_hours_per_week?: BaseRateProfileSchemaClockHoursPerWeek;
  clock_overtime_methodology: ClockOvertimeMethodology;
  /** @nullable */
  clock_overtime_penalty?: BaseRateProfileSchemaClockOvertimePenalty;
  is_business_default: boolean;
  /** @nullable */
  is_prorate_early_call_offs_enabled?: BaseRateProfileSchemaIsProrateEarlyCallOffsEnabled;
  /** @nullable */
  is_prorate_extensions_enabled?: BaseRateProfileSchemaIsProrateExtensionsEnabled;
  /** @nullable */
  meter_hours_per_day?: BaseRateProfileSchemaMeterHoursPerDay;
  /** @nullable */
  meter_hours_per_month?: BaseRateProfileSchemaMeterHoursPerMonth;
  /** @nullable */
  meter_hours_per_week?: BaseRateProfileSchemaMeterHoursPerWeek;
  meter_overtime_methodology: MeterOvertimeMethodology;
  name: string;
  shifts: ShiftSchema[];
}

/**
 * @nullable
 */
export type PaginatedRateProfileSchemaNext = number | null;

export interface PaginatedRateProfileSchema {
  elements: RateProfileDumpSchema[];
  /** @nullable */
  next?: PaginatedRateProfileSchemaNext;
  total_count: number;
}

export interface UsageMetricTypeUpdateSchema {
  metric: UsageMetricUnit;
  name: string;
  type: UsageMetricTypeEnum;
}

export interface UsageMetricTypeCreateSchema {
  metric: UsageMetricUnit;
  name: string;
  type: UsageMetricTypeEnum;
}

/**
 * @nullable
 */
export type PaginatedUsageMetricTypeResponseSchemaNext = number | null;

export interface PaginatedUsageMetricTypeResponseSchema {
  elements: UsageMetricTypeDumpSchema[];
  /** @nullable */
  next?: PaginatedUsageMetricTypeResponseSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type PaginatedKlassOrderDumpSchemaNext = number | null;

export interface PaginatedKlassOrderDumpSchema {
  elements: KlassOrderResponseSchema[];
  /** @nullable */
  next?: PaginatedKlassOrderDumpSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type KlassOrderListBodySchemaLocationGroupId = string | null;

/**
 * @nullable
 */
export type KlassOrderListBodySchemaCustomerId = string | null;

/**
 * @nullable
 */
export type KlassOrderListBodySchemaBillingStartsAt = string | null;

/**
 * @nullable
 */
export type KlassOrderListBodySchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type KlassOrderListBodySchemaBillingEndsAt = string | null;

export interface KlassOrderListBodySchema {
  /** @nullable */
  billing_ends_at?: KlassOrderListBodySchemaBillingEndsAt;
  /** @nullable */
  billing_profile_id?: KlassOrderListBodySchemaBillingProfileId;
  /** @nullable */
  billing_starts_at?: KlassOrderListBodySchemaBillingStartsAt;
  /** @nullable */
  customer_id?: KlassOrderListBodySchemaCustomerId;
  /** @nullable */
  location_group_id?: KlassOrderListBodySchemaLocationGroupId;
  location_id: string;
  quantity: number;
}

export interface UsedPricingDumpSchema {
  base_price_per_unit: string;
  extended_price_per_unit: string;
  frequency: KlassPricingFrequency;
  number_used: number;
  prorated: boolean;
}

export interface KlassPricingDumpSchema {
  amount: string;
  /** @nullable */
  customer_id?: KlassPricingDumpSchemaCustomerId;
  frequency: KlassPricingFrequency;
  id: string;
  /** @nullable */
  klass_id?: KlassPricingDumpSchemaKlassId;
  /** @nullable */
  line_item_id?: KlassPricingDumpSchemaLineItemId;
  /** @nullable */
  location_id?: KlassPricingDumpSchemaLocationId;
  payer_type: OrderPayerType;
  type: KlassPricingType;
}

export interface KlassOrderResponseSchema {
  base_price_per_unit: string;
  count_assets: number;
  count_available_assets__global: number;
  count_available_assets__location: number;
  count_available_assets__region: number;
  count_backordered_assets__confirmed: number;
  count_backordered_assets__draft: number;
  count_inactive_assets__global: number;
  count_inactive_assets__location: number;
  count_inactive_assets__region: number;
  count_on_rent_assets__global: number;
  count_on_rent_assets__location: number;
  count_on_rent_assets__region: number;
  count_unavailable_assets__global: number;
  count_unavailable_assets__location: number;
  count_unavailable_assets__region: number;
  extended_base_price: string;
  extended_price: string;
  id: string;
  klass: KlassDumpSchema;
  pricings: KlassPricingDumpSchema[];
  quantity: number;
  total_price_per_unit: string;
  used_pricings: UsedPricingDumpSchema[];
}

/**
 * @nullable
 */
export type KlassPricingDumpSchemaLocationId = string | null;

/**
 * @nullable
 */
export type KlassPricingDumpSchemaLineItemId = string | null;

/**
 * @nullable
 */
export type KlassPricingDumpSchemaKlassId = string | null;

/**
 * @nullable
 */
export type KlassPricingDumpSchemaCustomerId = string | null;

/**
 * @nullable
 */
export type KlassOrderDetailBodySchemaShiftId = string | null;

/**
 * @nullable
 */
export type KlassOrderDetailBodySchemaLocationGroupId = string | null;

/**
 * @nullable
 */
export type KlassOrderDetailBodySchemaLineItemId = string | null;

/**
 * @nullable
 */
export type KlassOrderDetailBodySchemaCustomerId = string | null;

/**
 * @nullable
 */
export type KlassOrderDetailBodySchemaBillingStartsAt = string | null;

/**
 * @nullable
 */
export type KlassOrderDetailBodySchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type KlassOrderDetailBodySchemaBillingEndsAt = string | null;

export interface KlassOrderDetailBodySchema {
  /** @nullable */
  billing_ends_at?: KlassOrderDetailBodySchemaBillingEndsAt;
  /** @nullable */
  billing_profile_id?: KlassOrderDetailBodySchemaBillingProfileId;
  /** @nullable */
  billing_starts_at?: KlassOrderDetailBodySchemaBillingStartsAt;
  /** @nullable */
  customer_id?: KlassOrderDetailBodySchemaCustomerId;
  /** @nullable */
  line_item_id?: KlassOrderDetailBodySchemaLineItemId;
  /** @nullable */
  location_group_id?: KlassOrderDetailBodySchemaLocationGroupId;
  location_id: string;
  modifiers?: ModifierSchema[];
  pricings?: KlassPricingCreateSchema[];
  quantity: number;
  /** @nullable */
  shift_id?: KlassOrderDetailBodySchemaShiftId;
}

/**
 * @nullable
 */
export type ModifierSchemaRate = string | null;

/**
 * @nullable
 */
export type ModifierSchemaExpenseRuleId = string | null;

export interface ModifierSchema {
  /** @nullable */
  expense_rule_id?: ModifierSchemaExpenseRuleId;
  /** @nullable */
  rate?: ModifierSchemaRate;
}

/**
 * @nullable
 */
export type KlassUpdateSchemaUsefulLife = number | null;

/**
 * @nullable
 */
export type KlassUpdateSchemaRateProfileId = string | null;

/**
 * @nullable
 */
export type KlassUpdateSchemaProductTaxCodeGroupId = string | null;

/**
 * @nullable
 */
export type KlassUpdateSchemaPickupCustomFormId = string | null;

/**
 * @nullable
 */
export type KlassUpdateSchemaMinimumRentalPeriodDelivery = number | null;

/**
 * @nullable
 */
export type KlassUpdateSchemaInventoryProfileId = string | null;

/**
 * @nullable
 */
export type KlassUpdateSchemaInternalDescription = string | null;

/**
 * @nullable
 */
export type KlassUpdateSchemaIdentifier = string | null;

/**
 * @nullable
 */
export type KlassUpdateSchemaExternalDescription = string | null;

/**
 * @nullable
 */
export type KlassUpdateSchemaDropoffCustomFormId = string | null;

/**
 * @nullable
 */
export type KlassUpdateSchemaDepreciationSettingId = string | null;

export interface KlassUpdateSchema {
  attachments: InventoryUploadCreateSchema[];
  category_id: string;
  /** @nullable */
  depreciation_setting_id?: KlassUpdateSchemaDepreciationSettingId;
  /** @nullable */
  dropoff_custom_form_id?: KlassUpdateSchemaDropoffCustomFormId;
  /** @nullable */
  external_description?: KlassUpdateSchemaExternalDescription;
  /** @nullable */
  identifier?: KlassUpdateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  /** @nullable */
  internal_description?: KlassUpdateSchemaInternalDescription;
  /** @nullable */
  inventory_profile_id?: KlassUpdateSchemaInventoryProfileId;
  is_print_meter_gauge: boolean;
  /** @nullable */
  minimum_rental_period_delivery?: KlassUpdateSchemaMinimumRentalPeriodDelivery;
  modifiers: ExpenseClassAssociationCreateSchema[];
  name: string;
  /** @nullable */
  pickup_custom_form_id?: KlassUpdateSchemaPickupCustomFormId;
  pricings: KlassPricingCreateSchema[];
  print_visibility_type: PrintVisibilityType;
  /** @nullable */
  product_tax_code_group_id?: KlassUpdateSchemaProductTaxCodeGroupId;
  products: ProductUpdateSchema[];
  /** @nullable */
  rate_profile_id?: KlassUpdateSchemaRateProfileId;
  specifications: KlassSpecificationSchema[];
  tags: string[];
  terms_and_conditions_addendum_ids: string[];
  tracking: TrackingType;
  /** @nullable */
  useful_life?: KlassUpdateSchemaUsefulLife;
  visibility: VisibilityType;
}

/**
 * @nullable
 */
export type UsageMetricConfigurationUpdateSchemaMaxCapacity = string | null;

/**
 * @nullable
 */
export type UsageMetricConfigurationUpdateSchemaId = string | null;

/**
 * @nullable
 */
export type UsageMetricConfigurationUpdateSchemaFuelType = FuelType | null;

export interface UsageMetricConfigurationUpdateSchema {
  /** @nullable */
  fuel_type?: UsageMetricConfigurationUpdateSchemaFuelType;
  /** @nullable */
  id?: UsageMetricConfigurationUpdateSchemaId;
  /** @nullable */
  max_capacity?: UsageMetricConfigurationUpdateSchemaMaxCapacity;
  primary: boolean;
  usage_metric_type_id: string;
}

/**
 * @nullable
 */
export type SerialNumberConfigurationUpdateSchemaId = string | null;

export interface SerialNumberConfigurationUpdateSchema {
  /** @nullable */
  id?: SerialNumberConfigurationUpdateSchemaId;
  is_primary: boolean;
  is_required: boolean;
  name: string;
}

/**
 * @nullable
 */
export type ProductUpdateSchemaModel = string | null;

/**
 * @nullable
 */
export type ProductUpdateSchemaMake = string | null;

/**
 * @nullable
 */
export type ProductUpdateSchemaId = string | null;

export interface ProductUpdateSchema {
  backordered_configurations: BackorderedConfigurationCreateSchema[];
  /** @nullable */
  id?: ProductUpdateSchemaId;
  /** @nullable */
  make?: ProductUpdateSchemaMake;
  /** @nullable */
  model?: ProductUpdateSchemaModel;
  name: string;
  serial_number_configurations: SerialNumberConfigurationUpdateSchema[];
  usage_metric_configurations: UsageMetricConfigurationUpdateSchema[];
  vendors: BaseProductVendorSchema[];
}

/**
 * @nullable
 */
export type KlassCreateSchemaUsefulLife = number | null;

/**
 * @nullable
 */
export type KlassCreateSchemaRateProfileId = string | null;

/**
 * @nullable
 */
export type KlassCreateSchemaProductTaxCodeGroupId = string | null;

/**
 * @nullable
 */
export type KlassCreateSchemaPickupCustomFormId = string | null;

/**
 * @nullable
 */
export type KlassCreateSchemaMinimumRentalPeriodDelivery = number | null;

/**
 * @nullable
 */
export type KlassCreateSchemaInventoryProfileId = string | null;

/**
 * @nullable
 */
export type KlassCreateSchemaInternalDescription = string | null;

/**
 * @nullable
 */
export type KlassCreateSchemaIdentifier = string | null;

/**
 * @nullable
 */
export type KlassCreateSchemaExternalDescription = string | null;

/**
 * @nullable
 */
export type KlassCreateSchemaDropoffCustomFormId = string | null;

/**
 * @nullable
 */
export type KlassCreateSchemaDepreciationSettingId = string | null;

export interface KlassCreateSchema {
  attachments: InventoryUploadCreateSchema[];
  category_id: string;
  /** @nullable */
  depreciation_setting_id?: KlassCreateSchemaDepreciationSettingId;
  /** @nullable */
  dropoff_custom_form_id?: KlassCreateSchemaDropoffCustomFormId;
  /** @nullable */
  external_description?: KlassCreateSchemaExternalDescription;
  /** @nullable */
  identifier?: KlassCreateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  /** @nullable */
  internal_description?: KlassCreateSchemaInternalDescription;
  /** @nullable */
  inventory_profile_id?: KlassCreateSchemaInventoryProfileId;
  is_print_meter_gauge: boolean;
  /** @nullable */
  minimum_rental_period_delivery?: KlassCreateSchemaMinimumRentalPeriodDelivery;
  modifiers: ExpenseClassAssociationCreateSchema[];
  name: string;
  /** @nullable */
  pickup_custom_form_id?: KlassCreateSchemaPickupCustomFormId;
  pricings: KlassPricingCreateSchema[];
  print_visibility_type: PrintVisibilityType;
  /** @nullable */
  product_tax_code_group_id?: KlassCreateSchemaProductTaxCodeGroupId;
  products: ProductCreateSchema[];
  /** @nullable */
  rate_profile_id?: KlassCreateSchemaRateProfileId;
  specifications: KlassSpecificationSchema[];
  tags: string[];
  terms_and_conditions_addendum_ids: string[];
  tracking: TrackingType;
  /** @nullable */
  useful_life?: KlassCreateSchemaUsefulLife;
  visibility: VisibilityType;
}

/**
 * @nullable
 */
export type UsageMetricConfigurationCreateSchemaMaxCapacity = string | null;

/**
 * @nullable
 */
export type UsageMetricConfigurationCreateSchemaFuelType = FuelType | null;

export interface UsageMetricConfigurationCreateSchema {
  /** @nullable */
  fuel_type?: UsageMetricConfigurationCreateSchemaFuelType;
  /** @nullable */
  max_capacity?: UsageMetricConfigurationCreateSchemaMaxCapacity;
  primary: boolean;
  usage_metric_type_id: string;
}

export interface SerialNumberConfigurationCreateSchema {
  is_primary: boolean;
  is_required: boolean;
  name: string;
}

/**
 * @nullable
 */
export type ProductCreateSchemaModel = string | null;

/**
 * @nullable
 */
export type ProductCreateSchemaMake = string | null;

/**
 * @nullable
 */
export type BaseProductVendorSchemaVendorSku = string | null;

export interface BaseProductVendorSchema {
  vendor_id: string;
  /** @nullable */
  vendor_sku?: BaseProductVendorSchemaVendorSku;
}

export interface ProductCreateSchema {
  backordered_configurations: BackorderedConfigurationCreateSchema[];
  /** @nullable */
  make?: ProductCreateSchemaMake;
  /** @nullable */
  model?: ProductCreateSchemaModel;
  name: string;
  serial_number_configurations: SerialNumberConfigurationCreateSchema[];
  usage_metric_configurations: UsageMetricConfigurationCreateSchema[];
  vendors: BaseProductVendorSchema[];
}

/**
 * @nullable
 */
export type BackorderedConfigurationCreateSchemaVendorId = string | null;

/**
 * @nullable
 */
export type BackorderedConfigurationCreateSchemaVendorContactId = string | null;

/**
 * @nullable
 */
export type BackorderedConfigurationCreateSchemaLocationId = string | null;

export interface BackorderedConfigurationCreateSchema {
  /** @nullable */
  location_id?: BackorderedConfigurationCreateSchemaLocationId;
  minimum_threshold: number;
  order_to_quantity: number;
  /** @nullable */
  vendor_contact_id?: BackorderedConfigurationCreateSchemaVendorContactId;
  /** @nullable */
  vendor_id?: BackorderedConfigurationCreateSchemaVendorId;
}

export interface KlassAvailabilityDumpSchema {
  count_assets: number;
  count_available_assets: number;
  count_backordered_assets__confirmed: number;
  count_backordered_assets__draft: number;
  count_inactive_assets: number;
  count_on_rent_assets: number;
  count_unavailable_assets: number;
  id: string;
}

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaUsefulLife = number | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaRateProfileId = string | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaRateProfile = RateProfileDumpSchema | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaProductTaxCodeGroupId = string | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaProductTaxCodeGroup = ProductTaxCodeGroupDumpSchema | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaPickupCustomForm = LinkNameSchema | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaMinimumRentalPeriodDelivery = number | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaInventoryProfileId = string | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaInventoryProfile = InventoryProfileDumpSchema | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaInternalDescription = string | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaExternalDescription = string | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaDropoffCustomForm = LinkNameSchema | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaDepreciationSettingId = string | null;

/**
 * @nullable
 */
export type KlassHydratedDumpSchemaDepreciationSetting = DepreciationSettingDumpSchema | null;

export interface KlassSpecificationSchema {
  name: string;
  primary: boolean;
  value: string;
}

export interface KlassHydratedDumpSchema {
  attachments: InventoryUploadDumpSchema[];
  category: CategoryDumpSchema;
  category_id: string;
  /** @nullable */
  depreciation_setting?: KlassHydratedDumpSchemaDepreciationSetting;
  /** @nullable */
  depreciation_setting_id?: KlassHydratedDumpSchemaDepreciationSettingId;
  /** @nullable */
  dropoff_custom_form?: KlassHydratedDumpSchemaDropoffCustomForm;
  /** @nullable */
  external_description?: KlassHydratedDumpSchemaExternalDescription;
  id: string;
  identifier: string;
  images: CustomerFileDumpSchema[];
  /** @nullable */
  internal_description?: KlassHydratedDumpSchemaInternalDescription;
  /** @nullable */
  inventory_profile?: KlassHydratedDumpSchemaInventoryProfile;
  /** @nullable */
  inventory_profile_id?: KlassHydratedDumpSchemaInventoryProfileId;
  is_print_meter_gauge: boolean;
  /** @nullable */
  minimum_rental_period_delivery?: KlassHydratedDumpSchemaMinimumRentalPeriodDelivery;
  modifiers: ExpenseClassDumpSchemaWithRule[];
  name: string;
  /** @nullable */
  pickup_custom_form?: KlassHydratedDumpSchemaPickupCustomForm;
  pricings: KlassPricingHydratedDumpSchema[];
  print_visibility_type: PrintVisibilityType;
  /** @nullable */
  product_tax_code_group?: KlassHydratedDumpSchemaProductTaxCodeGroup;
  /** @nullable */
  product_tax_code_group_id?: KlassHydratedDumpSchemaProductTaxCodeGroupId;
  products: ProductDumpSchema[];
  /** @nullable */
  rate_profile?: KlassHydratedDumpSchemaRateProfile;
  /** @nullable */
  rate_profile_id?: KlassHydratedDumpSchemaRateProfileId;
  specifications: KlassSpecificationSchema[];
  tags: string[];
  terms_and_conditions_addendums: LinkNameSchema[];
  tracking: TrackingType;
  type: KlassType;
  /** @nullable */
  useful_life?: KlassHydratedDumpSchemaUsefulLife;
  visibility: VisibilityType;
}

/**
 * @nullable
 */
export type PaginatedKlassDumpSchemaNext = number | null;

export interface PaginatedKlassDumpSchema {
  elements: KlassDumpSchema[];
  /** @nullable */
  next?: PaginatedKlassDumpSchemaNext;
  total_count: number;
}

export interface AssetUsageMetricBodySchema {
  usage_metric_values: UsageMetricValueCreateSchema[];
}

/**
 * @nullable
 */
export type BulkAssetUpdateSchemaModelYear = number | null;

export interface BulkAssetUpdateSchema {
  /** @nullable */
  model_year?: BulkAssetUpdateSchemaModelYear;
  tags: string[];
}

/**
 * @nullable
 */
export type PaginatedAssetOrderSchemaNext = number | null;

export interface PaginatedAssetOrderSchema {
  elements: AssetOrderDumpSchema[];
  /** @nullable */
  next?: PaginatedAssetOrderSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type AssetOrderDumpSchemaTitleNumber = string | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaTitleName = string | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaTitleDate = string | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaResidualValue = string | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaRegistrationDate = string | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaModelYear = number | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaLicensePlateNumber = string | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaEngineModel = string | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaEngineMake = string | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaEngineCapacity = string | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaDepreciationStartDate = string | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaDateRegistrationExpires = string | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaBarcodeType = BarcodeType | null;

/**
 * @nullable
 */
export type AssetOrderDumpSchemaBarcodeCode = string | null;

export interface AssetOrderDumpSchema {
  acquisition_date: string;
  acquisition_value: string;
  availability: AssetStatus;
  /** @nullable */
  barcode_code?: AssetOrderDumpSchemaBarcodeCode;
  /** @nullable */
  barcode_type?: AssetOrderDumpSchemaBarcodeType;
  count_assets: number;
  count_available_assets: number;
  count_inactive_assets: number;
  count_on_rent_assets: number;
  count_unavailable_assets: number;
  /** @nullable */
  date_registration_expires?: AssetOrderDumpSchemaDateRegistrationExpires;
  /** @nullable */
  depreciation_start_date?: AssetOrderDumpSchemaDepreciationStartDate;
  /** @nullable */
  engine_capacity?: AssetOrderDumpSchemaEngineCapacity;
  /** @nullable */
  engine_make?: AssetOrderDumpSchemaEngineMake;
  /** @nullable */
  engine_model?: AssetOrderDumpSchemaEngineModel;
  id: string;
  identifier: string;
  klass: KlassDumpSchema;
  klass_id: string;
  /** @nullable */
  license_plate_number?: AssetOrderDumpSchemaLicensePlateNumber;
  location: AssetDumpLocationSchema;
  location_id: string;
  /** @nullable */
  model_year?: AssetOrderDumpSchemaModelYear;
  product: LinkNameSchema;
  product_id: string;
  /** @nullable */
  registration_date?: AssetOrderDumpSchemaRegistrationDate;
  /** @nullable */
  residual_value?: AssetOrderDumpSchemaResidualValue;
  /** @nullable */
  title_date?: AssetOrderDumpSchemaTitleDate;
  /** @nullable */
  title_name?: AssetOrderDumpSchemaTitleName;
  /** @nullable */
  title_number?: AssetOrderDumpSchemaTitleNumber;
  vendor: LinkNameSchema;
  vendor_id: string;
}

/**
 * @nullable
 */
export type AssetOrderSearchParamsStart = number | null;

/**
 * @nullable
 */
export type AssetOrderSearchParamsSearch = string | null;

/**
 * @nullable
 */
export type AssetOrderSearchParamsPageSize = number | null;

/**
 * @nullable
 */
export type AssetOrderSearchParamsLocationIds = string[] | null;

/**
 * @nullable
 */
export type AssetOrderSearchParamsLocationGroupId = string | null;

/**
 * @nullable
 */
export type AssetOrderSearchParamsEndDatetime = string | null;

export interface AssetOrderSearchParams {
  /** @nullable */
  end_datetime?: AssetOrderSearchParamsEndDatetime;
  klass_ids: string[];
  /** @nullable */
  location_group_id?: AssetOrderSearchParamsLocationGroupId;
  /** @nullable */
  location_ids?: AssetOrderSearchParamsLocationIds;
  /** @nullable */
  page_size?: AssetOrderSearchParamsPageSize;
  /** @nullable */
  search?: AssetOrderSearchParamsSearch;
  /** @nullable */
  start?: AssetOrderSearchParamsStart;
  start_datetime: string;
}

/**
 * @nullable
 */
export type AssetUpdateSchemaTitleNumber = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaTitleName = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaTitleLocation = AddressCreateSchema | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaTitleDate = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaResidualValue = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaRegistrationDate = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaModelYear = number | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaLicensePlateNumber = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaIdentifier = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaEngineModel = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaEngineMake = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaEngineCapacity = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaDepreciationStartDate = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaDateRegistrationExpires = string | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaBarcodeType = BarcodeType | null;

/**
 * @nullable
 */
export type AssetUpdateSchemaBarcodeCode = string | null;

export interface AssetUpdateSchema {
  acquisition_date: string;
  acquisition_value: string;
  attachments: InventoryUploadCreateSchema[];
  /** @nullable */
  barcode_code?: AssetUpdateSchemaBarcodeCode;
  /** @nullable */
  barcode_type?: AssetUpdateSchemaBarcodeType;
  /** @nullable */
  date_registration_expires?: AssetUpdateSchemaDateRegistrationExpires;
  /** @nullable */
  depreciation_start_date?: AssetUpdateSchemaDepreciationStartDate;
  /** @nullable */
  engine_capacity?: AssetUpdateSchemaEngineCapacity;
  /** @nullable */
  engine_make?: AssetUpdateSchemaEngineMake;
  /** @nullable */
  engine_model?: AssetUpdateSchemaEngineModel;
  /** @nullable */
  identifier?: AssetUpdateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  is_delivery_truck?: boolean;
  klass_id: string;
  /** @nullable */
  license_plate_number?: AssetUpdateSchemaLicensePlateNumber;
  location_id: string;
  /** @nullable */
  model_year?: AssetUpdateSchemaModelYear;
  product_id: string;
  /** @nullable */
  registration_date?: AssetUpdateSchemaRegistrationDate;
  /** @nullable */
  residual_value?: AssetUpdateSchemaResidualValue;
  serial_number_values: SerialNumberValueCreateSchema[];
  tags: string[];
  /** @nullable */
  title_date?: AssetUpdateSchemaTitleDate;
  /** @nullable */
  title_location?: AssetUpdateSchemaTitleLocation;
  /** @nullable */
  title_name?: AssetUpdateSchemaTitleName;
  /** @nullable */
  title_number?: AssetUpdateSchemaTitleNumber;
  vendor_id: string;
}

/**
 * @nullable
 */
export type AssetCreateSchemaTitleNumber = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaTitleName = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaTitleLocation = AddressCreateSchema | null;

/**
 * @nullable
 */
export type AssetCreateSchemaTitleDate = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaResidualValue = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaRegistrationDate = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaModelYear = number | null;

/**
 * @nullable
 */
export type AssetCreateSchemaLicensePlateNumber = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaIdentifier = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaEngineModel = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaEngineMake = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaEngineCapacity = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaDepreciationStartDate = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaDateRegistrationExpires = string | null;

/**
 * @nullable
 */
export type AssetCreateSchemaBarcodeType = BarcodeType | null;

/**
 * @nullable
 */
export type AssetCreateSchemaBarcodeCode = string | null;

export interface UsageMetricValueCreateSchema {
  usage_metric_configuration_id: string;
  value: string;
}

export interface SerialNumberValueCreateSchema {
  serial_number_configuration_id: string;
  value: string;
}

export interface AssetCreateSchema {
  acquisition_date: string;
  acquisition_value: string;
  attachments: InventoryUploadCreateSchema[];
  /** @nullable */
  barcode_code?: AssetCreateSchemaBarcodeCode;
  /** @nullable */
  barcode_type?: AssetCreateSchemaBarcodeType;
  /** @nullable */
  date_registration_expires?: AssetCreateSchemaDateRegistrationExpires;
  /** @nullable */
  depreciation_start_date?: AssetCreateSchemaDepreciationStartDate;
  /** @nullable */
  engine_capacity?: AssetCreateSchemaEngineCapacity;
  /** @nullable */
  engine_make?: AssetCreateSchemaEngineMake;
  /** @nullable */
  engine_model?: AssetCreateSchemaEngineModel;
  /** @nullable */
  identifier?: AssetCreateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  is_delivery_truck?: boolean;
  klass_id: string;
  /** @nullable */
  license_plate_number?: AssetCreateSchemaLicensePlateNumber;
  location_id: string;
  /** @nullable */
  model_year?: AssetCreateSchemaModelYear;
  product_id: string;
  quantity: number;
  /** @nullable */
  registration_date?: AssetCreateSchemaRegistrationDate;
  /** @nullable */
  residual_value?: AssetCreateSchemaResidualValue;
  serial_number_values: SerialNumberValueCreateSchema[];
  tags: string[];
  /** @nullable */
  title_date?: AssetCreateSchemaTitleDate;
  /** @nullable */
  title_location?: AssetCreateSchemaTitleLocation;
  /** @nullable */
  title_name?: AssetCreateSchemaTitleName;
  /** @nullable */
  title_number?: AssetCreateSchemaTitleNumber;
  usage_metric_values: UsageMetricValueCreateSchema[];
  vendor_id: string;
}

/**
 * @nullable
 */
export type PaginatedUsageMetricDumpSchemaNext = number | null;

export interface UsageMetricValueBaseDumpSchema {
  created_at: string;
  usage_metric_configuration_id: string;
  user: LinkNameSchema;
  user_id: string;
  value: string;
}

export interface PaginatedUsageMetricDumpSchema {
  elements: UsageMetricValueBaseDumpSchema[];
  /** @nullable */
  next?: PaginatedUsageMetricDumpSchemaNext;
  total_count: number;
  usage_metric_configuration: UsageMetricConfigurationDumpSchema;
}

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaTitleNumber = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaTitleName = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaTitleLocation = AddressDumpSchema | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaTitleDate = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaThumbnail = CustomerFileDumpSchema | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaResidualValue = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaRegistrationDate = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaPurchaseOrder = LinkIDSchema | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaModelYear = number | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaLicensePlateNumber = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaEngineModel = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaEngineMake = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaEngineCapacity = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaDepreciationStartDate = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaDateRegistrationExpires = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaBarcodeType = BarcodeType | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaBarcodeCode = string | null;

/**
 * @nullable
 */
export type AssetHydratedDumpSchemaAverageAcquisitionValue = string | null;

export interface AssetHydratedDumpSchema {
  acquisition_date: string;
  acquisition_value: string;
  attachments: InventoryUploadDumpSchema[];
  /** @nullable */
  average_acquisition_value?: AssetHydratedDumpSchemaAverageAcquisitionValue;
  /** @nullable */
  barcode_code?: AssetHydratedDumpSchemaBarcodeCode;
  /** @nullable */
  barcode_type?: AssetHydratedDumpSchemaBarcodeType;
  count_assets: number;
  count_available_assets: number;
  count_inactive_assets: number;
  count_on_rent_assets: number;
  count_unavailable_assets: number;
  /** @nullable */
  date_registration_expires?: AssetHydratedDumpSchemaDateRegistrationExpires;
  /** @nullable */
  depreciation_start_date?: AssetHydratedDumpSchemaDepreciationStartDate;
  /** @nullable */
  engine_capacity?: AssetHydratedDumpSchemaEngineCapacity;
  /** @nullable */
  engine_make?: AssetHydratedDumpSchemaEngineMake;
  /** @nullable */
  engine_model?: AssetHydratedDumpSchemaEngineModel;
  id: string;
  identifier: string;
  images: CustomerFileDumpSchema[];
  is_delivery_truck: boolean;
  klass: KlassDumpSchema;
  klass_id: string;
  /** @nullable */
  license_plate_number?: AssetHydratedDumpSchemaLicensePlateNumber;
  location: LocationDumpSchema;
  location_id: string;
  /** @nullable */
  model_year?: AssetHydratedDumpSchemaModelYear;
  product: ProductDumpSchema;
  product_id: string;
  /** @nullable */
  purchase_order: AssetHydratedDumpSchemaPurchaseOrder;
  /** @nullable */
  registration_date?: AssetHydratedDumpSchemaRegistrationDate;
  /** @nullable */
  residual_value?: AssetHydratedDumpSchemaResidualValue;
  serial_numbers: SerialNumberValueDumpSchema[];
  tags: string[];
  /** @nullable */
  thumbnail?: AssetHydratedDumpSchemaThumbnail;
  /** @nullable */
  title_date?: AssetHydratedDumpSchemaTitleDate;
  /** @nullable */
  title_location?: AssetHydratedDumpSchemaTitleLocation;
  /** @nullable */
  title_name?: AssetHydratedDumpSchemaTitleName;
  /** @nullable */
  title_number?: AssetHydratedDumpSchemaTitleNumber;
  usage_metrics: UsageMetricValueDumpSchema[];
  vendor: VendorDumpSchema;
  vendor_id: string;
}

export type VendorTypeEnum = typeof VendorTypeEnum[keyof typeof VendorTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorTypeEnum = {
  business: 'business',
  individual: 'individual',
} as const;

/**
 * @nullable
 */
export type VendorDumpSchemaWebAddress = string | null;

/**
 * @nullable
 */
export type VendorDumpSchemaRemoteObject = RemoteObjectDumpSchema | null;

/**
 * @nullable
 */
export type VendorDumpSchemaProducts = string | null;

/**
 * @nullable
 */
export type VendorDumpSchemaPhone = string | null;

/**
 * @nullable
 */
export type VendorDumpSchemaNotes = string | null;

/**
 * @nullable
 */
export type VendorDumpSchemaEmail = string | null;

/**
 * @nullable
 */
export type VendorDumpSchemaDisplayName = string | null;

/**
 * @nullable
 */
export type VendorDumpSchemaAddress = AddressDumpSchema | null;

/**
 * @nullable
 */
export type VendorDumpSchemaAccountNumber = string | null;

/**
 * @nullable
 */
export type VendorContactDumpSchemaPhone = string | null;

/**
 * @nullable
 */
export type VendorContactDumpSchemaEmail = string | null;

export interface VendorContactDumpSchema {
  /** @nullable */
  email?: VendorContactDumpSchemaEmail;
  first_name: string;
  full_name: string;
  id: string;
  last_name: string;
  /** @nullable */
  phone?: VendorContactDumpSchemaPhone;
}

export interface VendorDumpSchema {
  /** @nullable */
  account_number?: VendorDumpSchemaAccountNumber;
  activity_log_id: string;
  /** @nullable */
  address?: VendorDumpSchemaAddress;
  autogenerated_identifier: string;
  contacts: VendorContactDumpSchema[];
  /** @nullable */
  display_name?: VendorDumpSchemaDisplayName;
  /** @nullable */
  email?: VendorDumpSchemaEmail;
  id: string;
  name: string;
  /** @nullable */
  notes?: VendorDumpSchemaNotes;
  original_name: string;
  payment_method: PaymentMethodType;
  payment_terms: PaymentTerms;
  /** @nullable */
  phone?: VendorDumpSchemaPhone;
  /** @nullable */
  products?: VendorDumpSchemaProducts;
  /** @nullable */
  remote_object?: VendorDumpSchemaRemoteObject;
  type: VendorTypeEnum;
  /** @nullable */
  web_address?: VendorDumpSchemaWebAddress;
}

export interface UsageMetricValueDumpSchema {
  created_at: string;
  usage_metric_configuration: UsageMetricConfigurationDumpSchema;
  usage_metric_configuration_id: string;
  user: LinkNameSchema;
  user_id: string;
  value: string;
}

export type UsageMetricUnit = typeof UsageMetricUnit[keyof typeof UsageMetricUnit];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageMetricUnit = {
  miles: 'miles',
  hours: 'hours',
  gallons: 'gallons',
  liters: 'liters',
} as const;

export type UsageMetricTypeEnum = typeof UsageMetricTypeEnum[keyof typeof UsageMetricTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageMetricTypeEnum = {
  standard: 'standard',
  fuel: 'fuel',
} as const;

export interface UsageMetricTypeDumpSchema {
  id: string;
  metric: UsageMetricUnit;
  name: string;
  type: UsageMetricTypeEnum;
}

/**
 * @nullable
 */
export type UsageMetricConfigurationDumpSchemaMaxCapacity = string | null;

/**
 * @nullable
 */
export type UsageMetricConfigurationDumpSchemaFuelType = FuelType | null;

export interface UsageMetricConfigurationDumpSchema {
  /** @nullable */
  fuel_type?: UsageMetricConfigurationDumpSchemaFuelType;
  id: string;
  /** @nullable */
  max_capacity?: UsageMetricConfigurationDumpSchemaMaxCapacity;
  primary: boolean;
  usage_metric_type: UsageMetricTypeDumpSchema;
  usage_metric_type_id: string;
}

export interface SerialNumberConfigurationDumpSchema {
  id: string;
  is_primary: boolean;
  is_required: boolean;
  name: string;
}

export interface SerialNumberValueDumpSchema {
  serial_number_configuration: SerialNumberConfigurationDumpSchema;
  serial_number_configuration_id: string;
  value: string;
}

/**
 * @nullable
 */
export type ProductVendorDumpSchemaVendorSku = string | null;

export interface ProductVendorDumpSchema {
  vendor: VendorDumpSchema;
  vendor_id: string;
  /** @nullable */
  vendor_sku?: ProductVendorDumpSchemaVendorSku;
}

/**
 * @nullable
 */
export type ProductDumpSchemaModel = string | null;

/**
 * @nullable
 */
export type ProductDumpSchemaMake = string | null;

export interface ProductDumpSchema {
  asset_count: number;
  backordered_configurations: BackorderedConfigurationDumpSchema[];
  can_delete: boolean;
  id: string;
  /** @nullable */
  make?: ProductDumpSchemaMake;
  /** @nullable */
  model?: ProductDumpSchemaModel;
  name: string;
  serial_number_configurations: SerialNumberConfigurationDumpSchema[];
  usage_gauge_configurations: UsageMetricConfigurationDumpSchema[];
  usage_metric_configurations: UsageMetricConfigurationDumpSchema[];
  vendors: ProductVendorDumpSchema[];
}

export type PaymentMethodType = typeof PaymentMethodType[keyof typeof PaymentMethodType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodType = {
  card: 'card',
  ach: 'ach',
  invoice: 'invoice',
} as const;

export type FuelType = typeof FuelType[keyof typeof FuelType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FuelType = {
  diesel: 'diesel',
  gasoline: 'gasoline',
  propane: 'propane',
  oil: 'oil',
} as const;

/**
 * @nullable
 */
export type BackorderedConfigurationDumpSchemaVendorId = string | null;

/**
 * @nullable
 */
export type BackorderedConfigurationDumpSchemaVendorContactId = string | null;

/**
 * @nullable
 */
export type BackorderedConfigurationDumpSchemaVendorContact = LinkNameSchema | null;

/**
 * @nullable
 */
export type BackorderedConfigurationDumpSchemaVendor = LinkIDNameSchema | null;

/**
 * @nullable
 */
export type BackorderedConfigurationDumpSchemaLocationId = string | null;

/**
 * @nullable
 */
export type BackorderedConfigurationDumpSchemaLocation = LinkIDNameSchema | null;

export interface BackorderedConfigurationDumpSchema {
  id: string;
  /** @nullable */
  location?: BackorderedConfigurationDumpSchemaLocation;
  /** @nullable */
  location_id?: BackorderedConfigurationDumpSchemaLocationId;
  minimum_threshold: number;
  order_to_quantity: number;
  product: LinkNameSchema;
  product_id: string;
  /** @nullable */
  vendor?: BackorderedConfigurationDumpSchemaVendor;
  /** @nullable */
  vendor_contact?: BackorderedConfigurationDumpSchemaVendorContact;
  /** @nullable */
  vendor_contact_id?: BackorderedConfigurationDumpSchemaVendorContactId;
  /** @nullable */
  vendor_id?: BackorderedConfigurationDumpSchemaVendorId;
}

/**
 * @nullable
 */
export type PaginatedAssetSchemaNext = number | null;

export interface PaginatedAssetSchema {
  elements: AssetDumpSchema[];
  /** @nullable */
  next?: PaginatedAssetSchemaNext;
  total_count: number;
}

export type VisibilityType = typeof VisibilityType[keyof typeof VisibilityType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VisibilityType = {
  active: 'active',
  hidden: 'hidden',
} as const;

export interface ShiftDumpSchema {
  id: string;
  max_hours_per_day: number;
  max_hours_per_month: number;
  max_hours_per_week: number;
  multiplier: string;
  name: string;
}

export type PrintVisibilityType = typeof PrintVisibilityType[keyof typeof PrintVisibilityType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrintVisibilityType = {
  consolidated: 'consolidated',
  broken_out: 'broken_out',
} as const;

export type MeterOvertimeMethodology = typeof MeterOvertimeMethodology[keyof typeof MeterOvertimeMethodology];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeterOvertimeMethodology = {
  default: 'default',
  simple: 'simple',
  rer: 'rer',
} as const;

export interface RateProfileDumpSchema {
  clock_hours_per_day: number;
  clock_hours_per_month: number;
  clock_hours_per_week: number;
  clock_overtime_methodology: ClockOvertimeMethodology;
  clock_overtime_penalty: string;
  id: string;
  is_business_default: boolean;
  is_prorate_early_call_offs_enabled: boolean;
  is_prorate_extensions_enabled: boolean;
  meter_hours_per_day: number;
  meter_hours_per_month: number;
  meter_hours_per_week: number;
  meter_overtime_methodology: MeterOvertimeMethodology;
  name: string;
  shifts: ShiftDumpSchema[];
}

/**
 * @nullable
 */
export type KlassDumpSchemaUsefulLife = number | null;

/**
 * @nullable
 */
export type KlassDumpSchemaRateProfileId = string | null;

/**
 * @nullable
 */
export type KlassDumpSchemaRateProfile = RateProfileDumpSchema | null;

/**
 * @nullable
 */
export type KlassDumpSchemaProductTaxCodeGroupId = string | null;

/**
 * @nullable
 */
export type KlassDumpSchemaMinimumRentalPeriodDelivery = number | null;

/**
 * @nullable
 */
export type KlassDumpSchemaInventoryProfileId = string | null;

/**
 * @nullable
 */
export type KlassDumpSchemaInternalDescription = string | null;

/**
 * @nullable
 */
export type KlassDumpSchemaExternalDescription = string | null;

/**
 * @nullable
 */
export type KlassDumpSchemaDepreciationSettingId = string | null;

export interface KlassDumpSchema {
  category: LinkIDNameSchema;
  category_id: string;
  /** @nullable */
  depreciation_setting_id?: KlassDumpSchemaDepreciationSettingId;
  /** @nullable */
  external_description?: KlassDumpSchemaExternalDescription;
  id: string;
  identifier: string;
  /** @nullable */
  internal_description?: KlassDumpSchemaInternalDescription;
  /** @nullable */
  inventory_profile_id?: KlassDumpSchemaInventoryProfileId;
  is_print_meter_gauge: boolean;
  /** @nullable */
  minimum_rental_period_delivery?: KlassDumpSchemaMinimumRentalPeriodDelivery;
  name: string;
  print_visibility_type: PrintVisibilityType;
  /** @nullable */
  product_tax_code_group_id?: KlassDumpSchemaProductTaxCodeGroupId;
  /** @nullable */
  rate_profile?: KlassDumpSchemaRateProfile;
  /** @nullable */
  rate_profile_id?: KlassDumpSchemaRateProfileId;
  tracking: TrackingType;
  type: KlassType;
  /** @nullable */
  useful_life?: KlassDumpSchemaUsefulLife;
  visibility: VisibilityType;
}

export type ClockOvertimeMethodology = typeof ClockOvertimeMethodology[keyof typeof ClockOvertimeMethodology];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClockOvertimeMethodology = {
  default: 'default',
  simple: 'simple',
} as const;

export type BarcodeType = typeof BarcodeType[keyof typeof BarcodeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BarcodeType = {
  code_39: 'code_39',
  code_128: 'code_128',
  qr_code: 'qr_code',
  data_matrix: 'data_matrix',
  pdf_417: 'pdf_417',
  aztec: 'aztec',
} as const;

export type AssetStatus = typeof AssetStatus[keyof typeof AssetStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetStatus = {
  available: 'available',
  on_rent: 'on_rent',
  unavailable: 'unavailable',
  inactive: 'inactive',
} as const;

/**
 * @nullable
 */
export type AssetDumpSchemaTitleNumber = string | null;

/**
 * @nullable
 */
export type AssetDumpSchemaTitleName = string | null;

/**
 * @nullable
 */
export type AssetDumpSchemaTitleDate = string | null;

/**
 * @nullable
 */
export type AssetDumpSchemaResidualValue = string | null;

/**
 * @nullable
 */
export type AssetDumpSchemaRegistrationDate = string | null;

/**
 * @nullable
 */
export type AssetDumpSchemaModelYear = number | null;

/**
 * @nullable
 */
export type AssetDumpSchemaLicensePlateNumber = string | null;

/**
 * @nullable
 */
export type AssetDumpSchemaEngineModel = string | null;

/**
 * @nullable
 */
export type AssetDumpSchemaEngineMake = string | null;

/**
 * @nullable
 */
export type AssetDumpSchemaEngineCapacity = string | null;

/**
 * @nullable
 */
export type AssetDumpSchemaDepreciationStartDate = string | null;

/**
 * @nullable
 */
export type AssetDumpSchemaDateRegistrationExpires = string | null;

/**
 * @nullable
 */
export type AssetDumpSchemaBarcodeType = BarcodeType | null;

/**
 * @nullable
 */
export type AssetDumpSchemaBarcodeCode = string | null;

/**
 * @nullable
 */
export type AssetDumpLocationSchemaLocationGroupId = string | null;

export interface AssetDumpLocationSchema {
  id: string;
  identifier: string;
  /** @nullable */
  location_group_id?: AssetDumpLocationSchemaLocationGroupId;
  name: string;
}

export interface AssetDumpSchema {
  acquisition_date: string;
  acquisition_value: string;
  availability: AssetStatus;
  /** @nullable */
  barcode_code?: AssetDumpSchemaBarcodeCode;
  /** @nullable */
  barcode_type?: AssetDumpSchemaBarcodeType;
  count_assets: number;
  count_available_assets: number;
  count_inactive_assets: number;
  count_on_rent_assets: number;
  count_unavailable_assets: number;
  /** @nullable */
  date_registration_expires?: AssetDumpSchemaDateRegistrationExpires;
  /** @nullable */
  depreciation_start_date?: AssetDumpSchemaDepreciationStartDate;
  /** @nullable */
  engine_capacity?: AssetDumpSchemaEngineCapacity;
  /** @nullable */
  engine_make?: AssetDumpSchemaEngineMake;
  /** @nullable */
  engine_model?: AssetDumpSchemaEngineModel;
  id: string;
  identifier: string;
  klass: KlassDumpSchema;
  klass_id: string;
  /** @nullable */
  license_plate_number?: AssetDumpSchemaLicensePlateNumber;
  location: AssetDumpLocationSchema;
  location_id: string;
  /** @nullable */
  model_year?: AssetDumpSchemaModelYear;
  product: LinkNameSchema;
  product_id: string;
  /** @nullable */
  registration_date?: AssetDumpSchemaRegistrationDate;
  /** @nullable */
  residual_value?: AssetDumpSchemaResidualValue;
  /** @nullable */
  title_date?: AssetDumpSchemaTitleDate;
  /** @nullable */
  title_name?: AssetDumpSchemaTitleName;
  /** @nullable */
  title_number?: AssetDumpSchemaTitleNumber;
  vendor: LinkNameSchema;
  vendor_id: string;
}

/**
 * @nullable
 */
export type PaginatedInventoryDumpSchemaNext = number | null;

export interface PaginatedInventoryDumpSchema {
  elements: InventoryUploadDumpSchema[];
  /** @nullable */
  next?: PaginatedInventoryDumpSchemaNext;
  total_count: number;
}

export interface ExpenseClassDumpSchemaWithRule {
  display_type: ExpenseClassDisplayType;
  id: string;
  name: string;
  /** @nullable */
  product_tax_code_group_id?: ExpenseClassDumpSchemaWithRuleProductTaxCodeGroupId;
  /** @nullable */
  revenue_account?: ExpenseClassDumpSchemaWithRuleRevenueAccount;
  /** @nullable */
  revenue_account_id?: ExpenseClassDumpSchemaWithRuleRevenueAccountId;
  rule: ExpenseRuleDumpSchema;
  type: ExpenseClassType;
}

export interface ExpenseClassOrderDumpSchema {
  excluded_modifiers: ExpenseClassDumpSchema[];
  optional_modifiers: ExpenseClassDumpSchemaWithRule[];
  required_modifiers: ExpenseClassDumpSchemaWithRule[];
}

/**
 * @nullable
 */
export type ExpenseClassDumpSchemaWithRuleRevenueAccountId = string | null;

/**
 * @nullable
 */
export type ExpenseClassDumpSchemaWithRuleRevenueAccount = LedgerAccountDumpSchema | null;

/**
 * @nullable
 */
export type ExpenseClassDumpSchemaWithRuleProductTaxCodeGroupId = string | null;

/**
 * @nullable
 */
export type ExpenseClassSearchParamsTypeSearch = string | null;

/**
 * @nullable
 */
export type ExpenseClassSearchParamsTypeKlassId = string | null;

/**
 * @nullable
 */
export type ExpenseClassSearchParamsTypeKitLineItemId = string | null;

/**
 * @nullable
 */
export type ExpenseClassSearchParamsTypeFeesOnly = boolean | null;

/**
 * @nullable
 */
export type ExpenseClassSearchParamsTypeDiscountsOnly = boolean | null;

/**
 * @nullable
 */
export type ExpenseClassSearchParamsTypeCustomerId = string | null;

/**
 * @nullable
 */
export type ExpenseClassSearchParamsTypeBillingProfileId = string | null;

export type ExpenseClassSearchType = typeof ExpenseClassSearchType[keyof typeof ExpenseClassSearchType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseClassSearchType = {
  order__rental: 'order__rental',
  order__sale: 'order__sale',
  order__delivery: 'order__delivery',
  item__rental: 'item__rental',
  item__sale: 'item__sale',
  rental__all: 'rental__all',
  sale__all: 'sale__all',
  order__all: 'order__all',
} as const;

export interface ExpenseClassSearchParamsType {
  /** @nullable */
  billing_profile_id?: ExpenseClassSearchParamsTypeBillingProfileId;
  /** @nullable */
  customer_id?: ExpenseClassSearchParamsTypeCustomerId;
  /** @nullable */
  discounts_only?: ExpenseClassSearchParamsTypeDiscountsOnly;
  /** @nullable */
  fees_only?: ExpenseClassSearchParamsTypeFeesOnly;
  /** @nullable */
  kit_line_item_id?: ExpenseClassSearchParamsTypeKitLineItemId;
  /** @nullable */
  klass_id?: ExpenseClassSearchParamsTypeKlassId;
  /** @nullable */
  search?: ExpenseClassSearchParamsTypeSearch;
  type: ExpenseClassSearchType;
}

/**
 * @nullable
 */
export type ExpenseClassUpdateSchemaRule = ExpenseRuleCreateSchema | null;

/**
 * @nullable
 */
export type ExpenseClassUpdateSchemaRevenueAccountId = string | null;

/**
 * @nullable
 */
export type ExpenseClassUpdateSchemaProductTaxCodeGroupId = string | null;

/**
 * @nullable
 */
export type ExpenseClassCreateSchemaRule = ExpenseRuleCreateSchema | null;

/**
 * @nullable
 */
export type ExpenseClassCreateSchemaRevenueAccountId = string | null;

/**
 * @nullable
 */
export type ExpenseClassCreateSchemaProductTaxCodeGroupId = string | null;

export interface ExpenseClassCreateSchema {
  display_type: ExpenseClassDisplayType;
  name: string;
  /** @nullable */
  product_tax_code_group_id?: ExpenseClassCreateSchemaProductTaxCodeGroupId;
  /** @nullable */
  revenue_account_id?: ExpenseClassCreateSchemaRevenueAccountId;
  /** @nullable */
  rule?: ExpenseClassCreateSchemaRule;
  type: ExpenseClassType;
}

/**
 * @nullable
 */
export type ExpenseRuleCreateSchemaKlassId = string | null;

/**
 * @nullable
 */
export type ExpenseRuleCreateSchemaKitLineItemId = string | null;

/**
 * @nullable
 */
export type ExpenseRuleCreateSchemaCustomerId = string | null;

/**
 * @nullable
 */
export type ExpenseRuleCreateSchemaBillingProfileId = string | null;

export interface ExpenseRuleCreateSchema {
  /** @nullable */
  billing_profile_id?: ExpenseRuleCreateSchemaBillingProfileId;
  /** @nullable */
  customer_id?: ExpenseRuleCreateSchemaCustomerId;
  is_editable: boolean;
  /** @nullable */
  kit_line_item_id?: ExpenseRuleCreateSchemaKitLineItemId;
  /** @nullable */
  klass_id?: ExpenseRuleCreateSchemaKlassId;
  percentage: string;
  type: ExpenseRuleType;
}

/**
 * @nullable
 */
export type PaginatedExpenseClassSchemaNext = number | null;

export interface PaginatedExpenseClassSchema {
  elements: ExpenseClassDumpSchema[];
  /** @nullable */
  next?: PaginatedExpenseClassSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type ExpenseRuleDumpSchemaKlassId = string | null;

/**
 * @nullable
 */
export type ExpenseRuleDumpSchemaKitLineItemId = string | null;

/**
 * @nullable
 */
export type ExpenseRuleDumpSchemaCustomerId = string | null;

/**
 * @nullable
 */
export type ExpenseRuleDumpSchemaBillingProfileId = string | null;

export interface ExpenseRuleDumpSchema {
  /** @nullable */
  billing_profile_id?: ExpenseRuleDumpSchemaBillingProfileId;
  /** @nullable */
  customer_id?: ExpenseRuleDumpSchemaCustomerId;
  id: string;
  is_editable: boolean;
  /** @nullable */
  kit_line_item_id?: ExpenseRuleDumpSchemaKitLineItemId;
  /** @nullable */
  klass_id?: ExpenseRuleDumpSchemaKlassId;
  percentage: string;
  type: ExpenseRuleType;
}

export type ExpenseClassType = typeof ExpenseClassType[keyof typeof ExpenseClassType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseClassType = {
  order__rental: 'order__rental',
  order__sale: 'order__sale',
  order__delivery: 'order__delivery',
  item__rental: 'item__rental',
  item__sale: 'item__sale',
} as const;

/**
 * @nullable
 */
export type ExpenseClassDumpSchemaRule = ExpenseRuleDumpSchema | null;

/**
 * @nullable
 */
export type ExpenseClassDumpSchemaRevenueAccountId = string | null;

/**
 * @nullable
 */
export type ExpenseClassDumpSchemaRevenueAccount = LedgerAccountDumpSchema | null;

/**
 * @nullable
 */
export type ExpenseClassDumpSchemaProductTaxCodeGroupId = string | null;

export type ExpenseClassDisplayType = typeof ExpenseClassDisplayType[keyof typeof ExpenseClassDisplayType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseClassDisplayType = {
  independent: 'independent',
  grouped: 'grouped',
  merged: 'merged',
} as const;

export interface ExpenseClassUpdateSchema {
  display_type: ExpenseClassDisplayType;
  name: string;
  /** @nullable */
  product_tax_code_group_id?: ExpenseClassUpdateSchemaProductTaxCodeGroupId;
  /** @nullable */
  revenue_account_id?: ExpenseClassUpdateSchemaRevenueAccountId;
  /** @nullable */
  rule?: ExpenseClassUpdateSchemaRule;
  type: ExpenseClassType;
}

export interface ExpenseClassDumpSchema {
  display_type: ExpenseClassDisplayType;
  id: string;
  name: string;
  /** @nullable */
  product_tax_code_group_id?: ExpenseClassDumpSchemaProductTaxCodeGroupId;
  /** @nullable */
  revenue_account?: ExpenseClassDumpSchemaRevenueAccount;
  /** @nullable */
  revenue_account_id?: ExpenseClassDumpSchemaRevenueAccountId;
  /** @nullable */
  rule?: ExpenseClassDumpSchemaRule;
  type: ExpenseClassType;
}

export interface DeliveryConfigurationCalculationResponseSchema {
  miles: string;
  minutes: number;
  price: string;
}

export interface DeliveryConfigurationCalculationRequestSchema {
  delivery_configuration_id: string;
  destination_address_id: string;
  location_id: string;
}

export interface DistanceResponseSchema {
  miles: string;
  minutes: number;
}

export interface DistanceRequestSchema {
  destination_address_id: string;
  location_id: string;
}

export interface DeliveryRequestsDumpSchema {
  delivery_requests: DeliveryRequestDumpSchema[];
}

/**
 * @nullable
 */
export type DeliveryRequestDumpSchemaToWindow = DateTimeWindowSchema | null;

export type DeliveryRequestDumpSchemaToLocation = DeliveryLocationBranchDumpSchema | DeliveryLocationCustomerLocationDumpSchema;

export type DeliveryRequestDumpSchemaFromLocation = DeliveryLocationBranchDumpSchema | DeliveryLocationCustomerLocationDumpSchema;

/**
 * @nullable
 */
export type DeliveryRequestDumpSchemaCustomer = LinkIDNameSchema | null;

export type DeliveryRequestDirection = typeof DeliveryRequestDirection[keyof typeof DeliveryRequestDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRequestDirection = {
  dropoff: 'dropoff',
  pickup: 'pickup',
} as const;

export interface DateTimeWindowSchema {
  end: string;
  start: string;
}

/**
 * @nullable
 */
export type DeliveryRequestDumpSchemaFromWindow = DateTimeWindowSchema | null;

export interface DeliveryRequestDumpSchema {
  /** @nullable */
  customer?: DeliveryRequestDumpSchemaCustomer;
  direction: DeliveryRequestDirection;
  from_location: DeliveryRequestDumpSchemaFromLocation;
  /** @nullable */
  from_window?: DeliveryRequestDumpSchemaFromWindow;
  id: string;
  location: LinkIDNameSchema;
  order: LinkIDSchema;
  to_location: DeliveryRequestDumpSchemaToLocation;
  /** @nullable */
  to_window?: DeliveryRequestDumpSchemaToWindow;
}

export interface LoadRequestsDumpSchema {
  load_requests: LoadRequestHydratedDumpSchema[];
}

export interface LoadRequestPutSchema {
  delivery_route_stop_id: string;
  klass_id: string;
  quantity_completed: number;
}

/**
 * @nullable
 */
export type PaginatedDeliveryDriversDumpSchemaNext = number | null;

export interface PaginatedDeliveryDriversDumpSchema {
  elements: DeliveryDriverDumpSchema[];
  /** @nullable */
  next?: PaginatedDeliveryDriversDumpSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type PaginatedDeliveryTrucksDumpSchemaNext = number | null;

export interface PaginatedDeliveryTrucksDumpSchema {
  elements: DeliveryTruckDumpSchema[];
  /** @nullable */
  next?: PaginatedDeliveryTrucksDumpSchemaNext;
  total_count: number;
}

export type DeliveryRouteGeometryDumpSchemaStopsItem = DeliveryRouteBranchStopSlimDumpSchema | DeliveryRouteCustomerStopSlimDumpSchema;

export interface DeliveryRouteGeometryDumpSchema {
  geometry: string;
  id: string;
  name: string;
  status: DeliveryRouteStatus;
  stops: DeliveryRouteGeometryDumpSchemaStopsItem[];
}

export interface DeliveryRoutesWithGeometriesDumpSchema {
  routes: DeliveryRouteGeometryDumpSchema[];
}

export type DeliveryRouteCustomerStopSlimDumpSchemaType = typeof DeliveryRouteCustomerStopSlimDumpSchemaType[keyof typeof DeliveryRouteCustomerStopSlimDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteCustomerStopSlimDumpSchemaType = {
  customer_address: 'customer_address',
} as const;

export interface DeliveryRouteCustomerStopSlimDumpSchema {
  customer: LinkIDNameSchema;
  id: string;
  location: DeliveryLocationCustomerLocationDumpSchema;
  position: number;
  status: DeliveryRouteStopStatus;
  type: DeliveryRouteCustomerStopSlimDumpSchemaType;
}

export type DeliveryRouteBranchStopSlimDumpSchemaType = typeof DeliveryRouteBranchStopSlimDumpSchemaType[keyof typeof DeliveryRouteBranchStopSlimDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteBranchStopSlimDumpSchemaType = {
  location: 'location',
} as const;

export interface DeliveryRouteBranchStopSlimDumpSchema {
  id: string;
  location: DeliveryLocationBranchDumpSchema;
  position: number;
  status: DeliveryRouteStopStatus;
  type: DeliveryRouteBranchStopSlimDumpSchemaType;
}

/**
 * @nullable
 */
export type DeliveryRouteSearchParamsStart = number | null;

/**
 * @nullable
 */
export type DeliveryRouteSearchParamsSearch = string | null;

/**
 * @nullable
 */
export type DeliveryRouteSearchParamsPageSize = number | null;

/**
 * @nullable
 */
export type DeliveryRouteSearchParamsLocationId = string | null;

/**
 * @nullable
 */
export type DeliveryRouteSearchParamsDeliveryDate = string | null;

export interface DeliveryRouteSearchParams {
  /** @nullable */
  delivery_date?: DeliveryRouteSearchParamsDeliveryDate;
  /** @nullable */
  location_id?: DeliveryRouteSearchParamsLocationId;
  /** @nullable */
  page_size?: DeliveryRouteSearchParamsPageSize;
  /** @nullable */
  search?: DeliveryRouteSearchParamsSearch;
  /** @nullable */
  start?: DeliveryRouteSearchParamsStart;
}

export interface StopResponse {
  arrival_time: string;
  stop_id: string;
}

export interface RouteResponse {
  distance_miles: string;
  duration_minutes: string;
  geometry: string;
  stops: StopResponse[];
}

export type OptimizationType = typeof OptimizationType[keyof typeof OptimizationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OptimizationType = {
  no_optimization: 'no_optimization',
  optimize: 'optimize',
} as const;

export interface RouteBody {
  optimization: OptimizationType;
}

/**
 * @nullable
 */
export type DeliveryRouteInsertSchemaInsertIndex = number | null;

export type DeliveryRouteStopInsertSchemaType = typeof DeliveryRouteStopInsertSchemaType[keyof typeof DeliveryRouteStopInsertSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteStopInsertSchemaType = {
  customer_address: 'customer_address',
} as const;

export interface DeliveryRouteStopInsertSchema {
  delivery_request_id: string;
  type: DeliveryRouteStopInsertSchemaType;
}

export interface DeliveryRouteInsertSchema {
  /** @nullable */
  insert_index?: DeliveryRouteInsertSchemaInsertIndex;
  stops: DeliveryRouteStopInsertSchema[];
}

/**
 * @nullable
 */
export type DeliveryRouteUpdateSchemaTruckId = string | null;

/**
 * @nullable
 */
export type DeliveryRouteUpdateSchemaRouteStartsAt = string | null;

/**
 * @nullable
 */
export type DeliveryRouteUpdateSchemaDriverId = string | null;

export interface DeliveryRouteUpdateSchema {
  delivery_date: string;
  /** @nullable */
  driver_id?: DeliveryRouteUpdateSchemaDriverId;
  name: string;
  /** @nullable */
  route_starts_at?: DeliveryRouteUpdateSchemaRouteStartsAt;
  stops: string[];
  /** @nullable */
  truck_id?: DeliveryRouteUpdateSchemaTruckId;
}

export type DeliveryRouteStopDumpSchemaWrappedStop = DeliveryRouteBranchStopDumpSchema | DeliveryRouteCustomerStopDumpSchema;

export interface DeliveryRouteStopDumpSchemaWrapped {
  stop: DeliveryRouteStopDumpSchemaWrappedStop;
}

export interface AdditionalItemCreateSchema {
  klass_id: string;
  quantity: number;
}

/**
 * @nullable
 */
export type DeliveryStopUpdateSchemaAdditionalItems = AdditionalItemCreateSchema[] | null;

export interface DeliveryStopUpdateSchema {
  /** @nullable */
  additional_items?: DeliveryStopUpdateSchemaAdditionalItems;
}

export type DeliveryRouteStopsDumpSchemaStopsItem = DeliveryRouteBranchStopDumpSchema | DeliveryRouteCustomerStopDumpSchema;

export interface DeliveryRouteStopsDumpSchema {
  stops: DeliveryRouteStopsDumpSchemaStopsItem[];
}

export interface DeliveryStopsUpdateStatusSchema {
  status: DeliveryRouteStopStatus;
  stops: string[];
}

export interface DeliveryRoutesDumpSchema {
  routes: DeliveryRouteDumpSchema[];
}

export interface DeliveryRoutesUpdateStatusSchema {
  routes: string[];
  status: DeliveryRouteStatus;
}

/**
 * @nullable
 */
export type DeliveryRouteCreateSchemaTruckId = string | null;

/**
 * @nullable
 */
export type DeliveryRouteCreateSchemaRouteStartsAt = string | null;

/**
 * @nullable
 */
export type DeliveryRouteCreateSchemaDriverId = string | null;

export interface DeliveryRouteCreateSchema {
  delivery_date: string;
  /** @nullable */
  driver_id?: DeliveryRouteCreateSchemaDriverId;
  location_id: string;
  name: string;
  /** @nullable */
  route_starts_at?: DeliveryRouteCreateSchemaRouteStartsAt;
  stops: DeliveryRouteOrderStopCreateSchema[];
  /** @nullable */
  truck_id?: DeliveryRouteCreateSchemaTruckId;
}

export type DeliveryRouteOrderStopCreateSchemaType = typeof DeliveryRouteOrderStopCreateSchemaType[keyof typeof DeliveryRouteOrderStopCreateSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteOrderStopCreateSchemaType = {
  customer_address: 'customer_address',
} as const;

export interface DeliveryRouteOrderStopCreateSchema {
  delivery_request_id: string;
  position: number;
  type: DeliveryRouteOrderStopCreateSchemaType;
}

/**
 * @nullable
 */
export type DeliveryRouteHydratedDumpSchemaTruck = DeliveryTruckDumpSchema | null;

export type DeliveryRouteHydratedDumpSchemaStopsItem = DeliveryRouteBranchStopDumpSchema | DeliveryRouteCustomerStopDumpSchema;

/**
 * @nullable
 */
export type DeliveryRouteHydratedDumpSchemaRouteStartsAt = string | null;

/**
 * @nullable
 */
export type DeliveryRouteHydratedDumpSchemaRouteStartedAt = string | null;

/**
 * @nullable
 */
export type DeliveryRouteHydratedDumpSchemaDriver = DeliveryDriverDumpSchema | null;

export interface DeliveryRouteHydratedDumpSchema {
  count_customer_stops: number;
  delivery_date: string;
  /** @nullable */
  driver?: DeliveryRouteHydratedDumpSchemaDriver;
  id: string;
  name: string;
  /** @nullable */
  route_started_at?: DeliveryRouteHydratedDumpSchemaRouteStartedAt;
  /** @nullable */
  route_starts_at?: DeliveryRouteHydratedDumpSchemaRouteStartsAt;
  status: DeliveryRouteStatus;
  stops: DeliveryRouteHydratedDumpSchemaStopsItem[];
  /** @nullable */
  truck?: DeliveryRouteHydratedDumpSchemaTruck;
}

export type TrackingType = typeof TrackingType[keyof typeof TrackingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TrackingType = {
  serialized: 'serialized',
  bulk: 'bulk',
} as const;

export interface LoadRequestKlassSchema {
  id: string;
  identifier: string;
  name: string;
  tracking: TrackingType;
  type: KlassType;
}

/**
 * @nullable
 */
export type LoadRequestHydratedDumpSchemaSkippedAt = string | null;

/**
 * @nullable
 */
export type LoadRequestHydratedDumpSchemaPickupCompletedAt = string | null;

/**
 * @nullable
 */
export type LoadRequestHydratedDumpSchemaDropoffCompletedAt = string | null;

/**
 * @nullable
 */
export type LoadRequestHydratedDumpSchemaCustomFormResponse = CustomFormResponseSlimDumpSchema | null;

/**
 * @nullable
 */
export type LoadRequestHydratedDumpSchemaAsset = LinkIDSchema | null;

export interface LoadRequestHydratedDumpSchema {
  /** @nullable */
  asset?: LoadRequestHydratedDumpSchemaAsset;
  /** @nullable */
  custom_form_response?: LoadRequestHydratedDumpSchemaCustomFormResponse;
  delivery_request_id: string;
  /** @nullable */
  dropoff_completed_at?: LoadRequestHydratedDumpSchemaDropoffCompletedAt;
  grouping_key: string;
  id: string;
  klass: LoadRequestKlassSchema;
  line_item_id: string;
  /** @nullable */
  pickup_completed_at?: LoadRequestHydratedDumpSchemaPickupCompletedAt;
  quantity: number;
  quantity_dropped_off: number;
  quantity_picked_up: number;
  quantity_skipped: number;
  /** @nullable */
  skipped_at?: LoadRequestHydratedDumpSchemaSkippedAt;
}

export type DeliveryRouteStopStatus = typeof DeliveryRouteStopStatus[keyof typeof DeliveryRouteStopStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteStopStatus = {
  scheduled: 'scheduled',
  arrived: 'arrived',
  completed: 'completed',
  cancelled: 'cancelled',
} as const;

export interface DeliveryRouteCustomerStopOrderDumpSchema {
  activity_log_id: string;
  id: string;
  identifier: string;
}

export type DeliveryRouteCustomerStopDumpSchemaType = typeof DeliveryRouteCustomerStopDumpSchemaType[keyof typeof DeliveryRouteCustomerStopDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteCustomerStopDumpSchemaType = {
  customer_address: 'customer_address',
} as const;

/**
 * @nullable
 */
export type DeliveryRouteCustomerStopDumpSchemaTargetWindowStart = string | null;

/**
 * @nullable
 */
export type DeliveryRouteCustomerStopDumpSchemaTargetWindowEnd = string | null;

/**
 * @nullable
 */
export type DeliveryRouteCustomerStopDumpSchemaDepartedAt = string | null;

/**
 * @nullable
 */
export type DeliveryRouteCustomerStopDumpSchemaCustomFormCustomerResponse = CustomFormResponseSlimDumpSchema | null;

/**
 * @nullable
 */
export type DeliveryRouteCustomerStopDumpSchemaCustomFormBusinessResponses = CustomFormConfigurationResponse[] | null;

/**
 * @nullable
 */
export type DeliveryRouteCustomerStopDumpSchemaArrivedAt = string | null;

export interface DeliveryRouteCustomerStopDumpSchema {
  additional_items: AdditionalItemDumpSchema[];
  /** @nullable */
  arrived_at?: DeliveryRouteCustomerStopDumpSchemaArrivedAt;
  /** @nullable */
  custom_form_business_responses?: DeliveryRouteCustomerStopDumpSchemaCustomFormBusinessResponses;
  /** @nullable */
  custom_form_customer_response?: DeliveryRouteCustomerStopDumpSchemaCustomFormCustomerResponse;
  customer: LinkIDNameSchema;
  /** @nullable */
  departed_at?: DeliveryRouteCustomerStopDumpSchemaDepartedAt;
  dropoff_load_requests: LoadRequestHydratedDumpSchema[];
  id: string;
  location: DeliveryLocationCustomerLocationDumpSchema;
  order: DeliveryRouteCustomerStopOrderDumpSchema;
  pickup_load_requests: LoadRequestHydratedDumpSchema[];
  position: number;
  status: DeliveryRouteStopStatus;
  /** @nullable */
  target_window_end?: DeliveryRouteCustomerStopDumpSchemaTargetWindowEnd;
  /** @nullable */
  target_window_start?: DeliveryRouteCustomerStopDumpSchemaTargetWindowStart;
  type: DeliveryRouteCustomerStopDumpSchemaType;
}

export type DeliveryRouteBranchStopDumpSchemaType = typeof DeliveryRouteBranchStopDumpSchemaType[keyof typeof DeliveryRouteBranchStopDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteBranchStopDumpSchemaType = {
  location: 'location',
} as const;

/**
 * @nullable
 */
export type DeliveryRouteBranchStopDumpSchemaTargetWindowStart = string | null;

/**
 * @nullable
 */
export type DeliveryRouteBranchStopDumpSchemaTargetWindowEnd = string | null;

/**
 * @nullable
 */
export type DeliveryRouteBranchStopDumpSchemaDepartedAt = string | null;

/**
 * @nullable
 */
export type DeliveryRouteBranchStopDumpSchemaCustomFormBusinessResponses = CustomFormConfigurationResponse[] | null;

/**
 * @nullable
 */
export type DeliveryRouteBranchStopDumpSchemaArrivedAt = string | null;

export interface DeliveryRouteBranchStopDumpSchema {
  /** @nullable */
  arrived_at?: DeliveryRouteBranchStopDumpSchemaArrivedAt;
  /** @nullable */
  custom_form_business_responses?: DeliveryRouteBranchStopDumpSchemaCustomFormBusinessResponses;
  /** @nullable */
  custom_form_customer_response?: DeliveryRouteBranchStopDumpSchemaCustomFormCustomerResponse;
  /** @nullable */
  departed_at?: DeliveryRouteBranchStopDumpSchemaDepartedAt;
  dropoff_load_requests: LoadRequestHydratedDumpSchema[];
  id: string;
  location: DeliveryLocationBranchDumpSchema;
  pickup_load_requests: LoadRequestHydratedDumpSchema[];
  position: number;
  status: DeliveryRouteStopStatus;
  /** @nullable */
  target_window_end?: DeliveryRouteBranchStopDumpSchemaTargetWindowEnd;
  /** @nullable */
  target_window_start?: DeliveryRouteBranchStopDumpSchemaTargetWindowStart;
  type: DeliveryRouteBranchStopDumpSchemaType;
}

export type DeliveryLocationCustomerLocationDumpSchemaType = typeof DeliveryLocationCustomerLocationDumpSchemaType[keyof typeof DeliveryLocationCustomerLocationDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryLocationCustomerLocationDumpSchemaType = {
  customer_location: 'customer_location',
} as const;

export interface DeliveryLocationCustomerLocationDumpSchema {
  delivery_address: DeliveryAddressDumpSchema;
  delivery_address_id: string;
  type: DeliveryLocationCustomerLocationDumpSchemaType;
}

export type DeliveryLocationBranchDumpSchemaType = typeof DeliveryLocationBranchDumpSchemaType[keyof typeof DeliveryLocationBranchDumpSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryLocationBranchDumpSchemaType = {
  branch: 'branch',
} as const;

export interface DeliveryLocationBranchDumpSchema {
  location: LocationDumpSchema;
  location_id: string;
  type: DeliveryLocationBranchDumpSchemaType;
}

export interface CustomFormResponseSlimDumpSchema {
  id: string;
  status: CustomFormResponseStatus;
}

/**
 * @nullable
 */
export type DeliveryRouteBranchStopDumpSchemaCustomFormCustomerResponse = CustomFormResponseSlimDumpSchema | null;

/**
 * @nullable
 */
export type CustomFormConfigurationResponseCustomFormResponse = CustomFormResponseSlimDumpSchema | null;

export interface CustomFormConfigurationResponse {
  action: CustomFormActionConfigurationType;
  /** @nullable */
  custom_form_response?: CustomFormConfigurationResponseCustomFormResponse;
}

export interface AdditionalItemDumpSchema {
  klass: LinkIDNameSchema;
  klass_id: string;
  quantity: number;
}

/**
 * @nullable
 */
export type PaginatedDeliveryRouteSchemaNext = number | null;

export interface PaginatedDeliveryRouteSchema {
  elements: DeliveryRouteDumpSchema[];
  /** @nullable */
  next?: PaginatedDeliveryRouteSchemaNext;
  total_count: number;
}

export interface DeliveryTruckDumpSchema {
  id: string;
  name: string;
}

export type DeliveryRouteStatus = typeof DeliveryRouteStatus[keyof typeof DeliveryRouteStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryRouteStatus = {
  draft: 'draft',
  scheduled: 'scheduled',
  driver_notified: 'driver_notified',
  en_route: 'en_route',
  completed: 'completed',
  cancelled: 'cancelled',
} as const;

/**
 * @nullable
 */
export type DeliveryRouteDumpSchemaTruck = DeliveryTruckDumpSchema | null;

/**
 * @nullable
 */
export type DeliveryRouteDumpSchemaRouteStartsAt = string | null;

/**
 * @nullable
 */
export type DeliveryRouteDumpSchemaRouteStartedAt = string | null;

export interface DeliveryDriverDumpSchema {
  id: string;
  user: UserDumpSchema;
}

/**
 * @nullable
 */
export type DeliveryRouteDumpSchemaDriver = DeliveryDriverDumpSchema | null;

export interface DeliveryRouteDumpSchema {
  count_customer_stops: number;
  delivery_date: string;
  /** @nullable */
  driver?: DeliveryRouteDumpSchemaDriver;
  id: string;
  name: string;
  /** @nullable */
  route_started_at?: DeliveryRouteDumpSchemaRouteStartedAt;
  /** @nullable */
  route_starts_at?: DeliveryRouteDumpSchemaRouteStartsAt;
  status: DeliveryRouteStatus;
  /** @nullable */
  truck?: DeliveryRouteDumpSchemaTruck;
}

/**
 * @nullable
 */
export type DeliveryConfigurationUpdateSchemaSecondaryRate = string | null;

/**
 * @nullable
 */
export type DeliveryConfigurationUpdateSchemaMinimum = string | null;

/**
 * @nullable
 */
export type DeliveryConfigurationUpdateSchemaDeliveryRevenueAccountId = string | null;

export interface DeliveryConfigurationUpdateSchema {
  /** @nullable */
  delivery_revenue_account_id?: DeliveryConfigurationUpdateSchemaDeliveryRevenueAccountId;
  is_business_default: boolean;
  /** @nullable */
  minimum?: DeliveryConfigurationUpdateSchemaMinimum;
  name: string;
  product_tax_code_group_id: string;
  rate: string;
  /** @nullable */
  secondary_rate?: DeliveryConfigurationUpdateSchemaSecondaryRate;
  split_delivery_across_first_and_last_invoice: boolean;
  type: DeliveryTypeEnum;
}

/**
 * @nullable
 */
export type DeliveryConfigurationCreateSchemaSecondaryRate = string | null;

/**
 * @nullable
 */
export type DeliveryConfigurationCreateSchemaMinimum = string | null;

/**
 * @nullable
 */
export type DeliveryConfigurationCreateSchemaDeliveryRevenueAccountId = string | null;

export interface DeliveryConfigurationCreateSchema {
  /** @nullable */
  delivery_revenue_account_id?: DeliveryConfigurationCreateSchemaDeliveryRevenueAccountId;
  is_business_default: boolean;
  /** @nullable */
  minimum?: DeliveryConfigurationCreateSchemaMinimum;
  name: string;
  product_tax_code_group_id: string;
  rate: string;
  /** @nullable */
  secondary_rate?: DeliveryConfigurationCreateSchemaSecondaryRate;
  split_delivery_across_first_and_last_invoice: boolean;
  type: DeliveryTypeEnum;
}

/**
 * @nullable
 */
export type PaginatedDeliveryConfigurationsDumpSchemaNext = number | null;

export interface PaginatedDeliveryConfigurationsDumpSchema {
  elements: DeliveryConfigurationDumpSchema[];
  /** @nullable */
  next?: PaginatedDeliveryConfigurationsDumpSchemaNext;
  total_count: number;
}

export type DeliveryTypeEnum = typeof DeliveryTypeEnum[keyof typeof DeliveryTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryTypeEnum = {
  flat: 'flat',
  per_mile: 'per_mile',
  per_mile_and_per_minute: 'per_mile_and_per_minute',
} as const;

/**
 * @nullable
 */
export type DeliveryConfigurationDumpSchemaSecondaryRate = string | null;

/**
 * @nullable
 */
export type DeliveryConfigurationDumpSchemaMinimum = string | null;

/**
 * @nullable
 */
export type DeliveryConfigurationDumpSchemaDeliveryRevenueAccount = LedgerAccountDumpSchema | null;

export interface DeliveryConfigurationDumpSchema {
  /** @nullable */
  delivery_revenue_account?: DeliveryConfigurationDumpSchemaDeliveryRevenueAccount;
  id: string;
  is_business_default: boolean;
  /** @nullable */
  minimum?: DeliveryConfigurationDumpSchemaMinimum;
  name: string;
  product_tax_code_group_id: string;
  rate: string;
  /** @nullable */
  secondary_rate?: DeliveryConfigurationDumpSchemaSecondaryRate;
  split_delivery_across_first_and_last_invoice: boolean;
  type: DeliveryTypeEnum;
}

export interface CustomFormResponseUpdateSchema {
  questions: QuestionResponseUpdateSchema[];
  status: CustomFormResponseStatus;
}

/**
 * @nullable
 */
export type QuestionResponseUpdateSchemaTextResponse = string | null;

/**
 * @nullable
 */
export type QuestionResponseUpdateSchemaSignatureResponse = string | null;

/**
 * @nullable
 */
export type QuestionResponseUpdateSchemaResponseChoices = string[] | null;

/**
 * @nullable
 */
export type QuestionResponseUpdateSchemaNumberResponse = string | null;

/**
 * @nullable
 */
export type QuestionResponseUpdateSchemaImageResponses = string[] | null;

/**
 * @nullable
 */
export type QuestionResponseUpdateSchemaId = string | null;

/**
 * @nullable
 */
export type QuestionResponseUpdateSchemaFileResponses = string[] | null;

/**
 * @nullable
 */
export type QuestionResponseUpdateSchemaDateResponse = string | null;

/**
 * @nullable
 */
export type QuestionResponseUpdateSchemaChildFormResponseId = string | null;

/**
 * @nullable
 */
export type QuestionResponseUpdateSchemaBooleanResponse = boolean | null;

export interface QuestionResponseUpdateSchema {
  /** @nullable */
  boolean_response?: QuestionResponseUpdateSchemaBooleanResponse;
  /** @nullable */
  child_form_response_id?: QuestionResponseUpdateSchemaChildFormResponseId;
  completed?: boolean;
  /** @nullable */
  date_response?: QuestionResponseUpdateSchemaDateResponse;
  /** @nullable */
  file_responses?: QuestionResponseUpdateSchemaFileResponses;
  form_response_id: string;
  /** @nullable */
  id?: QuestionResponseUpdateSchemaId;
  /** @nullable */
  image_responses?: QuestionResponseUpdateSchemaImageResponses;
  /** @nullable */
  number_response?: QuestionResponseUpdateSchemaNumberResponse;
  question_id: string;
  /** @nullable */
  response_choices?: QuestionResponseUpdateSchemaResponseChoices;
  /** @nullable */
  signature_response?: QuestionResponseUpdateSchemaSignatureResponse;
  /** @nullable */
  text_response?: QuestionResponseUpdateSchemaTextResponse;
}

export type CustomFormActionConfigurationType = typeof CustomFormActionConfigurationType[keyof typeof CustomFormActionConfigurationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomFormActionConfigurationType = {
  begin_dropoff: 'begin_dropoff',
  end_dropoff: 'end_dropoff',
  begin_pickup: 'begin_pickup',
  end_pickup: 'end_pickup',
} as const;

export interface CustomFormConfigurationCreateSchema {
  action: CustomFormActionConfigurationType;
  custom_form_id: string;
}

export interface CustomFormConfigurationsUpdateSchema {
  configurations: CustomFormConfigurationCreateSchema[];
}

export interface CustomFormConfigurationDumpSchema {
  action: CustomFormActionConfigurationType;
  custom_form: LinkNameSchema;
}

export interface CustomFormConfigurationsDumpSchema {
  configurations: CustomFormConfigurationDumpSchema[];
}

export interface QuestionResponseDumpSchema {
  /** @nullable */
  boolean_response?: QuestionResponseDumpSchemaBooleanResponse;
  /** @nullable */
  child_form_response?: QuestionResponseDumpSchemaChildFormResponse;
  /** @nullable */
  completed_by_user?: QuestionResponseDumpSchemaCompletedByUser;
  created_at: string;
  /** @nullable */
  date_response?: QuestionResponseDumpSchemaDateResponse;
  /** @nullable */
  file_responses?: QuestionResponseDumpSchemaFileResponses;
  form_response_id: string;
  id: string;
  /** @nullable */
  image_responses?: QuestionResponseDumpSchemaImageResponses;
  /** @nullable */
  number_response?: QuestionResponseDumpSchemaNumberResponse;
  question_id: string;
  /** @nullable */
  response_choices?: QuestionResponseDumpSchemaResponseChoices;
  /** @nullable */
  signature_response?: QuestionResponseDumpSchemaSignatureResponse;
  /** @nullable */
  text_response?: QuestionResponseDumpSchemaTextResponse;
}

export interface CustomFormResponseDumpSchema {
  created_at: string;
  custom_form: CustomFormDumpSchema;
  custom_form_id: string;
  id: string;
  question_responses: QuestionResponseDumpSchema[];
  status: CustomFormResponseStatus;
}

/**
 * @nullable
 */
export type QuestionResponseDumpSchemaTextResponse = string | null;

/**
 * @nullable
 */
export type QuestionResponseDumpSchemaSignatureResponse = CustomerFileDumpSchema | null;

/**
 * @nullable
 */
export type QuestionResponseDumpSchemaResponseChoices = AnswerChoiceDumpSchema[] | null;

/**
 * @nullable
 */
export type QuestionResponseDumpSchemaNumberResponse = string | null;

/**
 * @nullable
 */
export type QuestionResponseDumpSchemaImageResponses = CustomerFileDumpSchema[] | null;

/**
 * @nullable
 */
export type QuestionResponseDumpSchemaFileResponses = CustomerFileDumpSchema[] | null;

/**
 * @nullable
 */
export type QuestionResponseDumpSchemaDateResponse = string | null;

/**
 * @nullable
 */
export type QuestionResponseDumpSchemaCompletedByUser = LinkNameSchema | null;

/**
 * @nullable
 */
export type QuestionResponseDumpSchemaChildFormResponse = LinkNameSchema | null;

/**
 * @nullable
 */
export type QuestionResponseDumpSchemaBooleanResponse = boolean | null;

export interface CustomFormResponseCreateSchema {
  questions: QuestionResponseCreateSchema[];
  status: CustomFormResponseStatus;
}

/**
 * @nullable
 */
export type QuestionResponseCreateSchemaTextResponse = string | null;

/**
 * @nullable
 */
export type QuestionResponseCreateSchemaSignatureResponse = string | null;

/**
 * @nullable
 */
export type QuestionResponseCreateSchemaResponseChoices = string[] | null;

/**
 * @nullable
 */
export type QuestionResponseCreateSchemaNumberResponse = string | null;

/**
 * @nullable
 */
export type QuestionResponseCreateSchemaImageResponses = string[] | null;

/**
 * @nullable
 */
export type QuestionResponseCreateSchemaFileResponses = string[] | null;

/**
 * @nullable
 */
export type QuestionResponseCreateSchemaDateResponse = string | null;

/**
 * @nullable
 */
export type QuestionResponseCreateSchemaChildFormResponseId = string | null;

/**
 * @nullable
 */
export type QuestionResponseCreateSchemaBooleanResponse = boolean | null;

export interface QuestionResponseCreateSchema {
  /** @nullable */
  boolean_response?: QuestionResponseCreateSchemaBooleanResponse;
  /** @nullable */
  child_form_response_id?: QuestionResponseCreateSchemaChildFormResponseId;
  completed?: boolean;
  /** @nullable */
  date_response?: QuestionResponseCreateSchemaDateResponse;
  /** @nullable */
  file_responses?: QuestionResponseCreateSchemaFileResponses;
  form_response_id: string;
  /** @nullable */
  image_responses?: QuestionResponseCreateSchemaImageResponses;
  /** @nullable */
  number_response?: QuestionResponseCreateSchemaNumberResponse;
  question_id: string;
  /** @nullable */
  response_choices?: QuestionResponseCreateSchemaResponseChoices;
  /** @nullable */
  signature_response?: QuestionResponseCreateSchemaSignatureResponse;
  /** @nullable */
  text_response?: QuestionResponseCreateSchemaTextResponse;
}

export type CustomFormResponseStatus = typeof CustomFormResponseStatus[keyof typeof CustomFormResponseStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomFormResponseStatus = {
  created: 'created',
  started: 'started',
  submitted: 'submitted',
  locked: 'locked',
} as const;

export interface CustomFormUpdateSchema {
  label: string;
  questions: QuestionUpdateSchema[];
  type: CustomFormType;
  usage_type: CustomFormUsageType;
}

/**
 * @nullable
 */
export type QuestionUpdateSchemaId = string | null;

/**
 * @nullable
 */
export type QuestionUpdateSchemaDescription = string | null;

/**
 * @nullable
 */
export type QuestionUpdateSchemaChildFormId = string | null;

export interface QuestionUpdateSchema {
  /** @nullable */
  answer_choices?: QuestionUpdateSchemaAnswerChoices;
  /** @nullable */
  child_form_id?: QuestionUpdateSchemaChildFormId;
  /** @nullable */
  description?: QuestionUpdateSchemaDescription;
  /** @nullable */
  id?: QuestionUpdateSchemaId;
  is_editable: boolean;
  is_required: boolean;
  is_visible_to_customer: boolean;
  label: string;
  position: number;
  type: QuestionType;
}

/**
 * @nullable
 */
export type AnswerChoiceUpdateSchemaId = string | null;

/**
 * @nullable
 */
export type AnswerChoiceUpdateSchemaDescription = string | null;

export interface AnswerChoiceUpdateSchema {
  /** @nullable */
  description?: AnswerChoiceUpdateSchemaDescription;
  /** @nullable */
  id?: AnswerChoiceUpdateSchemaId;
  label: string;
}

/**
 * @nullable
 */
export type QuestionUpdateSchemaAnswerChoices = AnswerChoiceUpdateSchema[] | null;

export interface CustomFormDumpSchema {
  created_at: string;
  id: string;
  label: string;
  questions: QuestionDumpSchema[];
  type: CustomFormType;
  usage_type: CustomFormUsageType;
}

/**
 * @nullable
 */
export type QuestionDumpSchemaDescription = string | null;

/**
 * @nullable
 */
export type QuestionDumpSchemaChildForm = LinkNameSchema | null;

export interface QuestionDumpSchema {
  /** @nullable */
  answer_choices?: QuestionDumpSchemaAnswerChoices;
  /** @nullable */
  child_form?: QuestionDumpSchemaChildForm;
  /** @nullable */
  description?: QuestionDumpSchemaDescription;
  id: string;
  is_editable: boolean;
  is_required: boolean;
  is_visible_to_customer: boolean;
  label: string;
  position: number;
  type: QuestionType;
}

/**
 * @nullable
 */
export type AnswerChoiceDumpSchemaDescription = string | null;

export interface AnswerChoiceDumpSchema {
  /** @nullable */
  description?: AnswerChoiceDumpSchemaDescription;
  id: string;
  label: string;
}

/**
 * @nullable
 */
export type QuestionDumpSchemaAnswerChoices = AnswerChoiceDumpSchema[] | null;

export interface CustomFormCreateSchema {
  label: string;
  questions: QuestionCreateSchema[];
  type: CustomFormType;
  usage_type: CustomFormUsageType;
}

export type QuestionType = typeof QuestionType[keyof typeof QuestionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionType = {
  short_text: 'short_text',
  long_text: 'long_text',
  number: 'number',
  checkbox: 'checkbox',
  file_upload: 'file_upload',
  image_upload: 'image_upload',
  signature: 'signature',
  single_select: 'single_select',
  multi_select: 'multi_select',
  date_select: 'date_select',
  child_form: 'child_form',
} as const;

/**
 * @nullable
 */
export type QuestionCreateSchemaDescription = string | null;

/**
 * @nullable
 */
export type QuestionCreateSchemaChildFormId = string | null;

export interface QuestionCreateSchema {
  /** @nullable */
  answer_choices?: QuestionCreateSchemaAnswerChoices;
  /** @nullable */
  child_form_id?: QuestionCreateSchemaChildFormId;
  /** @nullable */
  description?: QuestionCreateSchemaDescription;
  is_editable: boolean;
  is_required: boolean;
  is_visible_to_customer: boolean;
  label: string;
  position: number;
  type: QuestionType;
}

/**
 * @nullable
 */
export type AnswerChoiceCreateSchemaDescription = string | null;

export interface AnswerChoiceCreateSchema {
  /** @nullable */
  description?: AnswerChoiceCreateSchemaDescription;
  label: string;
}

/**
 * @nullable
 */
export type QuestionCreateSchemaAnswerChoices = AnswerChoiceCreateSchema[] | null;

/**
 * @nullable
 */
export type PaginatedCustomFormSchemaNext = number | null;

export type CustomFormUsageType = typeof CustomFormUsageType[keyof typeof CustomFormUsageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomFormUsageType = {
  delivery: 'delivery',
  repair: 'repair',
} as const;

export type CustomFormType = typeof CustomFormType[keyof typeof CustomFormType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomFormType = {
  regular: 'regular',
  checklist: 'checklist',
} as const;

export interface CustomFormBaseDumpSchema {
  created_at: string;
  id: string;
  label: string;
  type: CustomFormType;
  usage_type: CustomFormUsageType;
}

export interface PaginatedCustomFormSchema {
  elements: CustomFormBaseDumpSchema[];
  /** @nullable */
  next?: PaginatedCustomFormSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type PaginatedDeliveryAddressesSchemaNext = number | null;

export interface PaginatedDeliveryAddressesSchema {
  elements: DeliveryAddressDumpSchema[];
  /** @nullable */
  next?: PaginatedDeliveryAddressesSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type CustomerUpdateSchemaStatementDate = number | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaRemoteObjectId = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaParentCustomerId = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaLeadSource = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaDisplayName = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaDefaultSalesRepId = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaCustomFormId = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaCreditLimit = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaCoiPolicyholderName = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaCoiPolicyNumber = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaCoiIssuedDate = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaCoiIssuedBy = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaCoiExpirationDate = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaCoiAmount = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaBillingPocName = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaBillingPhone = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaBillingEmail = string | null;

/**
 * @nullable
 */
export type CustomerUpdateSchemaBillingAddress = AddressCreateSchema | null;

/**
 * @nullable
 */
export type DeliveryAddressUpdateSchemaPointOfContactPhone = string | null;

/**
 * @nullable
 */
export type DeliveryAddressUpdateSchemaPointOfContactEmail = string | null;

/**
 * @nullable
 */
export type DeliveryAddressUpdateSchemaNotes = string | null;

/**
 * @nullable
 */
export type DeliveryAddressUpdateSchemaId = string | null;

export interface DeliveryAddressUpdateSchema {
  address: AddressCreateSchema;
  /** @nullable */
  id?: DeliveryAddressUpdateSchemaId;
  /** @nullable */
  notes?: DeliveryAddressUpdateSchemaNotes;
  /** @nullable */
  point_of_contact_email?: DeliveryAddressUpdateSchemaPointOfContactEmail;
  point_of_contact_name: string;
  /** @nullable */
  point_of_contact_phone?: DeliveryAddressUpdateSchemaPointOfContactPhone;
}

export interface CustomerUpdateSchema {
  automatic_statements: boolean;
  /** @nullable */
  billing_address?: CustomerUpdateSchemaBillingAddress;
  /** @nullable */
  billing_email?: CustomerUpdateSchemaBillingEmail;
  /** @nullable */
  billing_phone?: CustomerUpdateSchemaBillingPhone;
  /** @nullable */
  billing_poc_name?: CustomerUpdateSchemaBillingPocName;
  /** @nullable */
  billing_profile_id?: CustomerUpdateSchemaBillingProfileId;
  /** @nullable */
  coi_amount?: CustomerUpdateSchemaCoiAmount;
  /** @nullable */
  coi_expiration_date?: CustomerUpdateSchemaCoiExpirationDate;
  /** @nullable */
  coi_issued_by?: CustomerUpdateSchemaCoiIssuedBy;
  /** @nullable */
  coi_issued_date?: CustomerUpdateSchemaCoiIssuedDate;
  /** @nullable */
  coi_policy_number?: CustomerUpdateSchemaCoiPolicyNumber;
  /** @nullable */
  coi_policyholder_name?: CustomerUpdateSchemaCoiPolicyholderName;
  contacts: CustomerContactUpdateSchema[];
  /** @nullable */
  credit_limit?: CustomerUpdateSchemaCreditLimit;
  /** @nullable */
  custom_form_id?: CustomerUpdateSchemaCustomFormId;
  /** @nullable */
  default_sales_rep_id?: CustomerUpdateSchemaDefaultSalesRepId;
  delivery_addresses: DeliveryAddressUpdateSchema[];
  /** @nullable */
  display_name?: CustomerUpdateSchemaDisplayName;
  has_coi: boolean;
  is_credit_check_complete: boolean;
  is_do_not_rent: boolean;
  is_tax_exempt: boolean;
  /** @nullable */
  lead_source?: CustomerUpdateSchemaLeadSource;
  modifiers: ExpenseClassAssociationCreateSchema[];
  name: string;
  /** @nullable */
  parent_customer_id?: CustomerUpdateSchemaParentCustomerId;
  pricings: KlassPricingCreateSchema[];
  /** @nullable */
  remote_object_id?: CustomerUpdateSchemaRemoteObjectId;
  /** @nullable */
  statement_date?: CustomerUpdateSchemaStatementDate;
  tags: string[];
  type: CustomerTypeEnum;
  uploads: string[];
}

/**
 * @nullable
 */
export type CustomerContactUpdateSchemaPictureFrontId = string | null;

/**
 * @nullable
 */
export type CustomerContactUpdateSchemaPictureBackId = string | null;

/**
 * @nullable
 */
export type CustomerContactUpdateSchemaPhone = string | null;

/**
 * @nullable
 */
export type CustomerContactUpdateSchemaLicenseNumber = string | null;

/**
 * @nullable
 */
export type CustomerContactUpdateSchemaId = string | null;

/**
 * @nullable
 */
export type CustomerContactUpdateSchemaEmail = string | null;

/**
 * @nullable
 */
export type CustomerContactUpdateSchemaDateOfBirth = string | null;

/**
 * @nullable
 */
export type CustomerContactUpdateSchemaAddress = AddressCreateSchema | null;

export interface CustomerContactUpdateSchema {
  /** @nullable */
  address?: CustomerContactUpdateSchemaAddress;
  /** @nullable */
  date_of_birth?: CustomerContactUpdateSchemaDateOfBirth;
  /** @nullable */
  email?: CustomerContactUpdateSchemaEmail;
  first_name: string;
  /** @nullable */
  id?: CustomerContactUpdateSchemaId;
  last_name: string;
  /** @nullable */
  license_number?: CustomerContactUpdateSchemaLicenseNumber;
  /** @nullable */
  phone?: CustomerContactUpdateSchemaPhone;
  /** @nullable */
  picture_back_id?: CustomerContactUpdateSchemaPictureBackId;
  /** @nullable */
  picture_front_id?: CustomerContactUpdateSchemaPictureFrontId;
}

/**
 * @nullable
 */
export type CustomerCreateSchemaStatementDate = number | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaRemoteObjectId = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaParentCustomerId = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaLeadSource = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaIdentifier = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaDisplayName = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaDefaultSalesRepId = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaCustomFormId = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaCreditLimit = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaCoiPolicyholderName = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaCoiPolicyNumber = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaCoiIssuedDate = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaCoiIssuedBy = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaCoiExpirationDate = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaCoiAmount = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaBillingPocName = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaBillingPhone = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaBillingEmail = string | null;

/**
 * @nullable
 */
export type CustomerCreateSchemaBillingAddress = AddressCreateSchema | null;

/**
 * @nullable
 */
export type KlassPricingCreateSchemaLocationId = string | null;

/**
 * @nullable
 */
export type KlassPricingCreateSchemaLineItemId = string | null;

/**
 * @nullable
 */
export type KlassPricingCreateSchemaKlassId = string | null;

/**
 * @nullable
 */
export type KlassPricingCreateSchemaCustomerId = string | null;

export interface KlassPricingCreateSchema {
  amount: string;
  /** @nullable */
  customer_id?: KlassPricingCreateSchemaCustomerId;
  frequency: KlassPricingFrequency;
  /** @nullable */
  klass_id?: KlassPricingCreateSchemaKlassId;
  /** @nullable */
  line_item_id?: KlassPricingCreateSchemaLineItemId;
  /** @nullable */
  location_id?: KlassPricingCreateSchemaLocationId;
  payer_type: OrderPayerType;
  type: KlassPricingType;
}

export type ExpenseRuleType = typeof ExpenseRuleType[keyof typeof ExpenseRuleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseRuleType = {
  require: 'require',
  optional: 'optional',
  exclude: 'exclude',
} as const;

export interface ExpenseClassAssociationCreateSchema {
  expense_class_id: string;
  expense_rule: BaseExpenseRuleSchema;
}

/**
 * @nullable
 */
export type DeliveryAddressCreateSchemaPointOfContactPhone = string | null;

/**
 * @nullable
 */
export type DeliveryAddressCreateSchemaPointOfContactEmail = string | null;

/**
 * @nullable
 */
export type DeliveryAddressCreateSchemaNotes = string | null;

export interface DeliveryAddressCreateSchema {
  address: AddressCreateSchema;
  /** @nullable */
  notes?: DeliveryAddressCreateSchemaNotes;
  /** @nullable */
  point_of_contact_email?: DeliveryAddressCreateSchemaPointOfContactEmail;
  point_of_contact_name: string;
  /** @nullable */
  point_of_contact_phone?: DeliveryAddressCreateSchemaPointOfContactPhone;
}

export interface CustomerCreateSchema {
  automatic_statements: boolean;
  /** @nullable */
  billing_address?: CustomerCreateSchemaBillingAddress;
  /** @nullable */
  billing_email?: CustomerCreateSchemaBillingEmail;
  /** @nullable */
  billing_phone?: CustomerCreateSchemaBillingPhone;
  /** @nullable */
  billing_poc_name?: CustomerCreateSchemaBillingPocName;
  /** @nullable */
  billing_profile_id?: CustomerCreateSchemaBillingProfileId;
  /** @nullable */
  coi_amount?: CustomerCreateSchemaCoiAmount;
  /** @nullable */
  coi_expiration_date?: CustomerCreateSchemaCoiExpirationDate;
  /** @nullable */
  coi_issued_by?: CustomerCreateSchemaCoiIssuedBy;
  /** @nullable */
  coi_issued_date?: CustomerCreateSchemaCoiIssuedDate;
  /** @nullable */
  coi_policy_number?: CustomerCreateSchemaCoiPolicyNumber;
  /** @nullable */
  coi_policyholder_name?: CustomerCreateSchemaCoiPolicyholderName;
  contacts: CustomerContactCreateSchema[];
  /** @nullable */
  credit_limit?: CustomerCreateSchemaCreditLimit;
  /** @nullable */
  custom_form_id?: CustomerCreateSchemaCustomFormId;
  /** @nullable */
  default_sales_rep_id?: CustomerCreateSchemaDefaultSalesRepId;
  delivery_addresses: DeliveryAddressCreateSchema[];
  /** @nullable */
  display_name?: CustomerCreateSchemaDisplayName;
  has_coi: boolean;
  /** @nullable */
  identifier?: CustomerCreateSchemaIdentifier;
  is_credit_check_complete: boolean;
  is_do_not_rent: boolean;
  is_tax_exempt: boolean;
  /** @nullable */
  lead_source?: CustomerCreateSchemaLeadSource;
  modifiers: ExpenseClassAssociationCreateSchema[];
  name: string;
  /** @nullable */
  parent_customer_id?: CustomerCreateSchemaParentCustomerId;
  pricings: KlassPricingCreateSchema[];
  /** @nullable */
  remote_object_id?: CustomerCreateSchemaRemoteObjectId;
  /** @nullable */
  statement_date?: CustomerCreateSchemaStatementDate;
  tags: string[];
  type: CustomerTypeEnum;
  uploads: string[];
}

/**
 * @nullable
 */
export type CustomerContactCreateSchemaPictureFrontId = string | null;

/**
 * @nullable
 */
export type CustomerContactCreateSchemaPictureBackId = string | null;

/**
 * @nullable
 */
export type CustomerContactCreateSchemaPhone = string | null;

/**
 * @nullable
 */
export type CustomerContactCreateSchemaLicenseNumber = string | null;

/**
 * @nullable
 */
export type CustomerContactCreateSchemaEmail = string | null;

/**
 * @nullable
 */
export type CustomerContactCreateSchemaDateOfBirth = string | null;

/**
 * @nullable
 */
export type CustomerContactCreateSchemaAddress = AddressCreateSchema | null;

export interface CustomerContactCreateSchema {
  /** @nullable */
  address?: CustomerContactCreateSchemaAddress;
  /** @nullable */
  date_of_birth?: CustomerContactCreateSchemaDateOfBirth;
  /** @nullable */
  email?: CustomerContactCreateSchemaEmail;
  first_name: string;
  last_name: string;
  /** @nullable */
  license_number?: CustomerContactCreateSchemaLicenseNumber;
  /** @nullable */
  phone?: CustomerContactCreateSchemaPhone;
  /** @nullable */
  picture_back_id?: CustomerContactCreateSchemaPictureBackId;
  /** @nullable */
  picture_front_id?: CustomerContactCreateSchemaPictureFrontId;
}

export interface BaseExpenseRuleSchema {
  is_editable: boolean;
  percentage: string;
  type: ExpenseRuleType;
}

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaStatementDate = number | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaRemoteObjectId = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaRemoteObject = RemoteObjectDumpSchema | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaParentCustomerId = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaParentCustomer = LinkIDNameSchema | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaLeadSource = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaDefaultSalesRepId = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaDefaultSalesRep = UserDumpSchema | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaCustomForm = LinkNameSchema | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaCreditLimit = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaCoiPolicyholderName = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaCoiPolicyNumber = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaCoiIssuedDate = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaCoiIssuedBy = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaCoiExpirationDate = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaCoiAmount = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaBillingProfile = BillingProfileDumpSchema | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaBillingPocName = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaBillingPhone = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaBillingEmail = string | null;

/**
 * @nullable
 */
export type CustomerHydratedDumpSchemaBillingAddress = AddressDumpSchema | null;

export interface CustomerHydratedDumpSchema {
  activity_log_id: string;
  automatic_statements: boolean;
  balance: string;
  /** @nullable */
  billing_address?: CustomerHydratedDumpSchemaBillingAddress;
  /** @nullable */
  billing_email?: CustomerHydratedDumpSchemaBillingEmail;
  /** @nullable */
  billing_phone?: CustomerHydratedDumpSchemaBillingPhone;
  /** @nullable */
  billing_poc_name?: CustomerHydratedDumpSchemaBillingPocName;
  /** @nullable */
  billing_profile?: CustomerHydratedDumpSchemaBillingProfile;
  /** @nullable */
  billing_profile_id?: CustomerHydratedDumpSchemaBillingProfileId;
  /** @nullable */
  coi_amount?: CustomerHydratedDumpSchemaCoiAmount;
  /** @nullable */
  coi_expiration_date?: CustomerHydratedDumpSchemaCoiExpirationDate;
  /** @nullable */
  coi_issued_by?: CustomerHydratedDumpSchemaCoiIssuedBy;
  /** @nullable */
  coi_issued_date?: CustomerHydratedDumpSchemaCoiIssuedDate;
  /** @nullable */
  coi_policy_number?: CustomerHydratedDumpSchemaCoiPolicyNumber;
  /** @nullable */
  coi_policyholder_name?: CustomerHydratedDumpSchemaCoiPolicyholderName;
  contacts: CustomerContactHydratedDumpSchema[];
  /** @nullable */
  credit_limit?: CustomerHydratedDumpSchemaCreditLimit;
  /** @nullable */
  custom_form?: CustomerHydratedDumpSchemaCustomForm;
  /** @nullable */
  default_sales_rep?: CustomerHydratedDumpSchemaDefaultSalesRep;
  /** @nullable */
  default_sales_rep_id?: CustomerHydratedDumpSchemaDefaultSalesRepId;
  delivery_addresses: DeliveryAddressDumpSchema[];
  has_coi: boolean;
  id: string;
  identifier: string;
  is_credit_check_complete: boolean;
  is_do_not_rent: boolean;
  is_tax_exempt: boolean;
  /** @nullable */
  lead_source?: CustomerHydratedDumpSchemaLeadSource;
  name: string;
  original_name: string;
  /** @nullable */
  parent_customer?: CustomerHydratedDumpSchemaParentCustomer;
  /** @nullable */
  parent_customer_id?: CustomerHydratedDumpSchemaParentCustomerId;
  payment_methods: PaymentMethodDumpSchema[];
  pricings: KlassPricingHydratedDumpSchema[];
  /** @nullable */
  remote_object?: CustomerHydratedDumpSchemaRemoteObject;
  /** @nullable */
  remote_object_id?: CustomerHydratedDumpSchemaRemoteObjectId;
  /** @nullable */
  statement_date?: CustomerHydratedDumpSchemaStatementDate;
  tags: string[];
  type: CustomerTypeEnum;
  uploads: CustomerFileDumpSchema[];
}

export type PaymentType = typeof PaymentType[keyof typeof PaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentType = {
  card: 'card',
  us_bank_account: 'us_bank_account',
} as const;

export type PaymentTerms = typeof PaymentTerms[keyof typeof PaymentTerms];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentTerms = {
  zero: 'zero',
  net_30: 'net_30',
  net_60: 'net_60',
  net_90: 'net_90',
  net_120: 'net_120',
} as const;

export type PaymentMethodProvider = typeof PaymentMethodProvider[keyof typeof PaymentMethodProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodProvider = {
  stripe: 'stripe',
} as const;

export type PaymentMethodDumpSchemaDetail = CardPaymentMethodDetailSchema | ACHPaymentMethodDetailSchema;

export interface PaymentMethodDumpSchema {
  detail: PaymentMethodDumpSchemaDetail;
  id: string;
  provider: PaymentMethodProvider;
  remote_id: string;
  type: PaymentType;
}

export type PaymentMethodCardDisplayBrand = typeof PaymentMethodCardDisplayBrand[keyof typeof PaymentMethodCardDisplayBrand];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodCardDisplayBrand = {
  american_express: 'american_express',
  cartes_bancaires: 'cartes_bancaires',
  diners_club: 'diners_club',
  discover: 'discover',
  eftpos_australia: 'eftpos_australia',
  interac: 'interac',
  jcb: 'jcb',
  mastercard: 'mastercard',
  union_pay: 'union_pay',
  visa: 'visa',
  other: 'other',
} as const;

export type PaymentMethodBankAccountType = typeof PaymentMethodBankAccountType[keyof typeof PaymentMethodBankAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodBankAccountType = {
  checking: 'checking',
  savings: 'savings',
} as const;

export type PaymentMethodBankAccountHolderType = typeof PaymentMethodBankAccountHolderType[keyof typeof PaymentMethodBankAccountHolderType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodBankAccountHolderType = {
  individual: 'individual',
  company: 'company',
} as const;

export type PaymentDue = typeof PaymentDue[keyof typeof PaymentDue];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentDue = {
  in_arrears: 'in_arrears',
  align_with_rates: 'align_with_rates',
} as const;

export type OrderPayerType = typeof OrderPayerType[keyof typeof OrderPayerType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderPayerType = {
  customer: 'customer',
  internal: 'internal',
} as const;

export type KlassPricingType = typeof KlassPricingType[keyof typeof KlassPricingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KlassPricingType = {
  fixed: 'fixed',
  markup: 'markup',
} as const;

/**
 * @nullable
 */
export type KlassPricingHydratedDumpSchemaLocationId = string | null;

/**
 * @nullable
 */
export type KlassPricingHydratedDumpSchemaLocation = LinkIDNameSchema | null;

/**
 * @nullable
 */
export type KlassPricingHydratedDumpSchemaLineItemId = string | null;

/**
 * @nullable
 */
export type KlassPricingHydratedDumpSchemaKlassId = string | null;

/**
 * @nullable
 */
export type KlassPricingHydratedDumpSchemaKlass = LinkIDNameSchema | null;

/**
 * @nullable
 */
export type KlassPricingHydratedDumpSchemaCustomerId = string | null;

export type KlassPricingFrequency = typeof KlassPricingFrequency[keyof typeof KlassPricingFrequency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KlassPricingFrequency = {
  sale__fixed: 'sale__fixed',
  sale__markup: 'sale__markup',
  minimum: 'minimum',
  hourly: 'hourly',
  two_hourly: 'two_hourly',
  four_hourly: 'four_hourly',
  daily: 'daily',
  two_daily: 'two_daily',
  weekly: 'weekly',
  twenty_eight_days: 'twenty_eight_days',
} as const;

export interface KlassPricingHydratedDumpSchema {
  amount: string;
  /** @nullable */
  customer_id?: KlassPricingHydratedDumpSchemaCustomerId;
  frequency: KlassPricingFrequency;
  id: string;
  /** @nullable */
  klass?: KlassPricingHydratedDumpSchemaKlass;
  /** @nullable */
  klass_id?: KlassPricingHydratedDumpSchemaKlassId;
  /** @nullable */
  line_item_id?: KlassPricingHydratedDumpSchemaLineItemId;
  /** @nullable */
  location?: KlassPricingHydratedDumpSchemaLocation;
  /** @nullable */
  location_id?: KlassPricingHydratedDumpSchemaLocationId;
  payer_type: OrderPayerType;
  type: KlassPricingType;
}

/**
 * @nullable
 */
export type DeliveryAddressDumpSchemaPointOfContactPhone = string | null;

/**
 * @nullable
 */
export type DeliveryAddressDumpSchemaPointOfContactEmail = string | null;

/**
 * @nullable
 */
export type DeliveryAddressDumpSchemaNotes = string | null;

export interface DeliveryAddressDumpSchema {
  address: AddressDumpSchema;
  id: string;
  /** @nullable */
  notes?: DeliveryAddressDumpSchemaNotes;
  /** @nullable */
  point_of_contact_email?: DeliveryAddressDumpSchemaPointOfContactEmail;
  point_of_contact_name: string;
  /** @nullable */
  point_of_contact_phone?: DeliveryAddressDumpSchemaPointOfContactPhone;
}

/**
 * @nullable
 */
export type CustomerContactHydratedDumpSchemaPictureFront = CustomerFileDumpSchema | null;

/**
 * @nullable
 */
export type CustomerContactHydratedDumpSchemaPictureBack = CustomerFileDumpSchema | null;

/**
 * @nullable
 */
export type CustomerContactHydratedDumpSchemaPhone = string | null;

/**
 * @nullable
 */
export type CustomerContactHydratedDumpSchemaLicenseNumber = string | null;

/**
 * @nullable
 */
export type CustomerContactHydratedDumpSchemaEmail = string | null;

/**
 * @nullable
 */
export type CustomerContactHydratedDumpSchemaDateOfBirth = string | null;

/**
 * @nullable
 */
export type CustomerContactHydratedDumpSchemaAddress = AddressDumpSchema | null;

export interface CustomerContactHydratedDumpSchema {
  /** @nullable */
  address?: CustomerContactHydratedDumpSchemaAddress;
  /** @nullable */
  date_of_birth?: CustomerContactHydratedDumpSchemaDateOfBirth;
  /** @nullable */
  email?: CustomerContactHydratedDumpSchemaEmail;
  first_name: string;
  full_name: string;
  id: string;
  last_name: string;
  /** @nullable */
  license_number?: CustomerContactHydratedDumpSchemaLicenseNumber;
  /** @nullable */
  phone?: CustomerContactHydratedDumpSchemaPhone;
  /** @nullable */
  picture_back?: CustomerContactHydratedDumpSchemaPictureBack;
  /** @nullable */
  picture_front?: CustomerContactHydratedDumpSchemaPictureFront;
}

export type CardPaymentMethodDetailSchemaType = typeof CardPaymentMethodDetailSchemaType[keyof typeof CardPaymentMethodDetailSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardPaymentMethodDetailSchemaType = {
  card: 'card',
} as const;

export interface CardPaymentMethodDetailSchema {
  card_brand: PaymentMethodCardDisplayBrand;
  country: string;
  exp_month: number;
  exp_year: number;
  last_four: string;
  type: CardPaymentMethodDetailSchemaType;
}

export type BillingProfileType = typeof BillingProfileType[keyof typeof BillingProfileType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingProfileType = {
  rental: 'rental',
  sale: 'sale',
} as const;

export type BillingCycle = typeof BillingCycle[keyof typeof BillingCycle];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingCycle = {
  entire: 'entire',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
} as const;

export interface BillingProfileDumpSchema {
  cycle: BillingCycle;
  due: PaymentDue;
  id: string;
  name: string;
  terms: PaymentTerms;
  type: BillingProfileType;
}

export type ACHPaymentMethodDetailSchemaType = typeof ACHPaymentMethodDetailSchemaType[keyof typeof ACHPaymentMethodDetailSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHPaymentMethodDetailSchemaType = {
  us_bank_account: 'us_bank_account',
} as const;

/**
 * @nullable
 */
export type ACHPaymentMethodDetailSchemaBlockedReason = string | null;

/**
 * @nullable
 */
export type ACHPaymentMethodDetailSchemaBlockedNetworkCode = string | null;

export interface ACHPaymentMethodDetailSchema {
  account_holder_type: PaymentMethodBankAccountHolderType;
  account_type: PaymentMethodBankAccountType;
  bank_name: string;
  /** @nullable */
  blocked_network_code?: ACHPaymentMethodDetailSchemaBlockedNetworkCode;
  /** @nullable */
  blocked_reason?: ACHPaymentMethodDetailSchemaBlockedReason;
  last_four: string;
  routing_number: string;
  type: ACHPaymentMethodDetailSchemaType;
}

/**
 * @nullable
 */
export type PaginatedCustomerWithContactSchemaNext = number | null;

export interface PaginatedCustomerWithContactSchema {
  elements: CustomerWithContactSchema[];
  /** @nullable */
  next?: PaginatedCustomerWithContactSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type CustomerWithContactSchemaStatementDate = number | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaRemoteObjectId = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaParentCustomerId = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaParentCustomer = LinkIDNameSchema | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaMatchedContact = CustomerContactBaseDumpSchema | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaLeadSource = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaDefaultSalesRepId = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaCreditLimit = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaCoiPolicyholderName = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaCoiPolicyNumber = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaCoiIssuedDate = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaCoiIssuedBy = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaCoiExpirationDate = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaCoiAmount = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaBillingPocName = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaBillingPhone = string | null;

/**
 * @nullable
 */
export type CustomerWithContactSchemaBillingEmail = string | null;

/**
 * @nullable
 */
export type CustomerContactBaseDumpSchemaPhone = string | null;

/**
 * @nullable
 */
export type CustomerContactBaseDumpSchemaLicenseNumber = string | null;

/**
 * @nullable
 */
export type CustomerContactBaseDumpSchemaEmail = string | null;

/**
 * @nullable
 */
export type CustomerContactBaseDumpSchemaDateOfBirth = string | null;

export interface CustomerContactBaseDumpSchema {
  /** @nullable */
  date_of_birth?: CustomerContactBaseDumpSchemaDateOfBirth;
  /** @nullable */
  email?: CustomerContactBaseDumpSchemaEmail;
  first_name: string;
  full_name: string;
  id: string;
  last_name: string;
  /** @nullable */
  license_number?: CustomerContactBaseDumpSchemaLicenseNumber;
  /** @nullable */
  phone?: CustomerContactBaseDumpSchemaPhone;
}

/**
 * @nullable
 */
export type PaginatedCustomerSchemaNext = number | null;

export interface PaginatedCustomerSchema {
  elements: CustomerDumpSchema[];
  /** @nullable */
  next?: PaginatedCustomerSchemaNext;
  total_count: number;
}

export type CustomerTypeEnum = typeof CustomerTypeEnum[keyof typeof CustomerTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerTypeEnum = {
  business: 'business',
  individual: 'individual',
} as const;

export interface CustomerWithContactSchema {
  activity_log_id: string;
  automatic_statements: boolean;
  /** @nullable */
  billing_email?: CustomerWithContactSchemaBillingEmail;
  /** @nullable */
  billing_phone?: CustomerWithContactSchemaBillingPhone;
  /** @nullable */
  billing_poc_name?: CustomerWithContactSchemaBillingPocName;
  /** @nullable */
  billing_profile_id?: CustomerWithContactSchemaBillingProfileId;
  /** @nullable */
  coi_amount?: CustomerWithContactSchemaCoiAmount;
  /** @nullable */
  coi_expiration_date?: CustomerWithContactSchemaCoiExpirationDate;
  /** @nullable */
  coi_issued_by?: CustomerWithContactSchemaCoiIssuedBy;
  /** @nullable */
  coi_issued_date?: CustomerWithContactSchemaCoiIssuedDate;
  /** @nullable */
  coi_policy_number?: CustomerWithContactSchemaCoiPolicyNumber;
  /** @nullable */
  coi_policyholder_name?: CustomerWithContactSchemaCoiPolicyholderName;
  /** @nullable */
  credit_limit?: CustomerWithContactSchemaCreditLimit;
  /** @nullable */
  default_sales_rep_id?: CustomerWithContactSchemaDefaultSalesRepId;
  has_coi: boolean;
  id: string;
  identifier: string;
  is_credit_check_complete: boolean;
  is_do_not_rent: boolean;
  is_tax_exempt: boolean;
  /** @nullable */
  lead_source?: CustomerWithContactSchemaLeadSource;
  /** @nullable */
  matched_contact?: CustomerWithContactSchemaMatchedContact;
  name: string;
  /** @nullable */
  parent_customer?: CustomerWithContactSchemaParentCustomer;
  /** @nullable */
  parent_customer_id?: CustomerWithContactSchemaParentCustomerId;
  /** @nullable */
  remote_object_id?: CustomerWithContactSchemaRemoteObjectId;
  /** @nullable */
  statement_date?: CustomerWithContactSchemaStatementDate;
  type: CustomerTypeEnum;
}

/**
 * @nullable
 */
export type CustomerDumpSchemaStatementDate = number | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaRemoteObjectId = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaParentCustomerId = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaParentCustomer = LinkIDNameSchema | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaLeadSource = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaDefaultSalesRepId = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaCreditLimit = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaCoiPolicyholderName = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaCoiPolicyNumber = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaCoiIssuedDate = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaCoiIssuedBy = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaCoiExpirationDate = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaCoiAmount = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaBillingProfileId = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaBillingPocName = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaBillingPhone = string | null;

/**
 * @nullable
 */
export type CustomerDumpSchemaBillingEmail = string | null;

export interface CustomerDumpSchema {
  activity_log_id: string;
  automatic_statements: boolean;
  /** @nullable */
  billing_email?: CustomerDumpSchemaBillingEmail;
  /** @nullable */
  billing_phone?: CustomerDumpSchemaBillingPhone;
  /** @nullable */
  billing_poc_name?: CustomerDumpSchemaBillingPocName;
  /** @nullable */
  billing_profile_id?: CustomerDumpSchemaBillingProfileId;
  /** @nullable */
  coi_amount?: CustomerDumpSchemaCoiAmount;
  /** @nullable */
  coi_expiration_date?: CustomerDumpSchemaCoiExpirationDate;
  /** @nullable */
  coi_issued_by?: CustomerDumpSchemaCoiIssuedBy;
  /** @nullable */
  coi_issued_date?: CustomerDumpSchemaCoiIssuedDate;
  /** @nullable */
  coi_policy_number?: CustomerDumpSchemaCoiPolicyNumber;
  /** @nullable */
  coi_policyholder_name?: CustomerDumpSchemaCoiPolicyholderName;
  /** @nullable */
  credit_limit?: CustomerDumpSchemaCreditLimit;
  /** @nullable */
  default_sales_rep_id?: CustomerDumpSchemaDefaultSalesRepId;
  has_coi: boolean;
  id: string;
  identifier: string;
  is_credit_check_complete: boolean;
  is_do_not_rent: boolean;
  is_tax_exempt: boolean;
  /** @nullable */
  lead_source?: CustomerDumpSchemaLeadSource;
  name: string;
  /** @nullable */
  parent_customer?: CustomerDumpSchemaParentCustomer;
  /** @nullable */
  parent_customer_id?: CustomerDumpSchemaParentCustomerId;
  /** @nullable */
  remote_object_id?: CustomerDumpSchemaRemoteObjectId;
  /** @nullable */
  statement_date?: CustomerDumpSchemaStatementDate;
  type: CustomerTypeEnum;
}

/**
 * @nullable
 */
export type PaginatedTermsAndConditionsAddendumSchemaNext = number | null;

export interface TermsAndConditionsAddendumDumpSchema {
  id: string;
  name: string;
  terms_and_conditions: unknown[];
}

export interface PaginatedTermsAndConditionsAddendumSchema {
  elements: TermsAndConditionsAddendumDumpSchema[];
  /** @nullable */
  next?: PaginatedTermsAndConditionsAddendumSchemaNext;
  total_count: number;
}

export interface BaseTermsAndConditionsAddendumSchema {
  name: string;
  terms_and_conditions: unknown[];
}

export interface DocumentConfigurationUpdateSchema {
  attachments: string[];
  document_type: DocumentType;
  /** @nullable */
  footer?: DocumentConfigurationUpdateSchemaFooter;
  include_terms_and_conditions: boolean;
}

export interface DocumentConfigurationsUpdateSchema {
  document_configurations: DocumentConfigurationUpdateSchema[];
}

/**
 * @nullable
 */
export type DocumentConfigurationUpdateSchemaFooter = unknown[] | null;

/**
 * @nullable
 */
export type DocumentConfigurationDumpSchemaFooter = unknown[] | null;

export interface DocumentConfigurationDumpSchema {
  attachments: CustomerFileDumpSchema[];
  document_type: DocumentType;
  /** @nullable */
  footer?: DocumentConfigurationDumpSchemaFooter;
  include_terms_and_conditions: boolean;
}

export interface DocumentConfigurationsDumpSchema {
  document_configurations: DocumentConfigurationDumpSchema[];
}

export interface LocationGroupUpdateSchema {
  name: string;
}

export interface LocationGroupCreateSchema {
  name: string;
}

export interface LocationGroupHydratedDumpSchema {
  id: string;
  locations: LocationDumpSchema[];
  name: string;
}

/**
 * @nullable
 */
export type PaginatedLocationGroupSchemaNext = number | null;

export interface LocationGroupDumpSchema {
  id: string;
  locations: LinkIDNameSchema[];
  name: string;
}

export interface PaginatedLocationGroupSchema {
  elements: LocationGroupDumpSchema[];
  /** @nullable */
  next?: PaginatedLocationGroupSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type LocationCreateSchemaRemoteObjectId = string | null;

/**
 * @nullable
 */
export type LocationCreateSchemaPhone = string | null;

/**
 * @nullable
 */
export type LocationCreateSchemaLocationGroupId = string | null;

/**
 * @nullable
 */
export type LocationCreateSchemaIdentifier = string | null;

export interface LocationCreateSchema {
  address: AddressCreateSchema;
  holidays: HolidaySchema[];
  /** @nullable */
  identifier?: LocationCreateSchemaIdentifier;
  /** @nullable */
  location_group_id?: LocationCreateSchemaLocationGroupId;
  name: string;
  /** @nullable */
  phone?: LocationCreateSchemaPhone;
  /** @nullable */
  remote_object_id?: LocationCreateSchemaRemoteObjectId;
  timezone: TimezoneEnum;
}

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaWednesdayRollTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaWednesdayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaWednesdayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaTuesdayRollTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaTuesdayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaTuesdayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaThursdayRollTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaThursdayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaThursdayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaSundayRollTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaSundayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaSundayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaSaturdayRollTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaSaturdayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaSaturdayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaRemoteObject = RemoteObjectDumpSchema | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaPhone = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaMondayRollTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaMondayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaMondayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaLocationGroupId = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaLocationGroup = LinkNameSchema | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaFridayRollTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaFridayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationHydratedDumpSchemaFridayCloseTime = string | null;

/**
 * @nullable
 */
export type HolidaySchemaRollTime = string | null;

/**
 * @nullable
 */
export type HolidaySchemaOpenTime = string | null;

/**
 * @nullable
 */
export type HolidaySchemaCloseTime = string | null;

export interface HolidaySchema {
  /** @nullable */
  close_time?: HolidaySchemaCloseTime;
  date: string;
  /** @nullable */
  open_time?: HolidaySchemaOpenTime;
  /** @nullable */
  roll_time?: HolidaySchemaRollTime;
}

export interface LocationHydratedDumpSchema {
  address: AddressDumpSchema;
  /** @nullable */
  friday_close_time?: LocationHydratedDumpSchemaFridayCloseTime;
  /** @nullable */
  friday_open_time?: LocationHydratedDumpSchemaFridayOpenTime;
  /** @nullable */
  friday_roll_time?: LocationHydratedDumpSchemaFridayRollTime;
  holidays: HolidaySchema[];
  id: string;
  identifier: string;
  is_open_friday: boolean;
  is_open_monday: boolean;
  is_open_saturday: boolean;
  is_open_sunday: boolean;
  is_open_thursday: boolean;
  is_open_tuesday: boolean;
  is_open_wednesday: boolean;
  /** @nullable */
  location_group?: LocationHydratedDumpSchemaLocationGroup;
  /** @nullable */
  location_group_id?: LocationHydratedDumpSchemaLocationGroupId;
  /** @nullable */
  monday_close_time?: LocationHydratedDumpSchemaMondayCloseTime;
  /** @nullable */
  monday_open_time?: LocationHydratedDumpSchemaMondayOpenTime;
  /** @nullable */
  monday_roll_time?: LocationHydratedDumpSchemaMondayRollTime;
  name: string;
  /** @nullable */
  phone?: LocationHydratedDumpSchemaPhone;
  /** @nullable */
  remote_object?: LocationHydratedDumpSchemaRemoteObject;
  /** @nullable */
  saturday_close_time?: LocationHydratedDumpSchemaSaturdayCloseTime;
  /** @nullable */
  saturday_open_time?: LocationHydratedDumpSchemaSaturdayOpenTime;
  /** @nullable */
  saturday_roll_time?: LocationHydratedDumpSchemaSaturdayRollTime;
  /** @nullable */
  sunday_close_time?: LocationHydratedDumpSchemaSundayCloseTime;
  /** @nullable */
  sunday_open_time?: LocationHydratedDumpSchemaSundayOpenTime;
  /** @nullable */
  sunday_roll_time?: LocationHydratedDumpSchemaSundayRollTime;
  /** @nullable */
  thursday_close_time?: LocationHydratedDumpSchemaThursdayCloseTime;
  /** @nullable */
  thursday_open_time?: LocationHydratedDumpSchemaThursdayOpenTime;
  /** @nullable */
  thursday_roll_time?: LocationHydratedDumpSchemaThursdayRollTime;
  timezone: TimezoneEnum;
  /** @nullable */
  tuesday_close_time?: LocationHydratedDumpSchemaTuesdayCloseTime;
  /** @nullable */
  tuesday_open_time?: LocationHydratedDumpSchemaTuesdayOpenTime;
  /** @nullable */
  tuesday_roll_time?: LocationHydratedDumpSchemaTuesdayRollTime;
  /** @nullable */
  wednesday_close_time?: LocationHydratedDumpSchemaWednesdayCloseTime;
  /** @nullable */
  wednesday_open_time?: LocationHydratedDumpSchemaWednesdayOpenTime;
  /** @nullable */
  wednesday_roll_time?: LocationHydratedDumpSchemaWednesdayRollTime;
}

/**
 * @nullable
 */
export type PaginatedLocationSchemaNext = number | null;

export interface PaginatedLocationSchema {
  elements: LocationDumpSchema[];
  /** @nullable */
  next?: PaginatedLocationSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type BusinessUpdateSchemaWatermark = string | null;

/**
 * @nullable
 */
export type BusinessUpdateSchemaTermsAndConditions = unknown[] | null;

/**
 * @nullable
 */
export type BusinessUpdateSchemaReplyToEmail = string | null;

/**
 * @nullable
 */
export type BusinessUpdateSchemaPhone = string | null;

/**
 * @nullable
 */
export type BusinessUpdateSchemaLogo = string | null;

export interface BusinessUpdateSchema {
  /** @nullable */
  address?: BusinessUpdateSchemaAddress;
  clock_hours_per_day: number;
  clock_hours_per_month: number;
  clock_hours_per_week: number;
  default_timezone: TimezoneEnum;
  /** @nullable */
  logo?: BusinessUpdateSchemaLogo;
  meter_hours_per_day: number;
  meter_hours_per_month: number;
  meter_hours_per_week: number;
  name: string;
  /** @nullable */
  phone?: BusinessUpdateSchemaPhone;
  /** @nullable */
  reply_to_email?: BusinessUpdateSchemaReplyToEmail;
  /** @nullable */
  terms_and_conditions?: BusinessUpdateSchemaTermsAndConditions;
  /** @nullable */
  watermark?: BusinessUpdateSchemaWatermark;
}

/**
 * @nullable
 */
export type AddressCreateSchemaAddressTwo = string | null;

export interface AddressCreateSchema {
  address_one: string;
  /** @nullable */
  address_two?: AddressCreateSchemaAddressTwo;
  city: string;
  country: CountryEnum;
  state: StateEnum;
  zip: string;
}

/**
 * @nullable
 */
export type BusinessUpdateSchemaAddress = AddressCreateSchema | null;

/**
 * @nullable
 */
export type BusinessDumpSchemaWatermark = CustomerFileDumpSchema | null;

/**
 * @nullable
 */
export type BusinessDumpSchemaTermsAndConditions = unknown[] | null;

/**
 * @nullable
 */
export type BusinessDumpSchemaReplyToEmail = string | null;

/**
 * @nullable
 */
export type BusinessDumpSchemaPhone = string | null;

/**
 * @nullable
 */
export type BusinessDumpSchemaLogo = CustomerFileDumpSchema | null;

/**
 * @nullable
 */
export type BusinessDumpSchemaAddress = AddressDumpSchema | null;

export type TimezoneEnum = typeof TimezoneEnum[keyof typeof TimezoneEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimezoneEnum = {
  'America/Los_Angeles': 'America/Los_Angeles',
  'America/Denver': 'America/Denver',
  'America/Phoenix': 'America/Phoenix',
  'America/Chicago': 'America/Chicago',
  'America/New_York': 'America/New_York',
} as const;

export interface BusinessDumpSchema {
  /** @nullable */
  address?: BusinessDumpSchemaAddress;
  clock_hours_per_day: number;
  clock_hours_per_month: number;
  clock_hours_per_week: number;
  default_timezone: TimezoneEnum;
  locations: LocationDumpSchema[];
  /** @nullable */
  logo?: BusinessDumpSchemaLogo;
  meter_hours_per_day: number;
  meter_hours_per_month: number;
  meter_hours_per_week: number;
  name: string;
  /** @nullable */
  phone?: BusinessDumpSchemaPhone;
  /** @nullable */
  reply_to_email?: BusinessDumpSchemaReplyToEmail;
  /** @nullable */
  terms_and_conditions?: BusinessDumpSchemaTermsAndConditions;
  /** @nullable */
  watermark?: BusinessDumpSchemaWatermark;
}

/**
 * @nullable
 */
export type LocationDumpSchemaWednesdayRollTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaWednesdayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaWednesdayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaTuesdayRollTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaTuesdayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaTuesdayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaThursdayRollTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaThursdayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaThursdayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaSundayRollTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaSundayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaSundayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaSaturdayRollTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaSaturdayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaSaturdayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaPhone = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaMondayRollTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaMondayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaMondayCloseTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaLocationGroupId = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaLocationGroup = LinkNameSchema | null;

/**
 * @nullable
 */
export type LocationDumpSchemaFridayRollTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaFridayOpenTime = string | null;

/**
 * @nullable
 */
export type LocationDumpSchemaFridayCloseTime = string | null;

export interface LocationDumpSchema {
  address: AddressDumpSchema;
  /** @nullable */
  friday_close_time?: LocationDumpSchemaFridayCloseTime;
  /** @nullable */
  friday_open_time?: LocationDumpSchemaFridayOpenTime;
  /** @nullable */
  friday_roll_time?: LocationDumpSchemaFridayRollTime;
  id: string;
  identifier: string;
  is_open_friday: boolean;
  is_open_monday: boolean;
  is_open_saturday: boolean;
  is_open_sunday: boolean;
  is_open_thursday: boolean;
  is_open_tuesday: boolean;
  is_open_wednesday: boolean;
  /** @nullable */
  location_group?: LocationDumpSchemaLocationGroup;
  /** @nullable */
  location_group_id?: LocationDumpSchemaLocationGroupId;
  /** @nullable */
  monday_close_time?: LocationDumpSchemaMondayCloseTime;
  /** @nullable */
  monday_open_time?: LocationDumpSchemaMondayOpenTime;
  /** @nullable */
  monday_roll_time?: LocationDumpSchemaMondayRollTime;
  name: string;
  /** @nullable */
  phone?: LocationDumpSchemaPhone;
  /** @nullable */
  saturday_close_time?: LocationDumpSchemaSaturdayCloseTime;
  /** @nullable */
  saturday_open_time?: LocationDumpSchemaSaturdayOpenTime;
  /** @nullable */
  saturday_roll_time?: LocationDumpSchemaSaturdayRollTime;
  /** @nullable */
  sunday_close_time?: LocationDumpSchemaSundayCloseTime;
  /** @nullable */
  sunday_open_time?: LocationDumpSchemaSundayOpenTime;
  /** @nullable */
  sunday_roll_time?: LocationDumpSchemaSundayRollTime;
  /** @nullable */
  thursday_close_time?: LocationDumpSchemaThursdayCloseTime;
  /** @nullable */
  thursday_open_time?: LocationDumpSchemaThursdayOpenTime;
  /** @nullable */
  thursday_roll_time?: LocationDumpSchemaThursdayRollTime;
  timezone: TimezoneEnum;
  /** @nullable */
  tuesday_close_time?: LocationDumpSchemaTuesdayCloseTime;
  /** @nullable */
  tuesday_open_time?: LocationDumpSchemaTuesdayOpenTime;
  /** @nullable */
  tuesday_roll_time?: LocationDumpSchemaTuesdayRollTime;
  /** @nullable */
  wednesday_close_time?: LocationDumpSchemaWednesdayCloseTime;
  /** @nullable */
  wednesday_open_time?: LocationDumpSchemaWednesdayOpenTime;
  /** @nullable */
  wednesday_roll_time?: LocationDumpSchemaWednesdayRollTime;
}

export interface PreferencesSchema {
  preferences: PreferenceSchema[];
}

export type TemplateIdentifier = typeof TemplateIdentifier[keyof typeof TemplateIdentifier];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TemplateIdentifier = {
  following_comment: 'following_comment',
  comment_mention: 'comment_mention',
  client_templated: 'client_templated',
  dispatch_driver: 'dispatch_driver',
  forgot_password: 'forgot_password',
  password_updated: 'password_updated',
  new_user_password: 'new_user_password',
  test_template: 'test_template',
} as const;

export interface BasePreferenceSchema {
  preferences_by_type: PreferenceByTypeSchema[];
  template_identifier: TemplateIdentifier;
}

export interface BasePreferencesSchema {
  preferences: BasePreferenceSchema[];
}

export type NotificationPreferenceGroup = typeof NotificationPreferenceGroup[keyof typeof NotificationPreferenceGroup];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationPreferenceGroup = {
  comment: 'comment',
  custom: 'custom',
  dispatch: 'dispatch',
  system_user: 'system_user',
} as const;

/**
 * @nullable
 */
export type PreferenceSchemaGroup = NotificationPreferenceGroup | null;

export type MessageType = typeof MessageType[keyof typeof MessageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageType = {
  email: 'email',
  sms: 'sms',
} as const;

export interface PreferenceByTypeSchema {
  enabled: boolean;
  message_type: MessageType;
}

export interface PreferenceSchema {
  allow_unsubscribe: boolean;
  /** @nullable */
  group?: PreferenceSchemaGroup;
  preferences_by_type: PreferenceByTypeSchema[];
  template_identifier: TemplateIdentifier;
}

/**
 * @nullable
 */
export type EventConfigurationCreateSchemaMessageTemplateId = string | null;

export type EventType = typeof EventType[keyof typeof EventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventType = {
  invoice_issued: 'invoice_issued',
  credit_note_issued: 'credit_note_issued',
  auto_charge_reminder: 'auto_charge_reminder',
  success_payment_refund: 'success_payment_refund',
  failed_payment_refund: 'failed_payment_refund',
  contract_esign: 'contract_esign',
  customer_statement: 'customer_statement',
} as const;

export interface EventConfigurationCreateSchema {
  enabled: boolean;
  event_type: EventType;
  /** @nullable */
  message_template_id?: EventConfigurationCreateSchemaMessageTemplateId;
}

export interface EventConfigurationsCreateSchema {
  event_configurations: EventConfigurationCreateSchema[];
}

/**
 * @nullable
 */
export type EventConfigurationDumpSchemaMessageTemplateId = string | null;

export interface EventConfigurationDumpSchema {
  enabled: boolean;
  event_type: EventType;
  /** @nullable */
  message_template_id?: EventConfigurationDumpSchemaMessageTemplateId;
  object_type: MessageTemplateObject;
}

export interface EventConfigurationsDumpSchema {
  event_configurations: EventConfigurationDumpSchema[];
}

export type ResolvedMessageTemplateDumpSchemaResolvedRecipientsItem = ResolvedUserRecipient | ResolvedCustomerContactRecipient | ResolvedVendorContactRecipient | UserRecipient | ManualRecipient;

export type ResolvedMessageTemplateDumpSchemaResolvedCcRecipientsItem = ResolvedUserRecipient | ResolvedCustomerContactRecipient | ResolvedVendorContactRecipient | UserRecipient | ManualRecipient;

export type ResolvedMessageTemplateDumpSchemaResolvedBccRecipientsItem = ResolvedUserRecipient | ResolvedCustomerContactRecipient | ResolvedVendorContactRecipient | UserRecipient | ManualRecipient;

export type ResolvedMessageTemplateDumpSchemaPotentialRecipientsItem = ResolvedUserRecipient | ResolvedCustomerContactRecipient | ResolvedVendorContactRecipient;

/**
 * @nullable
 */
export type ResolvedMessageTemplateDumpSchemaMessageTemplate = MessageTemplateDumpSchema | null;

export interface ResolvedMessageTemplateDumpSchema {
  /** @nullable */
  message_template?: ResolvedMessageTemplateDumpSchemaMessageTemplate;
  potential_attachments: ResolvedAttachmentSchema[];
  potential_recipients: ResolvedMessageTemplateDumpSchemaPotentialRecipientsItem[];
  potential_variables: BaseVariableSchema[];
  resolved_attachments: ResolvedAttachmentSchema[];
  resolved_bcc_recipients: ResolvedMessageTemplateDumpSchemaResolvedBccRecipientsItem[];
  resolved_cc_recipients: ResolvedMessageTemplateDumpSchemaResolvedCcRecipientsItem[];
  resolved_recipients: ResolvedMessageTemplateDumpSchemaResolvedRecipientsItem[];
  resolved_variables: ResolvedVariableSchema[];
}

export type TimeVariableSchemaType = typeof TimeVariableSchemaType[keyof typeof TimeVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeVariableSchemaType = {
  time: 'time',
} as const;

/**
 * @nullable
 */
export type TimeVariableSchemaDateValue = string | null;

export interface TimeVariableSchema {
  /** @nullable */
  date_value?: TimeVariableSchemaDateValue;
  type: TimeVariableSchemaType;
}

export type StringVariableSchemaType = typeof StringVariableSchemaType[keyof typeof StringVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StringVariableSchemaType = {
  string: 'string',
} as const;

/**
 * @nullable
 */
export type StringVariableSchemaStringValue = string | null;

export interface StringVariableSchema {
  /** @nullable */
  string_value?: StringVariableSchemaStringValue;
  type: StringVariableSchemaType;
}

export type ResolvedVendorContactRecipientType = typeof ResolvedVendorContactRecipientType[keyof typeof ResolvedVendorContactRecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResolvedVendorContactRecipientType = {
  vendor_contact: 'vendor_contact',
} as const;

/**
 * @nullable
 */
export type ResolvedVendorContactRecipientEmail = string | null;

export interface ResolvedVendorContactRecipient {
  /** @nullable */
  email?: ResolvedVendorContactRecipientEmail;
  first_name: string;
  last_name: string;
  type: RecipientType;
  type_: ResolvedVendorContactRecipientType;
  vendor_contact_id: string;
}

export type ResolvedVariableSchemaValue = AddressVariableSchema | StringVariableSchema | DateTimeVariableSchema | DateVariableSchema | TimeVariableSchema | MoneyVariableSchema;

export interface ResolvedVariableSchema {
  name: string;
  type: TemplateVariableType;
  value: ResolvedVariableSchemaValue;
}

export type ResolvedUserRecipientType = typeof ResolvedUserRecipientType[keyof typeof ResolvedUserRecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResolvedUserRecipientType = {
  resolved_user: 'resolved_user',
} as const;

/**
 * @nullable
 */
export type ResolvedUserRecipientLastName = string | null;

/**
 * @nullable
 */
export type ResolvedUserRecipientFirstName = string | null;

export interface ResolvedUserRecipient {
  email: string;
  /** @nullable */
  first_name?: ResolvedUserRecipientFirstName;
  /** @nullable */
  last_name?: ResolvedUserRecipientLastName;
  type: RecipientType;
  type_: ResolvedUserRecipientType;
  user_id: string;
}

export type ResolvedCustomerContactRecipientType = typeof ResolvedCustomerContactRecipientType[keyof typeof ResolvedCustomerContactRecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResolvedCustomerContactRecipientType = {
  customer_contact: 'customer_contact',
} as const;

/**
 * @nullable
 */
export type ResolvedCustomerContactRecipientEmail = string | null;

export interface ResolvedCustomerContactRecipient {
  customer_contact_id: string;
  /** @nullable */
  email?: ResolvedCustomerContactRecipientEmail;
  first_name: string;
  last_name: string;
  type: RecipientType;
  type_: ResolvedCustomerContactRecipientType;
}

export type MoneyVariableSchemaType = typeof MoneyVariableSchemaType[keyof typeof MoneyVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MoneyVariableSchemaType = {
  money: 'money',
} as const;

/**
 * @nullable
 */
export type MoneyVariableSchemaMoneyValue = string | null;

export interface MoneyVariableSchema {
  /** @nullable */
  money_value?: MoneyVariableSchemaMoneyValue;
  type: MoneyVariableSchemaType;
}

export type DateVariableSchemaType = typeof DateVariableSchemaType[keyof typeof DateVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateVariableSchemaType = {
  date: 'date',
} as const;

/**
 * @nullable
 */
export type DateVariableSchemaDateValue = string | null;

export interface DateVariableSchema {
  /** @nullable */
  date_value?: DateVariableSchemaDateValue;
  type: DateVariableSchemaType;
}

export type DateTimeVariableSchemaType = typeof DateTimeVariableSchemaType[keyof typeof DateTimeVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateTimeVariableSchemaType = {
  datetime: 'datetime',
} as const;

/**
 * @nullable
 */
export type DateTimeVariableSchemaDatetimeValue = string | null;

export interface DateTimeVariableSchema {
  /** @nullable */
  datetime_value?: DateTimeVariableSchemaDatetimeValue;
  type: DateTimeVariableSchemaType;
}

export interface CoordinateDumpSchema {
  latitude: string;
  longitude: string;
}

export type AddressVariableSchemaType = typeof AddressVariableSchemaType[keyof typeof AddressVariableSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressVariableSchemaType = {
  address: 'address',
} as const;

export interface AddressVariableSchema {
  /** @nullable */
  address_value?: AddressVariableSchemaAddressValue;
  type: AddressVariableSchemaType;
}

/**
 * @nullable
 */
export type AddressDumpSchemaCoordinate = CoordinateDumpSchema | null;

/**
 * @nullable
 */
export type AddressDumpSchemaAddressTwo = string | null;

export interface AddressDumpSchema {
  address_one: string;
  /** @nullable */
  address_two?: AddressDumpSchemaAddressTwo;
  city: string;
  /** @nullable */
  coordinate?: AddressDumpSchemaCoordinate;
  country: CountryEnum;
  id: string;
  state: StateEnum;
  zip: string;
}

/**
 * @nullable
 */
export type AddressVariableSchemaAddressValue = AddressDumpSchema | null;

export type ResolveMessageTemplateBodySchemaOtherDataAnyOf = { [key: string]: unknown };

/**
 * @nullable
 */
export type ResolveMessageTemplateBodySchemaOtherData = ResolveMessageTemplateBodySchemaOtherDataAnyOf | null;

/**
 * @nullable
 */
export type ResolveMessageTemplateBodySchemaObjectType = MessageTemplateObject | null;

/**
 * @nullable
 */
export type ResolveMessageTemplateBodySchemaMessageTemplateId = string | null;

export interface ResolveMessageTemplateBodySchema {
  /** @nullable */
  message_template_id?: ResolveMessageTemplateBodySchemaMessageTemplateId;
  object_id: string;
  /** @nullable */
  object_type?: ResolveMessageTemplateBodySchemaObjectType;
  /** @nullable */
  other_data?: ResolveMessageTemplateBodySchemaOtherData;
}

export type TemplateVariableType = typeof TemplateVariableType[keyof typeof TemplateVariableType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TemplateVariableType = {
  string: 'string',
  datetime: 'datetime',
  date: 'date',
  time: 'time',
  money: 'money',
  address: 'address',
} as const;

export interface BaseVariableSchema {
  name: string;
  type: TemplateVariableType;
}

export interface PotentialVariablesAttachmentsSchema {
  potential_attachments: AttachmentType[];
  potential_recipients: TypeRecipient[];
  potential_variables: BaseVariableSchema[];
}

export interface UnresolvedBodySchema {
  object_type: MessageTemplateObject;
}

export type BaseMessageTemplateSchemaBccRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

/**
 * @nullable
 */
export type PaginatedMessageTemplateSchemaNext = number | null;

export interface PaginatedMessageTemplateSchema {
  elements: MessageTemplateDumpSchema[];
  /** @nullable */
  next?: PaginatedMessageTemplateSchemaNext;
  total_count: number;
}

export type UserRecipientType = typeof UserRecipientType[keyof typeof UserRecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRecipientType = {
  user: 'user',
} as const;

/**
 * @nullable
 */
export type UserRecipientLastName = string | null;

/**
 * @nullable
 */
export type UserRecipientFirstName = string | null;

export interface UserRecipient {
  email: string;
  /** @nullable */
  first_name?: UserRecipientFirstName;
  /** @nullable */
  last_name?: UserRecipientLastName;
  type_: UserRecipientType;
  user_id: string;
}

export type BaseMessageTemplateSchemaCcRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

export interface BaseMessageTemplateSchema {
  attachments: AttachmentType[];
  bcc_recipients: BaseMessageTemplateSchemaBccRecipientsItem[];
  body: unknown[];
  cc_recipients: BaseMessageTemplateSchemaCcRecipientsItem[];
  name: string;
  obj: MessageTemplateObject;
  recipients: BaseMessageTemplateSchemaRecipientsItem[];
  subject: unknown[];
}

export type TypeRecipientType = typeof TypeRecipientType[keyof typeof TypeRecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TypeRecipientType = {
  type: 'type',
} as const;

export type RecipientType = typeof RecipientType[keyof typeof RecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecipientType = {
  quote__sales_rep: 'quote__sales_rep',
  quote__secondary_sales_rep: 'quote__secondary_sales_rep',
  quote__customer_contact: 'quote__customer_contact',
  order__sales_rep: 'order__sales_rep',
  order__secondary_sales_rep: 'order__secondary_sales_rep',
  order__customer_contact: 'order__customer_contact',
  invoice__sales_rep: 'invoice__sales_rep',
  invoice__secondary_sales_rep: 'invoice__secondary_sales_rep',
  invoice__customer_contact: 'invoice__customer_contact',
  payment__customer_contact: 'payment__customer_contact',
  customer__contact: 'customer__contact',
  purchase_order__vendor_contact: 'purchase_order__vendor_contact',
} as const;

export interface TypeRecipient {
  type: RecipientType;
  type_: TypeRecipientType;
}

export type ManualRecipientType = typeof ManualRecipientType[keyof typeof ManualRecipientType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualRecipientType = {
  manual: 'manual',
} as const;

export interface ManualRecipient {
  email: string;
  type_: ManualRecipientType;
}

export type BaseMessageTemplateSchemaRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

export type MessageTemplateDumpSchemaRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

export type MessageTemplateDumpSchemaCcRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

export type MessageTemplateDumpSchemaBccRecipientsItem = TypeRecipient | UserRecipient | ManualRecipient;

export interface MessageTemplateDumpSchema {
  attachments: AttachmentType[];
  bcc_recipients: MessageTemplateDumpSchemaBccRecipientsItem[];
  body: unknown[];
  cc_recipients: MessageTemplateDumpSchemaCcRecipientsItem[];
  id: string;
  name: string;
  obj: MessageTemplateObject;
  recipients: MessageTemplateDumpSchemaRecipientsItem[];
  subject: unknown[];
}

export interface PDFBodySchema {
  document_type: DocumentType;
  obj_id: string;
}

export type FileResponseSchemaType = typeof FileResponseSchemaType[keyof typeof FileResponseSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileResponseSchemaType = {
  file: 'file',
} as const;

export interface FileResponseSchema {
  file: CustomerFileDumpSchema;
  type: FileResponseSchemaType;
}

export type AsyncResponseSchemaType = typeof AsyncResponseSchemaType[keyof typeof AsyncResponseSchemaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AsyncResponseSchemaType = {
  async: 'async',
} as const;

export interface AsyncResponseSchema {
  message_id: string;
  type: AsyncResponseSchemaType;
}

export type PDFResponseSchemaBody = FileResponseSchema | AsyncResponseSchema;

export interface PDFResponseSchema {
  body: PDFResponseSchemaBody;
}

/**
 * @nullable
 */
export type SendEmailBodySchemaCta = CTATypeEnum | null;

export interface SendingRecipientType {
  /** @nullable */
  email?: SendingRecipientTypeEmail;
  /** @nullable */
  user_id?: SendingRecipientTypeUserId;
}

export interface SendEmailBodySchema {
  additional_attachments: string[];
  attachments: ResolvedAttachmentSchema[];
  bcc: SendingRecipientType[];
  body: unknown[];
  cc: SendingRecipientType[];
  /** @nullable */
  cta?: SendEmailBodySchemaCta;
  obj: MessageTemplateObject;
  obj_id: string;
  recipients: SendingRecipientType[];
  subject: unknown[];
}

/**
 * @nullable
 */
export type SendingRecipientTypeUserId = string | null;

/**
 * @nullable
 */
export type SendingRecipientTypeEmail = string | null;

export type MessageTemplateObject = typeof MessageTemplateObject[keyof typeof MessageTemplateObject];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageTemplateObject = {
  quote: 'quote',
  order: 'order',
  invoice: 'invoice',
  payment: 'payment',
  customer: 'customer',
  purchase_order: 'purchase_order',
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentType = {
  quote: 'quote',
  order: 'order',
  invoice: 'invoice',
  invoice__credit_note: 'invoice__credit_note',
  invoice__debit_note: 'invoice__debit_note',
  payment: 'payment',
  customer: 'customer',
  delivery_receipt: 'delivery_receipt',
  purchase_order: 'purchase_order',
} as const;

export type CTATypeEnum = typeof CTATypeEnum[keyof typeof CTATypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CTATypeEnum = {
  esign: 'esign',
  payment: 'payment',
  order: 'order',
} as const;

export type AttachmentType = typeof AttachmentType[keyof typeof AttachmentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttachmentType = {
  quote__quote_pdf: 'quote__quote_pdf',
  order__order_pdf: 'order__order_pdf',
  order__invoices: 'order__invoices',
  order__latest_invoice: 'order__latest_invoice',
  order__delivery_receipts: 'order__delivery_receipts',
  invoice__invoice_pdf: 'invoice__invoice_pdf',
  customer__statement_pdf: 'customer__statement_pdf',
  customer__invoices: 'customer__invoices',
  purchase_order__po_pdf: 'purchase_order__po_pdf',
} as const;

export interface ResolvedAttachmentSchema {
  attachment_obj_id: string;
  document_type: DocumentType;
  key: AttachmentType;
  name: string;
  object_type: MessageTemplateObject;
}

/**
 * @nullable
 */
export type UserDumpSchemaPhone = string | null;

/**
 * @nullable
 */
export type UserDumpSchemaLastName = string | null;

/**
 * @nullable
 */
export type UserDumpSchemaFirstName = string | null;

export interface UserDumpSchema {
  email: string;
  /** @nullable */
  first_name?: UserDumpSchemaFirstName;
  full_name: string;
  id: string;
  /** @nullable */
  last_name?: UserDumpSchemaLastName;
  /** @nullable */
  phone?: UserDumpSchemaPhone;
  username: string;
}

export interface ActivityLogDumpSchema {
  events: DynamicEventDumpSchema[];
  followers: UserDumpSchema[];
  id: string;
}

export interface UpdatedItemDumpSchema {
  klass: LinkIDNameSchema;
  new_quantity: number;
  original_quantity: number;
}

export type UpdateDynamicEventSchemaEventType = typeof UpdateDynamicEventSchemaEventType[keyof typeof UpdateDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateDynamicEventSchemaEventType = {
  update: 'update',
} as const;

export interface UpdateDynamicEventSchema {
  event_data: Diff;
  event_type: UpdateDynamicEventSchemaEventType;
}

export type StateTransitionDynamicEventSchemaEventType = typeof StateTransitionDynamicEventSchemaEventType[keyof typeof StateTransitionDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StateTransitionDynamicEventSchemaEventType = {
  state_transition: 'state_transition',
} as const;

export interface StateTransitionDynamicEventSchema {
  event_data: ObjectStateTransitionSchema;
  event_type: StateTransitionDynamicEventSchemaEventType;
}

export type PurchaseOrderVendorBillCreateDynamicEventSchemaEventType = typeof PurchaseOrderVendorBillCreateDynamicEventSchemaEventType[keyof typeof PurchaseOrderVendorBillCreateDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderVendorBillCreateDynamicEventSchemaEventType = {
  purchase_order__vendor_bill_create: 'purchase_order__vendor_bill_create',
} as const;

export interface PurchaseOrderVendorBillActivitySchema {
  vendor_bill: LinkIDSchema;
  vendor_bill_id: string;
}

export interface PurchaseOrderVendorBillCreateDynamicEventSchema {
  event_data: PurchaseOrderVendorBillActivitySchema;
  event_type: PurchaseOrderVendorBillCreateDynamicEventSchemaEventType;
}

export type PurchaseOrderItemReceiptUpdateDynamicEventSchemaEventType = typeof PurchaseOrderItemReceiptUpdateDynamicEventSchemaEventType[keyof typeof PurchaseOrderItemReceiptUpdateDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderItemReceiptUpdateDynamicEventSchemaEventType = {
  purchase_order__item_receipt_update: 'purchase_order__item_receipt_update',
} as const;

export interface PurchaseOrderItemReceiptUpdateActivitySchema {
  from_state: ItemReceiptStatus;
  item_receipt: LinkIDSchema;
  item_receipt_id: string;
  to_state: ItemReceiptStatus;
}

export interface PurchaseOrderItemReceiptUpdateDynamicEventSchema {
  event_data: PurchaseOrderItemReceiptUpdateActivitySchema;
  event_type: PurchaseOrderItemReceiptUpdateDynamicEventSchemaEventType;
}

export type PurchaseOrderItemReceiptCreateDynamicEventSchemaEventType = typeof PurchaseOrderItemReceiptCreateDynamicEventSchemaEventType[keyof typeof PurchaseOrderItemReceiptCreateDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderItemReceiptCreateDynamicEventSchemaEventType = {
  purchase_order__item_receipt_create: 'purchase_order__item_receipt_create',
} as const;

export interface PurchaseOrderItemReceiptActivitySchema {
  item_receipt: LinkIDSchema;
  item_receipt_id: string;
}

export interface PurchaseOrderItemReceiptCreateDynamicEventSchema {
  event_data: PurchaseOrderItemReceiptActivitySchema;
  event_type: PurchaseOrderItemReceiptCreateDynamicEventSchemaEventType;
}

export type PurchaseOrderBackorderedAddedDynamicEventSchemaEventType = typeof PurchaseOrderBackorderedAddedDynamicEventSchemaEventType[keyof typeof PurchaseOrderBackorderedAddedDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOrderBackorderedAddedDynamicEventSchemaEventType = {
  purchase_order__backordered_added: 'purchase_order__backordered_added',
} as const;

export interface PurchaseOrderBackorderedAddedActivitySchema {
  order_to_quantity: number;
  product: LinkNameSchema;
  product_id: string;
  quantity: number;
  threshold_quantity: number;
}

export interface PurchaseOrderBackorderedAddedDynamicEventSchema {
  event_data: PurchaseOrderBackorderedAddedActivitySchema;
  event_type: PurchaseOrderBackorderedAddedDynamicEventSchemaEventType;
}

/**
 * The state machine to rule them all.

This will require many more states.

Consider breaking this up into different types of state,
e.g. payment state, quote state, etc
 */
export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderStatus = {
  quote: 'quote',
  reservation: 'reservation',
  contract: 'contract',
  active: 'active',
  off_rent: 'off_rent',
  returned: 'returned',
  closed: 'closed',
  deleted: 'deleted',
} as const;

export type OrderDeliveryStatus = typeof OrderDeliveryStatus[keyof typeof OrderDeliveryStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderDeliveryStatus = {
  left_branch: 'left_branch',
  one_stop_away: 'one_stop_away',
  arrived: 'arrived',
  delivered: 'delivered',
} as const;

export type OrderDeliveryOneStopAwayDynamicEventSchemaEventType = typeof OrderDeliveryOneStopAwayDynamicEventSchemaEventType[keyof typeof OrderDeliveryOneStopAwayDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderDeliveryOneStopAwayDynamicEventSchemaEventType = {
  order_delivery__one_stop_away: 'order_delivery__one_stop_away',
} as const;

export interface OrderDeliveryOneStopAwayDynamicEventSchema {
  event_data: OrderDeliveryEventOneStopAwaySchema;
  event_type: OrderDeliveryOneStopAwayDynamicEventSchemaEventType;
}

export interface OrderDeliveryItemChangeSchema {
  stop_id: string;
  updated_items: UpdatedItemDumpSchema[];
}

export interface OrderDeliveryEventSchema {
  new_status: OrderDeliveryStatus;
}

/**
 * @nullable
 */
export type OrderDeliveryEventOneStopAwaySchemaArrivalWindowStart = string | null;

/**
 * @nullable
 */
export type OrderDeliveryEventOneStopAwaySchemaArrivalWindowEnd = string | null;

/**
 * @nullable
 */
export type OrderDeliveryEventOneStopAwaySchemaArrivalTime = string | null;

export interface OrderDeliveryEventOneStopAwaySchema {
  /** @nullable */
  arrival_time?: OrderDeliveryEventOneStopAwaySchemaArrivalTime;
  /** @nullable */
  arrival_window_end?: OrderDeliveryEventOneStopAwaySchemaArrivalWindowEnd;
  /** @nullable */
  arrival_window_start?: OrderDeliveryEventOneStopAwaySchemaArrivalWindowStart;
  new_status: OrderDeliveryStatus;
}

export type OrderDeliveryDynamicEventSchemaEventType = typeof OrderDeliveryDynamicEventSchemaEventType[keyof typeof OrderDeliveryDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderDeliveryDynamicEventSchemaEventType = {
  order_delivery: 'order_delivery',
} as const;

export interface OrderDeliveryDynamicEventSchema {
  event_data: OrderDeliveryEventSchema;
  event_type: OrderDeliveryDynamicEventSchemaEventType;
}

export type OrderDeliveryAdditionalItemsDynamicEventSchemaEventType = typeof OrderDeliveryAdditionalItemsDynamicEventSchemaEventType[keyof typeof OrderDeliveryAdditionalItemsDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderDeliveryAdditionalItemsDynamicEventSchemaEventType = {
  order_delivery__additional_items: 'order_delivery__additional_items',
} as const;

export interface OrderDeliveryAdditionalItemsDynamicEventSchema {
  event_data: OrderDeliveryItemChangeSchema;
  event_type: OrderDeliveryAdditionalItemsDynamicEventSchemaEventType;
}

export type OrderCreateDynamicEventSchemaEventType = typeof OrderCreateDynamicEventSchemaEventType[keyof typeof OrderCreateDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderCreateDynamicEventSchemaEventType = {
  order_create: 'order_create',
} as const;

export interface OrderCreateActivitySchema {
  initial_status: OrderStatus;
}

export interface OrderCreateDynamicEventSchema {
  event_data: OrderCreateActivitySchema;
  event_type: OrderCreateDynamicEventSchemaEventType;
}

export interface ObjectStateTransitionSchema {
  from_state: unknown;
  to_state: unknown;
}

export interface LinkNameSchema {
  id: string;
  name: string;
}

export type ItemReceiptStatus = typeof ItemReceiptStatus[keyof typeof ItemReceiptStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemReceiptStatus = {
  draft: 'draft',
  received: 'received',
  received_and_serialized: 'received_and_serialized',
} as const;

/**
 * @nullable
 */
export type DynamicEventDumpSchemaUser = UserDumpSchema | null;

export type DynamicEventDumpSchemaEventData = CommentDynamicEventSchema | OrderCreateDynamicEventSchema | UpdateDynamicEventSchema | StateTransitionDynamicEventSchema | OrderDeliveryDynamicEventSchema | OrderDeliveryOneStopAwayDynamicEventSchema | OrderDeliveryAdditionalItemsDynamicEventSchema | CreateDynamicEventSchema | PurchaseOrderItemReceiptCreateDynamicEventSchema | PurchaseOrderItemReceiptUpdateDynamicEventSchema | PurchaseOrderVendorBillCreateDynamicEventSchema | PurchaseOrderBackorderedAddedDynamicEventSchema;

export interface DynamicEventDumpSchema {
  created_at: string;
  event_data: DynamicEventDumpSchemaEventData;
  id: string;
  /** @nullable */
  user?: DynamicEventDumpSchemaUser;
}

export type DiffListType = typeof DiffListType[keyof typeof DiffListType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffListType = {
  list: 'list',
} as const;

export interface DiffList {
  elements: Diff[];
  type_: DiffListType;
}

export type DiffItemStringType = typeof DiffItemStringType[keyof typeof DiffItemStringType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemStringType = {
  string: 'string',
} as const;

/**
 * @nullable
 */
export type DiffItemStringOldValue = string | null;

/**
 * @nullable
 */
export type DiffItemStringNewValue = string | null;

export interface DiffItemString {
  /** @nullable */
  new_value?: DiffItemStringNewValue;
  /** @nullable */
  old_value?: DiffItemStringOldValue;
  type_: DiffItemStringType;
}

export type DiffItemIntType = typeof DiffItemIntType[keyof typeof DiffItemIntType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemIntType = {
  int: 'int',
} as const;

/**
 * @nullable
 */
export type DiffItemIntOldValue = number | null;

/**
 * @nullable
 */
export type DiffItemIntNewValue = number | null;

export interface DiffItemInt {
  /** @nullable */
  new_value?: DiffItemIntNewValue;
  /** @nullable */
  old_value?: DiffItemIntOldValue;
  type_: DiffItemIntType;
}

export type DiffItemDictType = typeof DiffItemDictType[keyof typeof DiffItemDictType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemDictType = {
  dict: 'dict',
} as const;

export interface DiffItemDict {
  /** @nullable */
  new_value?: DiffItemDictNewValue;
  /** @nullable */
  old_value?: DiffItemDictOldValue;
  type_: DiffItemDictType;
}

export type DiffItemDictOldValueAnyOf = { [key: string]: unknown };

/**
 * @nullable
 */
export type DiffItemDictOldValue = DiffItemDictOldValueAnyOf | null;

export type DiffItemDictNewValueAnyOf = { [key: string]: unknown };

/**
 * @nullable
 */
export type DiffItemDictNewValue = DiffItemDictNewValueAnyOf | null;

export type DiffItemDecimalType = typeof DiffItemDecimalType[keyof typeof DiffItemDecimalType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemDecimalType = {
  decimal: 'decimal',
} as const;

/**
 * @nullable
 */
export type DiffItemDecimalOldValue = string | null;

/**
 * @nullable
 */
export type DiffItemDecimalNewValue = string | null;

export interface DiffItemDecimal {
  /** @nullable */
  new_value?: DiffItemDecimalNewValue;
  /** @nullable */
  old_value?: DiffItemDecimalOldValue;
  type_: DiffItemDecimalType;
}

export type DiffItemDatetimeType = typeof DiffItemDatetimeType[keyof typeof DiffItemDatetimeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemDatetimeType = {
  datetime: 'datetime',
} as const;

/**
 * @nullable
 */
export type DiffItemDatetimeOldValue = string | null;

/**
 * @nullable
 */
export type DiffItemDatetimeNewValue = string | null;

export interface DiffItemDatetime {
  /** @nullable */
  new_value?: DiffItemDatetimeNewValue;
  /** @nullable */
  old_value?: DiffItemDatetimeOldValue;
  type_: DiffItemDatetimeType;
}

export type DiffItemDateType = typeof DiffItemDateType[keyof typeof DiffItemDateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffItemDateType = {
  date: 'date',
} as const;

/**
 * @nullable
 */
export type DiffItemDateOldValue = string | null;

/**
 * @nullable
 */
export type DiffItemDateNewValue = string | null;

export interface DiffItemDate {
  /** @nullable */
  new_value?: DiffItemDateNewValue;
  /** @nullable */
  old_value?: DiffItemDateOldValue;
  type_: DiffItemDateType;
}

export type DiffType = typeof DiffType[keyof typeof DiffType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffType = {
  diff: 'diff',
} as const;

export type DiffChanges = {[key: string]: DiffItemString | DiffItemInt | DiffItemDatetime | DiffItemDate | DiffItemDict | DiffItemDecimal | Diff | DiffList};

export interface Diff {
  changes?: DiffChanges;
  type_: DiffType;
}

export type CreateDynamicEventSchemaEventType = typeof CreateDynamicEventSchemaEventType[keyof typeof CreateDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateDynamicEventSchemaEventType = {
  create: 'create',
} as const;

/**
 * @nullable
 */
export type CreateDynamicEventSchemaEventData = unknown | null;

export interface CreateDynamicEventSchema {
  /** @nullable */
  event_data: CreateDynamicEventSchemaEventData;
  event_type: CreateDynamicEventSchemaEventType;
}

export type CommentableObjects = typeof CommentableObjects[keyof typeof CommentableObjects];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommentableObjects = {
  order: 'order',
  payment: 'payment',
  customer: 'customer',
  vendor: 'vendor',
  invoice: 'invoice',
  purchase_order: 'purchase_order',
  repair: 'repair',
} as const;

export type CommentDynamicEventSchemaEventType = typeof CommentDynamicEventSchemaEventType[keyof typeof CommentDynamicEventSchemaEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommentDynamicEventSchemaEventType = {
  comment: 'comment',
} as const;

export interface CommentCreateSchema {
  comment: unknown[];
  mentioned_users: string[];
  object: CommentableObjects;
  object_id: string;
}

export interface CommentDynamicEventSchema {
  event_data: CommentCreateSchema;
  event_type: CommentDynamicEventSchemaEventType;
}

/**
 * @nullable
 */
export type CategoryUpdateSchemaUsefulLife = number | null;

/**
 * @nullable
 */
export type CategoryUpdateSchemaProductTaxCodeGroupId = string | null;

/**
 * @nullable
 */
export type CategoryUpdateSchemaInventoryProfileId = string | null;

/**
 * @nullable
 */
export type CategoryUpdateSchemaIdentifier = string | null;

/**
 * @nullable
 */
export type CategoryUpdateSchemaDepreciationSettingId = string | null;

export interface CategoryUpdateSchema {
  attachments: InventoryUploadCreateSchema[];
  /** @nullable */
  depreciation_setting_id?: CategoryUpdateSchemaDepreciationSettingId;
  /** @nullable */
  identifier?: CategoryUpdateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  /** @nullable */
  inventory_profile_id?: CategoryUpdateSchemaInventoryProfileId;
  name: string;
  /** @nullable */
  product_tax_code_group_id?: CategoryUpdateSchemaProductTaxCodeGroupId;
  tags: string[];
  type: KlassType;
  /** @nullable */
  useful_life?: CategoryUpdateSchemaUsefulLife;
}

/**
 * @nullable
 */
export type CategoryCreateSchemaUsefulLife = number | null;

/**
 * @nullable
 */
export type CategoryCreateSchemaProductTaxCodeGroupId = string | null;

/**
 * @nullable
 */
export type CategoryCreateSchemaInventoryProfileId = string | null;

/**
 * @nullable
 */
export type CategoryCreateSchemaIdentifier = string | null;

/**
 * @nullable
 */
export type CategoryCreateSchemaDepreciationSettingId = string | null;

export interface InventoryUploadCreateSchema {
  is_on_order?: boolean;
  upload: string;
}

export interface CategoryCreateSchema {
  attachments: InventoryUploadCreateSchema[];
  /** @nullable */
  depreciation_setting_id?: CategoryCreateSchemaDepreciationSettingId;
  /** @nullable */
  identifier?: CategoryCreateSchemaIdentifier;
  images: InventoryUploadCreateSchema[];
  /** @nullable */
  inventory_profile_id?: CategoryCreateSchemaInventoryProfileId;
  name: string;
  /** @nullable */
  product_tax_code_group_id?: CategoryCreateSchemaProductTaxCodeGroupId;
  tags: string[];
  type: KlassType;
  /** @nullable */
  useful_life?: CategoryCreateSchemaUsefulLife;
}

/**
 * @nullable
 */
export type CategoryHydratedDumpSchemaUsefulLife = number | null;

/**
 * @nullable
 */
export type CategoryHydratedDumpSchemaProductTaxCodeGroupId = string | null;

/**
 * @nullable
 */
export type CategoryHydratedDumpSchemaProductTaxCodeGroup = ProductTaxCodeGroupDumpSchema | null;

/**
 * @nullable
 */
export type CategoryHydratedDumpSchemaInventoryProfileId = string | null;

/**
 * @nullable
 */
export type CategoryHydratedDumpSchemaInventoryProfile = InventoryProfileDumpSchema | null;

/**
 * @nullable
 */
export type CategoryHydratedDumpSchemaDepreciationSettingId = string | null;

/**
 * @nullable
 */
export type CategoryHydratedDumpSchemaDepreciationSetting = DepreciationSettingDumpSchema | null;

export interface CategoryHydratedDumpSchema {
  attachments: InventoryUploadDumpSchema[];
  count_assets: number;
  /** @nullable */
  depreciation_setting?: CategoryHydratedDumpSchemaDepreciationSetting;
  /** @nullable */
  depreciation_setting_id?: CategoryHydratedDumpSchemaDepreciationSettingId;
  id: string;
  identifier: string;
  images: CustomerFileDumpSchema[];
  /** @nullable */
  inventory_profile?: CategoryHydratedDumpSchemaInventoryProfile;
  /** @nullable */
  inventory_profile_id?: CategoryHydratedDumpSchemaInventoryProfileId;
  name: string;
  /** @nullable */
  product_tax_code_group?: CategoryHydratedDumpSchemaProductTaxCodeGroup;
  /** @nullable */
  product_tax_code_group_id?: CategoryHydratedDumpSchemaProductTaxCodeGroupId;
  tags: string[];
  type: KlassType;
  /** @nullable */
  useful_life?: CategoryHydratedDumpSchemaUsefulLife;
}

export type UploadType = typeof UploadType[keyof typeof UploadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadType = {
  openai_cache: 'openai_cache',
  category_image: 'category_image',
  category_attachment: 'category_attachment',
  klass_image: 'klass_image',
  klass_attachment: 'klass_attachment',
  asset_image: 'asset_image',
  asset_attachment: 'asset_attachment',
  variant_specification: 'variant_specification',
  customer_attachment: 'customer_attachment',
  customer_contact_attachment: 'customer_contact_attachment',
  question_response_signature: 'question_response_signature',
  question_response_photo: 'question_response_photo',
  question_response_file: 'question_response_file',
  purchase_order: 'purchase_order',
  item_receipt: 'item_receipt',
  vendor_bill: 'vendor_bill',
  logo: 'logo',
  watermark: 'watermark',
  document_configuration: 'document_configuration',
  line_item_receiving_attachment: 'line_item_receiving_attachment',
  pdf__quote: 'pdf__quote',
  pdf__order: 'pdf__order',
  pdf__invoice: 'pdf__invoice',
  pdf__invoice__credit_note: 'pdf__invoice__credit_note',
  pdf__invoice__debit_note: 'pdf__invoice__debit_note',
  pdf__payment_receipt: 'pdf__payment_receipt',
  pdf__statement: 'pdf__statement',
  pdf__delivery_receipt: 'pdf__delivery_receipt',
  pdf__purchase_order: 'pdf__purchase_order',
} as const;

export type TaxTypeEnum = typeof TaxTypeEnum[keyof typeof TaxTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxTypeEnum = {
  sales_tax: 'sales_tax',
  vat: 'vat',
  gst: 'gst',
  custom: 'custom',
} as const;

export type TaxSourceEnum = typeof TaxSourceEnum[keyof typeof TaxSourceEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxSourceEnum = {
  origin: 'origin',
  destination: 'destination',
} as const;

/**
 * @nullable
 */
export type TaxConfigurationDumpSchemaTaxLiabilityAccount = LedgerAccountDumpSchema | null;

/**
 * @nullable
 */
export type TaxConfigurationDumpSchemaDescription = string | null;

/**
 * @nullable
 */
export type TaxConfigurationChildDumpSchemaTaxLiabilityAccount = LedgerAccountDumpSchema | null;

export interface TaxConfigurationChildDumpSchema {
  id: string;
  name: string;
  rate: string;
  /** @nullable */
  tax_liability_account?: TaxConfigurationChildDumpSchemaTaxLiabilityAccount;
  zip_codes: string[];
}

export type StateEnum = typeof StateEnum[keyof typeof StateEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StateEnum = {
  alabama: 'alabama',
  alaska: 'alaska',
  arizona: 'arizona',
  arkansas: 'arkansas',
  california: 'california',
  colorado: 'colorado',
  connecticut: 'connecticut',
  delaware: 'delaware',
  florida: 'florida',
  georgia: 'georgia',
  hawaii: 'hawaii',
  idaho: 'idaho',
  illinois: 'illinois',
  indiana: 'indiana',
  iowa: 'iowa',
  kansas: 'kansas',
  kentucky: 'kentucky',
  louisiana: 'louisiana',
  maine: 'maine',
  maryland: 'maryland',
  massachusetts: 'massachusetts',
  michigan: 'michigan',
  minnesota: 'minnesota',
  mississippi: 'mississippi',
  missouri: 'missouri',
  montana: 'montana',
  nebraska: 'nebraska',
  nevada: 'nevada',
  new_hampshire: 'new_hampshire',
  new_jersey: 'new_jersey',
  new_mexico: 'new_mexico',
  new_york: 'new_york',
  north_carolina: 'north_carolina',
  north_dakota: 'north_dakota',
  ohio: 'ohio',
  oklahoma: 'oklahoma',
  oregon: 'oregon',
  pennsylvania: 'pennsylvania',
  rhode_island: 'rhode_island',
  south_carolina: 'south_carolina',
  south_dakota: 'south_dakota',
  tennessee: 'tennessee',
  texas: 'texas',
  utah: 'utah',
  vermont: 'vermont',
  virginia: 'virginia',
  washington: 'washington',
  west_virginia: 'west_virginia',
  wisconsin: 'wisconsin',
  wyoming: 'wyoming',
  district_of_columbia: 'district_of_columbia',
  american_samoa: 'american_samoa',
  guam: 'guam',
  puerto_rico: 'puerto_rico',
} as const;

export interface TaxConfigurationDumpSchema {
  country_code: CountryEnum;
  /** @nullable */
  description?: TaxConfigurationDumpSchemaDescription;
  id: string;
  name: string;
  rate: string;
  source: TaxSourceEnum;
  state_code: StateEnum;
  sub_configurations: TaxConfigurationChildDumpSchema[];
  /** @nullable */
  tax_liability_account?: TaxConfigurationDumpSchemaTaxLiabilityAccount;
  type: TaxTypeEnum;
}

export interface ProductTaxCodeGroupDumpSchema {
  configurations: TaxConfigurationDumpSchema[];
  id: string;
  is_business_default: boolean;
  name: string;
}

export interface CustomerFileDumpSchema {
  created_at: string;
  file_type: UploadType;
  filename: string;
  id: string;
  url: string;
}

export interface InventoryUploadDumpSchema {
  id: string;
  is_on_order?: boolean;
  upload: CustomerFileDumpSchema;
}

export type CountryEnum = typeof CountryEnum[keyof typeof CountryEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CountryEnum = {
  united_states: 'united_states',
  canada: 'canada',
} as const;

/**
 * @nullable
 */
export type PaginatedCategoryDumpSchemaNext = number | null;

export interface PaginatedCategoryDumpSchema {
  elements: CategoryDumpSchema[];
  /** @nullable */
  next?: PaginatedCategoryDumpSchemaNext;
  total_count: number;
}

export type KlassType = typeof KlassType[keyof typeof KlassType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KlassType = {
  rental: 'rental',
  sale: 'sale',
  service: 'service',
  part: 'part',
  non_rental: 'non_rental',
} as const;

/**
 * @nullable
 */
export type CategoryDumpSchemaUsefulLife = number | null;

/**
 * @nullable
 */
export type CategoryDumpSchemaProductTaxCodeGroupId = string | null;

/**
 * @nullable
 */
export type CategoryDumpSchemaInventoryProfileId = string | null;

/**
 * @nullable
 */
export type CategoryDumpSchemaDepreciationSettingId = string | null;

export interface CategoryDumpSchema {
  /** @nullable */
  depreciation_setting_id?: CategoryDumpSchemaDepreciationSettingId;
  id: string;
  identifier: string;
  /** @nullable */
  inventory_profile_id?: CategoryDumpSchemaInventoryProfileId;
  name: string;
  /** @nullable */
  product_tax_code_group_id?: CategoryDumpSchemaProductTaxCodeGroupId;
  type: KlassType;
  /** @nullable */
  useful_life?: CategoryDumpSchemaUsefulLife;
}

export interface LoginBodySchema {
  body: LoginBodySchemaBody;
}

export interface TokenLoginSchema {
  token: string;
}

export interface LoginRequestBody {
  password: string;
  username: string;
}

export type LoginBodySchemaBody = TokenLoginSchema | LoginRequestBody;

/**
 * @nullable
 */
export type RelatedObjectsBodyStart = number | null;

/**
 * @nullable
 */
export type RelatedObjectsBodyPageSize = number | null;

export interface RelatedObjectsBody {
  model: RelatedObjectEnum;
  /** @nullable */
  page_size?: RelatedObjectsBodyPageSize;
  /** @nullable */
  start?: RelatedObjectsBodyStart;
}

export type JournalEntrySyncSchemaObjectType = typeof JournalEntrySyncSchemaObjectType[keyof typeof JournalEntrySyncSchemaObjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JournalEntrySyncSchemaObjectType = {
  journal_entry: 'journal_entry',
} as const;

export interface JournalEntrySyncSchema {
  /** @nullable */
  filter_list?: JournalEntrySyncSchemaFilterList;
  ids_to_sync: string[];
  object_type: JournalEntrySyncSchemaObjectType;
  sync_all?: boolean;
}

export type RelatedObjectEnum = typeof RelatedObjectEnum[keyof typeof RelatedObjectEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelatedObjectEnum = {
  invoice: 'invoice',
  payment: 'payment',
  deposit: 'deposit',
  purchase_order: 'purchase_order',
} as const;

/**
 * @nullable
 */
export type JournalEntrySearchParamsStatuses = AccountingActionStatus[] | null;

/**
 * @nullable
 */
export type JournalEntrySearchParamsStart = number | null;

/**
 * @nullable
 */
export type JournalEntrySearchParamsRelatedObjects = RelatedObjectEnum[] | null;

/**
 * @nullable
 */
export type JournalEntrySearchParamsPageSize = number | null;

export interface JournalEntrySearchParams {
  /** @nullable */
  page_size?: JournalEntrySearchParamsPageSize;
  /** @nullable */
  related_objects?: JournalEntrySearchParamsRelatedObjects;
  /** @nullable */
  start?: JournalEntrySearchParamsStart;
  /** @nullable */
  statuses?: JournalEntrySearchParamsStatuses;
}

/**
 * @nullable
 */
export type JournalEntrySyncSchemaFilterList = JournalEntrySearchParams | null;

/**
 * @nullable
 */
export type JournalEntryUpdateSchemaDescription = string | null;

export interface JournalEntryUpdateSchema {
  date: string;
  /** @nullable */
  description?: JournalEntryUpdateSchemaDescription;
  line_items: JournalEntryLineItemsUpdateSchema[];
}

/**
 * @nullable
 */
export type JournalEntryLineItemsUpdateSchemaId = string | null;

/**
 * @nullable
 */
export type JournalEntryLineItemsUpdateSchemaDescription = string | null;

export interface JournalEntryLineItemsUpdateSchema {
  amount: string;
  /** @nullable */
  description?: JournalEntryLineItemsUpdateSchemaDescription;
  /** @nullable */
  id: JournalEntryLineItemsUpdateSchemaId;
  line_item_type: LedgerEntryType;
  remote_account_id: string;
  remote_object_ids: string[];
}

/**
 * @nullable
 */
export type JournalEntryHydratedDumpSchemaRemoteSync = AccountingActionDumpSchema | null;

/**
 * @nullable
 */
export type JournalEntryHydratedDumpSchemaPayments = LinkIDSchema[] | null;

/**
 * @nullable
 */
export type JournalEntryHydratedDumpSchemaKlasses = LinkIDNameSchema[] | null;

/**
 * @nullable
 */
export type JournalEntryHydratedDumpSchemaInvoices = LinkIDSchema[] | null;

/**
 * @nullable
 */
export type JournalEntryHydratedDumpSchemaErpLink = string | null;

/**
 * @nullable
 */
export type JournalEntryHydratedDumpSchemaDescription = string | null;

export interface LinkIDSchema {
  id: string;
  identifier: string;
}

/**
 * @nullable
 */
export type JournalEntryHydratedDumpSchemaDeposits = LinkIDSchema[] | null;

export interface LinkIDNameSchema {
  id: string;
  identifier: string;
  name: string;
}

export type LedgerEntryType = typeof LedgerEntryType[keyof typeof LedgerEntryType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LedgerEntryType = {
  debit: 'debit',
  credit: 'credit',
} as const;

/**
 * @nullable
 */
export type JournalEntryLineItemDumpSchemaVendor = RemoteObjectDumpSchema | null;

/**
 * @nullable
 */
export type JournalEntryLineItemDumpSchemaLocation = RemoteObjectDumpSchema | null;

/**
 * @nullable
 */
export type JournalEntryLineItemDumpSchemaDescription = string | null;

/**
 * @nullable
 */
export type JournalEntryLineItemDumpSchemaCustomer = RemoteObjectDumpSchema | null;

export interface JournalEntryLineItemDumpSchema {
  amount: string;
  /** @nullable */
  customer?: JournalEntryLineItemDumpSchemaCustomer;
  /** @nullable */
  description?: JournalEntryLineItemDumpSchemaDescription;
  id: string;
  line_item_type: LedgerEntryType;
  /** @nullable */
  location?: JournalEntryLineItemDumpSchemaLocation;
  remote_account: RemoteAccountDumpSchema;
  /** @nullable */
  vendor?: JournalEntryLineItemDumpSchemaVendor;
}

export interface JournalEntryHydratedDumpSchema {
  date: string;
  /** @nullable */
  deposits?: JournalEntryHydratedDumpSchemaDeposits;
  /** @nullable */
  description?: JournalEntryHydratedDumpSchemaDescription;
  /** @nullable */
  erp_link?: JournalEntryHydratedDumpSchemaErpLink;
  id: string;
  /** @nullable */
  invoices?: JournalEntryHydratedDumpSchemaInvoices;
  /** @nullable */
  klasses?: JournalEntryHydratedDumpSchemaKlasses;
  line_items: JournalEntryLineItemDumpSchema[];
  /** @nullable */
  payments?: JournalEntryHydratedDumpSchemaPayments;
  /** @nullable */
  remote_sync?: JournalEntryHydratedDumpSchemaRemoteSync;
  total_amount: string;
}

/**
 * @nullable
 */
export type PaginatedJournalEntrySchemaNext = number | null;

export interface PaginatedJournalEntrySchema {
  elements: JournalEntryDumpSchema[];
  /** @nullable */
  next?: PaginatedJournalEntrySchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type JournalEntryDumpSchemaRemoteSync = AccountingActionDumpSchema | null;

/**
 * @nullable
 */
export type JournalEntryDumpSchemaErpLink = string | null;

/**
 * @nullable
 */
export type JournalEntryDumpSchemaDescription = string | null;

export interface JournalEntryDumpSchema {
  date: string;
  /** @nullable */
  description?: JournalEntryDumpSchemaDescription;
  /** @nullable */
  erp_link?: JournalEntryDumpSchemaErpLink;
  id: string;
  /** @nullable */
  remote_sync?: JournalEntryDumpSchemaRemoteSync;
  total_amount: string;
}

export type AccountingActionStatus = typeof AccountingActionStatus[keyof typeof AccountingActionStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountingActionStatus = {
  not_synced: 'not_synced',
  ready: 'ready',
  pending: 'pending',
  failed: 'failed',
  synced: 'synced',
} as const;

/**
 * @nullable
 */
export type AccountingActionDumpSchemaSyncedAt = string | null;

/**
 * @nullable
 */
export type AccountingActionDumpSchemaRemoteId = string | null;

/**
 * @nullable
 */
export type AccountingActionDumpSchemaError = string | null;

export interface AccountingActionDumpSchema {
  /** @nullable */
  error?: AccountingActionDumpSchemaError;
  /** @nullable */
  remote_id?: AccountingActionDumpSchemaRemoteId;
  status: AccountingActionStatus;
  /** @nullable */
  synced_at?: AccountingActionDumpSchemaSyncedAt;
}

/**
 * @nullable
 */
export type PaginatedRemoteObjectSchemaNext = number | null;

export interface RemoteObjectDumpSchema {
  active: boolean;
  id: string;
  name: string;
  type: RemoteObjectTypeEnum;
}

export interface PaginatedRemoteObjectSchema {
  elements: RemoteObjectDumpSchema[];
  /** @nullable */
  next?: PaginatedRemoteObjectSchemaNext;
  total_count: number;
}

export type RemoteObjectTypeEnum = typeof RemoteObjectTypeEnum[keyof typeof RemoteObjectTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RemoteObjectTypeEnum = {
  customer: 'customer',
  vendor: 'vendor',
  location: 'location',
} as const;

/**
 * @nullable
 */
export type PaginatedRemoteAccountSchemaNext = number | null;

export interface PaginatedRemoteAccountSchema {
  elements: RemoteAccountDumpSchema[];
  /** @nullable */
  next?: PaginatedRemoteAccountSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type BaseLedgerAccountSchemaParentId = string | null;

/**
 * @nullable
 */
export type BaseLedgerAccountSchemaAccountType = QuickbooksAccountType | null;

/**
 * @nullable
 */
export type BaseLedgerAccountSchemaAccountSubType = QuickbooksSubAccountType | null;

/**
 * @nullable
 */
export type PaginatedLedgerAccountSchemaNext = number | null;

export interface PaginatedLedgerAccountSchema {
  elements: LedgerAccountDumpSchema[];
  /** @nullable */
  next?: PaginatedLedgerAccountSchemaNext;
  total_count: number;
}

export type QuickbooksSubAccountType = typeof QuickbooksSubAccountType[keyof typeof QuickbooksSubAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuickbooksSubAccountType = {
  cash_on_hand: 'cash_on_hand',
  checking: 'checking',
  money_market: 'money_market',
  rents_held_in_trust: 'rents_held_in_trust',
  savings: 'savings',
  trust_accounts: 'trust_accounts',
  cash_and_cash_equivalents: 'cash_and_cash_equivalents',
  other_ear_marked_bank_accounts: 'other_ear_marked_bank_accounts',
  allowance_for_bad_debts: 'allowance_for_bad_debts',
  development_costs: 'development_costs',
  employee_cash_advances: 'employee_cash_advances',
  other_current_assets: 'other_current_assets',
  inventory: 'inventory',
  investment_mortgage_real_estate_loans: 'investment_mortgage_real_estate_loans',
  investment_other: 'investment_other',
  investment_tax_exempt_securities: 'investment_tax_exempt_securities',
  investment_us_government_obligations: 'investment_us_government_obligations',
  loans_to_officers: 'loans_to_officers',
  loans_to_others: 'loans_to_others',
  loans_to_stockholders: 'loans_to_stockholders',
  prepaid_expenses: 'prepaid_expenses',
  retainage: 'retainage',
  undeposited_funds: 'undeposited_funds',
  assets_available_for_sale: 'assets_available_for_sale',
  bal_with_govt_authorities: 'bal_with_govt_authorities',
  called_up_share_capital_not_paid: 'called_up_share_capital_not_paid',
  expenditure_authorisations_and_letters_of_credit: 'expenditure_authorisations_and_letters_of_credit',
  global_tax_deferred: 'global_tax_deferred',
  global_tax_refund: 'global_tax_refund',
  internal_transfers: 'internal_transfers',
  other_consumables: 'other_consumables',
  provisions_current_assets: 'provisions_current_assets',
  short_term_investments_in_related_parties: 'short_term_investments_in_related_parties',
  short_term_loans_and_advances_to_related_parties: 'short_term_loans_and_advances_to_related_parties',
  trade_and_other_receivables: 'trade_and_other_receivables',
  accumulated_depletion: 'accumulated_depletion',
  accumulated_depreciation: 'accumulated_depreciation',
  depletable_assets: 'depletable_assets',
  fixed_asset_computers: 'fixed_asset_computers',
  fixed_asset_copiers: 'fixed_asset_copiers',
  fixed_asset_furniture: 'fixed_asset_furniture',
  fixed_asset_phone: 'fixed_asset_phone',
  fixed_asset_photo_video: 'fixed_asset_photo_video',
  fixed_asset_software: 'fixed_asset_software',
  fixed_asset_other_tools_equipment: 'fixed_asset_other_tools_equipment',
  furniture_and_fixtures: 'furniture_and_fixtures',
  land: 'land',
  leasehold_improvements: 'leasehold_improvements',
  other_fixed_assets: 'other_fixed_assets',
  accumulated_amortization: 'accumulated_amortization',
  buildings: 'buildings',
  intangible_assets: 'intangible_assets',
  machinery_and_equipment: 'machinery_and_equipment',
  vehicles: 'vehicles',
  assets_in_course_of_construction: 'assets_in_course_of_construction',
  capital_wip: 'capital_wip',
  cumulative_depreciation_on_intangible_assets: 'cumulative_depreciation_on_intangible_assets',
  intangible_assets_under_development: 'intangible_assets_under_development',
  land_asset: 'land_asset',
  non_current_assets: 'non_current_assets',
  participating_interests: 'participating_interests',
  provisions_fixed_assets: 'provisions_fixed_assets',
  lease_buyout: 'lease_buyout',
  other_long_term_assets: 'other_long_term_assets',
  security_deposits: 'security_deposits',
  accumulated_amortization_of_other_assets: 'accumulated_amortization_of_other_assets',
  goodwill: 'goodwill',
  licenses: 'licenses',
  organizational_costs: 'organizational_costs',
  assets_held_for_sale: 'assets_held_for_sale',
  available_for_sale_financial_assets: 'available_for_sale_financial_assets',
  deferred_tax: 'deferred_tax',
  investments: 'investments',
  long_term_investments: 'long_term_investments',
  long_term_loans_and_advances_to_related_parties: 'long_term_loans_and_advances_to_related_parties',
  other_intangible_assets: 'other_intangible_assets',
  other_long_term_investments: 'other_long_term_investments',
  other_long_term_loans_and_advances: 'other_long_term_loans_and_advances',
  prepayments_and_accrued_income: 'prepayments_and_accrued_income',
  accounts_receivable: 'accounts_receivable',
  opening_balance_equity: 'opening_balance_equity',
  partners_equity: 'partners_equity',
  retained_earnings: 'retained_earnings',
  accumulated_adjustment: 'accumulated_adjustment',
  owners_equity: 'owners_equity',
  paid_in_capital_or_surplus: 'paid_in_capital_or_surplus',
  partner_contributions: 'partner_contributions',
  partner_distributions: 'partner_distributions',
  preferred_stock: 'preferred_stock',
  common_stock: 'common_stock',
  treasury_stock: 'treasury_stock',
  estimated_taxes: 'estimated_taxes',
  healthcare: 'healthcare',
  personal_income: 'personal_income',
  personal_expense: 'personal_expense',
  accumulated_other_comprehensive_income: 'accumulated_other_comprehensive_income',
  called_up_share_capital: 'called_up_share_capital',
  capital_reserves: 'capital_reserves',
  dividend_disbursed: 'dividend_disbursed',
  equity_in_earnings_of_subsiduaries: 'equity_in_earnings_of_subsiduaries',
  investment_grants: 'investment_grants',
  money_received_against_share_warrants: 'money_received_against_share_warrants',
  other_free_reserves: 'other_free_reserves',
  share_application_money_pending_allotment: 'share_application_money_pending_allotment',
  share_capital: 'share_capital',
  funds: 'funds',
  advertising_promotional: 'advertising_promotional',
  bad_debts: 'bad_debts',
  bank_charges: 'bank_charges',
  charitable_contributions: 'charitable_contributions',
  commissions_and_fees: 'commissions_and_fees',
  entertainment: 'entertainment',
  entertainment_meals: 'entertainment_meals',
  equipment_rental: 'equipment_rental',
  finance_costs: 'finance_costs',
  global_tax_expense: 'global_tax_expense',
  insurance: 'insurance',
  interest_paid: 'interest_paid',
  legal_professional_fees: 'legal_professional_fees',
  office_expenses: 'office_expenses',
  office_general_administrative_expenses: 'office_general_administrative_expenses',
  other_business_expenses: 'other_business_expenses',
  other_miscellaneous_service_cost: 'other_miscellaneous_service_cost',
  promotional_meals: 'promotional_meals',
  rent_or_lease_of_buildings: 'rent_or_lease_of_buildings',
  repair_maintenance: 'repair_maintenance',
  shipping_freight_delivery: 'shipping_freight_delivery',
  supplies_materials: 'supplies_materials',
  travel: 'travel',
  travel_meals: 'travel_meals',
  utilities: 'utilities',
  auto: 'auto',
  cost_of_labor: 'cost_of_labor',
  dues_subscriptions: 'dues_subscriptions',
  payroll_expenses: 'payroll_expenses',
  taxes_paid: 'taxes_paid',
  unapplied_cash_bill_payment_expense: 'unapplied_cash_bill_payment_expense',
  amortization_expense: 'amortization_expense',
  appropriations_to_depreciation: 'appropriations_to_depreciation',
  borrowing_cost: 'borrowing_cost',
  distribution_costs: 'distribution_costs',
  external_services: 'external_services',
  extraordinary_charges: 'extraordinary_charges',
  income_tax_expense: 'income_tax_expense',
  loss_on_discontinued_operations_net_of_tax: 'loss_on_discontinued_operations_net_of_tax',
  management_compensation: 'management_compensation',
  other_current_operating_charges: 'other_current_operating_charges',
  other_external_services: 'other_external_services',
  other_rental_costs: 'other_rental_costs',
  other_selling_expenses: 'other_selling_expenses',
  project_studies_surveys_assessments: 'project_studies_surveys_assessments',
  purchases_rebates: 'purchases_rebates',
  shipping_and_delivery_expense: 'shipping_and_delivery_expense',
  staff_costs: 'staff_costs',
  sundry: 'sundry',
  travel_expenses_general_and_admin_expenses: 'travel_expenses_general_and_admin_expenses',
  travel_expenses_selling_expense: 'travel_expenses_selling_expense',
  depreciation: 'depreciation',
  exchange_gain_or_loss: 'exchange_gain_or_loss',
  other_miscellaneous_expense: 'other_miscellaneous_expense',
  penalties_settlements: 'penalties_settlements',
  amortization: 'amortization',
  gas_and_fuel: 'gas_and_fuel',
  home_office: 'home_office',
  home_owner_rental_insurance: 'home_owner_rental_insurance',
  other_home_office_expenses: 'other_home_office_expenses',
  mortgage_interest: 'mortgage_interest',
  rent_and_lease: 'rent_and_lease',
  repairs_and_maintenance: 'repairs_and_maintenance',
  parking_and_tolls: 'parking_and_tolls',
  vehicle: 'vehicle',
  vehicle_insurance: 'vehicle_insurance',
  vehicle_lease: 'vehicle_lease',
  vehicle_loan_interest: 'vehicle_loan_interest',
  vehicle_loan: 'vehicle_loan',
  vehicle_registration: 'vehicle_registration',
  vehicle_repairs: 'vehicle_repairs',
  other_vehicle_expenses: 'other_vehicle_expenses',
  wash_and_road_services: 'wash_and_road_services',
  deferred_tax_expense: 'deferred_tax_expense',
  depletion: 'depletion',
  exceptional_items: 'exceptional_items',
  extraordinary_items: 'extraordinary_items',
  income_tax_other_expense: 'income_tax_other_expense',
  mat_credit: 'mat_credit',
  prior_period_items: 'prior_period_items',
  tax_roundoff_gain_or_loss: 'tax_roundoff_gain_or_loss',
  equipment_rental_cos: 'equipment_rental_cos',
  other_costs_of_service_cos: 'other_costs_of_service_cos',
  shipping_freight_delivery_cos: 'shipping_freight_delivery_cos',
  supplies_materials_cogs: 'supplies_materials_cogs',
  cost_of_labor_cos: 'cost_of_labor_cos',
  cost_of_sales: 'cost_of_sales',
  freight_and_delivery_cost: 'freight_and_delivery_cost',
  accounts_payable: 'accounts_payable',
  outstanding_dues_micro_small_enterprises: 'outstanding_dues_micro_small_enterprises',
  outstanding_dues_other_than_micro_small_enterprises: 'outstanding_dues_other_than_micro_small_enterprises',
  credit_card: 'credit_card',
  other_long_term_liabilities: 'other_long_term_liabilities',
  shareholder_notes_payable: 'shareholder_notes_payable',
  accruals_and_deferred_income: 'accruals_and_deferred_income',
  accrued_long_term_liabilities: 'accrued_long_term_liabilities',
  accrued_vacation_payable: 'accrued_vacation_payable',
  bank_loans: 'bank_loans',
  debts_related_to_participating_interests: 'debts_related_to_participating_interests',
  deferred_tax_liabilities: 'deferred_tax_liabilities',
  government_and_other_public_authorities: 'government_and_other_public_authorities',
  group_and_associates: 'group_and_associates',
  liabilities_related_to_assets_held_for_sale: 'liabilities_related_to_assets_held_for_sale',
  long_term_borrowings: 'long_term_borrowings',
  long_term_debit: 'long_term_debit',
  long_term_employee_benefit_obligations: 'long_term_employee_benefit_obligations',
  obligations_under_finance_leases: 'obligations_under_finance_leases',
  other_long_term_provisions: 'other_long_term_provisions',
  provision_for_liabilities: 'provision_for_liabilities',
  provisions_non_current_liabilities: 'provisions_non_current_liabilities',
  staff_and_related_long_term_liability_accounts: 'staff_and_related_long_term_liability_accounts',
  direct_deposit_payable: 'direct_deposit_payable',
  line_of_credit: 'line_of_credit',
  loan_payable: 'loan_payable',
  global_tax_payable: 'global_tax_payable',
  global_tax_suspense: 'global_tax_suspense',
  other_current_liabilities: 'other_current_liabilities',
  payroll_clearing: 'payroll_clearing',
  payroll_tax_payable: 'payroll_tax_payable',
  prepaid_expenses_payable: 'prepaid_expenses_payable',
  rents_in_trust_liability: 'rents_in_trust_liability',
  trust_accounts_liabilities: 'trust_accounts_liabilities',
  federal_income_tax_payable: 'federal_income_tax_payable',
  insurance_payable: 'insurance_payable',
  sales_tax_payable: 'sales_tax_payable',
  state_local_income_tax_payable: 'state_local_income_tax_payable',
  accrued_liabilities: 'accrued_liabilities',
  current_liabilities: 'current_liabilities',
  current_portion_employee_benefits_obligations: 'current_portion_employee_benefits_obligations',
  current_portion_of_obligations_under_finance_leases: 'current_portion_of_obligations_under_finance_leases',
  current_tax_liability: 'current_tax_liability',
  dividends_payable: 'dividends_payable',
  duties_and_taxes: 'duties_and_taxes',
  interest_payables: 'interest_payables',
  provision_for_warranty_obligations: 'provision_for_warranty_obligations',
  provisions_current_liabilities: 'provisions_current_liabilities',
  short_term_borrowings: 'short_term_borrowings',
  social_security_agencies: 'social_security_agencies',
  staff_and_related_liability_accounts: 'staff_and_related_liability_accounts',
  sundry_debtors_and_creditors: 'sundry_debtors_and_creditors',
  trade_and_other_payables: 'trade_and_other_payables',
  non_profit_income: 'non_profit_income',
  other_primary_income: 'other_primary_income',
  sales_of_product_income: 'sales_of_product_income',
  service_fee_income: 'service_fee_income',
  discounts_refunds_given: 'discounts_refunds_given',
  unapplied_cash_payment_income: 'unapplied_cash_payment_income',
  cash_receipt_income: 'cash_receipt_income',
  operating_grants: 'operating_grants',
  other_current_operating_income: 'other_current_operating_income',
  own_work_capitalized: 'own_work_capitalized',
  revenue_general: 'revenue_general',
  sales_retail: 'sales_retail',
  sales_wholesale: 'sales_wholesale',
  savings_by_tax_scheme: 'savings_by_tax_scheme',
  dividend_income: 'dividend_income',
  interest_earned: 'interest_earned',
  other_investment_income: 'other_investment_income',
  other_miscellaneous_income: 'other_miscellaneous_income',
  tax_exempt_interest: 'tax_exempt_interest',
  gain_loss_on_sale_of_fixed_assets: 'gain_loss_on_sale_of_fixed_assets',
  gain_loss_on_sale_of_investments: 'gain_loss_on_sale_of_investments',
  loss_on_disposal_of_assets: 'loss_on_disposal_of_assets',
  other_operating_income: 'other_operating_income',
  unrealised_loss_on_securities_net_of_tax: 'unrealised_loss_on_securities_net_of_tax',
} as const;

export type QuickbooksAccountType = typeof QuickbooksAccountType[keyof typeof QuickbooksAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuickbooksAccountType = {
  bank: 'bank',
  other_current_asset: 'other_current_asset',
  fixed_asset: 'fixed_asset',
  other_asset: 'other_asset',
  accounts_receivable: 'accounts_receivable',
  equity: 'equity',
  expense: 'expense',
  other_expense: 'other_expense',
  cost_of_goods_sold: 'cost_of_goods_sold',
  accounts_payable: 'accounts_payable',
  credit_card: 'credit_card',
  long_term_liability: 'long_term_liability',
  other_current_liability: 'other_current_liability',
  income: 'income',
  other_income: 'other_income',
} as const;

export type LedgerAccountType = typeof LedgerAccountType[keyof typeof LedgerAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LedgerAccountType = {
  asset: 'asset',
  contra_asset: 'contra_asset',
  equity: 'equity',
  expense: 'expense',
  liability: 'liability',
  revenue: 'revenue',
} as const;

export interface BaseLedgerAccountSchema {
  account_number: string;
  /** @nullable */
  account_sub_type?: BaseLedgerAccountSchemaAccountSubType;
  /** @nullable */
  account_type?: BaseLedgerAccountSchemaAccountType;
  active?: boolean;
  name: string;
  /** @nullable */
  parent_id?: BaseLedgerAccountSchemaParentId;
  type: LedgerAccountType;
}

/**
 * @nullable
 */
export type LedgerAccountDumpSchemaParentId = string | null;

/**
 * @nullable
 */
export type LedgerAccountDumpSchemaParent = LedgerAccountDumpSchema | null;

/**
 * @nullable
 */
export type LedgerAccountDumpSchemaAccountType = QuickbooksAccountType | null;

/**
 * @nullable
 */
export type LedgerAccountDumpSchemaAccountSubType = QuickbooksSubAccountType | null;

export interface LedgerAccountDumpSchema {
  account_number: string;
  /** @nullable */
  account_sub_type?: LedgerAccountDumpSchemaAccountSubType;
  /** @nullable */
  account_type?: LedgerAccountDumpSchemaAccountType;
  active?: boolean;
  balance: string;
  id: string;
  name: string;
  /** @nullable */
  parent?: LedgerAccountDumpSchemaParent;
  /** @nullable */
  parent_id?: LedgerAccountDumpSchemaParentId;
  type: LedgerAccountType;
}

/**
 * @nullable
 */
export type PaginatedInventoryProfileSchemaNext = number | null;

export type InventoryProfileType = typeof InventoryProfileType[keyof typeof InventoryProfileType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InventoryProfileType = {
  rental: 'rental',
  sales: 'sales',
  parts: 'parts',
  service: 'service',
  re_rentals: 're_rentals',
  non_rental_assets: 'non_rental_assets',
} as const;

export interface InventoryProfileCreateSchema {
  account_mappings: BaseAccountMappingSchema[];
  asset_type: InventoryProfileType;
  name: string;
}

export interface InventoryProfileDumpSchema {
  account_mappings: AccountMappingDumpSchema[];
  asset_type: InventoryProfileType;
  id: string;
  name: string;
}

export interface PaginatedInventoryProfileSchema {
  elements: InventoryProfileDumpSchema[];
  /** @nullable */
  next?: PaginatedInventoryProfileSchemaNext;
  total_count: number;
}

/**
 * @nullable
 */
export type BaseAccountMappingSchemaRemoteAccountId = string | null;

/**
 * @nullable
 */
export type BaseAccountMappingSchemaInventoryProfileId = string | null;

export interface BaseAccountMappingSchema {
  /** @nullable */
  inventory_profile_id?: BaseAccountMappingSchemaInventoryProfileId;
  /** @nullable */
  remote_account_id?: BaseAccountMappingSchemaRemoteAccountId;
  type: MoabAccountType;
}

export interface AccountMappingsCreateSchema {
  mappings: BaseAccountMappingSchema[];
}

/**
 * @nullable
 */
export type RemoteAccountDumpSchemaParentAccount = RemoteAccountDumpSchema | null;

/**
 * @nullable
 */
export type RemoteAccountDumpSchemaAccountNumber = string | null;

export type RemoteAccountClassification = typeof RemoteAccountClassification[keyof typeof RemoteAccountClassification];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RemoteAccountClassification = {
  asset: 'asset',
  contra_asset: 'contra_asset',
  equity: 'equity',
  expense: 'expense',
  liability: 'liability',
  revenue: 'revenue',
} as const;

export interface RemoteAccountDumpSchema {
  /** @nullable */
  account_number?: RemoteAccountDumpSchemaAccountNumber;
  active: boolean;
  classification: RemoteAccountClassification;
  id: string;
  name: string;
  /** @nullable */
  parent_account?: RemoteAccountDumpSchemaParentAccount;
}

export type MoabAccountType = typeof MoabAccountType[keyof typeof MoabAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MoabAccountType = {
  inventory: 'inventory',
  cash: 'cash',
  stripe_cash: 'stripe_cash',
  cash_undeposited_funds: 'cash_undeposited_funds',
  stripe_undeposited_funds: 'stripe_undeposited_funds',
  unbilled_receivables: 'unbilled_receivables',
  accounts_receivable: 'accounts_receivable',
  asset_purchase_expense: 'asset_purchase_expense',
  deferred_revenue: 'deferred_revenue',
  tax_liability: 'tax_liability',
  accumulated_depreciation: 'accumulated_depreciation',
  depreciation_expense: 'depreciation_expense',
  revenue: 'revenue',
  payment_processing_fees: 'payment_processing_fees',
  refund: 'refund',
  bad_debt: 'bad_debt',
  cogs: 'cogs',
} as const;

/**
 * @nullable
 */
export type AccountMappingDumpSchemaRemoteAccountId = string | null;

/**
 * @nullable
 */
export type AccountMappingDumpSchemaRemoteAccount = RemoteAccountDumpSchema | null;

/**
 * @nullable
 */
export type AccountMappingDumpSchemaInventoryProfileId = string | null;

export interface AccountMappingDumpSchema {
  id: string;
  /** @nullable */
  inventory_profile_id?: AccountMappingDumpSchemaInventoryProfileId;
  /** @nullable */
  remote_account?: AccountMappingDumpSchemaRemoteAccount;
  /** @nullable */
  remote_account_id?: AccountMappingDumpSchemaRemoteAccountId;
  type: MoabAccountType;
}

export interface AccountMappingsDumpSchema {
  mappings: AccountMappingDumpSchema[];
}

/**
 * @nullable
 */
export type DepreciationSettingCreateSchemaUsefulLife = number | null;

/**
 * @nullable
 */
export type DepreciationSettingCreateSchemaDepreciationPercentage = string | null;

export interface DepreciationSettingCreateSchema {
  /** @nullable */
  depreciation_frequency?: DepreciationSettingCreateSchemaDepreciationFrequency;
  depreciation_methodology: DepreciationMethodology;
  /** @nullable */
  depreciation_percentage?: DepreciationSettingCreateSchemaDepreciationPercentage;
  first_period_depreciation: FirstPeriodDepreciationSetting;
  is_business_default: boolean;
  name: string;
  /** @nullable */
  useful_life?: DepreciationSettingCreateSchemaUsefulLife;
}

/**
 * @nullable
 */
export type DepreciationSettingUpdateSchemaUsefulLife = number | null;

/**
 * @nullable
 */
export type DepreciationSettingUpdateSchemaDepreciationPercentage = string | null;

/**
 * @nullable
 */
export type DepreciationSettingUpdateSchemaDepreciationFrequency = DepreciationFrequency | null;

/**
 * @nullable
 */
export type PaginatedDepreciationSettingResponseSchemaNext = number | null;

export interface PaginatedDepreciationSettingResponseSchema {
  elements: DepreciationSettingDumpSchema[];
  /** @nullable */
  next?: PaginatedDepreciationSettingResponseSchemaNext;
  total_count: number;
}

export type FirstPeriodDepreciationSetting = typeof FirstPeriodDepreciationSetting[keyof typeof FirstPeriodDepreciationSetting];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FirstPeriodDepreciationSetting = {
  full_month: 'full_month',
  mid_month: 'mid_month',
  zero_month: 'zero_month',
  full_year: 'full_year',
  half_year: 'half_year',
  zero_year: 'zero_year',
} as const;

/**
 * @nullable
 */
export type DepreciationSettingDumpSchemaUsefulLife = number | null;

/**
 * @nullable
 */
export type DepreciationSettingDumpSchemaDepreciationPercentage = string | null;

export type DepreciationMethodology = typeof DepreciationMethodology[keyof typeof DepreciationMethodology];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepreciationMethodology = {
  straight_line: 'straight_line',
} as const;

export interface DepreciationSettingUpdateSchema {
  /** @nullable */
  depreciation_frequency?: DepreciationSettingUpdateSchemaDepreciationFrequency;
  depreciation_methodology: DepreciationMethodology;
  /** @nullable */
  depreciation_percentage?: DepreciationSettingUpdateSchemaDepreciationPercentage;
  first_period_depreciation: FirstPeriodDepreciationSetting;
  is_business_default: boolean;
  name: string;
  /** @nullable */
  useful_life?: DepreciationSettingUpdateSchemaUsefulLife;
}

export type DepreciationFrequency = typeof DepreciationFrequency[keyof typeof DepreciationFrequency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepreciationFrequency = {
  monthly: 'monthly',
  yearly: 'yearly',
} as const;

/**
 * @nullable
 */
export type DepreciationSettingCreateSchemaDepreciationFrequency = DepreciationFrequency | null;

export interface DepreciationSettingDumpSchema {
  depreciation_frequency: DepreciationFrequency;
  depreciation_methodology: DepreciationMethodology;
  /** @nullable */
  depreciation_percentage?: DepreciationSettingDumpSchemaDepreciationPercentage;
  first_period_depreciation: FirstPeriodDepreciationSetting;
  id: string;
  is_business_default: boolean;
  name: string;
  /** @nullable */
  useful_life?: DepreciationSettingDumpSchemaUsefulLife;
}

/**
 * @nullable
 */
export type AccountingAccessDumpSchemaWrapperAccess = AccountingAccessDumpSchema | null;

export interface AccountingAccessDumpSchemaWrapper {
  /** @nullable */
  access?: AccountingAccessDumpSchemaWrapperAccess;
}

export interface SuccessResponse {
  success?: boolean;
}

export interface QBOAuth {
  code: string;
  realm_id: string;
}

export interface ClientAuthUrl {
  url: string;
}

export type AccountingAccessStatus = typeof AccountingAccessStatus[keyof typeof AccountingAccessStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountingAccessStatus = {
  active: 'active',
  pending: 'pending',
  failed: 'failed',
} as const;

export type AccountingAccessProvider = typeof AccountingAccessProvider[keyof typeof AccountingAccessProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountingAccessProvider = {
  quickbooks: 'quickbooks',
  sage_intacct: 'sage_intacct',
} as const;

export interface AccountingAccessDumpSchema {
  business_id: string;
  id: string;
  provider: AccountingAccessProvider;
  status: AccountingAccessStatus;
}

export interface SageAuthSetup {
  company_id: string;
  email: string;
  password: string;
  user_id: string;
}

