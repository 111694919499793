import { urlFactory } from 'routing'

export const accountingUrls = urlFactory('/accounting', {
  detail: urlFactory('/:id', {}),
  edit: urlFactory('/:id/edit', {}),
})

export const assetUrls = urlFactory('/assets', {
  serialized: urlFactory('/serialized', {}),
  bulk: urlFactory('/bulk', {}),
  create: urlFactory('/create', {}),
  detail: urlFactory('/:id', {
    orders: urlFactory('/orders', {}),
    repairs: urlFactory('/repairs', {}),
    usage: urlFactory('/usage', {}),
  }),
  edit: urlFactory('/:id/edit', {}),
  serialize: urlFactory(
    '/serialize/product/:productId/location/:locationId',
    {}
  ),
})

export const categoryUrls = urlFactory('/categories', {
  create: urlFactory('/create', {}),
  detail: urlFactory('/:id', {
    klasses: urlFactory('/classes', {}),
    assets: urlFactory('/assets', {}),
  }),
  edit: urlFactory('/:id/edit', {}),
})

export const klassUrls = urlFactory('/classes', {
  create: urlFactory('/create', {}),
  detail: urlFactory('/:id', {
    assets: urlFactory('/assets', {}),
    orders: urlFactory('/orders', {}),
    purchaseOrders: urlFactory('/purchase-orders', {}),
  }),
  edit: urlFactory('/:id/edit', {}),
})

export const customerUrls = urlFactory('/customers', {
  create: urlFactory('/create', {}),
  detail: urlFactory('/:id', {
    orders: urlFactory('/orders', {}),
    invoices: urlFactory('/invoices', {}),
    payments: urlFactory('/payments', {}),
  }),
  edit: urlFactory('/:id/edit', {}),
})

export const vendorUrls = urlFactory('/vendors', {
  create: urlFactory('/create', {}),
  detail: urlFactory('/:id', {
    purchaseOrders: urlFactory('/purchase-orders', {}),
  }),
  edit: urlFactory('/:id/edit', {}),
})

export const reportingUrls = urlFactory('/reporting', {})

export const dispatchUrls = urlFactory('/dispatch', {
  route: urlFactory('/routes/:routeId', {}),
})

export const driverExperienceUrls = urlFactory('/driver', {
  route: urlFactory('/routes/:routeId', {
    stop: urlFactory('/stops/:stopId', {
      activity: urlFactory('/activity', {}),
      klassChecklists: urlFactory(
        '/checklists/klasses/:direction/:klassId',
        {}
      ),
      checklist: urlFactory('/checklists/:checklistType/:checklistId', {}),
    }),
  }),
})

export const purchaseOrderUrls = urlFactory('/purchase-orders', {
  create: urlFactory('/create', {}),
  detail: urlFactory('/:id', {
    activity: urlFactory('/activity', {}),
  }),
  edit: urlFactory('/:id/edit', {}),
  bills: urlFactory('/:purchaseOrderId/bills', {
    create: urlFactory('/create', {}),
    detail: urlFactory('/:vendorBillId', {}),
    edit: urlFactory('/:vendorBillId/edit', {}),
  }),
  itemReceipts: urlFactory('/:purchaseOrderId/item-receipts', {
    create: urlFactory('/create', {}),
    detail: urlFactory('/:itemReceiptId', {}),
    edit: urlFactory('/:itemReceiptId/edit', {}),
    serialize: urlFactory('/:itemReceiptId/serialize', {
      product: urlFactory('/:productId', {}),
    }),
  }),
})

export const repairsUrls = urlFactory('/repairs', {
  create: urlFactory('/create', {}),
  detail: urlFactory('/:id', {
    activity: urlFactory('/activity', {}),
  }),
  edit: urlFactory('/:id/edit', {}),
  technicianWork: urlFactory('/work/:id', {
    editLineItems: urlFactory('/edit-line-items', {}),
    activity: urlFactory('/activity', {}),
  }),
})

export { settingsUrls } from './Settings/settingsUrls'
