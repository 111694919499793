/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  CategoryCreateSchema,
  CategoryHydratedDumpSchema,
  CategoryUpdateSchema,
  GetCategoriesParams,
  PaginatedCategoryDumpSchema,
  SuccessResponse
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getCategories = (
    params?: GetCategoriesParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedCategoryDumpSchema>(
      {url: `/category/categories`, method: 'GET',
        params, signal
    },
      options);
    }


export const getGetCategoriesQueryKey = (params?: GetCategoriesParams,) => {
    return [`/category/categories`, ...(params ? [params]: [])] as const;
    }


export const getGetCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getCategories>>, TError = ErrorType<unknown>>(params?: GetCategoriesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoriesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategories>>> = ({ signal }) => getCategories(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getCategories>>>
export type GetCategoriesQueryError = ErrorType<unknown>


export function useGetCategories<TData = Awaited<ReturnType<typeof getCategories>>, TError = ErrorType<unknown>>(
 params: undefined |  GetCategoriesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCategories>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategories<TData = Awaited<ReturnType<typeof getCategories>>, TError = ErrorType<unknown>>(
 params?: GetCategoriesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCategories>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategories<TData = Awaited<ReturnType<typeof getCategories>>, TError = ErrorType<unknown>>(
 params?: GetCategoriesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCategories<TData = Awaited<ReturnType<typeof getCategories>>, TError = ErrorType<unknown>>(
 params?: GetCategoriesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCategoriesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCategoriesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCategories>>, TError = ErrorType<unknown>>(params?: GetCategoriesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoriesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategories>>> = ({ signal }) => getCategories(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCategoriesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCategories>>>
export type GetCategoriesSuspenseQueryError = ErrorType<unknown>


export function useGetCategoriesSuspense<TData = Awaited<ReturnType<typeof getCategories>>, TError = ErrorType<unknown>>(
 params: undefined |  GetCategoriesParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategoriesSuspense<TData = Awaited<ReturnType<typeof getCategories>>, TError = ErrorType<unknown>>(
 params?: GetCategoriesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategoriesSuspense<TData = Awaited<ReturnType<typeof getCategories>>, TError = ErrorType<unknown>>(
 params?: GetCategoriesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCategoriesSuspense<TData = Awaited<ReturnType<typeof getCategories>>, TError = ErrorType<unknown>>(
 params?: GetCategoriesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCategoriesSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createCategory = (
    categoryCreateSchema: BodyType<CategoryCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CategoryHydratedDumpSchema>(
      {url: `/category/categories`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: categoryCreateSchema
    },
      options);
    }


export const getCategory = (
    categoryId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<CategoryHydratedDumpSchema>(
      {url: `/category/categories/${categoryId}`, method: 'GET', signal
    },
      options);
    }


export const getGetCategoryQueryKey = (categoryId: string,) => {
    return [`/category/categories/${categoryId}`] as const;
    }


export const getGetCategoryQueryOptions = <TData = Awaited<ReturnType<typeof getCategory>>, TError = ErrorType<unknown>>(categoryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoryQueryKey(categoryId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategory>>> = ({ signal }) => getCategory(categoryId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(categoryId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof getCategory>>>
export type GetCategoryQueryError = ErrorType<unknown>


export function useGetCategory<TData = Awaited<ReturnType<typeof getCategory>>, TError = ErrorType<unknown>>(
 categoryId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCategory>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategory<TData = Awaited<ReturnType<typeof getCategory>>, TError = ErrorType<unknown>>(
 categoryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCategory>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategory<TData = Awaited<ReturnType<typeof getCategory>>, TError = ErrorType<unknown>>(
 categoryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCategory<TData = Awaited<ReturnType<typeof getCategory>>, TError = ErrorType<unknown>>(
 categoryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCategoryQueryOptions(categoryId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCategorySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCategory>>, TError = ErrorType<unknown>>(categoryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoryQueryKey(categoryId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategory>>> = ({ signal }) => getCategory(categoryId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(categoryId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCategorySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCategory>>>
export type GetCategorySuspenseQueryError = ErrorType<unknown>


export function useGetCategorySuspense<TData = Awaited<ReturnType<typeof getCategory>>, TError = ErrorType<unknown>>(
 categoryId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategorySuspense<TData = Awaited<ReturnType<typeof getCategory>>, TError = ErrorType<unknown>>(
 categoryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategorySuspense<TData = Awaited<ReturnType<typeof getCategory>>, TError = ErrorType<unknown>>(
 categoryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetCategorySuspense<TData = Awaited<ReturnType<typeof getCategory>>, TError = ErrorType<unknown>>(
 categoryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCategorySuspenseQueryOptions(categoryId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateCategory = (
    categoryId: string,
    categoryUpdateSchema: BodyType<CategoryUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CategoryHydratedDumpSchema>(
      {url: `/category/categories/${categoryId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: categoryUpdateSchema
    },
      options);
    }


export const deleteCategory = (
    categoryId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/category/categories/${categoryId}`, method: 'DELETE'
    },
      options);
    }


