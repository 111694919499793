import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
  type ClientRect,
  type DragEndEvent,
  type DragStartEvent,
  type Modifier,
  type UniqueIdentifier,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS, type Transform } from '@dnd-kit/utilities'
import { Portal } from '@mantine/core'
import { Fragment, useEffect, useState, type ReactNode } from 'react'

import { type KuiThemeSpacingSize } from './_internal/theme'
import { KuiButton } from './KuiButton'
import { KuiStack } from './KuiStack'

export type KuiSortableListProps<TItem> = {
  items: TItem[]
  getItemId: (item: TItem) => string
  getItemData?: (item: TItem) => any
  getItemIsDisabled?: (item: TItem) => boolean
  disabled?: boolean
  gapSize?: KuiThemeSpacingSize
  renderItem: (_: { item: TItem; dragHandle: ReactNode }) => ReactNode
} & (
  | {
      withContext?: true
      onSort: (items: TItem[]) => void
    }
  | {
      withContext: false
      onSort?: never
    }
)

export function KuiSortableList<TItem>({
  items: consumerItems,
  getItemId,
  getItemData,
  getItemIsDisabled = () => false,
  renderItem,
  withContext = true,
  disabled = false,
  gapSize = 'xs',
  onSort,
}: KuiSortableListProps<TItem>) {
  const items = consumerItems.map((item) => ({ id: getItemId(item), item }))

  const sortableList = (
    // <div style={{ overflowY: 'scroll' }}>
    <KuiStack gapSize={gapSize}>
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}
        disabled={disabled}
      >
        {items.map(({ id, item }) => (
          <KuiSortableListItem
            key={id}
            id={id}
            data={getItemData?.(item)}
            disabled={disabled || getItemIsDisabled?.(item)}
            item={item}
            renderItem={renderItem}
          />
        ))}
      </SortableContext>
    </KuiStack>
    // </div>
  )

  if (withContext && onSort) {
    return (
      <KuiSortableListContext
        items={consumerItems}
        getItemId={getItemId}
        renderItem={renderItem}
        onSort={onSort}
      >
        {sortableList}
      </KuiSortableListContext>
    )
  }

  return sortableList
}

type KuiSortableListContextProps<TItem> = {
  items: TItem[]
  getItemId: (item: TItem) => string
  renderItem: (_: { item: TItem; dragHandle: ReactNode }) => ReactNode
  onSort: (items: TItem[]) => void
  children: ReactNode
}

function KuiSortableListContext<TItem>({
  items: consumerItems,
  getItemId,
  renderItem,
  onSort,
  children,
}: KuiSortableListContextProps<TItem>) {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const items = consumerItems.map((item) => ({ id: getItemId(item), item }))
  const activeItem = items.find(({ id }) => id === activeId)?.item

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={() => setActiveId(null)}
      modifiers={[restrictToFirstScrollableAncestor]}
    >
      <Fragment>
        {children}

        <Portal>
          <DragOverlay
            dropAnimation={{
              sideEffects: defaultDropAnimationSideEffects({
                styles: { active: { opacity: '0.5' } },
              }),
            }}
          >
            {activeItem ? (
              <DragOverlayRoot>
                {renderItem({
                  item: activeItem,
                  dragHandle: (
                    <KuiButton
                      size='xs'
                      iconType='grip-vertical'
                      _style={{ cursor: 'grabbing' }}
                    />
                  ),
                })}
              </DragOverlayRoot>
            ) : null}
          </DragOverlay>
        </Portal>
      </Fragment>
    </DndContext>
  )

  function handleDragStart(event: DragStartEvent) {
    const { active } = event

    setActiveId(active.id)
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (over && active.id !== over.id) {
      const oldIndex = consumerItems.findIndex(
        (item) => getItemId(item) === active.id
      )
      const newIndex = consumerItems.findIndex(
        (item) => getItemId(item) === over.id
      )

      onSort(arrayMove(consumerItems, oldIndex, newIndex))
    }

    setActiveId(null)
  }
}

export function arrayMove<T>(array: T[], from: number, to: number): T[] {
  const newArray = array.slice()
  newArray.splice(
    to < 0 ? newArray.length + to : to,
    0,
    newArray.splice(from, 1)[0]
  )

  return newArray
}

function DragOverlayRoot({ children }: { children: ReactNode }) {
  useEffect(() => {
    document.body.style.cursor = 'grabbing'

    return () => {
      document.body.style.cursor = ''
    }
  }, [])

  return children
}

type KuiSortableListItemProps<TItem> = {
  id: string
  data?: any
  item: TItem
  disabled: boolean
  renderItem: (_: { item: TItem; dragHandle: ReactNode }) => ReactNode
}

function KuiSortableListItem<TItem>({
  id,
  data,
  item,
  disabled,
  renderItem,
}: KuiSortableListItemProps<TItem>) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data,
    disabled,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  }

  const dragHandle = (
    <KuiButton
      ref={setActivatorNodeRef}
      size='xs'
      iconType='grip-vertical'
      {...listeners}
      _style={{ cursor: 'grab' }}
    />
  )

  return (
    <div ref={setNodeRef} style={style} {...attributes} tabIndex={-1}>
      {renderItem({ item, dragHandle })}
    </div>
  )
}

const restrictToFirstScrollableAncestor: Modifier = ({
  draggingNodeRect,
  transform,
  scrollableAncestorRects,
}) => {
  const firstScrollableAncestorRect = scrollableAncestorRects[0]

  if (!draggingNodeRect || !firstScrollableAncestorRect) {
    return transform
  }

  return restrictToBoundingRect(
    transform,
    draggingNodeRect,
    firstScrollableAncestorRect
  )
}

function restrictToBoundingRect(
  transform: Transform,
  rect: ClientRect,
  boundingRect: ClientRect
): Transform {
  const value = {
    ...transform,
  }

  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top
  } else if (
    rect.bottom + transform.y >=
    boundingRect.top + boundingRect.height
  ) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom
  }

  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left
  } else if (
    rect.right + transform.x >=
    boundingRect.left + boundingRect.width
  ) {
    value.x = boundingRect.left + boundingRect.width - rect.right
  }

  return value
}
