/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  BaseTermsAndConditionsAddendumSchema,
  BusinessDumpSchema,
  BusinessUpdateSchema,
  DocumentConfigurationsDumpSchema,
  DocumentConfigurationsUpdateSchema,
  ListTermsAndConditionsAddendumsParams,
  LocationCreateSchema,
  LocationGroupCreateSchema,
  LocationGroupHydratedDumpSchema,
  LocationGroupListParams,
  LocationGroupUpdateSchema,
  LocationHydratedDumpSchema,
  LocationListParams,
  PaginatedLocationGroupSchema,
  PaginatedLocationSchema,
  PaginatedTermsAndConditionsAddendumSchema,
  SuccessResponse,
  TermsAndConditionsAddendumDumpSchema
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const currentBusiness = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<BusinessDumpSchema>(
      {url: `/business/current-business`, method: 'GET', signal
    },
      options);
    }


export const getCurrentBusinessQueryKey = () => {
    return [`/business/current-business`] as const;
    }


export const getCurrentBusinessQueryOptions = <TData = Awaited<ReturnType<typeof currentBusiness>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCurrentBusinessQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof currentBusiness>>> = ({ signal }) => currentBusiness(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData> & { queryKey: QueryKey }
}

export type CurrentBusinessQueryResult = NonNullable<Awaited<ReturnType<typeof currentBusiness>>>
export type CurrentBusinessQueryError = ErrorType<unknown>


export function useCurrentBusiness<TData = Awaited<ReturnType<typeof currentBusiness>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof currentBusiness>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useCurrentBusiness<TData = Awaited<ReturnType<typeof currentBusiness>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof currentBusiness>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useCurrentBusiness<TData = Awaited<ReturnType<typeof currentBusiness>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useCurrentBusiness<TData = Awaited<ReturnType<typeof currentBusiness>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getCurrentBusinessQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCurrentBusinessSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof currentBusiness>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCurrentBusinessQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof currentBusiness>>> = ({ signal }) => currentBusiness(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData> & { queryKey: QueryKey }
}

export type CurrentBusinessSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof currentBusiness>>>
export type CurrentBusinessSuspenseQueryError = ErrorType<unknown>


export function useCurrentBusinessSuspense<TData = Awaited<ReturnType<typeof currentBusiness>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useCurrentBusinessSuspense<TData = Awaited<ReturnType<typeof currentBusiness>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useCurrentBusinessSuspense<TData = Awaited<ReturnType<typeof currentBusiness>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useCurrentBusinessSuspense<TData = Awaited<ReturnType<typeof currentBusiness>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof currentBusiness>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getCurrentBusinessSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateCurrentBusiness = (
    businessUpdateSchema: BodyType<BusinessUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<BusinessDumpSchema>(
      {url: `/business/current-business`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: businessUpdateSchema
    },
      options);
    }


export const locationList = (
    params?: LocationListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedLocationSchema>(
      {url: `/business/locations`, method: 'GET',
        params, signal
    },
      options);
    }


export const getLocationListQueryKey = (params?: LocationListParams,) => {
    return [`/business/locations`, ...(params ? [params]: [])] as const;
    }


export const getLocationListQueryOptions = <TData = Awaited<ReturnType<typeof locationList>>, TError = ErrorType<unknown>>(params?: LocationListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLocationListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof locationList>>> = ({ signal }) => locationList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData> & { queryKey: QueryKey }
}

export type LocationListQueryResult = NonNullable<Awaited<ReturnType<typeof locationList>>>
export type LocationListQueryError = ErrorType<unknown>


export function useLocationList<TData = Awaited<ReturnType<typeof locationList>>, TError = ErrorType<unknown>>(
 params: undefined |  LocationListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof locationList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationList<TData = Awaited<ReturnType<typeof locationList>>, TError = ErrorType<unknown>>(
 params?: LocationListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof locationList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationList<TData = Awaited<ReturnType<typeof locationList>>, TError = ErrorType<unknown>>(
 params?: LocationListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLocationList<TData = Awaited<ReturnType<typeof locationList>>, TError = ErrorType<unknown>>(
 params?: LocationListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLocationListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getLocationListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof locationList>>, TError = ErrorType<unknown>>(params?: LocationListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLocationListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof locationList>>> = ({ signal }) => locationList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData> & { queryKey: QueryKey }
}

export type LocationListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof locationList>>>
export type LocationListSuspenseQueryError = ErrorType<unknown>


export function useLocationListSuspense<TData = Awaited<ReturnType<typeof locationList>>, TError = ErrorType<unknown>>(
 params: undefined |  LocationListParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationListSuspense<TData = Awaited<ReturnType<typeof locationList>>, TError = ErrorType<unknown>>(
 params?: LocationListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationListSuspense<TData = Awaited<ReturnType<typeof locationList>>, TError = ErrorType<unknown>>(
 params?: LocationListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLocationListSuspense<TData = Awaited<ReturnType<typeof locationList>>, TError = ErrorType<unknown>>(
 params?: LocationListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLocationListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const locationCreate = (
    locationCreateSchema: BodyType<LocationCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<LocationHydratedDumpSchema>(
      {url: `/business/locations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: locationCreateSchema
    },
      options);
    }


export const locationGet = (
    locationId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<LocationHydratedDumpSchema>(
      {url: `/business/locations/${locationId}`, method: 'GET', signal
    },
      options);
    }


export const getLocationGetQueryKey = (locationId: string,) => {
    return [`/business/locations/${locationId}`] as const;
    }


export const getLocationGetQueryOptions = <TData = Awaited<ReturnType<typeof locationGet>>, TError = ErrorType<unknown>>(locationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLocationGetQueryKey(locationId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof locationGet>>> = ({ signal }) => locationGet(locationId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(locationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData> & { queryKey: QueryKey }
}

export type LocationGetQueryResult = NonNullable<Awaited<ReturnType<typeof locationGet>>>
export type LocationGetQueryError = ErrorType<unknown>


export function useLocationGet<TData = Awaited<ReturnType<typeof locationGet>>, TError = ErrorType<unknown>>(
 locationId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof locationGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGet<TData = Awaited<ReturnType<typeof locationGet>>, TError = ErrorType<unknown>>(
 locationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof locationGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGet<TData = Awaited<ReturnType<typeof locationGet>>, TError = ErrorType<unknown>>(
 locationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLocationGet<TData = Awaited<ReturnType<typeof locationGet>>, TError = ErrorType<unknown>>(
 locationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLocationGetQueryOptions(locationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getLocationGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof locationGet>>, TError = ErrorType<unknown>>(locationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLocationGetQueryKey(locationId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof locationGet>>> = ({ signal }) => locationGet(locationId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(locationId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData> & { queryKey: QueryKey }
}

export type LocationGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof locationGet>>>
export type LocationGetSuspenseQueryError = ErrorType<unknown>


export function useLocationGetSuspense<TData = Awaited<ReturnType<typeof locationGet>>, TError = ErrorType<unknown>>(
 locationId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGetSuspense<TData = Awaited<ReturnType<typeof locationGet>>, TError = ErrorType<unknown>>(
 locationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGetSuspense<TData = Awaited<ReturnType<typeof locationGet>>, TError = ErrorType<unknown>>(
 locationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLocationGetSuspense<TData = Awaited<ReturnType<typeof locationGet>>, TError = ErrorType<unknown>>(
 locationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLocationGetSuspenseQueryOptions(locationId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateLocation = (
    locationId: string,
    locationCreateSchema: BodyType<LocationCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<LocationHydratedDumpSchema>(
      {url: `/business/locations/${locationId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: locationCreateSchema
    },
      options);
    }


export const locationDelete = (
    locationId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/business/locations/${locationId}`, method: 'DELETE'
    },
      options);
    }


export const locationGroupList = (
    params?: LocationGroupListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedLocationGroupSchema>(
      {url: `/business/location-groups`, method: 'GET',
        params, signal
    },
      options);
    }


export const getLocationGroupListQueryKey = (params?: LocationGroupListParams,) => {
    return [`/business/location-groups`, ...(params ? [params]: [])] as const;
    }


export const getLocationGroupListQueryOptions = <TData = Awaited<ReturnType<typeof locationGroupList>>, TError = ErrorType<unknown>>(params?: LocationGroupListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLocationGroupListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof locationGroupList>>> = ({ signal }) => locationGroupList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData> & { queryKey: QueryKey }
}

export type LocationGroupListQueryResult = NonNullable<Awaited<ReturnType<typeof locationGroupList>>>
export type LocationGroupListQueryError = ErrorType<unknown>


export function useLocationGroupList<TData = Awaited<ReturnType<typeof locationGroupList>>, TError = ErrorType<unknown>>(
 params: undefined |  LocationGroupListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof locationGroupList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGroupList<TData = Awaited<ReturnType<typeof locationGroupList>>, TError = ErrorType<unknown>>(
 params?: LocationGroupListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof locationGroupList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGroupList<TData = Awaited<ReturnType<typeof locationGroupList>>, TError = ErrorType<unknown>>(
 params?: LocationGroupListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLocationGroupList<TData = Awaited<ReturnType<typeof locationGroupList>>, TError = ErrorType<unknown>>(
 params?: LocationGroupListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLocationGroupListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getLocationGroupListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof locationGroupList>>, TError = ErrorType<unknown>>(params?: LocationGroupListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLocationGroupListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof locationGroupList>>> = ({ signal }) => locationGroupList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData> & { queryKey: QueryKey }
}

export type LocationGroupListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof locationGroupList>>>
export type LocationGroupListSuspenseQueryError = ErrorType<unknown>


export function useLocationGroupListSuspense<TData = Awaited<ReturnType<typeof locationGroupList>>, TError = ErrorType<unknown>>(
 params: undefined |  LocationGroupListParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGroupListSuspense<TData = Awaited<ReturnType<typeof locationGroupList>>, TError = ErrorType<unknown>>(
 params?: LocationGroupListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGroupListSuspense<TData = Awaited<ReturnType<typeof locationGroupList>>, TError = ErrorType<unknown>>(
 params?: LocationGroupListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLocationGroupListSuspense<TData = Awaited<ReturnType<typeof locationGroupList>>, TError = ErrorType<unknown>>(
 params?: LocationGroupListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLocationGroupListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const locationGroupCreate = (
    locationGroupCreateSchema: BodyType<LocationGroupCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<LocationGroupHydratedDumpSchema>(
      {url: `/business/location-groups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: locationGroupCreateSchema
    },
      options);
    }


export const locationGroupGet = (
    locationGroupId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<LocationGroupHydratedDumpSchema>(
      {url: `/business/location-groups/${locationGroupId}`, method: 'GET', signal
    },
      options);
    }


export const getLocationGroupGetQueryKey = (locationGroupId: string,) => {
    return [`/business/location-groups/${locationGroupId}`] as const;
    }


export const getLocationGroupGetQueryOptions = <TData = Awaited<ReturnType<typeof locationGroupGet>>, TError = ErrorType<unknown>>(locationGroupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLocationGroupGetQueryKey(locationGroupId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof locationGroupGet>>> = ({ signal }) => locationGroupGet(locationGroupId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(locationGroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData> & { queryKey: QueryKey }
}

export type LocationGroupGetQueryResult = NonNullable<Awaited<ReturnType<typeof locationGroupGet>>>
export type LocationGroupGetQueryError = ErrorType<unknown>


export function useLocationGroupGet<TData = Awaited<ReturnType<typeof locationGroupGet>>, TError = ErrorType<unknown>>(
 locationGroupId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof locationGroupGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGroupGet<TData = Awaited<ReturnType<typeof locationGroupGet>>, TError = ErrorType<unknown>>(
 locationGroupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof locationGroupGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGroupGet<TData = Awaited<ReturnType<typeof locationGroupGet>>, TError = ErrorType<unknown>>(
 locationGroupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLocationGroupGet<TData = Awaited<ReturnType<typeof locationGroupGet>>, TError = ErrorType<unknown>>(
 locationGroupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLocationGroupGetQueryOptions(locationGroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getLocationGroupGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof locationGroupGet>>, TError = ErrorType<unknown>>(locationGroupId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLocationGroupGetQueryKey(locationGroupId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof locationGroupGet>>> = ({ signal }) => locationGroupGet(locationGroupId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(locationGroupId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData> & { queryKey: QueryKey }
}

export type LocationGroupGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof locationGroupGet>>>
export type LocationGroupGetSuspenseQueryError = ErrorType<unknown>


export function useLocationGroupGetSuspense<TData = Awaited<ReturnType<typeof locationGroupGet>>, TError = ErrorType<unknown>>(
 locationGroupId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGroupGetSuspense<TData = Awaited<ReturnType<typeof locationGroupGet>>, TError = ErrorType<unknown>>(
 locationGroupId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLocationGroupGetSuspense<TData = Awaited<ReturnType<typeof locationGroupGet>>, TError = ErrorType<unknown>>(
 locationGroupId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLocationGroupGetSuspense<TData = Awaited<ReturnType<typeof locationGroupGet>>, TError = ErrorType<unknown>>(
 locationGroupId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof locationGroupGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLocationGroupGetSuspenseQueryOptions(locationGroupId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const locationGroupPatch = (
    locationGroupId: string,
    locationGroupUpdateSchema: BodyType<LocationGroupUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<LocationGroupHydratedDumpSchema>(
      {url: `/business/location-groups/${locationGroupId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: locationGroupUpdateSchema
    },
      options);
    }


export const locationGroupDelete = (
    locationGroupId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/business/location-groups/${locationGroupId}`, method: 'DELETE'
    },
      options);
    }


export const documentConfigurationList = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<DocumentConfigurationsDumpSchema>(
      {url: `/business/document-configurations`, method: 'GET', signal
    },
      options);
    }


export const getDocumentConfigurationListQueryKey = () => {
    return [`/business/document-configurations`] as const;
    }


export const getDocumentConfigurationListQueryOptions = <TData = Awaited<ReturnType<typeof documentConfigurationList>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDocumentConfigurationListQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof documentConfigurationList>>> = ({ signal }) => documentConfigurationList(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData> & { queryKey: QueryKey }
}

export type DocumentConfigurationListQueryResult = NonNullable<Awaited<ReturnType<typeof documentConfigurationList>>>
export type DocumentConfigurationListQueryError = ErrorType<unknown>


export function useDocumentConfigurationList<TData = Awaited<ReturnType<typeof documentConfigurationList>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof documentConfigurationList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDocumentConfigurationList<TData = Awaited<ReturnType<typeof documentConfigurationList>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof documentConfigurationList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDocumentConfigurationList<TData = Awaited<ReturnType<typeof documentConfigurationList>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useDocumentConfigurationList<TData = Awaited<ReturnType<typeof documentConfigurationList>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getDocumentConfigurationListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDocumentConfigurationListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof documentConfigurationList>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDocumentConfigurationListQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof documentConfigurationList>>> = ({ signal }) => documentConfigurationList(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData> & { queryKey: QueryKey }
}

export type DocumentConfigurationListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof documentConfigurationList>>>
export type DocumentConfigurationListSuspenseQueryError = ErrorType<unknown>


export function useDocumentConfigurationListSuspense<TData = Awaited<ReturnType<typeof documentConfigurationList>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDocumentConfigurationListSuspense<TData = Awaited<ReturnType<typeof documentConfigurationList>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDocumentConfigurationListSuspense<TData = Awaited<ReturnType<typeof documentConfigurationList>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useDocumentConfigurationListSuspense<TData = Awaited<ReturnType<typeof documentConfigurationList>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof documentConfigurationList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getDocumentConfigurationListSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const documentConfigurationUpdate = (
    documentConfigurationsUpdateSchema: BodyType<DocumentConfigurationsUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DocumentConfigurationsDumpSchema>(
      {url: `/business/document-configurations`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: documentConfigurationsUpdateSchema
    },
      options);
    }


export const createTermsAndConditionsAddendum = (
    baseTermsAndConditionsAddendumSchema: BodyType<BaseTermsAndConditionsAddendumSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<TermsAndConditionsAddendumDumpSchema>(
      {url: `/business/terms-and-conditions-addendums`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: baseTermsAndConditionsAddendumSchema
    },
      options);
    }


export const listTermsAndConditionsAddendums = (
    params?: ListTermsAndConditionsAddendumsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedTermsAndConditionsAddendumSchema>(
      {url: `/business/terms-and-conditions-addendums`, method: 'GET',
        params, signal
    },
      options);
    }


export const getListTermsAndConditionsAddendumsQueryKey = (params?: ListTermsAndConditionsAddendumsParams,) => {
    return [`/business/terms-and-conditions-addendums`, ...(params ? [params]: [])] as const;
    }


export const getListTermsAndConditionsAddendumsQueryOptions = <TData = Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError = ErrorType<unknown>>(params?: ListTermsAndConditionsAddendumsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTermsAndConditionsAddendumsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>> = ({ signal }) => listTermsAndConditionsAddendums(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData> & { queryKey: QueryKey }
}

export type ListTermsAndConditionsAddendumsQueryResult = NonNullable<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>>
export type ListTermsAndConditionsAddendumsQueryError = ErrorType<unknown>


export function useListTermsAndConditionsAddendums<TData = Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError = ErrorType<unknown>>(
 params: undefined |  ListTermsAndConditionsAddendumsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListTermsAndConditionsAddendums<TData = Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError = ErrorType<unknown>>(
 params?: ListTermsAndConditionsAddendumsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListTermsAndConditionsAddendums<TData = Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError = ErrorType<unknown>>(
 params?: ListTermsAndConditionsAddendumsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useListTermsAndConditionsAddendums<TData = Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError = ErrorType<unknown>>(
 params?: ListTermsAndConditionsAddendumsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getListTermsAndConditionsAddendumsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListTermsAndConditionsAddendumsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError = ErrorType<unknown>>(params?: ListTermsAndConditionsAddendumsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTermsAndConditionsAddendumsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>> = ({ signal }) => listTermsAndConditionsAddendums(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData> & { queryKey: QueryKey }
}

export type ListTermsAndConditionsAddendumsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>>
export type ListTermsAndConditionsAddendumsSuspenseQueryError = ErrorType<unknown>


export function useListTermsAndConditionsAddendumsSuspense<TData = Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError = ErrorType<unknown>>(
 params: undefined |  ListTermsAndConditionsAddendumsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListTermsAndConditionsAddendumsSuspense<TData = Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError = ErrorType<unknown>>(
 params?: ListTermsAndConditionsAddendumsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListTermsAndConditionsAddendumsSuspense<TData = Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError = ErrorType<unknown>>(
 params?: ListTermsAndConditionsAddendumsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useListTermsAndConditionsAddendumsSuspense<TData = Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError = ErrorType<unknown>>(
 params?: ListTermsAndConditionsAddendumsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTermsAndConditionsAddendums>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getListTermsAndConditionsAddendumsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateTermsAndConditionsAddendum = (
    termsAndConditionsAddendumId: string,
    baseTermsAndConditionsAddendumSchema: BodyType<BaseTermsAndConditionsAddendumSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<TermsAndConditionsAddendumDumpSchema>(
      {url: `/business/terms-and-conditions-addendums/${termsAndConditionsAddendumId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: baseTermsAndConditionsAddendumSchema
    },
      options);
    }


export const getTermsAndConditionsAddendum = (
    termsAndConditionsAddendumId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<TermsAndConditionsAddendumDumpSchema>(
      {url: `/business/terms-and-conditions-addendums/${termsAndConditionsAddendumId}`, method: 'GET', signal
    },
      options);
    }


export const getGetTermsAndConditionsAddendumQueryKey = (termsAndConditionsAddendumId: string,) => {
    return [`/business/terms-and-conditions-addendums/${termsAndConditionsAddendumId}`] as const;
    }


export const getGetTermsAndConditionsAddendumQueryOptions = <TData = Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError = ErrorType<unknown>>(termsAndConditionsAddendumId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTermsAndConditionsAddendumQueryKey(termsAndConditionsAddendumId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>> = ({ signal }) => getTermsAndConditionsAddendum(termsAndConditionsAddendumId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(termsAndConditionsAddendumId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTermsAndConditionsAddendumQueryResult = NonNullable<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>>
export type GetTermsAndConditionsAddendumQueryError = ErrorType<unknown>


export function useGetTermsAndConditionsAddendum<TData = Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError = ErrorType<unknown>>(
 termsAndConditionsAddendumId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTermsAndConditionsAddendum<TData = Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError = ErrorType<unknown>>(
 termsAndConditionsAddendumId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTermsAndConditionsAddendum<TData = Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError = ErrorType<unknown>>(
 termsAndConditionsAddendumId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetTermsAndConditionsAddendum<TData = Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError = ErrorType<unknown>>(
 termsAndConditionsAddendumId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTermsAndConditionsAddendumQueryOptions(termsAndConditionsAddendumId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTermsAndConditionsAddendumSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError = ErrorType<unknown>>(termsAndConditionsAddendumId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTermsAndConditionsAddendumQueryKey(termsAndConditionsAddendumId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>> = ({ signal }) => getTermsAndConditionsAddendum(termsAndConditionsAddendumId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(termsAndConditionsAddendumId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTermsAndConditionsAddendumSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>>
export type GetTermsAndConditionsAddendumSuspenseQueryError = ErrorType<unknown>


export function useGetTermsAndConditionsAddendumSuspense<TData = Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError = ErrorType<unknown>>(
 termsAndConditionsAddendumId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTermsAndConditionsAddendumSuspense<TData = Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError = ErrorType<unknown>>(
 termsAndConditionsAddendumId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTermsAndConditionsAddendumSuspense<TData = Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError = ErrorType<unknown>>(
 termsAndConditionsAddendumId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetTermsAndConditionsAddendumSuspense<TData = Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError = ErrorType<unknown>>(
 termsAndConditionsAddendumId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTermsAndConditionsAddendum>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTermsAndConditionsAddendumSuspenseQueryOptions(termsAndConditionsAddendumId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const deleteTermsAndConditionsAddendum = (
    termsAndConditionsAddendumId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/business/terms-and-conditions-addendums/${termsAndConditionsAddendumId}`, method: 'DELETE'
    },
      options);
    }


