import {
  Avatar as MantineAvatar,
  Stepper as MantineStepper,
} from '@mantine/core'
import {
  forwardRef,
  type HTMLAttributes,
  type Key,
  type ReactNode,
} from 'react'

import { KuiIcon, type KuiIconType } from './KuiIcon/KuiIcon'
import { KuiPad } from './KuiPad'
import { KuiTextBits } from './KuiTextBits'

type KuiStepIconColor = 'constructive' | 'default'

export type KuiStep = {
  key: Key

  stepNumber: number
  iconType?: KuiIconType

  label: ReactNode
  description?: string | { bits: ReactNode[] } | null

  //** @default 'default' */
  color?: KuiStepIconColor

  children?: React.ReactNode
}

type KuiStepsProps = {
  steps: KuiStep[]
}

export function KuiSteps({ steps }: KuiStepsProps) {
  return (
    <MantineStepper
      active={-1}
      iconSize={32}
      orientation='vertical'
      styles={{
        root: {
          // offset top padding from first step
          marginTop: '-5px',
        },
        stepDescription: { color: 'unset', fontSize: 'unset' },
        step: { width: '100%' },
        stepBody: { flexGrow: 1 },
      }}
    >
      {steps.map((step, index) => (
        <MantineStepper.Step
          component='div'
          data-kui-step-key={step.key}
          key={step.key ?? index}
          label={step.label}
          icon={
            step.iconType ? (
              <KuiIcon type={step.iconType} size='m' />
            ) : (
              step.stepNumber
            )
          }
          description={
            (step.description || step.children) && (
              <div>
                {step.description && (
                  <KuiTextBits
                    color='hushed'
                    size='sm'
                    bits={
                      typeof step.description === 'string'
                        ? [step.description]
                        : step.description.bits
                    }
                  />
                )}

                {step.children && (
                  <KuiPad verticalSize='xs'>{step.children}</KuiPad>
                )}
              </div>
            )
          }
          styles={{
            step: {
              marginTop: 0,
              paddingTop: '5px',
            },
            stepLabel:
              !step.description && !step.children
                ? {
                    marginTop: '8px',
                    height: '16px',
                    display: 'flex',
                    alignItems: 'center',
                  }
                : undefined,
            stepIcon:
              step.color === 'constructive'
                ? {
                    color: 'white',
                    backgroundColor: 'var(--mantine-color-green-filled)',
                    borderColor: 'var(--mantine-color-green-filled)',
                  }
                : undefined,
          }}
        />
      ))}
    </MantineStepper>
  )
}

type KuiStepIconMarkerColor =
  | 'default'
  | 'constructive'
  | 'accent'
  | 'secondaryAccent'

const stepIconColorMap: Record<
  KuiStepIconMarkerColor,
  { color: string; backgroundColor: string; hasBorder: boolean }
> = {
  default: {
    color: 'var(--mantine-color-gray-7)',
    backgroundColor: 'var(--mantine-color-gray-1',
    hasBorder: true,
  },
  constructive: {
    color: 'white',
    backgroundColor: 'var(--mantine-color-green-filled)',
    hasBorder: false,
  },
  accent: {
    color: 'var(--mantine-color-blue-light-color)',
    backgroundColor: 'rgb(236,243,252)',
    hasBorder: true,
  },
  secondaryAccent: {
    color: 'var(--mantine-color-violet-light-color)',
    backgroundColor: 'rgb(241,237,253)',
    hasBorder: true,
  },
}

type KuiStepIconSize = 'xs' | 'sm' | 'md'

export type KuiStepIconProps = Pick<
  HTMLAttributes<HTMLDivElement>,
  'onPointerLeave' | 'onPointerEnter'
> & {
  /** @default 'default' */
  color?: KuiStepIconMarkerColor

  /** @default 'sm' */
  size?: KuiStepIconSize

  /** @default false */
  hasBorder?: boolean

  icon:
    | {
        type: 'icon'
        iconType: KuiIconType
      }
    | {
        type: 'number'
        number: number
      }
    | null

  _customColor?: string
}

export const KuiStepIcon = forwardRef<HTMLDivElement, KuiStepIconProps>(
  (
    {
      icon,
      size = 'sm',
      color = 'default',
      hasBorder = false,
      _customColor,
      ...rest
    },
    ref
  ) => {
    return (
      <MantineAvatar
        {...rest}
        ref={ref}
        color={
          !icon
            ? stepIconColorMap[color].backgroundColor
            : stepIconColorMap[color].color
        }
        bd='var(--mantine-color-gray-7)'
        size={size}
        styles={{
          root: {
            '--avatar-placeholder-fz': 'var(--mantine-font-size-sm)',
          },
          placeholder: {
            backgroundColor: stepIconColorMap[color].backgroundColor,
            borderColor:
              hasBorder && stepIconColorMap[color].hasBorder
                ? _customColor ?? stepIconColorMap[color].color
                : stepIconColorMap[color].backgroundColor,
            borderWidth: icon ? '1px' : '2px',
          },
        }}
      >
        {icon?.type === 'number'
          ? icon.number
          : icon && (
              <KuiIcon type={icon.iconType} size={size === 'xs' ? 'xs' : 's'} />
            )}
      </MantineAvatar>
    )
  }
)
