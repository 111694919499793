import { useDebouncedCallback } from '@mantine/hooks'
import { Spotlight, type SpotlightStore } from '@mantine/spotlight'
import { useState } from 'react'
import { Link, type To } from 'react-router-dom'
import styled from 'styled-components'

import { KuiBadge, type KuiBadgeProps } from './KuiBadge'
import { KuiFlex } from './KuiFlex'
import { KuiIcon } from './KuiIcon/KuiIcon'
import { KuiText } from './KuiText'
import { KuiTextBits, type KuiTextBitsProps } from './KuiTextBits'

type ParsedItem = {
  key: string
  label: string
  to: To
  onClick?: () => void

  badgeProps?: KuiBadgeProps

  description?: string | Pick<KuiTextBitsProps, 'bits'>
}

type ParseItemFn<TItemSingle> = (item: TItemSingle) => ParsedItem

type KuiSpotlightProps<TItem> = {
  store: SpotlightStore
  items: TItem[]
  parseItem: ParseItemFn<TItem>
  onSearchDebounced?: (nextSearch: string) => void
  loading?: boolean
}

export function KuiSpotlight<TItem>({
  store,
  items,
  parseItem,
  loading: loadingItems,
  onSearchDebounced: consumerOnSearchDebounced,
}: KuiSpotlightProps<TItem>) {
  const [query, setQueryInternal] = useState('')

  const [debouncedSearch, setDebouncedSearch] = useState<string>('')
  const isDebouncing = consumerOnSearchDebounced && query !== debouncedSearch

  const onSearchDebounced = useDebouncedCallback((nextSearch: string) => {
    setDebouncedSearch(nextSearch)
    consumerOnSearchDebounced?.(nextSearch)
  }, 200)

  const loading = loadingItems || isDebouncing
  const isEmpty = items.length === 0

  return (
    <Spotlight.Root
      store={store}
      query={query}
      onQueryChange={setQuery}
      scrollable={true}
      maxHeight={350}
    >
      <Spotlight.Search
        placeholder='Search…'
        leftSection={<KuiIcon type='search' />}
      />

      <Spotlight.ActionsList>
        {items.map((item, index) => {
          const parsedItem = parseItem(item)

          return (
            <KuiSpotlightAction
              key={parsedItem.key}
              parsedItem={parsedItem}
              onPointerMove={() => handleActionHover(index)}
            />
          )
        })}

        {loading && isEmpty && <Spotlight.Empty>Loading…</Spotlight.Empty>}

        {!loading && isEmpty && (
          <Spotlight.Empty>
            {query ? 'Nothing found…' : 'Search for anything…'}
          </Spotlight.Empty>
        )}
      </Spotlight.ActionsList>
    </Spotlight.Root>
  )

  function setQuery(nextSearch: string) {
    setQueryInternal(nextSearch)

    if (consumerOnSearchDebounced) {
      onSearchDebounced(nextSearch)
    }
  }

  function handleActionHover(index: number) {
    // Adapted from https://github.com/mantinedev/mantine/blob/5fa987c04147094330d10b34b3ff055bac008b7e/packages/%40mantine/spotlight/src/spotlight.store.ts#L83
    const state = store.getState()
    const actionsList = state.listId
      ? findElementByQuerySelector(`#${state.listId}`)
      : null

    const selected =
      actionsList?.querySelector<HTMLButtonElement>('[data-selected]')

    selected?.removeAttribute('data-selected')

    const actions =
      actionsList?.querySelectorAll<HTMLButtonElement>('[data-action]') ?? []

    actions[index]?.setAttribute('data-selected', 'true')

    store.updateState((state) => ({ ...state, selected: index }))
  }
}

const KuiSpotlightActionRoot = styled(Spotlight.Action)`
  &:hover {
    background-color: inherit;
  }

  &:where([data-selected]) {
    background-color: var(--mantine-color-gray-1) !important;
    color: unset;
  }
`

function KuiSpotlightAction({
  parsedItem,
  onPointerMove,
}: {
  parsedItem: ParsedItem
  onPointerMove: () => void
}) {
  return (
    <KuiSpotlightActionRoot
      onPointerMove={onPointerMove}
      component={Link}
      // @ts-ignore
      to={parsedItem.to}
      onClick={parsedItem.onClick}
    >
      <div>
        <KuiFlex gapSize='xs'>
          <KuiText.div>{parsedItem.label}</KuiText.div>

          {parsedItem.badgeProps && <KuiBadge {...parsedItem.badgeProps} />}
        </KuiFlex>

        {parsedItem.description && (
          <KuiTextBits
            as='div'
            size='sm'
            color='hushed'
            bits={
              typeof parsedItem.description === 'string'
                ? [parsedItem.description]
                : parsedItem.description.bits
            }
          />
        )}
      </div>
    </KuiSpotlightActionRoot>
  )
}

// Copied from https://github.com/mantinedev/mantine/blob/5fa987c04147094330d10b34b3ff055bac008b7e/packages/%40mantine/spotlight/src/spotlight.store.ts#L55
function findElementByQuerySelector<T extends HTMLElement>(
  selector: string,
  root: Document | Element | ShadowRoot = document
): T | null {
  // Directly try to find the element in the current root.
  const element = root.querySelector<T>(selector)
  if (element) {
    return element
  }

  // Iterate through all children of the current root.
  const children =
    root instanceof ShadowRoot ? root.host.children : root.children
  for (let i = 0; i < children.length; i += 1) {
    const child = children[i]

    // Recursively search in the child's shadow root if it exists.
    if (child.shadowRoot) {
      const shadowElement = findElementByQuerySelector<T>(
        selector,
        child.shadowRoot
      )
      if (shadowElement) {
        return shadowElement
      }
    }

    // Also, search recursively in the child itself if it does not have a shadow root or the element wasn't found in its shadow root.
    const nestedElement = findElementByQuerySelector<T>(selector, child)
    if (nestedElement) {
      return nestedElement
    }
  }

  // Return null if the element isn't found in the current root or any of its shadow DOMs.
  return null
}
