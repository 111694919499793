import { Progress, RemoveScroll, Title } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { type FormHTMLAttributes, type ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import { kuiThemeVars } from './_internal/theme'
import {
  KuiActionList,
  KuiActionListItem,
  type KuiAction,
} from './KuiActionList'
import { KuiFlex } from './KuiFlex'
import { KuiGrid } from './KuiGrid'
import { mixKuiPad } from './KuiPad'

const KuiFullPageRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  max-height: auto;
  flex: 0 0 100%;
  width: 100%;
`

const KuiFullPageHeader = styled.div`
  background-color: ${kuiThemeVars.colors.mediumShade};
  border-bottom: 1px solid var(--mantine-color-gray-3);

  flex-shrink: 0;
`

const KuiFullPageBody = styled.section`
  height: 100%;
  overflow-y: auto;
  ${mixKuiPad({ size: 'md' })};
`

const KuiFullPageFooter = styled.div`
  border-top: 2px solid var(--mantine-color-gray-3);
  flex-shrink: 0;
  ${mixKuiPad({ size: 'md' })}
`

export type KuiFullPageProps = {
  title: string
  leftAction?: KuiAction
  actions?: KuiAction[]

  footerActions?: KuiAction[]

  progress?: number

  children: ReactNode

  rightContent?: ReactNode

  formProps?: Pick<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'>
}

export function KuiFullPage({
  title,
  leftAction,
  actions = [],
  footerActions = [],
  progress,
  formProps,
  children,
  rightContent,
}: KuiFullPageProps) {
  const { ref: headerRef, height: headerHeight } = useElementSize()

  return (
    <RemoveScroll>
      <KuiFullPageRoot
        {...(formProps
          ? {
              as: 'form',
              ...formProps,
            }
          : {})}
      >
        <Helmet>
          <title>{title} — Moab</title>
        </Helmet>

        <KuiFullPageHeader ref={headerRef}>
          <KuiFlex
            justifyContent='spaceBetween'
            gapSize='xs'
            padding={{
              size: 'xs',
              rightSize: 'md',
            }}
          >
            <KuiFlex gapSize='xs'>
              {leftAction && <KuiActionListItem size='m' action={leftAction} />}

              <Title order={4}>{title}</Title>
            </KuiFlex>

            {actions.length > 0 && <KuiActionList size='m' actions={actions} />}
          </KuiFlex>

          {typeof progress === 'number' && (
            <Progress
              value={progress}
              radius={0}
              color='var(--mantine-color-green-filled)'
            />
          )}
        </KuiFullPageHeader>

        <KuiFullPageBody>
          {rightContent ? (
            <KuiGrid columns={2} gapSize='md' _style={{ position: 'relative' }}>
              {children}

              <div
                style={{
                  position: 'sticky',
                  top: 0,
                  height: `calc(100vh - ${headerHeight}px - 1px - 2 * ${kuiThemeVars.spacingSizes.md})`, // - 1px for border
                }}
              >
                {rightContent}
              </div>
            </KuiGrid>
          ) : (
            children
          )}
        </KuiFullPageBody>

        {footerActions.length > 0 && (
          <KuiFullPageFooter>
            <KuiActionList
              size='m'
              variant='outline'
              actions={footerActions}
              fullWidth={true}
            />
          </KuiFullPageFooter>
        )}
      </KuiFullPageRoot>
    </RemoveScroll>
  )
}
