import { omit } from 'lodash-es'

import { KuiButton, type KuiButtonProps } from 'components/kui/KuiButton'
import { KuiFlex } from 'components/kui/KuiFlex'

import { type KuiThemeSpacingSize } from './_internal/theme'
import { KuiMenu, type KuiMenuItem, type KuiMenuProps } from './KuiMenu'

export type KuiButtonAction = Omit<KuiButtonProps, 'size' | 'children'> & {
  label?: string
}

export type KuiAction = KuiButtonAction | KuiMenuProps

export type KuiActionListProps = Pick<
  KuiButtonProps,
  'size' | 'variant' | 'fullWidth'
> & {
  /** @default 'sm' */
  gapSize?: KuiThemeSpacingSize
  actions: KuiAction[]
  menuItems?: KuiMenuItem[]
}

export function KuiActionList({
  gapSize = 'sm',
  actions,
  menuItems = [],
  ...commonProps
}: KuiActionListProps) {
  if (menuItems.length === 0 && actions.length === 0) {
    return null
  }

  return (
    <KuiFlex gapSize={gapSize} wrap={true}>
      {menuItems.length > 0 && (
        <KuiMenu
          items={menuItems}
          buttonProps={{
            ...commonProps,
            iconType: 'dots-vertical',
          }}
        />
      )}

      {actions.map((action, index) => (
        <KuiActionListItem key={index} action={action} {...commonProps} />
      ))}
    </KuiFlex>
  )
}

export function KuiActionListItem({
  action,
  fullWidth,
  ...commonProps
}: Pick<KuiButtonProps, 'size' | 'variant' | 'fullWidth'> & {
  action: KuiAction
}) {
  if ('items' in action) {
    return (
      <KuiMenu
        {...action}
        buttonProps={{
          ...commonProps,
          ...action.buttonProps,
        }}
      />
    )
  }

  return (
    <KuiButton
      {...commonProps}
      {...omit(action, 'label', 'fullWidth')}
      children={action.label}
      _style={{ flexGrow: action.fullWidth ?? fullWidth ? 1 : 0 }}
    />
  )
}
