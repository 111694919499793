import { lazily } from 'react-lazily'

import {
  getItemReceiptGetQueryOptions,
  getPurchaseOrderGetQueryOptions,
  getVendorBillGetQueryOptions,
} from '_autogenerated'
import { purchaseOrderUrls } from 'routes/urls'
import { createRoute } from 'routing'

const {
  PurchaseOrderListPage,
  PurchaseOrderCreatePage,
  PurchaseOrderDetailPageRoot,
  PurchaseOrderDetailPageDetailsTab,
  PurchaseOrderDetailPageActivityTab,
  PurchaseOrderEditPage,
  VendorBillCreatePage,
  VendorBillEditPage,
  VendorBillDetailPage,
  ItemReceiptCreatePage,
  ItemReceiptEditPage,
  ItemReceiptDetailPage,
  ItemReceiptSerializationPage,
  ItemReceiptSerializeProductPage,
} = lazily(() => import('./components'))

export const purchaseOrderRoutes = [
  createRoute({
    route: purchaseOrderUrls,
    Component: PurchaseOrderListPage,
  }),
  createRoute({
    route: purchaseOrderUrls.create,
    Component: PurchaseOrderCreatePage,
  }),
  createRoute({
    route: purchaseOrderUrls.detail,
    Component: PurchaseOrderDetailPageRoot,
    prefetch: ({ id }) => [getPurchaseOrderGetQueryOptions(id)],
    children: [
      createRoute({
        route: purchaseOrderUrls.detail,
        Component: PurchaseOrderDetailPageDetailsTab,
      }),
      createRoute({
        route: purchaseOrderUrls.detail.activity,
        Component: PurchaseOrderDetailPageActivityTab,
      }),
    ],
  }),
  createRoute({
    route: purchaseOrderUrls.edit,
    Component: PurchaseOrderEditPage,
    prefetch: ({ id }) => [getPurchaseOrderGetQueryOptions(id)],
  }),
  createRoute({
    route: purchaseOrderUrls.bills.detail,
    Component: VendorBillDetailPage,
    prefetch: ({ vendorBillId }) => [
      getVendorBillGetQueryOptions(vendorBillId),
    ],
  }),
  createRoute({
    route: purchaseOrderUrls.itemReceipts.detail,
    Component: ItemReceiptDetailPage,
    prefetch: ({ itemReceiptId }) => [
      getItemReceiptGetQueryOptions(itemReceiptId),
    ],
  }),
]

export const purchaseOrderFullPageRoutes = [
  createRoute({
    route: purchaseOrderUrls.bills.create,
    Component: VendorBillCreatePage,
    fullPage: true,
    prefetch: ({ purchaseOrderId }) => [
      getPurchaseOrderGetQueryOptions(purchaseOrderId),
    ],
  }),
  createRoute({
    route: purchaseOrderUrls.bills.edit,
    Component: VendorBillEditPage,
    fullPage: true,
    prefetch: ({ purchaseOrderId, vendorBillId }) => [
      getPurchaseOrderGetQueryOptions(purchaseOrderId),
      getVendorBillGetQueryOptions(vendorBillId),
    ],
  }),
  createRoute({
    route: purchaseOrderUrls.itemReceipts.create,
    Component: ItemReceiptCreatePage,
    fullPage: true,
    prefetch: ({ purchaseOrderId }) => [
      getPurchaseOrderGetQueryOptions(purchaseOrderId),
    ],
  }),
  createRoute({
    route: purchaseOrderUrls.itemReceipts.edit,
    Component: ItemReceiptEditPage,
    fullPage: true,
    prefetch: ({ purchaseOrderId, itemReceiptId }) => [
      getPurchaseOrderGetQueryOptions(purchaseOrderId),
      getItemReceiptGetQueryOptions(itemReceiptId),
    ],
  }),
  createRoute({
    route: purchaseOrderUrls.itemReceipts.serialize,
    Component: ItemReceiptSerializationPage,
    fullPage: true,
    prefetch: ({ itemReceiptId }) => [
      getItemReceiptGetQueryOptions(itemReceiptId),
    ],
  }),
  createRoute({
    route: purchaseOrderUrls.itemReceipts.serialize.product,
    Component: ItemReceiptSerializeProductPage,
    fullPage: true,
    prefetch: ({ itemReceiptId }) => [
      getItemReceiptGetQueryOptions(itemReceiptId),
    ],
  }),
]
